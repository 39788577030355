import React, {Component} from 'react';
import {connect} from "react-redux";
import toast from '../Toast';
import queryString from "query-string";
import {Link, Redirect} from "react-router-dom";
import {isEmpty, isUndefined} from "lodash";

// Services
import {GET, PATCH} from "../services/rest.service";
import PageLoader from "../components/PageLoader";
import history from "../helpers/history";
import {setRecord} from "../services/indexdb.service";
import {APPEND_NANNY_REGISTRATION} from "../redux/nanny-registration/NannyRegistrationReducer";
import {LOGIN_SUCCESS} from "../redux/user/UserActions";

const VerificationSuccess = ({message, onLogin}) => (
    <div className="text-center mb-5">
      <h4>{message}</h4>
      <br/>
      <button onClick={onLogin} className="btn btn-signup">View Your Profile</button>
    </div>
);

const VerificationFailure = ({message}) => (
    <div className="text-center mb-5">
      <h4>{message}</h4>
      <br/>
      <Link to={'/'} className="btn btn-signup">Home</Link>
    </div>
);


class VerifyComponent extends Component {

  doVerifyPassword = (payload) => {
    const {dispatch} = this.props;
    const verify$ = PATCH('/users/verify', payload);
    verify$
        .then(({access_token, data}) => {
          toast.success('Verification Completed! Please login to continue');
          localStorage.setItem('token', access_token);
          return GET('/users/me');
        })
        .then((res) => {
          // dispatch({
          //   type: LOGIN_SUCCESS,
          //   payload: {
          //     isLoggedIn: true,
          //     user: res.data,
          //     type: res.data.type,
          //     message: 'logged in successfully'
          //   }
          // });
          this.setState({
            user: res.data,
            status: true,
            message: `${res.data.first_name} ${res.data.last_name}, your account is now active.`,
          });
        })
        .catch(err => {
          this.setState({
            isVerifying: false,
            status: false,
            message: err.message
          });
        })
  };

  doLogin = () => {
    const {dispatch} = this.props;
    const {user} = this.state;
    const access_token = localStorage.getItem('token');

    if (user.register_last_step > 0) {
      setRecord('TT', access_token);
      setRecord('LCAR', true);
      setRecord('UD', user);
      const step = user.register_last_step;
      toast.success('Pick up where you left of to complete your profile');
      localStorage.setItem('reg_auth', JSON.stringify({email: user.email, _id: user._id}));
      dispatch({
        type: APPEND_NANNY_REGISTRATION,
        payload: {
          first_name: user.first_name,
          last_name: user.last_name,
          email: user.email,
          location: user.location,
          mobile_number: user.mobile_number,
          _id: user._id
        }
      });
      switch (step) {
        case 1:
          history.push('/nanny/profile');
          break;
        case 2:
          history.push('/nanny/skills');
          break;
        case 3:
          history.push('/nanny/references');
          break;
        case 4:
          history.push('/nanny/authorization');
          break;
        default:
          history.push('/nanny/login');
          break;
      }
    } else {
      localStorage.setItem('token', access_token);
      dispatch({
        type: LOGIN_SUCCESS,
        payload: {
          isLoggedIn: true,
          user: user,
          type: user.type,
          message: 'logged in successfully'
        }
      });
      history.push(`/${user.type == 'Family' ? 'family' : 'nanny'}/account`);
    }
  };

  constructor(props) {
    super(props);
    this.state = {
      isInvalidRoute: false,
      isVerifying: '',
      status: '',
      message: '',
      user: {}
    };
  }

  componentDidMount() {
    const queryParams = this.props.location.search;

    const {id, token} = queryString.parse(queryParams);

    if ((isEmpty(token) || isUndefined(token)) && (isEmpty(id) || isUndefined(id))) {
      this.setState({isInvalidRoute: true});
    } else {
      const payload = {
        activation_token: token,
        id,
      };
      this.doVerifyPassword(payload);
    }
  }

  render() {
    const {url} = this.props.match;
    const {isVerifying, status, message} = this.state;

    let verificationContent = null;

    if (this.state.isInvalidRoute) {
      return <Redirect from={`${url}`} to={'/'}/>
    }

    if (isVerifying) {
      verificationContent = <PageLoader title="Verifying... Please wait"/>;
    }

    if (!isVerifying && status === true) {
      verificationContent = <VerificationSuccess message={message} onLogin={this.doLogin}/>;
    }

    if (!isVerifying && status === false) {
      verificationContent = <VerificationFailure message={message}/>;
    }

    return (
        <div className="page-content page-auth">
          <div className="container">
            <section className="section bg-auth">
              <div className="row">
                <div className="col-12 col-lg-7 col-xl-6 col-xll-5 mx-auto px-xll-4 px-xl-4">
                  <div className="text-center">
                    <h2 className="heading">{status ? 'Welcome!' : 'Email Verification'}</h2>

                  </div>
                  {verificationContent}
                </div>
              </div>
            </section>
          </div>
        </div>
    )
  }
}

export default connect()(VerifyComponent);
