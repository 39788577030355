import React from 'react';
import config from "../../../config";
import Thumbnail from "../../../components/ThumbnailComponent";

const SingleSkill = ({skill}) => (
    <div className="skill-box">
      <div className="card">
        <img src={`${config.api.URL}${skill.poster.name}`} className="card-img-top" alt=""/>
        <div className="card-body">
          <Thumbnail className="skills-icon" source={skill.icon.name}/>
          <h5 className="card-title">{skill.title}</h5>
          <p className="card-description">{skill.description}</p>
        </div>
      </div>
    </div>
);

export default SingleSkill;
