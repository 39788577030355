import React, {Component} from 'react'
import {connect} from "react-redux";
import {Formik} from "formik";
import {object, ref, string} from 'yup';
import {Link} from "react-router-dom";
import ReactTelInput from 'react-telephone-input';
import {find, findIndex, isEmpty, isObject, isNull, isString, isUndefined} from "lodash";
import toast from '../../Toast';
import FormikObserver from 'formik-observer';
// Components
import PageLoader from "../../components/PageLoader";
import ReferencesForm from "../../components/ReferencesForm";
import TextInput from "../../components/UI/TextInput";
import TextareaInput from "../../components/UI/TextareaInput";
import Checkbox from "../../components/UI/Checkbox";
import Thumbnail from "../../components/ThumbnailComponent";
import FormikPlaceSearch from "../../components/UI/FormikPlaceSearch";
import PasswordInput from "../../components/UI/PasswordInput";
// services
import {PERSON_NAME} from "../../helpers/constants";
import {GET, PATCH, POST, PUT} from "../../services/rest.service";
import {convertJsonToFormData} from "../../helpers/helper";
import {hideLoading, showLoading} from "../../redux/loader/LoaderActions";
import {getMe} from "../../redux/user/UserActions";
// Images
import flag from '../../assets/images/flags.png';
import attachment from '../../assets/images/svg/attachment.svg';
import other from '../../assets/images/svg/favorites-button.svg';
import autofill from '../../assets/images/svg/arrow-left-fill.svg';
import nanny from "../../assets/images/svg/nanny.svg";
import sitter from "../../assets/images/svg/sitter.svg";


const EditPersonalForm = (props) => (
    <Formik initialValues={{
      first_name: props.values.first_name,
      last_name: props.values.last_name,
      email: props.values.email,
      about_nanny: props.values.about_nanny,
      mobile_number: props.values.mobile_number,
      location: isObject(props.values.location) ? props.values.location.address : props.values.location,
    }}
            onSubmit={(values) => {
              props.onSubmit(values);
            }}
            validationSchema={object().shape({
              email: string().email("Enter a valid email").required("Email is required"),
              first_name: string().required("Please enter your First Name").matches(PERSON_NAME, 'Please enter a valid first name'),
              last_name: string().required("Please enter your Last Name").matches(PERSON_NAME, 'Please enter a valid last name'),
              mobile_number: string().required("Please enter your phone number"),
              about_nanny: string(),
              location: string(),
              password: string()
                  .min(6, "Password must contain at least 6 characters")
                  .required("Enter your password"),
            })}>
      {renderProps => {
        const {values, touched, errors, handleChange, isValid, setFieldValue, handleBlur, handleSubmit,setFieldTouched,setFieldError} = renderProps;
        return (
            <form className="row">
              <TextInput id="first_name"
                         type="text"
                         size="col-12 col-md-6"
                         label="First Name"
                         placeholder="Please enter first name"
                         error={touched.first_name && errors.first_name}
                         value={values.first_name}
                         onChange={handleChange}
                         onBlur={handleBlur}/>
              <TextInput id="last_name"
                         type="text"
                         size="col-12 col-md-6"
                         label="Last Name"
                         placeholder="Please enter last name"
                         error={touched.last_name && errors.last_name}
                         value={values.last_name}
                         onChange={handleChange}
                         onBlur={handleBlur}/>
              <TextInput id="email"
                         type="email"
                         size="col-12 col-md-6"
                         label="Email"
                         placeholder="Please enter your email"
                         error={touched.email && errors.email}
                         value={values.email}
                         onChange={handleChange}
                         onBlur={handleBlur}/>
              <div className="form-group col-12 col-md-6">
                <label className="form-label">Mobile Number</label>
                <ReactTelInput defaultCountry="us"
                               value={values.mobile_number}
                               flagsImagePath={flag}
                               onChange={(e) => setFieldValue('mobile_number', e)}
                               onBlur={value => {
                                setFieldTouched('mobile_number', true);
                                setFieldError('mobile_number', value.error);
                                }}

                />
              </div>
              <div className="col-12 col-md-6">
                <FormikPlaceSearch id="location"
                                   name="location"
                                   type="search"
                                   label="Location"
                                   placeholder="Select your location"
                                   error={touched.location && errors.location}
                                   value={values.location}
                                   onChange={(e) => setFieldValue('location', e)}
                                   onBlur={handleBlur}/>
                <FormikObserver debounce={1000} onChange={({values}) => {
                  props.onFormChange(values);
                }}/>
              </div>
              <TextareaInput
                id="about_nanny"
                name="about_nanny"
                size="col-12 col-md-12"
                label="About Me"
                placeholder="Tell us something about yourself"
                error={touched.about_nanny && errors.about_nanny}
                value={values.about_nanny}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </form>
        )
      }}
    </Formik>
);

const ChangePasswordForm = (props) => (
    <Formik initialValues={{
      oldPassword: '',
      newPassword: '',
      confirmNewPassword: ''
    }} onSubmit={(values) => {
      props.onSubmit(values);
    }}
            validationSchema={object().shape({
              oldPassword: string("").required("Please enter your current password"),
              newPassword: string("")
                  .required("Enter your password")
                  .min(6, "Password must contain at least 6 characters"),
              confirmNewPassword: string().required("Please confirm your password").oneOf([ref("newPassword")], "Passwords does not match")
            })}>
      {renderProps => {
        const {values, touched, errors, handleChange, isValid, handleBlur, handleSubmit} = renderProps;
        return (
            <form className="row" onSubmit={handleSubmit}>
              <PasswordInput id="oldPassword"
                             size="col-12"
                             label="Current Password"
                             placeholder="Enter current password"
                             error={touched.oldPassword && errors.oldPassword}
                             value={values.oldPassword}
                             onChange={handleChange}
                             onBlur={handleBlur}/>

              <PasswordInput id="newPassword"
                             size="col-12 col-md-6"
                             label="New Password"
                             placeholder="Enter new password"
                             error={touched.newPassword && errors.newPassword}
                             value={values.newPassword}
                             onChange={handleChange}
                             onBlur={handleBlur}/>

              <PasswordInput id="confirmNewPassword"
                             size="col-12 col-md-6"
                             label="Repeat New Password"
                             placeholder="Confirm your new password"
                             error={touched.confirmNewPassword && errors.confirmNewPassword}
                             value={values.confirmNewPassword}
                             onChange={handleChange}
                             onBlur={handleBlur}/>

              <div className="form-group col-12 text-center mt-5">
                <button type="submit" className="btn btn-outline-primary">change password</button>
              </div>
            </form>
        )
      }}
    </Formik>
);

class NannyAccountUpdateComponent extends Component {


  /* CHANGE PASSWORD CODE ENDS HERE */

  handleNannyChangePassword = (formData) => {
    const {dispatch} = this.props;
    dispatch(showLoading('Please wait...'));
    PATCH(`user/changePassword`, formData)
        .then((res) => {
          localStorage.setItem('token', res.access_token);
          dispatch(hideLoading());
          this.props.history.push('/nanny/account');
          toast.success('Password changed successfully! You will be logged out from everywhere else');
        })
        .catch(err => {
          dispatch(hideLoading());
          toast.error(err.message)
        })
  };


  /* SUBMIT */
  doUpdateProfile = () => {
    const {user} = this.props.user;
    const {dispatch} = this.props;
    const {first_name, last_name, email, mobile_number, location, reference, profile_picture, about_nanny} = this.state;
    let type = this.state.type.length === 2 ? 'Both' : this.state.type[0];
    const currentLocation = JSON.parse(localStorage.getItem('location'));

    const payload = {
      user_id: user._id,
      first_name: first_name ? first_name : user.first_name,
      last_name: last_name ? last_name : user.last_name,
      email: email ? email : user.email,
      mobile_number: mobile_number ? mobile_number : user.mobile_number,
      about_nanny: about_nanny ? about_nanny : user.nanny.about_nanny,
      location: isNull(currentLocation) ? user.location : currentLocation,
      reference,
      skills: this.processSkills(this.state.selectedSkills),
      type,
      other_skills: [],
      profile_picture: profile_picture ? profile_picture : user.nanny.profile_picture,
    };

    if(payload.about_nanny ===null)
    {
payload.about_nanny=""

    }
    

    payload.other_skills = this.state.isOtherAvailable ? [{
      title: this.state.otherSkillDetails.title,
      document: this.state.otherSkillDetails.document
    }] : [];

    dispatch(showLoading('Updating Profile. Please wait...'));
    PUT(`/users/${user._id}/nanny/edit`, payload)
        .then(() => {
          return GET('/users/me');
        })
        .then(res => {
          toast.success('Profile Updated');
          dispatch(hideLoading());
          dispatch(getMe(res));
        })
        .catch(err => {
          dispatch(hideLoading());
          toast.error(err.message);
        })
  };

  /* SUBMIT ENDS */

  /* PROFILE PICTURE RELATED CODE */

  /* PERSONAL DETAILS */
  handlePersonalDetailsChanges = (e) => {
    this.setState({
      ...this.state,
      ...e,
    })
  };

  /* PROFILE PICTURE RELATED CODE ENDS HERE */


  /* SKILLS RELATED */

  handleFileChange = (files) => {
    const {dispatch} = this.props;

    const payload = {
      profile_picture: files[0]
    };

    const formData = convertJsonToFormData(payload);

    dispatch(showLoading('Updating Profile Picture...'));
    POST('/auth/user/nannyProfileUpload', formData)
        .then(res => {
          dispatch(hideLoading());
          toast.success('Profile picture uploaded');
          this.setState({selectedFile: files[0], profile_picture: res.data});
        })
        .catch(err => {
          dispatch(hideLoading());
          toast.error(err.message);
        })
  };

  processSkills = (skills) => {
    return this.state.selectedSkills.map((x, idx) => {
      return {
        skill_id: x.skill_id._id,
        document: x.document,
      };
    });

  };

  handleSkillNameChange = (e) => {
    this.setState({
      otherSkillDetails: {
        ...this.state.otherSkillDetails,
        title: e.target.value
      }
    })
  };

  handleSkillCheck = (e) => {
    const value = {
      skill_id: {_id: e.target.value, title: e.target.name},
      document: {}
    };
    const preValues = this.state.selectedSkills;
    if (e.target.checked) {
      this.setState({
        selectedSkills: [...preValues, value]
      })
    } else {
      let index = preValues.findIndex(x => x.skill_id._id === e.target.value);
      this.setState({
        selectedSkills: [
          ...preValues.slice(0, index),
          ...preValues.slice(index + 1)
        ]
      })
    }
  };

  handleOtherSkillCheck = (e) => {
    this.setState({
      isOtherAvailable: !this.state.isOtherAvailable,
      otherSkillDetails: {
        title: '',
        document: ''
      }
    });
  };

  handleDocChange = (event) => {
    const id = event.target.name;
    const document = event.target.files[0];
    const preValues = this.state.selectedSkills;

    // THIS IS THE SKILL
    let skill = find(preValues, (x) => x.skill_id._id === id);

    // THIS IS THE INDEX OF THE SELECTED SKILL
    const index = findIndex(preValues, (x) => x.skill_id._id === id);

    // Uploading File Document
    if (document.size < 10000000) {
      const payload = convertJsonToFormData({skill_document: document});
      POST('/auth/user/nannyFileUpload', payload)
          .then(res => {
            // Adding doc file to skill
            skill.document = res.data.document;
            // skill.documentName = document.name;
            this.setState({
              selectedSkills: Object.assign([], preValues, {[index]: skill})
            });
          })
          .catch(err => {
            toast.error(err.message);
          });
    } else {
      toast.error('This document is too large, please upload another. Maximum allowed size is 10MB');
    }
  };

  handleOtherFileChange = (e) => {
    const document = e.target.files[0];
    const payload = convertJsonToFormData({skill_document: document});
    POST('/auth/user/nannyFileUpload', payload)
        .then(res => {
          this.setState({
            otherSkillDetails: {
              title: this.state.otherSkillDetails.title,
              document: res.data.document
            }
          });
        })
        .catch(err => {
          toast.error(err.message);
        })

  };

  /* SKILLS RELATED CODE ENDS */

  /* REFERENCES */

  removeDocument = (id) => {
    const preValues = this.state.selectedSkills;

    // THIS IS THE SKILL
    let skill = find(preValues, (x) => x.skill_id._id === id);

    // THIS IS THE INDEX OF THE SELECTED SKILL
    const index = findIndex(preValues, (x) => x.skill_id._id === id);

    // Adding doc file to skill
    skill.document = {};
    skill.documentName = '';
    this.setState({
      selectedSkills: Object.assign([], preValues, {[index]: skill})
    });
  };

  /* REFERENCES CODE ENDS HERE */

  handleRefChanges = (values) => {
    let reference = [];
    if (values.ref1name && values.ref1phone_number) {
      reference.push({name: values.ref1name, phone_number: values.ref1phone_number, email: values.ref1email})
    }
    if (values.ref2name && values.ref2phone_number) {
      reference.push({name: values.ref2name, phone_number: values.ref2phone_number, email: values.ref2email})
    }
    if (values.ref3name && values.ref3phone_number) {
      reference.push({name: values.ref3name, phone_number: values.ref3phone_number, email: values.ref3email})
    }
    if (values.ref4name && values.ref4phone_number && values.ref4email) {
      reference.push({name: values.ref4name, phone_number: values.ref4phone_number, email: values.ref4email});
    }
    if (values.ref5name && values.ref5phone_number && values.ref5email) {
      reference.push({name: values.ref5name, phone_number: values.ref5phone_number, email: values.ref5email});
    }
    this.setState({
      reference
    });
  };

  /* SETTING PROFILE TYPE */

  initializeType = (type) => {
    if (type == 'Both') {
      return ['Nanny', 'Sitter'];
    } else if (isUndefined(type) || type.length === 0) {
      return [];
    } else {
      let arr = [];
      arr.push(type);
      return arr;
    }
  };

  /* PROFILE TYPE ENDS HERE */

  handleTypeChange = (e) => {
    const value = e.target.checked;
    if (value) {
      const nextValue = this.state.type.concat(e.target.value);
      this.setState({type: nextValue});
    } else {
      const nextValue = this.state.type.filter(value => value !== e.target.value);
      this.setState({type: nextValue});
    }
  };

  setStateFromProp = (user) => {
    const otherSkill = user.nanny.other_skills[0];
    this.setState({
      selectedSkills: user.nanny.skills,
      reference: user.nanny.reference,
      type: this.initializeType(user.type),
      otherSkillDetails: {
        title: !isEmpty(otherSkill) ? otherSkill.title : '',
        document: !isEmpty(otherSkill) ? otherSkill.document : {}
      },
      isOtherAvailable: !isEmpty(otherSkill),
    })
  };


  constructor(props) {
    super(props);
    this.state = {
      first_name: '',
      last_name: '',
      location: '',
      about_nanny: '',
      email: '',
      type: [],
      mobile_number: '',
      selectedSkills: [],
      reference: [],
      isOtherAvailable: false,
      otherSkillDetails: {
        title: '',
        document: {},
      },
      selectedFile: null
    };
  }

  componentWillReceiveProps(nextProps) {
    const {user} = nextProps.user;
    if (!isEmpty(user)) {
      this.setStateFromProp(user);
    }

  }

  componentDidMount() {
    localStorage.removeItem('location');
    const {user} = this.props.user;
    if (!isEmpty(user)) {
      this.setStateFromProp(user);
    }
  }


  render() {
    const {user} = this.props.user;

    if (isEmpty(user)) {
      return <PageLoader/>
    }

    return (
        <div className="page-details  page-content page-auth page-family-detail">
          <div className="container">
            <div className="mb-4 mb-sm-5 bp-2">
              <Link className="text-primary font-14" to={'/nanny/account'}>
                <img className="align-middle mr-2" width="24" height="24" src={autofill} alt={user.first_name}/>
                Back to Profile
              </Link>
            </div>
            <section className="section p-0">
              <div className="row">
                <div className="col-12 col-lg-4 col-xl-4 wow fadeInUp">
                  <div className="nannies-profile-avatar text-center bg-lightdark py-5">
                    <label htmlFor="avatar" className="position-relative">
                      <Thumbnail className="nannies-profile-image" source={user.nanny.profile_picture.name} file={this.state.selectedFile}/>
                      <span className="cam-icon"></span>
                    </label>
                    <input id="avatar" type="file" name="avatar" className="d-none" accept="image/png, image/jpeg"
                           onChange={(e) => this.handleFileChange(e.target.files)}/>
                  </div>
                </div>
                <div className="col-12 col-lg-8">
                  <div className="row row-20 mx-0 mt-4 mt-xs-5 mt-lg-0 bg-lightdark py-5">
                    <div className="col-11 col-lg-10 mx-auto">
                      <div className="wow fadeInUp" data-wow-delay=".5s">
                        <div className="text-center">
                          <h2 className="heading">Edit personal details</h2>
                        </div>
                        <div className="row row-20 mt-5">
                          <div className="col-12">
                            <EditPersonalForm values={{
                              first_name: user.first_name,
                              last_name: user.last_name,
                              email: user.email,
                              mobile_number: user.mobile_number,
                              about_nanny: user.nanny && user.nanny.about_nanny,
                              location: user.location.address
                            }} onFormChange={this.handlePersonalDetailsChanges}/>
                          </div>
                        </div>
                      </div>
                      <label className="form-label">CHOOSE YOUR TYPE</label>
                      <div className="row row-20 select-skills">
                        <div className="col-6 col-md-4 col-lg-4 col-xl-3 col-lg-4">
                          <input type="checkbox" name={'type'} id={'nanny'} value={'Nanny'} className="select-item-chk"
                                 checked={this.state.type.includes('Nanny') || this.state.type.includes('Both')}
                                 onChange={this.handleTypeChange}/>
                          <label className="select-skills-item" htmlFor={'nanny'}>
                            <img src={nanny} className="img-fluid select-skills-icon" alt={'nanny'}/>
                            <span className="select-skills-name">Nanny</span>
                          </label>
                        </div>
                        <div className="col-6 col-md-4 col-lg-4 col-xl-3 col-lg-4">
                          <input type="checkbox" name={'type'} id={'sitter'} value={'Sitter'}
                                 className="select-item-chk"
                                 checked={this.state.type.includes('Sitter') || this.state.type.includes('Both')}
                                 onChange={this.handleTypeChange}/>
                          <label className="select-skills-item" htmlFor={'sitter'}>
                            <img src={sitter} className="img-fluid select-skills-icon" alt={'sitter'}/>
                            <span className="select-skills-name">Sitter</span>
                          </label>
                        </div>
                      </div>
                      <div>
                        <div className="text-center mt-5">
                          <h2 className="heading pt-4">Edit Elite skills</h2>
                        </div>
                        <div className="row row-20 select-skills">
                          {
                            this.props.skills.map((x, index) => {
                              return <Checkbox key={index}
                                               uniqueId={x._id}
                                               id={x.title.toLowerCase()}
                                               checked={this.state.selectedSkills.findIndex(skill => skill.skill_id._id === x._id) > -1}
                                               image={x.icon.name}
                                               onChange={this.handleSkillCheck}
                                               name={x.title.toLowerCase()}
                                               label={x.title}/>
                            })
                          }
                          <div className="col-6 col-md-4 col-lg-4 col-xl-3 col-lg-4">
                            <input type="checkbox" name="other_skill" id="other_skill"
                                   value={this.state.isOtherAvailable} checked={this.state.isOtherAvailable}
                                   className="select-item-chk" onChange={this.handleOtherSkillCheck}/>
                            <label className="select-skills-item" htmlFor="other_skill">
                              <img src={other} className="img-fluid select-skills-icon" alt="other_skill"/>
                              <span className="select-skills-name">Other</span>
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="wow fadeInUp" data-wow-delay=".5s">
                        <div className="text-center mt-5">
                          <h2 className="heading">Qualifications</h2>
                        </div>
                        <div className="row row-20 mt-5">
                          {
                            this.state.selectedSkills.map((doc, index) => {
                              return <div key={index} className="form-group col-12 form-group-btn">
                                <label className="form-label">{doc.skill_id.title}</label>
                                <div className="input-group append-left">
                                  <div className="input-group-append">
                                    <button className="btn btn-clear btn-lg text-primary px-4" type="button">
                                      <img width="15" height="19" src={attachment} alt="attachment"/>
                                    </button>
                                  </div>
                                  <input type="file" className="form-control d-none" id={doc.skill_id._id}
                                         name={doc.skill_id._id} onChange={this.handleDocChange}/>
                                  <label htmlFor={doc.skill_id._id} className="form-control">
                                    <span>{!isEmpty(doc.document) ? 'Document Uploaded' : 'Please provide skill document'}</span>
                                  </label>
                                  {!isEmpty(doc.document) && <button className="btn btn-clear min-w-auto py-0 px-2"
                                                                     onClick={() => this.removeDocument(doc.skill_id._id)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="12px" height="12px"
                                         viewBox="0 0 64 64">
                                      <path fill="#D80027"
                                            d="M28.941 31.786L.613 60.114c-.787.787-.787 2.062 0 2.849.393.394.909.59 1.424.59.516 0 1.031-.196 1.424-.59l28.541-28.541 28.541 28.541c.394.394.909.59 1.424.59.515 0 1.031-.196 1.424-.59.787-.787.787-2.062 0-2.849L35.064 31.786 63.41 3.438c.787-.787.787-2.062 0-2.849-.787-.786-2.062-.786-2.848 0L32.003 29.15 3.441.59C2.654-.196 1.38-.196.593.59c-.787.787-.787 2.062 0 2.849l28.348 28.347z"/>
                                    </svg>
                                  </button>}
                                </div>
                              </div>
                            })
                          }
                          {
                            this.state.isOtherAvailable &&
                            <div className="d-flex flex-wrap w-100">
                              <div className="col-12 col-md-5 form-group">
                                <label className="form-label">Other Skill's Name</label>
                                <input className="form-control"
                                       value={this.state.otherSkillDetails.title}
                                       onChange={this.handleSkillNameChange}/>
                              </div>
                              <div className="col-12 col-md-7 form-group form-group-btn">
                                <label className="form-label">Other Skill's Document</label>
                                <div className="input-group append-left">
                                  <div className="input-group-append">
                                    <button className="btn btn-clear btn-lg text-primary px-4" type="button">
                                      <img width="15" height="19" src={attachment} alt="attachment"/>
                                    </button>
                                  </div>
                                  <input type="file" className="form-control d-none" name="other" id="otherSkill"
                                         onChange={this.handleOtherFileChange}/>
                                  <label htmlFor="otherSkill" className="form-control"><span>
                                  {this.state.otherSkillDetails.document && !isEmpty(this.state.otherSkillDetails.document)
                                      ? 'Document Uploaded'
                                      : 'Upload your credential document'}</span></label>
                                  {this.state.otherSkillDetails.document && !isEmpty(this.state.otherSkillDetails.document)
                                  && <button className="btn btn-clear min-w-auto py-0 px-2"
                                             onClick={(e) => this.setState({
                                               otherSkillDetails: {
                                                 ...this.state.otherSkillDetails,
                                                 document: {}
                                               }
                                             })}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="12px" height="12px"
                                         viewBox="0 0 64 64">
                                      <path fill="#D80027"
                                            d="M28.941 31.786L.613 60.114c-.787.787-.787 2.062 0 2.849.393.394.909.59 1.424.59.516 0 1.031-.196 1.424-.59l28.541-28.541 28.541 28.541c.394.394.909.59 1.424.59.515 0 1.031-.196 1.424-.59.787-.787.787-2.062 0-2.849L35.064 31.786 63.41 3.438c.787-.787.787-2.062 0-2.849-.787-.786-2.062-.786-2.848 0L32.003 29.15 3.441.59C2.654-.196 1.38-.196.593.59c-.787.787-.787 2.062 0 2.849l28.348 28.347z"/>
                                    </svg>
                                  </button>}
                                </div>
                              </div>
                            </div>
                          }
                        </div>
                      </div>
                      <div>
                        <div className="text-center mt-5">
                          <h2 className="heading">Edit references</h2>
                        </div>
                        <ReferencesForm showSubmit={false} onFormChange={this.handleRefChanges}
                                        values={user.nanny.reference}/>
                      </div>
                      <div className="form-group text-center mt-5">
                        <button className="btn btn-outline-primary" onClick={this.doUpdateProfile}>SAVE</button>
                      </div>
                    </div>
                  </div>
                  <div className="bg-light-5 mt-4 mt-xs-5 py-5 py-xs-3">
                    <div className="col-11 col-lg-10 mx-auto">
                      <div className="text-center">
                        <h2 className="heading">Change Password</h2>
                      </div>
                      <div>
                        <ChangePasswordForm onSubmit={this.handleNannyChangePassword}/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    skills: state.skills,
  };
};

export default connect(mapStateToProps, null)(NannyAccountUpdateComponent);
