import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";

import logo from '../../src/assets/images/logo.svg';
import inalogo from '../../src/assets/images/ina.png';
import sealLogo from '../../src/assets/images/seal.png';
import { currentYear } from '../helpers/helper';
class FooterComponent extends Component {
  render() {
    return (
      <footer className="wow fadeIn">
        <div className="container px-xll-0 footer-item-list">
          <div className="row">
            <div className="col-footer">
              <div className="footer-item">
                <h3 className="footer-heading mt-0">
                  <img src={logo} className="img-fluid" alt="Footer Logo" width="130" />
                </h3>
                <ul className="list-unstyled list-group">
                  <li className="list-inline-item">
                    <Link to={'/'}>Home</Link>
                  </li>
                  <li className="list-inline-item">
                    <Link to={'/our-nannies'}>Our Nannies</Link>
                  </li>
                  <li className="list-inline-item">
                    <Link to={'/about-us'}>About Us</Link>
                  </li>
                  <li className="list-inline-item">
                    <Link to={'/contact-us'}>Contact</Link>
                  </li>
                  <li className="list-inline-item">
                    <a href="https://blog.elitenannies.com">Blog</a>
                  </li>
                  <li className="list-inline-item">
                    <Link to={'/privacy-policy'}>Privacy Policy</Link>
                  </li>
                  <li className="list-inline-item">
                    <Link to={'/terms-of-use'}>Terms Of Use</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-footer">
              <div className="footer-item footer-item-menu">
                <h3 className="footer-heading">FOR FAMILIES</h3>
                <ul className="list-unstyled list-group">
                  <li className="list-inline-item">
                    <Link to={'/family/nanny-pricing'}>Nanny Placement Package Fee</Link>
                  </li>
                  {/*<li className="list-inline-item">*/}
                  {/*<Link to={'/family/sitter-pricing'}>Sitter Pricing Packages</Link>*/}
                  {/*</li>*/}
                  <li className="list-inline-item">
                    <Link to={'/family/screening-process'}>The Screening Process</Link>
                  </li>

                  <li className="list-inline-item">
                    <a href="https://blog.elitenannies.com/category/family-blog/">Family Blog</a>
                  </li>

                </ul>
              </div>
            </div>
            <div className="col-footer">
              <div className="footer-item footer-item-menu">
                <h3 className="footer-heading">FOR NANNIES</h3>
                <ul className="list-unstyled list-group">
                  <li className="list-inline-item">
                    <Link to={'/nanny/why-choose'}>Why Elite Nannies</Link>
                  </li>
                  <li className="list-inline-item">
                    <Link to={'/nanny/how-it-works'}>How It Works</Link>
                  </li>
                  <li className="list-inline-item">
                    <Link to={'/nanny/requirements'}>Requirements</Link>
                  </li>
                  <li className="list-inline-item">
                    <Link to={'/nanny/jobs'}>
                      Jobs
                    </Link>
                  </li>
                  <li className="list-inline-item">
                    <a href="https://blog.elitenannies.com/category/nanny-blog">Nanny Blog</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-footer">
              <div className="footer-item footer-item-menu">
                <h3 className="footer-heading">GET STARTED</h3>
                <ul className="list-unstyled list-group">
                  <li className="list-inline-item">
                    <Link to={'/family/registration'}>Family Sign Up</Link>
                  </li>
                  <li className="list-inline-item">
                    <Link to={'/family/login'}>Family Login</Link>
                  </li>
                  <li className="list-inline-item">
                    <Link to={'/nanny/registration'}>Join the Elite</Link>
                  </li>
                  <li className="list-inline-item">

                    <span><a href="tel: +1 (310)-955-4820">Phone: +1 (310)-955-4820</a></span>
                  </li>
                 
                </ul>
              </div>
            </div>
            <div className="col-footer">
              <div className="footer-item">
                <h3 className="footer-heading">Follow us on</h3>
                <ul className="list-unstyled list-inline">
                  <li className="list-inline-item">
                    <a rel="noopener noreferrer" target="_blank" href="https://www.facebook.com/elite.nannies">
                      <span className="social-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 486.736 486.736" width="12"
                          height="15"><path
                            d="M382.1 0h-56.953c-43.574 0-74.872 10.343-93.893 31.022-19.027 20.686-28.532 51.151-28.532 91.408v38.373c0 10.141-8.219 18.36-18.36 18.36H164.68c-10.141 0-18.36 8.219-18.36 18.36v57.503c0 10.141 8.219 18.36 18.36 18.36h19.682c10.141 0 18.36 8.219 18.36 18.36v236.666c0 10.141 8.219 18.36 18.36 18.36h76.414c10.141 0 18.36-8.22 18.36-18.36V291.747c0-10.142 8.22-18.36 18.36-18.36h38.592c10.142 0 19.224-8.176 20.289-18.256l6.095-57.712c1.065-10.085-6.291-18.256-16.433-18.256H334.21c-10.141 0-18.36-8.219-18.36-18.36v-28.752c0-7.075.331-12.552.997-16.426.661-3.868 2.154-7.681 4.48-11.445 2.319-3.757 6.083-6.358 11.279-7.797 5.195-1.438 12.111-2.16 20.734-2.16h28.752c10.141 0 18.359-8.219 18.359-18.36V18.36C400.459 8.219 392.24 0 382.1 0z" /></svg>
                      </span>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a rel="noopener noreferrer" target="_blank" href="https://www.instagram.com/elite.nannies">
                      <span className="social-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 512 512"><path
                          d="M352 0H160A160 160 0 0 0 0 160v192a160 160 0 0 0 160 160h192a160 160 0 0 0 160-160V160A160 160 0 0 0 352 0zm112 352c0 61.8-50.2 112-112 112H160c-61.8 0-112-50.2-112-112V160C48 98.2 98.2 48 160 48h192c61.8 0 112 50.2 112 112v192z" /><path
                            d="M256 128a128 128 0 1 0 0 256 128 128 0 0 0 0-256zm0 208a80.1 80.1 0 0 1 0-160 80 80 0 0 1 0 160z" /><circle
                            cx="393.6" cy="118.4" r="17.1" /></svg>
                      </span>
                    </a>
                  </li>
                </ul>
                <div className="border-top pt-3">
                  <img src={inalogo} className="img-fluid" alt="Footer Logo" width="75" />
                  <img src={sealLogo} className="img-fluid" alt="Footer Logo" width="75" />

                </div>
              </div>
            </div>
          </div>
          <p className="text-copyright">&copy; {currentYear()} Elite Nannies</p>
        </div>
      </footer>
    )
  }
}

export default withRouter(FooterComponent);
