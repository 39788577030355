import React, {Component} from 'react';
import {Link} from "react-router-dom";
import ImgSkillNightNurse from '../../assets/images/night-nurse.jpg';

import history from '../../helpers/history';
import {connect} from "react-redux";

class NurseDetails extends Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  goBack = () => {
    history.push('/our-nannies');
  };

  render() {
    const { isLoggedIn } = this.props.user;

    return (
      <div className="page-content mt-0">
        <section className="section section-skills">
          <div className="container mt-2 mt-md-4 mt-lg-5 px-xll-0">
            <div className="row">
              <div className="col-12 w-100 wow fadeInRight">
                <button className="btn btn-clear btn-back-skills p-0" onClick={this.goBack}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="26" viewBox="0 0 31.494 31.494">
                    <path
                      d="M10.273 5.009a1.112 1.112 0 0 1 1.587 0 1.12 1.12 0 0 1 0 1.571l-8.047 8.047h26.554c.619 0 1.127.492 1.127 1.111s-.508 1.127-1.127 1.127H3.813l8.047 8.032c.429.444.429 1.159 0 1.587a1.112 1.112 0 0 1-1.587 0L.321 16.532a1.12 1.12 0 0 1 0-1.571l9.952-9.952z"
                      fill="#7f7f7f"/>
                  </svg>
                </button>
              </div>
              <div className="col-12 col-lg-6 wow fadeInLeft">
                <h2 className="heading mb-4 d-block d-lg-none">Nurse</h2>
                <img src={ImgSkillNightNurse} className="w-100 img-fluid" alt=""/>
              </div>
              <div className="col-12 col-lg-6 col-xl-5 ml-auto  wow fadeIn">
                <div className="skill-content pt-0 pt-5 py-md-5">
                  <h2 className="heading mb-4 d-none d-lg-block">Nurse</h2>
                  <p>Nannies with a background in nursing are a wonderful asset to any family, but they’re especially
                    comforting for first-time parents. They will provide the reassurance you need navigating those
                    precious early months of life. As your children grow older, they will continue to provide peace of
                    mind if your children fall ill.
                  </p>
                  <p>Our Nurses provide extraordinary care while maintaining a safe environment. As with all of our
                    nannies, they are CPR certified and experienced with administering medication. Whether it’s
                    attending to medical or behavioral challenges, our nannies put safety first and foremost.
                  </p>
                  <p>We search for nurses who have a true passion for working with special needs children, only hiring
                    those with big hearts, who provide an elevated level of patience, compassion, and empathy.
                  </p>
                  <p>Not only are our Nurses well-versed in the medical side, they happily work alongside speech and
                    language specialists, physical and occupational therapists and teachers. They are all naturally
                    collaborative, and team-oriented.
                  </p>
                  <p>Our Nurses have completed at least an Associate’s Degree in Nursing (ADN) or a Bachelor of
                    Nursing/Bachelor of Science in Nursing (BSN) either in the US or internationally.
                  </p>
                  <p>Although our candidates are licensed nurses, it may be outside their scope of practice to provide
                    specialized medical attention for your children, however, they are experts in health assessments and
                    have the clinical skills necessary for implementing early interventions if your child requires
                    urgent medical attention. </p>
                </div>
              </div>
              {/*<div className="col-12 w-100 mt-0 mt-lg-5 text-center wow fadeInUp">*/}
                {/*<Link to={'/family/registration'} className="btn btn-skill btn-lg">START YOUR SEARCH</Link>*/}
                {/*/!*<Link to={'/search/nannies?skills=Night%20Nurse'} className="btn btn-skill btn-lg">START YOUR SEARCH</Link>*!/*/}
              {/*</div>*/}
            </div>
          </div>
        </section>
        <section className="section pt-0">
          <div className="container">
            <div className="row wow fadeInUp">
              <div className="col-12 col-md-12 col-lg-6 col-xl-6">
                <ul className="list-group list-icon">
                  <li className="list-group-item">Assisting and supporting breastfeeding</li>
                  <li className="list-group-item">Feeding unassisted if using expressed breastmilk or formula</li>
                  <li className="list-group-item">Breast pump preparation, cleaning and sterilization</li>
                  <li className="list-group-item">Breastmilk storage</li>
                  <li className="list-group-item">Formula preparation</li>
                  <li className="list-group-item">Bottle washing and sterilization</li>
                  <li className="list-group-item mb-0">Burping</li>
                </ul>
              </div>
              <div className="col-12 col-md-12 col-lg-6 col-xl-6">
                <ul
                  className="list-group list-icon border-left border-c-light pl-lg-5 pt-lg-0 pt-md-4 pt-sm-4 border-md-0">
                  <li className="list-group-item">Diapering</li>
                  <li className="list-group-item">Bathing (if required)</li>
                  <li className="list-group-item">Swaddling and settling to sleep</li>
                  <li className="list-group-item">Baby laundry</li>
                  <li className="list-group-item">Restocking the nursery, diapers etc.</li>
                  <li className="list-group-item mb-0">Documenting the nightly events and open communication with you
                    as parents
                  </li>
                </ul>
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-12 col-xl-10 pt-5">
                <p>We can tailor our services to your specific family needs – we will consider even the little things.
                  For example, if you are breastfeeding, would you prefer to go to the nursery to breastfeed or would
                  you prefer the night nurse to bring the baby to you to breastfeed in bed? You can discuss every
                  detail of care when you interview your prospective night nanny.</p>
              </div>
            </div>
          </div>
        </section>
        <section className="section section-applynow bg-light">
          <div className="container">
            <div className="banner-caption">
              <div className="row wow fadeInUp">
                <div className="col-12 col-xl-10 col-xll-8 mx-auto">
                  <div className="banner-text">
                    <h2 className="heading">Want A Qualified Nurse As Your Nanny?</h2>
                    <Link to={!isLoggedIn ? '/family/registration' : '/family/preferences'} className="btn btn-outline-primary">START YOUR SEARCH</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }
}

const MapStateToProps = (state) => {
  return {
    user: state.user
  }
};

export default connect(MapStateToProps, null)(NurseDetails);
