import React, {Component} from 'react';
import {connect} from "react-redux";
// IMAGES
import thankYou from '../../assets/images/svg/thankyou.svg';

class JobThankYouComponent extends Component {
  constructor(props) {
    super(props);
  }

  handleSubmit = () => {
      this.props.history.push('/nanny/jobs');
  };

  render() {
    return (
      <div className="page-content page-auth">
          <div className="container">
            <section className="section bg-auth">
              <div className="row">
                <div className="col-12 col-lg-8 col-xl-6 col-xll-6 mx-auto wow fadeInUp">
                  <div className="text-center">
                    <h2 className="heading">
                      THANK YOU!
                    </h2>
                    <p className="px-md-5">We have received your job application and will reach out to you soon.</p>
                  </div>
                  <div className="form-group">
                    <img className="email-icon img-fluid" src={thankYou} alt="thankYou"/>
                  </div>
                  <div className="form-group text-center mt-5 mb-0">
                    <button className="btn btn-outline-primary mt-3" onClick={this.handleSubmit}>ok</button>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
    )
  }
}

const MapStateToProps = (state) => {
  return {
    user: state.user
  }
};

export default connect(MapStateToProps, null)(JobThankYouComponent);
