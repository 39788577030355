import React from 'react';
import ReactDOM from 'react-dom';

import {Provider} from "react-redux";
import {Router} from 'react-router-dom';
/* HELPERS */
import history from './helpers/history';
import ScrollToTop from "./components/ScrollToTop";
/* COMPONENTS */
import App from './App';
/* VENDOR */
import './vendor';

import * as serviceWorker from './serviceWorker';
import 'raf/polyfill';
// React-Redux store
import store from "./redux/store";
import ReactGA from 'react-ga';

// ReactGA.initialize('UA-140448100-1');
// history.listen((location, action) => {
//     ReactGA.pageview(location.pathname + location.search);
// });

const trackPageView = location => {
  // ReactGA.set({ page: location.pathname });
  ReactGA.pageview(location.pathname);
};

const initGa = history => {
  ReactGA.initialize('UA-140448100-1');
  trackPageView(history.location);
  history.listen(trackPageView);
};

initGa(history);

const root = (
    <Provider store={store}>
      <Router history={history}>
        <ScrollToTop>
          <App/>
        </ScrollToTop>
      </Router>
    </Provider>
);

const rootElement = document.getElementById("root");
ReactDOM.render(root, rootElement);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
