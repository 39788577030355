import React, {Component} from 'react';
import {Link, Redirect} from "react-router-dom";
import queryString from 'query-string';
import toast from '../../Toast';
import {isEmpty, isUndefined} from 'lodash';

import ResetPasswordForm from "./ResetPasswordForm";
import {connect} from "react-redux";
import {PUT} from "../../services/rest.service";
import {hideLoading, showLoading} from "../../redux/loader/LoaderActions";

class ResetPasswordComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      id: '',
      token: '',
      isResetSuccessful: false,
      isInvalidRoute: false,
    };
  }

  handleResetPassword = (formData) => {
    const {dispatch} = this.props;
    const payload = {
      ...formData,
      id: this.state.id,
      reset_token: this.state.token,
    };
    const reset$ = PUT('/users/resetPassword', payload);
    dispatch(showLoading('Resetting Password...'));
    reset$
        .then(res => {
          dispatch(hideLoading());
          this.setState({isResetSuccessful: true});
          toast.success('Reset password successful! Please login to continue');
        })
        .catch(err => {
          dispatch(hideLoading());
          this.setState({isResetSuccessful: false, message: err.message});
          toast.error(err.message);
        })

  };

  componentDidMount() {
    const queryParams = this.props.location.search;
    const {id, token} = queryString.parse(queryParams);

    if ((isEmpty(token) || isUndefined(token)) && (isEmpty(id) || isUndefined(id))) {
      this.setState({isInvalidRoute: true});
    } else {
      this.setState({id, token});
    }

  }

  render() {
    const {url} = this.props.match;
    const {isLoggedIn} = this.props.user;

    if(isLoggedIn) {
      return <Redirect from={url} to={'/'} />
    }

    if(this.state.isInvalidRoute) {
      return <Redirect from={`${url}`} to={'/'} />
    }

    if(this.state.isResetSuccessful) {
      return <Redirect from={`${url}`} to={'/'}/>
    }

    return(
        <div className="page-content page-auth">
          <div className="container">
            <section className="section bg-light">
              <div className="row">
                <div className="col-12 col-lg-7 col-xl-6 col-xll-5 mx-auto px-xll-4 px-xl-4">
                  <div className="text-center">
                    <h2 className="heading">Reset Password</h2>
                    <p>&nbsp;</p>
                  </div>
                  <ResetPasswordForm onSubmit={this.handleResetPassword}/>
                  <div className="form-group text-center">
                    <p>Already have an account? <Link className="link-auth" to={`/family/login`}>Sign In</Link></p>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
    )
  }
}

const MapStateToProps = (state) => {
  return {
    user: state.user
  }
};

export default connect(MapStateToProps, null)(ResetPasswordComponent);
