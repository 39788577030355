import React from 'react';
import config from '../../config';

// Functional Components
const Checkbox = (props) => (
    <div className="col-6 col-md-4 col-lg-4 col-xl-3 col-lg-4">
      <input type="checkbox" name={props.name} id={props.id} value={props.uniqueId} checked={props.checked}
             className="select-item-chk"
             onChange={props.onChange}/>
      <label className="select-skills-item" htmlFor={props.id}>
        <img src={config.api.URL + props.image} className="img-fluid select-skills-icon" alt={props.name}/>
        <span className="select-skills-name">{props.label}</span>
      </label>
    </div>
);

export default Checkbox;