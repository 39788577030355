import React, { Component, Fragment } from 'react';
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import LocationGray from '../../assets/images/svg/location-gray.svg';
import { Helmet } from 'react-helmet';
import { Select } from 'semantic-ui-react';
import { JOB_LOCATION_CITIES } from '../../helpers/constants';
import { GET } from '../../services/rest.service';
import moment from "moment";
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import placeholder from '../../assets/images/search-placeholder.svg';
import attachment from '../../assets/images/svg/attachment.svg';
import TextInput from '../../components/UI/TextInput';
import ReactTelInput from 'react-telephone-input';
import flag from '../../assets/images/flags.png';
import { POST } from "../../services/rest.service";
import { toast } from "react-toastify";
import { convertJsonToFormData } from '../../helpers/helper';
import { Formik } from "formik";
import { object, string } from 'yup';
import history from '../../helpers/history';
import PageLoader from '../../components/PageLoader';
import NotFound from '../NotFound';
import { isNull } from 'util';
class IndividualJobComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      location: 'All',
      jobs: [],
      applyModal: false,
      modalData: {},
      name: "",
      email: "",
      phone: "",
      resume: {},
      cover: {},
      htmlData: "",
      showindetails: false,
      id: "",
      records: null,
      ready:false,

    };
  }
  toggleApplyModal = (x) => {
    const { user } = this.props;
    this.setState(prevState => ({
      applyModal: !prevState.applyModal,
      modalData: prevState.applyModal ? {} : x,
      name: prevState.applyModal ? "" : user.isLoggedIn ? (user.user.first_name + " " + user.user.last_name) : "",
      email: prevState.applyModal ? "" : user.isLoggedIn ? user.user.email : "",
      phone: prevState.applyModal ? "" : user.isLoggedIn ? user.user.mobile_number : "",
      resume: {},
      cover: {}
    }));
  }
  componentWillUnmount() {
    document.title = "Elite Nannies";

  }
  componentDidMount() {
    const slug = this.props.match.params.type;

    GET(`/public/job-posting/${slug}`)
      .then((res) => {
        this.setState({
          records: res.data,
          ready:true
        })
      })
  }



  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }
  handleChangePhoneNumber = (e) => {
    this.setState({
      phone: e
    })
  }

  handleResumeChange = (e) => {
    const document = e.target.files[0];
    const payload = convertJsonToFormData({ skill_document: document });
    POST('/auth/user/nannyFileUpload', payload)
      .then(res => {
        this.setState({
          resume: {
            mimeType: res.data.document.mimeType,
            name: res.data.document.name,
            filename: document.name
          }
        });
      })
      .catch(err => {
        toast.error(err.message);
      })

  };

  handleCoverChange = (e) => {
    const document = e.target.files[0];
    const payload = convertJsonToFormData({ skill_document: document });
    POST('/auth/user/nannyFileUpload', payload)
      .then(res => {
        this.setState({
          cover: {
            mimeType: res.data.document.mimeType,
            name: res.data.document.name,
            filename: document.name
          }
        });
      })
      .catch(err => {
        toast.error(err.message);
      })

  };

  handleForm = (values) => {


    if (this.state.resume.name === undefined && this.state.resume.mimeType === undefined) {
      return toast.error("Please Provide Resume");
    }
    // if (this.state.cover.name === undefined && this.state.cover.mimeType === undefined) {
    //   return toast.error("Please Provide Cover Letter");
    // }
    else {
      const payload =
      {
        "jobPosting": this.state.modalData && this.state.modalData._id,
        "name": values.name,
        "email": values.email,
        "phone": values.phone,
        "resume": {
          "mimeType": this.state.resume.mimeType,
          "name": this.state.resume.name,
        },
        "coverLetter": {
          "mimeType": this.state.cover.mimeType || '',
          "name": this.state.cover.name || '',
        }
      }

      POST('/job-application/create', payload)
        .then((res) => {
          // toast.success("Your Application is Successfully Submitted!")
          this.toggleApplyModal();
          history.push('/nanny/jobs-thankyou');

        })
        .catch(err => {
          toast.error(err);
        })
    }


  }


  showinDetails = (data, x) => {
    this.setState({
      id: x._id,
      showindetails: true,
    })
  }


  render() {
    const { records } = this.state;
    if (!this.state.ready) {
      return <PageLoader />
    }
    if (isNull(records)) {
      return <NotFound />
    }


    const structuredJSON = {
      "@context": "https://schema.org/",
      "@type": "JobPosting",
      "title": this.state.records.title,
      "description": this.state.records.jobDescription,
      "datePosted": moment(this.state.records.createdAt).format('YYYY-MM-DD'),
      "validThrough": "",
      "employmentType": this.state.records.type,
      "hiringOrganization": {
        "@type": "Organization",
        "name": "Elitenannies",
        "sameAs": "https://www.elitenannies.com",
        "logo": "http://www.example.com/images/logo.png"
      },
      "jobLocation": {
        "@type": "Place",
        "address": {
          "@type": "PostalAddress",
          "streetAddress": records.location.address,
          "addressLocality":records.location.city,
          "addressRegion": records.location.address.split(",")[1].trim(),
          "postalCode": records.zipcode,
          "addressCountry": "US"
        }
      },
      // "baseSalary": {
      //   "@type": "MonetaryAmount",
      //   "currency": "USD",
      //   "value": {
      //     "@type": "QuantitativeValue",
      //     "value": 40.00,
      //     "unitText": "HOUR"
      //   }
      // }
    };

    return (
      <Fragment>
        <div className="page-content">
          <Helmet>
            <title>{records.title}</title>
            <meta name="title" content={records.title} />
            <meta name="description"
              content="High paid nanny jobs in New York and Los Angeles with high profile and loving families. Find part-time or full-time child care jobs. Elite Nannies provide Elite Jobs with Elite Families - apply for free today! " />
            <meta property="og:type" content="website" />
            <meta property="og:url" content="https://www.elitenannies.com/about-us" />
            <meta property="og:title" content={records.title} />
            <meta property="og:description"
              content="High paid nanny jobs in New York and Los Angeles with high profile and loving families. Find part-time or full-time child care jobs. Elite Nannies provide Elite Jobs with Elite Families - apply for free today! " />
            <meta property="og:image" content="https://www.elitenannies.com/static/media/roxanne-dominic.8f38b02b.jpg" />
            <meta property="twitter:card" content="summary_large_image" />
            <meta property="twitter:url" content="https://www.elitenannies.com/about-us" />
            <meta property="twitter:title" content={records.title} />
            <meta property="twitter:description"
              content="High paid nanny jobs in New York and Los Angeles with high profile and loving families. Find part-time or full-time child care jobs. Elite Nannies provide Elite Jobs with Elite Families - apply for free today! " />
            <meta property="twitter:image"
              content="https://www.elitenannies.com/static/media/roxanne-dominic.8f38b02b.jpg" />
            <script className='structured-data-list' type="application/ld+json">{JSON.stringify(structuredJSON)}</script>

          </Helmet>
          <section className="section pt-0">
            <div className="container">

              <div className="row wow fadeInUp">
                <div className="col-12 col-lg-11 col-xl-8 mx-auto">
                 <span class="btn-back d-inline-block mb-4 c-pointer" onClick={() => { history.push(`/nanny/jobs`) }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 268.8 268.8">
                      <path d="M3.7 143.3l80 80c2.4 2.4 5.6 3.7 8.8 3.7s6.4-1.2 8.8-3.7c4.9-4.9 4.9-12.8 0-17.7l-58.7-58.7h213.7c6.9 0 12.5-5.6 12.5-12.5s-5.6-12.5-12.5-12.5H42.7l58.7-58.7c4.9-4.9 4.9-12.8 0-17.7-4.9-4.9-12.8-4.9-17.7 0l-80 80c-4.9 5-4.9 12.9 0 17.8z" fill="#f1d1cc"></path></svg>
                  </span>
                  <div className="joblist">

                    <div className="joblist-item border-bottom-0">
                      <div className="d-flex justify-content-between">
                        {/* <p className="post-date">Posted: <strong>{moment(records.createdAt).format('MMMM Do YYYY')}</strong></p> */}
                        <p className="post-date">Status: <strong className={records.status === "Open" ? "text-status-success" : "text-status-filled"}>{records.status}</strong></p>
                      </div>
                      <h2 className="post-title">{records.title}</h2>
                      <ul className="post-list list-inline">
                        <li className="list-inline-item">
                          <span>
                            <img src={LocationGray} width="18" height="18" alt="location img" className="mr-2" />
                          </span>
                          {records.location && records.location.address ? records.location.address.split(",")[0] + ", " + records.location.address.split(",")[1] : ""}
                        </li>
                        <li className="list-inline-item">
                          {records.type}
                        </li>
                        {records.status ==="Open" && moment().isSameOrBefore(moment(records.approxStartDate)) &&   <li className="list-inline-item"> Start Date:  <strong>
                                {moment(records.approxStartDate).format("MMMM Do YYYY")}
                              </strong></li>}
                      </ul>

                      <div className="post-text" dangerouslySetInnerHTML={{ __html: records.jobDescription }} />



                      <div className="post-action">
                        <p className="post-rate">Rate: <strong>{records.rate}</strong></p>

                        {
                          records.status !== "Filled" &&
                          <button className="btn btn-primary text-white text-uppercase btn-lg btn-250" onClick={() => { this.toggleApplyModal(records) }}>Apply Now</button>}
                      </div>
                    </div>


                  </div>
                </div>
              </div>
            </div>
          </section>


        </div>


        <Modal isOpen={this.state.applyModal} centered={true} >
          <ModalHeader className="modal-title font-18 py-3" toggle={this.toggleApplyModal}>
            {this.state.modalData && this.state.modalData.type} Nanny
          <small className="d-block">{this.state.modalData && this.state.modalData.location && this.state.modalData.location.address ? this.state.modalData.location.address.split(",")[0] + ", " + this.state.modalData.location.address.split(",")[1] : ""}</small>
          </ModalHeader>
          <ModalBody className="modal-body">

            <Formik
              initialValues={{
                name: this.state.name,
                email: this.state.email,
                phone: this.state.phone,
              }}
              onSubmit={(values) => {
                this.handleForm(values);
              }}
              validationSchema={object().shape({
                email: string().email("Please enter your valid email").required("Please enter your email"),
                name: string().required("Please enter your name"),
                phone: string().required("Please enter your phone number"),

              })}>
              {renderProps => {
                const { values, touched, errors, handleChange, handleBlur, handleSubmit, setFieldValue, setFieldTouched, setFieldError } = renderProps;

                return (
                  <form onSubmit={handleSubmit}>
                    <div className="form-group">

                      <label className="form-label">Name</label>
                      <TextInput id="name"
                        className="form-control"
                        type="text"
                        placeholder="Please enter name"
                        error={touched.name && errors.name}
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur} />
                    </div>
                    <div className="form-group">

                      <label className="form-label">Email</label>
                      <TextInput id="email"
                        type="email"
                        className="form-control"
                        placeholder="Please enter email"
                        error={touched.email && errors.email}
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur} />
                    </div>

                    <div className="form-group">

                      <label className="form-label">Phone Number</label>
                      <ReactTelInput
                        defaultCountry="us"
                        value={values.phone}
                        flagsImagePath={flag}
                        onChange={(e) => setFieldValue('phone', e)}
                        onBlur={value => {
                          setFieldTouched('phone', true);
                          setFieldError('phone', value.error);
                        }}
                      />
                      {errors.phone && touched.phone && <small className="text-danger">{errors.phone}</small>}

                    </div>

                    <div className="form-group form-group-btn">
                      <label className="form-label">Resume</label>
                      <div className="input-group append-left">
                        <div className="input-group-append">
                          <button className="btn btn-clear btn-lg text-primary px-4" type="button">
                            <img width="15" height="19" src={attachment} alt="attachment" />
                          </button>
                        </div>
                        <input type="file" className="form-control d-none" name="other" id="Resume" onChange={this.handleResumeChange} />

                        <label htmlFor="Resume" className="form-control">  <span className="d-inline-block pt-1">{this.state.resume && this.state.resume.filename ? this.state.resume.filename : 'Upload your Resume'}</span></label>

                      </div>

                    </div>


                    <div className="form-group form-group-btn">
                      <label className="form-label">Cover Letter (Optional)</label>
                      <div className="input-group append-left">
                        <div className="input-group-append">
                          <button className="btn btn-clear btn-lg text-primary px-4" type="button">
                            <img width="15" height="19" src={attachment} alt="attachment" />
                          </button>
                        </div>
                        <input type="file" className="form-control d-none" name="other" id="CoverLetter" onChange={this.handleCoverChange} />
                        <label htmlFor="CoverLetter" className="form-control"> <span className="d-inline-block pt-1">{this.state.cover && this.state.cover.filename ? this.state.cover.filename : 'Upload your Cover Letter'}</span></label>
                      </div>
                    </div>
                    <div className="mt-2 text-right">
                      <button type="button" data-dismiss="modal" className="btn btn-default d-none d-lg-inline-block mb-2 text-uppercase" onClick={this.toggleApplyModal}>Cancel</button>
                      <button type="submit" className="btn btn-primary text-white ml-lg-3 mb-2 text-uppercase">Submit</button>
                    </div>

                  </form>
                )
              }}
            </Formik>


          </ModalBody>
        </Modal>
      </Fragment>
    )
  }
}


const MapStateToProps = (state) => {
  return {
    user: state.user
  }
};

export default connect(MapStateToProps, null)(IndividualJobComponent);

