import React, {Component} from 'react';
import {Formik} from "formik";
import {array, object, string} from 'yup';
import {isArray, isEmpty} from "lodash";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import moment from "moment";
import queryString from "query-string";
import {Select} from "semantic-ui-react";
import DatePicker from "react-datepicker";
import toast from '../../Toast';
import history from '../../helpers/history';
// Services
import {PUT} from "../../services/rest.service";
// Components
import Checkbox from '../../components/UI/Checkbox';
import TextInput from "../../components/UI/TextInput";
import TextareaInput from "../../components/UI/TextareaInput";
import FormikPlaceSearch from "../../components/UI/FormikPlaceSearch";
// Constants
import {AVAILABILITY_OPTIONS, TRANSPORTATION_OPTIONS} from '../../helpers/constants';
import {
  APPEND_FAMILY_REGISTRATION,
  FRESH_FAMILY_REGISTRATION
} from "../../redux/family-registeration/FamilyRegistrationReducer";
import {HIDE_LOADING, SHOW_LOADING} from "../../redux/loader/LoaderActions";


/* MAIN CONTAINER PAGE */
class FamilyRegPreferencesComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      currentStep: 1,
      isBackAvailable: false,
    };
  }

  handleSignUp = (formData) => {

    this.props.showLoading();
    PUT('/auth/user/family_register/request-nanny', formData)
        .then(res => {
          this.props.hideLoading();
          // history.push('/family/nanny-package/payment');
          history.push('/family/thank-you-nanny-request');
        })
        .catch(err => {
          toast.error(err.message);
          this.props.hideLoading();
        })

  };

  changeStep = (step) => {
    this.setState({currentStep: step});
  };

  componentDidMount() {
    const parsed = queryString.parse(this.props.location.search);
    if(!isEmpty(parsed)) {
      if (parsed.utmSrc == 'pmts') {
          this.setState({
            isBackAvailable: true
          })
      }
    }
  }

  handleBack = () => {
    const parsed = queryString.parse(this.props.location.search);
    this.changeStep(this.state.currentStep - 1);

    if(this.state.isBackAvailable) {
        history.goBack();
    }
  }

  render() {
    let {user, isLoggedIn} = this.props.user;
    let {currentStep, isBackAvailable} = this.state;
    const BackButton = (
        <a className="btn btn-back btn-back-inline" href="javascript:void(0);" onClick={this.handleBack}>
          <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 268.8 268.8">
            <path
                d="M3.7 143.3l80 80c2.4 2.4 5.6 3.7 8.8 3.7s6.4-1.2 8.8-3.7c4.9-4.9 4.9-12.8 0-17.7l-58.7-58.7h213.7c6.9 0 12.5-5.6 12.5-12.5s-5.6-12.5-12.5-12.5H42.7l58.7-58.7c4.9-4.9 4.9-12.8 0-17.7-4.9-4.9-12.8-4.9-17.7 0l-80 80c-4.9 5-4.9 12.9 0 17.8z"
                fill="#f1d1cc"/>
          </svg>
        </a>
    );

    return (
        <div className="page-content page-auth">
          <div className="container">
            <section className="section section-request-sitter bg-auth">
              <div className="row">
                <div className="col-12 col-lg-8 col-xl-7 col-xll-7 mx-auto wow fadeInUp">
                  <div className="text-center mb-5">
                    <h2 className="heading">
                      {isBackAvailable && BackButton}
                      {currentStep >= 2 && BackButton}
                      REQUEST A NANNY
                    </h2>
                    {/* <p className="text-note">Select the desired qualifications you want in your nanny.</p> */}
                  </div>
                  <Formik initialValues={{
                            start_date: '',
                            availability: '',
                            no_of_children: '',
                            children_age: '',
                            transport_children: '',
                            additional_info: '',
                            how_soon_start: '',
                            place_of_work: '',
                            skills: [],
                          }}
                          onSubmit={(values) => {
                            this.handleSignUp(values);
                          }}
                          validationSchema={object().shape({
                            start_date: string(),
                            how_soon_start: string(),
                            availability: string(),
                            no_of_children: string(),
                            children_age: string(),
                            transport_children: string(),
                            place_of_work: string(),
                            additional_info: string(),
                            skills: array(),
                          })}>
                    {renderProps => {
                      const {values, touched, errors, handleChange, handleBlur, handleSubmit, setFieldValue} = renderProps;
                      return (
                          <form className="request-sitter-form form-group-30" onSubmit={handleSubmit}>
                            <div className={`${currentStep == 1 ? 'd-block' : 'd-none'}`}>
                              <div className="row">
                                <div className="form-group col-12">
                                  <h4>Approx. Start Date</h4>
                                  <label className="form-label">Select the approximate date you want your nanny to start working.</label>
                                  <DatePicker
                                      className={"form-control"}
                                      placeholderText={"Start Date"}
                                      showYearDropdown
                                      minDate={new Date()}
                                      value={values.start_date}
                                      onChange={(value) => setFieldValue('start_date', moment(value).format('MM/DD/YYYY'))}
                                  />
                                  {errors.start_date &&
                                  <p style={{fontSize: '80%'}} className="text-danger">{errors.start_date}</p>}
                                </div>
                                {/* <div className="form-group col-12 form-group-btn">
                                  <h4>How soon to work</h4>
                                  <Select placeholder={'Please select an option'}
                                          selection
                                          options={DURATION_OPTIONS}
                                          value={values.how_soon_start}
                                          onChange={(e, {value}) => setFieldValue('how_soon_start', value)}/>
                                  {errors.how_soon_start &&
                                  <p style={{fontSize: '80%'}} className="text-danger">{errors.how_soon_start}</p>}
                                </div> */}
                              </div>
                            </div>
                            <div className={`${currentStep == 2 ? 'd-block' : 'd-none'}`}>
                              <div className="row">
                                <div className="form-group col-12">
                                  <h4>type of employment</h4>
                                  <label className="form-label">Do you require a full-time, part time or casual nanny.</label>
                                  <Select placeholder={'- Select -'}
                                          selection
                                          options={AVAILABILITY_OPTIONS}
                                          value={values.availability}
                                          onChange={(e, {value}) => setFieldValue('availability', value)}/>
                                  {errors.availability &&
                                  <p style={{fontSize: '80%'}} className="text-danger">{errors.availability}</p>}
                                </div>
                              </div>
                            </div>
                            <div className={`${currentStep == 3 ? 'd-block' : 'd-none'}`}>
                              <div className="row">

                                <TextInput id="no_of_children"
                                  type="text"
                                  size="col-12"
                                  label="Number of Children"
                                  subText={"How many children for this nanny? For safety, we recommend a nanny to child ratio of 1:3 or 1:4 depending on children's ages."}
                                  placeholder="Number of children"
                                  error={touched.no_of_children && errors.no_of_children}
                                  value={values.no_of_children}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  isBig={true}/>


                                <TextInput id="children_age"
                                  type="text"
                                  size="col-12"
                                  label="Ages of Children"
                                  isBig={true}
                                  subText={'List the age range including the youngest child and oldest child - separated by a comma, such as 1,4,7 or list an age range, such as 1-7.'}
                                  placeholder="Range from youngest to oldest child"
                                  error={touched.children_age && errors.children_age}
                                  value={values.children_age}
                                  onChange={handleChange}
                                  onBlur={handleBlur}/>
                              </div>
                            </div>
                            <div className={`${currentStep == 4 ? 'd-block' : 'd-none'}`}>
                              <div className="row">
                                <div className="form-group col-12">
                                  <h4>Do you require your nanny to transport the children around?</h4>
                                  <label className="form-label">Will driving be required, and if so does the nanny require their own vehicle or will you provide.</label>
                                  <Select placeholder={'- Select -'}
                                          selection
                                          options={TRANSPORTATION_OPTIONS}
                                          value={values.transport_children}
                                          onChange={(e, {value}) => setFieldValue('transport_children', value)}/>
                                  {errors.transport_children &&
                                  <p style={{fontSize: '80%'}} className="text-danger">{errors.transport_children}</p>}
                                </div>
                              </div>
                            </div>
                            <div className={`${currentStep == 5 ? 'd-block' : 'd-none'}`}>
                              <div className="row">
                                <div className="form-group mb-0 col-12">
                                  <h4>ELITE SKILLS (OPTIONAL)</h4>
                                  <label className="form-label">Select the desired elite skills. You can select more than one.</label>
                                  <div className="row select-skills row-20">
                                    {
                                      this.props.skills.map((x, index) => {
                                        return <Checkbox
                                            key={index}
                                            uniqueId={x._id}
                                            id={x.title.toLowerCase()}
                                            image={x.icon.name}
                                            checked={values.skills.includes(x._id)}
                                            onChange={(event) => {
                                              const value = event.target.checked;
                                              if (value) {
                                                const nextValue = values.skills.concat(x._id);
                                                setFieldValue('skills', nextValue);
                                              } else {
                                                const nextValue = values.skills.filter(
                                                    value => value !== x._id
                                                );
                                                setFieldValue('skills', nextValue);
                                              }
                                            }}
                                            name={x.title.toLowerCase()}
                                            label={x.title}/>
                                      })
                                    }
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className={`${currentStep == 6 ? 'd-block' : 'd-none'}`}>
                              <div className="row">
                                <div className="form-group mb-0 col-12">
                                  <FormikPlaceSearch id={'place_of_work'}
                                                     name={'place_of_work'}
                                                     isBig={true}
                                                     subText="Enter the suburb location where the nanny will be working."
                                                     label={'Place of Work'}
                                                     error={errors.location}
                                                     onChange={(e) => {
                                                       const place = JSON.parse(localStorage.getItem('location'));
                                                       setFieldValue('place_of_work', place);
                                                       setTimeout(() => {
                                                         localStorage.removeItem('location')
                                                       }, 100)
                                                     }}
                                                     placeholder={'Please enter place of work'}/>
                                </div>
                              </div>
                            </div>
                            <div className={`${currentStep == 7 ? 'd-block' : 'd-none'}`}>
                              <div className="row">
                                <div className="form-group mb-0 col-12">
                                  <TextareaInput id="additional_info"
                                                 name="additional_info"
                                                 size=""
                                                 label="additional info"
                                                 isBig={true}
                                                 subText="Let us know any additional info about you and your family. Feel free to share whatever you like here."
                                                 placeholder="Tell us more about you..."
                                                 error={touched.additional_info && errors.additional_info}
                                                 value={values.additional_info}
                                                 onChange={handleChange}
                                                 onBlur={handleBlur}/>
                                </div>
                              </div>
                            </div>
                            {/*<div className="col-12 form-group text-center my-5">*/}
                            {/*<button className="btn btn-outline-primary" type="submit" onClick={handleSubmit}>Submit</button>*/}
                            {/*</div>*/}
                            <div className="row">
                              <div className="form-group col-12 mb-0 mt-4 text-center">
                                {
                                  currentStep == 1 &&
                                  <button className="btn btn-signup"
                                          disabled={isEmpty(values.start_date)}
                                          onClick={() => this.changeStep(2)}>
                                    Next
                                  </button>
                                }
                                {
                                  currentStep == 2 &&
                                  <button className="btn btn-signup"
                                          disabled={isEmpty(values.availability)}
                                          onClick={() => this.changeStep(3)}>
                                    Next
                                  </button>
                                }
                                {
                                  currentStep == 3 &&
                                  <button className="btn btn-signup"
                                          disabled={isEmpty(values.no_of_children) || isEmpty(values.children_age)}
                                          onClick={() => this.changeStep(4)}>
                                    Next
                                  </button>
                                }
                                {
                                  currentStep == 4 &&
                                  <button className="btn btn-signup"
                                          disabled={isEmpty(values.transport_children)}
                                          onClick={() => this.changeStep(5)}>
                                    Next
                                  </button>
                                }
                                {
                                  currentStep == 5 &&
                                  <button className="btn btn-signup"
                                          // disabled={!isArray(values.skills) || values.skills.length <= 0}
                                          onClick={() => this.changeStep(6)}>
                                    Next
                                  </button>
                                }
                                {
                                  currentStep == 6 &&
                                  <button className="btn btn-signup"
                                          disabled={isEmpty(values.place_of_work)}
                                          onClick={() => this.changeStep(7)}>
                                    Next
                                  </button>
                                }

                                {
                                  currentStep == 7 &&
                                  <button type={'submit'}
                                          onClick={handleSubmit}
                                          className="btn bg-primary text-white btn-lg mb-3">
                                    Submit Request
                                  </button>
                                }
                              </div>
                            </div>
                          </form>
                      )
                    }}
                  </Formik>
                  {
                    !isLoggedIn && <div className="form-group text-center font-14 mb-0">
                      <p>Already have an account? <Link className="link-auth" to={`/family/login`}>Sign In</Link></p>
                    </div>
                  }

                </div>
              </div>
            </section>
          </div>
        </div>
    )
  }
}

const MapStateToProps = (state) => {
  return {
    family: state.family,
    skills: state.skills,
    user: state.user,
  }
};

const MapDispatchToProps = (dispatch) => {
  return {
    doSetRegistration: (data) => {
      dispatch({
        type: APPEND_FAMILY_REGISTRATION,
        payload: data
      })
    },
    showLoading: (title) => {
      dispatch({
        type: SHOW_LOADING,
        payload: {title, isLoading: true}
      })
    },
    hideLoading: () => {
      dispatch({
        type: HIDE_LOADING,
        payload: {title: '', isLoading: false}
      })
    },
    resetRegistration: () => {
      dispatch({
        type: FRESH_FAMILY_REGISTRATION
      })
    }
  }
};

export default connect(MapStateToProps, MapDispatchToProps)(FamilyRegPreferencesComponent);
