export const APPEND_FAMILY_REGISTRATION = 'APPEND_FAMILY_REGISTRATION';
export const FRESH_FAMILY_REGISTRATION = 'FRESH_FAMILY_REGISTRATION';

const initialState = {
  first_name: '',
  last_name: '',
  email: '',
  password: '',
  mobile_number: '',
  location: '',
  place_of_work: '',
  type: 'Family',
  skills: [],
  preferredContactMethod:'',
  // how_soon_start: 'Within a week',
  additional_info: 'random info',
  availability: 'Full time',
  children_age: '10, 12',
  no_of_children: '2',
  transport_children: 'No',
  nanny_start_date: '2019-02-02',
  looking_for: 'Nanny',
};

const FamilyRegistrationReducer = (state = initialState, {type, payload}) => {
  switch (type) {
    case APPEND_FAMILY_REGISTRATION:
      return {
        ...state,
        ...payload
      };

    case FRESH_FAMILY_REGISTRATION:
      return initialState;

    default:
      return state;
  }


};
export default FamilyRegistrationReducer;