import React, {Component} from 'react';
import {connect} from "react-redux";
import {isNull, isUndefined} from 'lodash';
import {getRecord} from '../../services/indexdb.service';
// Services
import {LOGIN_SUCCESS} from '../../redux/user/UserActions';
import {FRESH_NANNY_REGISTRATION} from "../../redux/nanny-registration/NannyRegistrationReducer";
// IMAGES
import thankYou from '../../assets/images/svg/thankyou.svg';
import {GET} from "../../services/rest.service";

class NannyRegThankYouComponent extends Component {

  handleSubmit = () => {
    const record = JSON.parse(localStorage.getItem('reg_auth'));
    if(record._confirmed) {
      getRecord('LCAR').then(value => {
        if ((!isNull(value) || !isUndefined(value)) && value === true) {
          const token = getRecord('TT');
          const user = getRecord('UD');
          token
              .then(tokenValue => {
                localStorage.setItem('token', tokenValue);
                localStorage.removeItem('reg_auth');
                return GET('/users/me');
              })
              .then(userValue => {
                this.props.doLogin(userValue.data);
                this.props.history.replace('/nanny/account');
              });
        } else {
          this.props.history.push('/nanny/confirmation');
          localStorage.removeItem('reg_auth');
          this.props.doResetRegistration();
        }
      })
    } else {
      this.props.history.push('/nanny/confirmation');
      localStorage.removeItem('reg_auth');
      this.props.doResetRegistration();
    }
  };

  render() {
    return (
        <div className="page-content page-auth">
          <div className="container">
            <section className="section bg-auth">
              <div className="row">
                <div className="col-12 col-lg-8 col-xl-6 col-xll-6 mx-auto wow fadeInUp">
                  <div className="text-center">
                    <h2 className="heading">
                      THANK YOU!
                    </h2>
                    <p className="px-md-5">We have received your nanny application. We will review your qualifications shortly.</p>
                  </div>
                  <div className="form-group">
                    <img className="email-icon img-fluid" src={thankYou} alt="thankYou"/>
                  </div>
                  <div className="form-group text-center mt-5 mb-0">
                    <button className="btn btn-outline-primary mt-3" onClick={this.handleSubmit}>ok</button>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
    )
  }
}

const MapStateToProps = (state) => {
  return {
    user: state.user
  }
};

const MapDispatchToProps = (dispatch) => {
  return {
    doResetRegistration: () => {
      dispatch({
        type: FRESH_NANNY_REGISTRATION,
      })
    },
    doLogin: (data) => {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: {
          isLoggedIn: true,
          user: data,
          type: data.type,
          message: 'Logged in Successfully'
        }
      })
    }
  }
};

export default connect(MapStateToProps, MapDispatchToProps)(NannyRegThankYouComponent);
