import React, {Component} from 'react';
import {connect} from "react-redux";
import {toast} from "react-toastify";
import {isEmpty, isNull, isObject} from 'lodash';
// Services
import {PATCH, POST} from "../../services/rest.service";
import {HIDE_LOADING, SHOW_LOADING} from "../../redux/loader/LoaderActions";
// Components
// Others
import {convertJsonToFormData} from "../../helpers/helper";
import {APPEND_NANNY_REGISTRATION} from "../../redux/nanny-registration/NannyRegistrationReducer";
import config from '../../config';
import avatar from '../../assets/images/svg/avatar.svg';
import Thumbnail from "../../components/ThumbnailComponent";

class NannyRegProfileComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      selectedProfilePicture: null,
      profile_picture: null,
      personalDetails: JSON.parse(localStorage.getItem('reg_auth')),
    };
  }

  handleFileChange = (files) => {
    const payload = {
      profile_picture: files[0]
    };

    if (payload.profile_picture.size <= 10000000) {
      const formData = convertJsonToFormData(payload);

      this.props.doShowLoading('Updating Profile Picture...');
      POST('/auth/user/nannyProfileUpload', formData)
          .then(res => {
            this.props.doHideLoading();
            toast.success('Profile picture uploaded');
            this.setState({
              selectedProfilePicture: files[0],
              profile_picture: res.data
            });
          })
          .catch(err => {
            this.props.doHideLoading();
            toast.error(err.message);
          })
    } else {
      toast.error('This picture is too large, please upload another. Maximum allowed size is 10MB');
    }
  };

  handleProfile = () => {
    const payload = {
      ...this.state.personalDetails,
      profile_picture: this.state.profile_picture
    };

    if (!isNull(payload.profile_picture) || !isEmpty(payload.profile_picture)) {
      const register$ = PATCH('/auth/user/nanny_register/step2', payload);
      this.props.doShowLoading('Please wait...');
      register$
          .then(({data}) => {
            toast.success('Profile picture successfully set!');
            this.props.doHideLoading();
            this.props.history.push('/nanny/type');
            this.props.doSetProfile({
              profile_picture: data.profile_picture
            })
          })
          .catch(err => {
            toast.error(err.message);
            this.props.doHideLoading();
          })
    } else {
      toast.error('Please provide profile picture');
    }
  };

  componentDidMount() {
    this.setState({
      profile_picture: this.props.nanny.profile_picture
    })
  }

  render() {
    const {profile_picture} = this.props.nanny;

    return (
        <div className="page-content page-auth">
          <div className="container">
            <section className="section bg-auth">
              <div className="row row-20">
                <div className="col-12 col-lg-6 col-xl-5 col-xll-5 mx-auto wow fadeInUp">
                  <div className="text-center">
                    <h2 className="heading">
                      CREATE PROFILE
                    </h2>
                    <p className="text-note">Add your profile photo.</p>
                    <div className="profile-pic">
                      <div className="pic-card mx-auto">
                        <input type="file" id="profile-pic" name="profile-pic" className="d-none" onChange={(e) => this.handleFileChange(e.target.files)}/>
                        <label className="profile-avatar" htmlFor="profile-pic">
                          <Thumbnail file={this.state.selectedProfilePicture} source={isObject(profile_picture) ? profile_picture.name : undefined}/>
                        </label>
                      </div>
                    </div>
                    <p className="px-xll-5 px-xl-4 font-14">Please note this photo will be viewed by potential families
                      so
                      choose a photo that’s appropriate and professional.</p>
                    <div className="form-group mt-5 mb-0">
                      <button onClick={this.handleProfile} className="btn btn-outline-primary">NEXT</button>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
    )
  }
}

const MapStateToProps = (state) => {
  return {
    nanny: state.nanny
  }
};

const MapDispatchToProps = (dispatch) => {
  return {
    doSetProfile: (data) => {
      dispatch({
        type: APPEND_NANNY_REGISTRATION,
        payload: data
      })
    },
    doShowLoading: (title) => {
      dispatch({
        type: SHOW_LOADING,
        payload: {
          title,
          isLoading: true,
        }
      })
    },
    doHideLoading: () => {
      dispatch({
        type: HIDE_LOADING,
        payload: {
          title: '',
          isLoading: false,
        }
      })
    }
  }
};


export default connect(MapStateToProps, MapDispatchToProps)(NannyRegProfileComponent);
