import React from 'react';
import Slider from "react-slick";
import SingleSkill from "./SkillCard";


export const LandingSlickCarousel = ({ skills }) => {
  const LeftArrow = ({ className, style, onClick }) => (
    <div
      className={className}
      style={{ ...style }}
      onClick={onClick}>
      <span className="slider-arrow">
        <svg xmlns="http://www.w3.org/2000/svg" height="58" width="58" viewBox="0 0 58 58"><path d="M29 58C13 58 0 45 0 29S13 0 29 0s29 13 29 29-13 29-29 29zm0-56C14.1 2 2 14.1 2 29s12.1 27 27 27 27-12.1 27-27S43.9 2 29 2zm0 0" /><path d="M25.4 39.2c-.3 0-.5-.1-.7-.3-.4-.4-.4-1 0-1.4l8.9-8.9-8.9-8.9c-.4-.4-.4-1 0-1.4.4-.4 1-.4 1.4 0l9.6 9.6c.4.4.4 1 0 1.4l-9.6 9.6c-.2.2-.5.3-.7.3zm0 0" /></svg>
      </span>
    </div>
  );

  const RightArrow = ({ className, style, onClick }) => (
    <div
      className={className}
      style={{ ...style }}
      onClick={onClick}>
      <span className="slider-arrow">
        <svg xmlns="http://www.w3.org/2000/svg" height="58" width="58" viewBox="0 0 58 58"><path d="M29 0c16 0 29 13 29 29S45 58 29 58 0 45 0 29 13 0 29 0zm0 56c14.9 0 27-12.1 27-27S43.9 2 29 2 2 14.1 2 29s12.1 27 27 27zm0 0" /><path d="M32.6 18.8c.3 0 .5.1.7.3.4.4.4 1 0 1.4l-8.9 8.9 8.9 8.9c.4.4.4 1 0 1.4-.4.4-1 .4-1.4 0l-9.6-9.6c-.4-.4-.4-1 0-1.4l9.6-9.6c.2-.2.5-.3.7-.3zm0 0" /></svg>
      </span>
    </div>
  );

  const settings = {
    infinite: true,
    speed: 1000,
    dots: false,
    autoplay: true,
    slidesToShow: 6,
    slidesToScroll: 1,
    nextArrow: <LeftArrow />,
    prevArrow: <RightArrow />,
    responsive: [
      {
        breakpoint: 1441,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        }
      },
      {
        breakpoint: 1080,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ]
  };

  return (
    <div className="container-fluid px-0">
      <div className="nannyskill-list specialised-list" data-wow-delay=".5s">
        <Slider {...settings}>
          {
            skills.map((x, index) => {
              return <SingleSkill key={index} skill={x} />
            })
          }
        </Slider>
      </div>
    </div>
  )
};

export default LandingSlickCarousel;
