import React from 'react';
import classnames from 'classnames';

const InputFeedback = ({ error }) => error ? <div className="invalid-feedback">{error}</div> : null;

const Label = ({ error, className, children, ...props }) => children ? <label className="form-label" {...props}>{children}</label>: null;

const TextareaInput = ({ id, size, label, isBig, subText, error, value, onChange, className, ...props }) => {
  const InputClasses = classnames(
      'form-control',
      {
        'is-invalid': !!error,
      },
      className
  );
  const FormGroup = classnames(
      'form-group',
      size
  );
  return (
      <div className={FormGroup}>
        {isBig && <h4>{label}</h4>}
        {subText && <label className="form-label">{subText}</label>}
        {!isBig && <Label htmlFor={id} error={error}>{label}</Label>}
        <textarea
            id={id}
            className={InputClasses}
            value={value}
            onChange={onChange}
            {...props}
        ></textarea>
        <InputFeedback error={error} />
      </div>
  );
};

export default TextareaInput;
