import {HIDE_LOADING, SHOW_LOADING} from "./LoaderActions";

const initialState = {
  title: '',
  isLoading: false
};

const LoadingReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_LOADING:
      return {
        ...action.payload
      };

    case HIDE_LOADING:
      return initialState;

    default:
      return state;
  }
};


export default LoadingReducer;