import React, {Component} from 'react';
import {Link} from "react-router-dom";

// IMAGES
import email from '../../assets/images/svg/email.svg';

class NannyRegConfirmationComponent extends Component {
  render() {
    return (
        <div className="page-content page-auth">
          <div className="container">
            <section className="section bg-auth">
              <div className="row">
                <div className="col-12 col-lg-8 col-xl-6 col-xll-5 mx-auto wow fadeInUp">
                  <div className="text-center">
                    <h2 className="heading">CHECK YOUR EMAIL!</h2>
                    <p>Check your inbox for a confirmation email, this may take a few moments. </p>
                  </div>
                  <div className="form-group">
                    <img className="email-icon img-fluid" src={email} alt="email" />
                  </div>
                  <div className="form-group text-center mt-5 mb-0">
                    <Link to={'/nanny/login'} className="btn btn-outline-primary mt-3">ok</Link>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
    )
  }
}

export default NannyRegConfirmationComponent;
