import React from 'react';
import {Formik} from "formik";
import {object, string, boolean} from 'yup';
import {isObject} from 'lodash';
import TextInput from "./UI/TextInput";
import PasswordInput from "./UI/PasswordInput";
import ReactTelInput from 'react-telephone-input';
import {PERSON_NAME} from "../helpers/constants";
import flag from '../assets/images/flags.png';
import FormikPlaceSearch from "./UI/FormikPlaceSearch";

const PersonalDetailsForm = (props) => (
    <Formik
        initialValues={{
          first_name: props.values.first_name,
          last_name: props.values.last_name,
          email: props.values.email,
          password: props.values.password,
          mobile_number: props.values.mobile_number,
          location: isObject(props.values.location) ? props.values.location.address : props.values.location,
          subscribe: true,
          preferredContactMethod:props.values.preferredContactMethod || "",
        }}
        onSubmit={(values) => {
          const payload={...values}
          if(props.type!=="family")
          {
            delete payload.preferredContactMethod
          }
          props.onSubmit(payload);
        }}
        validationSchema={object().shape({
          email: string().email("Enter a valid email").required("Email is required"),
          first_name: string().required("Please enter your First Name").matches(PERSON_NAME, 'Please enter a valid first name'),
          last_name: string().required("Please enter your Last Name").matches(PERSON_NAME, 'Please enter a valid last name'),
          mobile_number: string().required("Please enter your phone number"),
          location: string(),
          password: string()
              .min(6, "Password must contain at least 6 characters")
              .required("Enter your password"),
          subscribe: boolean(),
        })}>
      {renderProps => {
        const {values, touched, errors, handleChange, handleBlur, handleSubmit, setFieldValue,setFieldTouched,setFieldError} = renderProps;

        return (
            <form className="row" onSubmit={handleSubmit}>
              <TextInput id="first_name"
                         type="text"
                         size="col-12 col-md-6"
                         label="First Name"
                         placeholder="Please enter first name"
                         error={touched.first_name && errors.first_name}
                         value={values.first_name}
                         onChange={handleChange}
                         onBlur={handleBlur}/>
              <TextInput id="last_name"
                         type="text"
                         size="col-12 col-md-6"
                         label="Last Name"
                         placeholder="Please enter last name"
                         error={touched.last_name && errors.last_name}
                         value={values.last_name}
                         onChange={handleChange}
                         onBlur={handleBlur}/>
              <TextInput id="email"
                         type="email"
                         size="col-12 col-md-6"
                         label="Email"
                         placeholder="Please enter your email"
                         error={touched.email && errors.email}
                         value={values.email}
                         onChange={handleChange}
                         onBlur={handleBlur}/>
              <div className="form-group col-12 col-md-6">
                <label className="form-label">Mobile Number</label>
                <ReactTelInput defaultCountry="us"
                               value={values.mobile_number}
                               flagsImagePath={flag}
                               onChange={(e) => setFieldValue('mobile_number', e)}
                              //  onBlur={handleBlur}
                              onBlur={value => {
                                setFieldTouched('mobile_number', true);
                                setFieldError('mobile_number', value.error);
                                }}

                />
                {errors.mobile_number && touched.mobile_number && <small>{errors.mobile_number}</small>}
              </div>
              <PasswordInput id="password"
                             size="col-12"
                             label="Password"
                             placeholder="Enter a password"
                             error={touched.password && errors.password}
                             value={values.password}
                             onChange={handleChange}
                             onBlur={handleBlur}/>
               <div className="col-12">
                 {/*const place = JSON.parse(localStorage.getItem('location'));*/}
                 {/*setFieldValue('sitter_service_zipcode', place);*/}
                 {/*setTimeout(() => {*/}
                 {/*localStorage.removeItem('location')*/}
               {/*}, 100)*/}
                 <FormikPlaceSearch id="location"
                                    name="location"
                                    type="search"
                                    label="Location"
                                    placeholder="Select your location"
                                    error={touched.location && errors.location}
                                    value={values.location}
                                    onChange={(e) => setFieldValue('location', e)}
                                    onBlur={handleBlur}/>
               </div>

              {props.type==="family" && 
              <div className="form-group col-12 col-md-6">
                                <label className="form-label">What is best way to contact you?</label>

              <select className="form-control icon-down" name="preferredContactMethod" value={values.preferredContactMethod} onChange={handleChange}>
                  <option value="" label="Select">Select</option>
                  <option value="EMAIL" label="Email">Email</option>
                  <option value="TEXT" label="Text">Text</option>
                  <option value="PHONE_CALL" label="Phone Call">Phone Call</option>
                </select></div>}

               <div className="col-12 form-group cus-control-sm pt-2">
                <div className="custom-control custom-checkbox">
                  <input type="checkbox" className="custom-control-input" id="subscribe" name="subscribe" checked={values.subscribe} value={values.subscribe}
                         onChange={handleChange}
                         onBlur={handleBlur} />
                  <label className="custom-control-label" htmlFor="subscribe">Subscribe to our weekly {props.type} newsletter</label>
                </div>
               </div>

              <div className="form-group col-12 text-center my-4 my-sm-5">
                <button type="submit" className="btn btn-outline-primary">next</button>
              </div>
            </form>
        )
      }}
    </Formik>
);


export default PersonalDetailsForm;



