import React, {Component} from 'react';
import {Link} from "react-router-dom";
import history from "../../helpers/history";
import M from "materialize-css";
import {connect} from "react-redux";
// Images
import ChineseMandarinBanner from "../../assets/images/chinesemandarinbanner.jpg";

class ChineseMandarinTeacher extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    M.Parallax.init(this.Parallax1);
  }

  goBack = () => {
    history.push('/our-nannies');
  };

  render() {
    const {isLoggedIn} = this.props.user;
    return (
      <div className="page-content">
        <section className="page-banner pt-0 section bg-lightdark">
          {/* <div className="w-100 wow fadeInRight ml-5 pt-5">
            <Link to={'/nanny-services/foreign-language-teacher'} className="btn btn-clear btn-back-skills p-0">
              <svg xmlns="http://www.w3.org/2000/svg" width="26" viewBox="0 0 31.494 31.494">
                <path
                  d="M10.273 5.009a1.112 1.112 0 0 1 1.587 0 1.12 1.12 0 0 1 0 1.571l-8.047 8.047h26.554c.619 0 1.127.492 1.127 1.111s-.508 1.127-1.127 1.127H3.813l8.047 8.032c.429.444.429 1.159 0 1.587a1.112 1.112 0 0 1-1.587 0L.321 16.532a1.12 1.12 0 0 1 0-1.571l9.952-9.952z"
                  fill="#f1d1cc"/>
              </svg>
            </Link>
          </div> */}
          <div className="banner-caption mt-0">
            <div className="container pt-4 pt-lg-5">
              <div className="row wow fadeInUp">
                <div className="col-12 w-100 wow fadeInRight">
                  <Link to={'/nanny-services/foreign-language-teacher'} className="btn btn-clear btn-back-skills p-0">
                    <svg xmlns="http://www.w3.org/2000/svg" width="26" viewBox="0 0 31.494 31.494">
                      <path
                        d="M10.273 5.009a1.112 1.112 0 0 1 1.587 0 1.12 1.12 0 0 1 0 1.571l-8.047 8.047h26.554c.619 0 1.127.492 1.127 1.111s-.508 1.127-1.127 1.127H3.813l8.047 8.032c.429.444.429 1.159 0 1.587a1.112 1.112 0 0 1-1.587 0L.321 16.532a1.12 1.12 0 0 1 0-1.571l9.952-9.952z"
                        fill="#f1d1cc"/>
                    </svg>
                  </Link>
                </div>
                <div className="col-12 col-xl-8 col-xll-8 mx-auto">
                  <div className="banner-text">
                    <h2 className="heading page-heading">Chinese Mandarin Language Teacher</h2>
                    <p className="mb-0 px-xll-5">The ideal time for someone to learn Mandarin and achieve fluency is at
                      a young age.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <SectionThoroughBackground para1={Parallax => {
          this.Parallax1 = Parallax;
        }}/>
        <SectionBenefitsLearning isLoggedIn={isLoggedIn}/>
        <section className="section section-applynow bg-light">
          <div className="container">
            <div className="banner-caption">
              <div className="row wow fadeInUp">
                <div className="col-12 col-xl-11 col-xll-9 mx-auto wow fadeInUp">
                  <div className="banner-text">
                    <h2 className="heading">Do You Speak A Second Language Fluently And Want To Become An Elite
                      Nanny?</h2>
                    <Link to={'/nanny/registration'} className="btn btn-outline-primary">SIGN UP NOW</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }
}

const SectionThoroughBackground = ({para1}) => (
  <section className="parallax-container section">
    <div ref={para1} className="parallax">
      <img src={ChineseMandarinBanner}/>
    </div>
    <div className="container">
      <div className="box">
        <div className="row align-items-center wow fadeInUp">
          <div className="col-12 col-lg-10 col-xl-10 col-xll-9 mx-auto">
            <div className="banner-text text-center py-5">
              <p className="text-white sub-heading-italic"> With China being the fastest growing economy, there are
                ample benefits of knowing Mandarin as an adult, but studies show that children benefit in incredible
                ways from learning Mandarin while they are young. </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

const SectionBenefitsLearning = ({isLoggedIn}) => (
  <section className="section">
    <div className="container">
      <div className="row">
        <div className="col-12 col-xl-7 col-xll-6 mx-auto wow fadeInUp">
          <div className="text-center">
            <h2 className="heading">Benefits of learning Chinese Mandarin</h2>
          </div>
        </div>
      </div>
      <div className="row process-list mt-3">
        <div className="col-12 col-xl-7 col-xll-6 mx-auto wow fadeInUp">
          <div className="processitem">
            <div className="media">
              <span className="mr-4 process-number">1</span>
              <div className="media-body">
                <h5 className="">Develop Accurate Hearing and Interpretation of Sounds</h5>
                <p>Familiarity with tones and sounds actually helps cultivate musical ability in children.</p>
              </div>
            </div>
          </div>
          <div className="processitem">
            <div className="media">
              <span className="mr-4 process-number">2</span>
              <div className="media-body">
                <h5 className="">Better Interpretation of Symbols</h5>
                <p>Since Chinese writing relies on thousands of characters, learners of Mandarin Chinese have to read
                  and interpret a vast number of visual symbols, activating more regions of the brain than English,
                  which only relies on a phonetic alphabet.</p>
              </div>
            </div>
          </div>
          <div className="processitem">
            <div className="media">
              <span className="mr-4 process-number">3</span>
              <div className="media-body">
                <h5 className="">Improved Hand-Eye Coordination</h5>
                <p>Writing Chinese characters (pen strokes in multiple directions and differentiating hand pressure) has
                  been shown to improve fine motor skills and spatial recognition in children.</p>
              </div>
            </div>
          </div>
          <div className="processitem">
            <div className="media">
              <span className="mr-4 process-number">4</span>
              <div className="media-body">
                <h5 className="">Stronger Math Skills</h5>
                <p className="mb-3">Chinese involves learning language, sounds, drawing, and math all at once, meaning
                  it activates more regions of the brain, compared to English-speakers which mainly use right and left
                  hemispheres, ultimately improving cognitive development, leading to greater overall creativity,
                  enhanced problem-solving, and increased emotional intelligence</p>
                <p className="mb-3">With all these benefits for the mind and brain, Mandarin language learning for
                  children is sure to be an asset for their whole life, regardless of their eventual interests or
                  profession.</p>
                <p>Let us match you with a Chinese Speaking Teacher + Nanny today.</p>
              </div>
            </div>
          </div>
          <div className="processitem">
            <div className="media">
              <span className="mr-4 process-number opacity-0"></span>
              <div className="media-body">
                <Link to={!isLoggedIn ? '/family/registration' : '/family/preferences'}
                      className="btn btn-lg btn-skill btn-outline-primary mt-2">GET STARTED</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);


const MapStateToProps = (state) => {
  return {
    user: state.user
  }
};

export default connect(MapStateToProps, null)(ChineseMandarinTeacher);
