import React, {Component} from 'react';
import {Elements, StripeProvider} from "react-stripe-elements";
import config from '../../config';
import {connect} from "react-redux";
import {HIDE_LOADING, hideLoading, SHOW_LOADING} from "../../redux/loader/LoaderActions";
import {GET, POST} from "../../services/rest.service";
import toast from '../../Toast';
import history from '../../helpers/history';
import PaymentForm from "./PaymentForm";
import {Link} from "react-router-dom";

import img_visa from '../../assets/images/payment/img-visa.png';
import img_mastercard from '../../assets/images/payment/img-mastercard.png';
import diners_club from '../../assets/images/payment/diners-club.png';
import discover from '../../assets/images/payment/discover.png';
import jcb from '../../assets/images/payment/jcb.png';
import american_express from '../../assets/images/payment/american-express.png';
import {getMe, USER} from "../../redux/user/UserActions";

class MembershipPayment extends Component {

  constructor(props) {
    super(props);
    this.state = {
      amount: 0,
    };
  }

  componentDidMount() {
    this.setState({
      amount: localStorage.getItem('amount')
    })
  }


  handleSubmit = (e) => {
    const payload = {
      email: localStorage.getItem('email'),
      plan_id: localStorage.getItem('plan'),
      stripe_token: e.id,
    };
    POST('/user/stripe/payments', payload)
        .then(res => {
          history.push('/family/request-sitter');
          localStorage.removeItem('email');
          localStorage.removeItem('plan');
          localStorage.removeItem('amount');
          return GET('/users/me');
        })
        .then(res => {
          this.props.getLoggedInData(res);
          toast.success('Your payment is successful!');
          this.props.hideLoading();
        })
        .catch(err => {
          toast.error(err.message);
          this.props.hideLoading();
        })

  };

  render() {
    return (
        <div className="page-content page-auth">
          <div className="container">
            <section className="section bg-light">
              <div className="wow fadeInUp">
                <div className="row mb-4">
                  <div className="col-12 col-md-11 col-lg-10 col-xl-7 mx-auto">
                    <div className="text-center">
                      <h2 className="heading">
                        <Link to={'/family/membership'} className="btn btn-back btn-back-inline"><svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 268.8 268.8"><path d="M3.7 143.3l80 80c2.4 2.4 5.6 3.7 8.8 3.7s6.4-1.2 8.8-3.7c4.9-4.9 4.9-12.8 0-17.7l-58.7-58.7h213.7c6.9 0 12.5-5.6 12.5-12.5s-5.6-12.5-12.5-12.5H42.7l58.7-58.7c4.9-4.9 4.9-12.8 0-17.7-4.9-4.9-12.8-4.9-17.7 0l-80 80c-4.9 5-4.9 12.9 0 17.8z" fill="#f1d1cc"></path></svg></Link>PAYMENT
                      </h2>
                      <p className="text-note mb-3">Please enter your credit card details</p>
                    </div>
                  </div>
                </div>
                <div className="row mb-5">
                  <div className="col-12 col-md-8 col-lg-6 col-xl-5 col-xll-4 mx-auto">
                  <div className="payment-card">
                      <img src={img_visa} alt="" />
                      <img src={img_mastercard} alt="" />
                      <img src={diners_club} alt="" />
                      <img src={discover} alt="" />
                      <img src={jcb} alt="" />
                      <img src={american_express} alt="" />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-md-8 col-lg-6 col-xl-5 col-xll-4 mx-auto">
                    <StripeProvider apiKey={config.PAYMENTS.STRIPE_API_KEY}>
                      <Elements>
                        <PaymentForm amount={this.state.amount} onSubmit={this.handleSubmit}/>
                      </Elements>
                    </StripeProvider>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
    )
  }
}

const MapDispatchToProps = (dispatch) => {
  return {
    showLoading: (title) => {
      dispatch({
        type: SHOW_LOADING,
        payload: {title, isLoading: true}
      })
    },
    hideLoading: () => {
      dispatch({
        type: HIDE_LOADING,
        payload: {title: '', isLoading: false}
      })
    },
    getLoggedInData: (res) => {
      dispatch({
        type: USER,
        payload: {
          user: res.data,
          type: res.data.type,
        }
      })
    }
  }
};

export default connect(null, MapDispatchToProps)(MembershipPayment);
