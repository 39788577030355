import React, {Component} from "react";
import {Elements, StripeProvider} from "react-stripe-elements";
import config from "../../config";
import PaymentForm from "./PaymentForm";
import {GET, POST} from "../../services/rest.service";
import history from "../../helpers/history";
import {toast} from "react-toastify";
import {HIDE_LOADING, SHOW_LOADING} from "../../redux/loader/LoaderActions";
import {USER} from "../../redux/user/UserActions";
import {connect} from "react-redux";

import img_visa from "../../assets/images/payment/img-visa.png";
import img_mastercard from "../../assets/images/payment/img-mastercard.png";
import diners_club from "../../assets/images/payment/diners-club.png";
import discover from "../../assets/images/payment/discover.png";
import jcb from "../../assets/images/payment/jcb.png";
import american_express from "../../assets/images/payment/american-express.png";

class RegistrationFees extends Component {
  constructor(props) {
    super(props);
    this.state = {
      amount: 500
    };
  }

  handleSubmit = e => {
    const {user} = this.props.user;
    const payload = {
      email: user.email,
      stripe_token: e.id
    };
    POST("/user/registration/payment", payload)
      .then(res => {
        history.push("/family/thank-you-nanny-request");
        return GET("/users/me");
      })
      .then(res => {
        this.props.getLoggedInData(res);
        toast.success("Your payment is successful!");
        this.props.hideLoading();
      })
      .catch(err => {
        toast.error(err.message);
        this.props.hideLoading();
      });
  };

  render() {
    return (
      <div className="page-content page-auth">
        <div className="container">
          <section className="section bg-light">
            <div className="wow fadeInUp">
              <div className="row mb-4">
                <div className="col-12 col-md-11 col-lg-10 col-xl-7 mx-auto">
                  <div className="text-center">
                    <h2 className="heading">
                      REGISTRATION FEE
                    </h2>
                  </div>
                </div>
                <div className="w-100"></div>
                <div className="col-12 col-md-8 col-lg-6 col-xl-5 mx-auto">
                  <div className="text-center">
                    <p className="text-note mb-3">
                      In order to find you the perfect fit,{" "}
                      <strong>we require a $500 registration fee</strong>, and
                      upon hire, we apply that registration fee to the referral
                      fee.
                    </p>
                  </div>
                </div>
              </div>
              <div className="row mb-5">
                <div className="col-12 col-md-8 col-lg-6 col-xl-5 col-xll-4 mx-auto">
                  <div className="payment-card">
                    <img src={img_visa} alt=""/>
                    <img src={img_mastercard} alt=""/>
                    <img src={diners_club} alt=""/>
                    <img src={discover} alt=""/>
                    <img src={jcb} alt=""/>
                    <img src={american_express} alt=""/>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-8 col-lg-6 col-xl-5 col-xll-4 mx-auto">
                  <StripeProvider apiKey={config.PAYMENTS.STRIPE_API_KEY}>
                    <Elements>
                      <PaymentForm
                        amount={this.state.amount}
                        onSubmit={this.handleSubmit}
                      />
                    </Elements>
                  </StripeProvider>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-12 col-md-8 col-lg-6 col-xl-5 col-xll-4 mx-auto">
                  <p className="text-center">
                    <a
                      className="w-100 d-block mt-2"
                      href="javascript:void(0);"
                      onClick={() =>
                        history.push("/family/thank-you-nanny-request")
                      }
                    >
                      Or skip, and pay later
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

const MapStateToProps = state => {
  return {
    user: state.user
  };
};

const MapDispatchToProps = dispatch => {
  return {
    showLoading: title => {
      dispatch({
        type: SHOW_LOADING,
        payload: {title, isLoading: true}
      });
    },
    hideLoading: () => {
      dispatch({
        type: HIDE_LOADING,
        payload: {title: "", isLoading: false}
      });
    },
    getLoggedInData: res => {
      dispatch({
        type: USER,
        payload: {
          user: res.data,
          type: res.data.type
        }
      });
    }
  };
};

export default connect(
  MapStateToProps,
  MapDispatchToProps
)(RegistrationFees);
