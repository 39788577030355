import React, {Component} from 'react';
import {Accordion} from 'semantic-ui-react';

class PlacementFeesComponent extends Component {

  handleClick = (e, titleProps) => {
    const {index} = titleProps
    const {activeIndex} = this.state
    const newIndex = activeIndex === index ? -1 : index

    this.setState({activeIndex: newIndex})
  }
  ;
          constructor(props) {
    super(props);
    this.state = {
      activeIndex: 0,
    };
  }

  render() {

    const {activeIndex} = this.state;

    return (
            <div className="page-content">
              <SectionBanner/>
              <SectionThoroughBackground/>

              <section className="section bg-light-5 mb-60">
                <div className="container">
                  <div className="row">
                    <div className="col-12 wow fadeInUp mb-2 mb-md-5">
                      <div className="banner-text text-center">
                        <h2 className="heading heading-36">Pricing Packages</h2>
                      </div>
                    </div>
                    <div className="col-12 col-lg-10 col-xl-8 mx-auto wow fadeInUp ui-accordion mt-4">
                      <Accordion>
                        <Accordion.Title active={activeIndex === 0} index={0} onClick={this.handleClick}>
                          CLASSIC PACKAGE
                        </Accordion.Title>
                        <Accordion.Content active={activeIndex === 0}>
                          <div>
                            <p>We match you with an Elite Nanny at 20% of the Candidate’s Total Gross Annual Compensation.</p>
                            <h5 className="accordion-title">Your investment includes:</h5>
                            <ol className="pricing-infolist">
                              <li>Criminal Background Investigation: DMV Driving Record, Social Security Number Verification, Sex Offender Registry Check</li>
                              <li>Professional Employment Agreement</li>
                              <li>Candidate replacement (one only), if needed, within 2 months from first day of employment.</li>
                            </ol>
                          </div>
                        </Accordion.Content>
                        <Accordion.Title active={activeIndex === 1} index={1} onClick={this.handleClick}>
                          ELITE PACKAGE
                        </Accordion.Title>
                        <Accordion.Content active={activeIndex === 1}>
                          <div>
                            <p>We match you with an Elite Nanny at 25% of the Candidate’s Total Gross Annual Compensation.</p>
                            <h5 className="accordion-title">Your investment includes:</h5>
                            <ol className="pricing-infolist">
                              <li>Criminal Background Investigation: DMV Driving Record, Social Security Number Verification, Sex Offender Registry Check</li>
                              <li>Civil Restraining Orders Check, National Wants and Warrants</li>
                              <li>Professional Employment Agreement</li>
                              <li>Professional Confidentiality Agreement</li>
                              <li>Ongoing monthly membership to our database of sitters</li>
                              <li>Candidate replacement (one only), if needed, within 3 months from first day of employment.</li>
                            </ol>
                          </div>
                        </Accordion.Content>
                        <Accordion.Title active={activeIndex === 2} index={2} onClick={this.handleClick}>
                          TEMPORARY NANNIES PRICING PACKAGE
                        </Accordion.Title>
                        <Accordion.Content active={activeIndex === 2}>
                          <div>
                            <p>Should you require a nanny for a short time, and are not looking for an ongoing engagement, we offer a temporary nanny placement service. These placements usually occur between 1-4 months. We can match you with a temporary Elite Nanny for a one-time placement fee of $1250.</p>
                            <h5 className="accordion-title">Your investment includes:</h5>
                            <ol className="pricing-infolist">
                              <li>Criminal Background Investigation. DMV Driving Record Check, Social Security Number Verification, Sex Offender Registry Check</li>
                            </ol>
                          </div>
                        </Accordion.Content>
                        <Accordion.Title className="mb-0" active={activeIndex === 3} index={3} onClick={this.handleClick}>
                          ELITE SITTER PRICING PACKAGE
                        </Accordion.Title>
                        <Accordion.Content active={activeIndex === 3}>
                          <div>
                            <p>Elite Nannies also provides a professional Elite Sitter Service providing in-home care, whilst traveling, at hotels, events or whilst on vacation. All of our sitters have at least 3 years child care experience and are 21 years+. They comply with the same strict hiring process, having undergone a thorough background check, interviewing process and are registered with Trustline.</p>
                            <p>To use our Elite Sitter service, you will need to obtain a membership, granting you full access to our extensive database of Elite Sitters. Our online platform will allow you to communicate with our hand selected, pre-screened network of elite sitters where you can coordinate your own schedules and needs.</p>
                            <h5 className="accordion-title my-4">MONTHLY MEMBERSHIP</h5>
                            <ol className="pricing-infolist">
                              <li>If you aren’t sure how often you’ll use a sitter, our monthly subscription is the best choice for you. Pay as you go, pause or cancel anytime.</li>
                              <li>$30 per month.</li>
                            </ol>
                            <h5 className="accordion-title my-4">3 MONTHLY MEMBERSHIP</h5>
                            <ol className="pricing-infolist">
                              <li>Signing up for 3 months will save you over 15%.</li>
                              <li>$75 for 3 months.</li>
                            </ol>
                            <h5 className="accordion-title my-4">6 MONTHLY MEMBERSHIP</h5>
                            <ol className="pricing-infolist">
                              <li>Signing up for 6 months will save you over 20%.</li>
                              <li>$140 for 6 months.</li>
                            </ol>
                            <h5 className="accordion-title my-4">12 MONTHLY MEMBERSHIP</h5>
                            <ol className="pricing-infolist">
                              <li>Signing up for 12 months will save you over 25%.</li>
                              <li>$260 for 6 months.</li>
                            </ol>
                            <p>The sitter is paid directly at their own hourly rate at the end of the shift. (There is a 3-hour minimum and cancellations must be made within reasonable timing, or you will be charged a percentage of the fees).</p>
                            <p>Elite Sitters have individual hourly rates, ranging from $35-60 per hour depending on their qualifications and level of experience.</p>
                          </div>
                        </Accordion.Content>
                      </Accordion>
                    </div>
                  </div>
                </div>
              </section>
            </div>
            )
  }
}

const SectionBanner = () => (
          <section className="page-banner section bg-lightdark">
            <div className="container">
              <div className="banner-caption">
                <div className="row">
                  <div className="col-12 col-lg-8 col-xl-8 mx-auto wow fadeInUp">
                    <div className="banner-text">
                      <h2 className="heading page-heading">Placement Fees</h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          );


const SectionThoroughBackground = () => (
          <section className="section">
            <div className="container">
              <div className="box">
                <div className="row align-items-center">
                  <div className="col-12 col-lg-10 col-xl-8 col-xll-7 mx-auto wow fadeInUp">
                    <div className="banner-text text-center">
                      <h2 className="heading heading-36">Elite Nanny Pricing Packages</h2>
                      <p>We endeavour to support you in raising respectful, happy and determined children, which is why at Elite Nannies, you will be offered only the most exceptional nannies. In order to find you the perfect fit, we require a $1000 registration fee, and upon hire, we apply that registration fee to the referral fee. If you have any questions on pricing, do not hesitate to
                        <a className="text-primary" href="tel:+1 424-343-3783"> call.</a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          );

export default PlacementFeesComponent;
