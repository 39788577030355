import {GET} from "../../services/rest.service";

export const GET_ALL = 'GET_ALL';
export const RESET = 'CLEAR';
export const ERROR = 'ERROR';

export const getAllSkills = () => {
  return dispatch => {
    GET('/general/skill/getAll')
        .then(res => {
          dispatch({
            type: GET_ALL,
            payload: res.data
          })
        })
        .catch((err) => {
          dispatch({
            type: ERROR,
            payload: []
          })
        })
  }
};