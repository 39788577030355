import React from 'react';
import ImgAvatar from "../../../assets/images/testimonial.jpg";
import Svglocation from "../../../assets/images/svg/location.svg";

const Testimonial = () => (
  <div className="mt-5 bg-light-5 section">
    <div className="row">
      <div className="col-12 col-xl-7 col-xll-6 mx-auto">
        <div className="px-3 px-lg-0">
          <div className="text-center">
            <img src={ImgAvatar} width="14" className="img-fluid client-avatar mb-4" alt=""/>
            <p>"Roxanne made us feel incredibly comfortable about hiring a nanny. As a first time mom, she made me feel at ease - critical for a successful transition back to work after maternity leave. It was amazing to see how quickly our nanny became a part of our family - now we couldn’t imagine life without her"</p>
            <p className="font-bold mb-1">Molly S. - HR Manager / Mom</p>
            <p className="font-bold text-primary mb-0">
              <img src={Svglocation} width="14" className="img-fluid" alt=""/> Manhattan, NY</p>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Testimonial;
