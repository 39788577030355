import React from 'react';
import {Formik} from 'formik';
import {object, string} from 'yup';
import {Link} from "react-router-dom";

// Images
import PasswordInput from "./UI/PasswordInput";
import TextInput from "./UI/TextInput";

const LoginForm = (props) => (
    <Formik
        initialValues={{email: '', password: ''}}
        onSubmit={(values) => {
          props.onSubmit(values);
        }}
        validationSchema={object().shape({
          email: string().email("Enter a valid email").required("Email is required"),
          password: string()
              .min(6, "Password must contain at least 6 characters")
              .required("Enter your password"),
        })}
    >{renderProps => {
      const {values, touched, errors, handleChange, handleBlur, handleSubmit} = renderProps;
      return (
          <form className="row" onSubmit={handleSubmit}>
            <TextInput id="email"
                       type="email"
                       size="col-12"
                       label="Email"
                       placeholder="Your email address"
                       error={touched.email && errors.email}
                       value={values.email}
                       onChange={handleChange}
                       onBlur={handleBlur}/>

            <PasswordInput id="password"
                           size="col-12"
                           label="Password"
                           placeholder="Your password"
                           error={touched.password && errors.password}
                           value={values.password}
                           onChange={handleChange}
                           onBlur={handleBlur}/>

            {/*Forgot Password*/}
            <div className="form-group text-right w-100">
              <Link className="link-auth" to={'/forgot/' + props.type}>Forgot password?</Link>
            </div>
            {/*submit button */}
            <div className="form-group text-center my-4 my-sm-5 col-12">
              <button type="submit" className="btn btn-outline-primary">Login</button>
            </div>
          </form>
      )
    }}
    </Formik>
);

export default LoginForm;
