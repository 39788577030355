import React, {Component} from 'react';
import classnames from 'classnames';

// Images
import eye from '../../assets/images/svg/eye.svg';

const InputFeedback = ({ error }) => error ? <div className="invalid-feedback">{error}</div> : null;

const Label = ({ error, className, children, ...props }) => {
  return (
      <label className="form-label" {...props}>
        {children}
      </label>
  );
};

class PasswordInput extends Component {

  constructor(props) {
    super(props);
    this.state = {
      hide: true
    };
  }

  handleEyeContact = () => this.setState({hide: !this.state.hide});

  render() {
    const {label, id , size, error, value, onChange, className, ...props} = this.props;
    const InputClasses = classnames(
        'form-control',
        {
          'is-invalid': !!error,
        },
        className
    );

    const FormGroup = classnames(
        'form-group',
        'form-group-btn',
        size
    );
    return(
        <div className={FormGroup}>
          <Label htmlFor={id} error={error}>
            {label}
          </Label>
          <div className="input-group">
            <input
                id={id}
                className={InputClasses}
                type={this.state.hide ? 'password' : 'text'}
                value={value}
                onChange={onChange}
                {...props}
            />
            <div className="input-group-append">
              <button className="btn btn-clear text-primary px-4" tabIndex="-1" type="button"
                      onClick={this.handleEyeContact}>
                <img src={eye} alt="eye"/>
              </button>
            </div>
            <InputFeedback error={error} />
          </div>
        </div>
    )
  }
}

export default PasswordInput;