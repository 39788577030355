export const SHOW_LOADING = 'SHOW_LOADING';
export const HIDE_LOADING= 'HIDE_LOADING';

export const showLoading = (title) => {
  return {
    type: SHOW_LOADING,
    payload: {title, isLoading: true},
  };
};

export const hideLoading = () => {
  return {
    type: HIDE_LOADING,
    payload: {title: '', isLoading: false},
  };
};
