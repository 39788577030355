import React, {Component} from 'react';

class TermsComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
            <div className="page-content">
              <section className="page-banner section bg-lightdark">
                <div className="container">
                  <div className="banner-caption">
                    <div className="row wow fadeInUp">
                      <div className="col-12 col-xl-8 col-xll-7 mx-auto">
                        <div className="banner-text">
                          <h2 className="heading page-heading">TERMS OF USE</h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section className="section">
                <div className="container px-xll-0">
                  <div className="box">
                    <div className="row">
                      <div className="col-12 wow fadeInUp">
                        <p>The use of the site indicates your unconditional acceptance of these terms of use. We reserve the right, in our sole discretion to update or revise these terms of use. Your continued use of the site following the posting of any changes to the terms of use constitutes acceptance of those changes.</p>
                        <h3 className="sub-heading my-3">USE OF SITE</h3>
                        <p>You may use the site only in accordance with and subject to these Terms of Use and the Site’s Privacy Policy. Once you complete and submit your registration you have opted-in to receive email communication from us. You may not use the Site for any purpose that is unlawful or prohibited by these Terms of Use, or to solicit the performance of any illegal activity of other activity that infringes the rights of Elite Nannies or others. Notwithstanding any other rights or restrictions in these Terms of Use, you may not use this Site to:</p>
                        <ul className="pl-4">
                          <li>Transmit via or through the Site any information, data, text, images, files, links, or software except in connection with your authorized use of this Site or otherwise in response to specific requests for information by us.</li>
                          <li>Introduce to the Site or any other computer or website viruses, worms, Trojan horses and /or harmful codes.</li>
                          <li>Obtain unauthorized access to any computer system.</li>
                          <li>Impersonate any other person including but not limited to, a registered user of this Site or an employee of Elite Nannies.</li>
                          <li>Invade the privacy or violate any personal or proprietary right, including intellectual property rights, of any person or entity.</li>
                          <li>Misrepresent the identity of a user or use a false e-mail address.</li>
                          <li>Tamper with or obtain access to this Site or any component of this Site.</li>
                          <li>Conduct fraudulent activities</li>
                          <li>Collect or harvest information regarding other users of the Site for any reason whatsoever, including, without limitation, for sending such users unsolicited commercial email.</li>
                        </ul>
                        <h3 className="sub-heading my-3">Additional Terms and Conditions</h3>
                        <p>Additional terms and conditions may apply to specific portions of the Site or your membership which terms are made part of these Terms of Use by reference. You agree to abide by such other terms and conditions. If there is a conflict between these Terms of Use and the terms posted or emailed for, or applicable to, a specific portion of the Site or your membership, the latter terms shall control with respect to your use of that portion of the Site or your membership.</p>
                        <h3 className="sub-heading my-3">Proprietary Rights</h3>
                        <p>You acknowledge and agree that the content materials and other components (including but not limited to logos, graphics, button icons and page headers) available on the Site are the property of Elite Nannies and are protected by copyrights, trademarks, service marks or other proprietary rights and laws. You agree not to sell, license, rent, modify, distribute, copy, reproduce, transmit publicly display, publicly perform, publish, adapt, edit or create derivative works from content or materials on the Site. Use of the content and materials for any purpose not expressly permitted in these Terms of Use is prohibited.</p>
                        <h3 className="sub-heading my-3">Submissions</h3>
                        <p>Any resumes, cover letters, recommendation letters, pictures, transcripts, education documents or other communications or information posted to the site, submitted to Elite Nannies, or otherwise communicated in connection with your use of the Site (collectively,” Submissions”) shall be and remain the exclusive property of Elite Nannies with no further compensation to you. As such we will own exclusively all such rights, titles and interests and shall not be limited in any way in the use, commercial or otherwise, of any Submissions. UNDER NO CIRCUMSTANCES SHALL ANY DISCLOSURE OR ANY SUBMISSION TO ELITE NANNIES CREATE ANY OBLIGATION OR LIABILITY ON THE PART OF ELITE NANNIES. YOU ARE WAIVING ANY AND ALL RIGHTS THAT YOU MAY HAVE IN THE SUBMISSION AND YOU AGREE THAT ANY SUBMISSIONS BY YOU WILL NOT VIOLATE ANY RIGHT OF ANY THIRD PARTY, INCLUDING COPYRIGHT TRADEMARK PRIVACY OR OTHER PERSONAL OR PROPRETARY RIGHTS. YOU ALSO AGREE THAT NO SUBMISSION BY YOU WILL BE OR CONTAIN LIBELOUS OR OTHERWISE UNLAWFUL, ABUSIVE OR OBSCENE MATERIAL. YOU ARE AND SHALL REMAIN SOLEY RESPONSIBLE FOR THE CONTENT OF ANY SUBMISSIONS YOU MAKE. YOU REPRESENT AND WARRANT TO ELITE NANNIES THAT ELITE NANNIES IS FREE TO IMPLEMENT THE SUBMISSION IF IT SO DESIRES, AS PROVIDED OR AS MODIFIED BY ELITE NANNIES, WITHOUT OBTAINING PERMISSION OR LICENSE FROM ANY THIRD PARTY. Elite Nannies takes no responsibility and assumes no liability for any content submitted or posted by you or any third party.</p>
                        <h3 className="sub-heading my-3">LINKS TO THIRD-PARTY WEBSITES</h3>
                        <p><strong>No Implied Endorsements:</strong> This Site may contain links to other websites on the Internet. You acknowledge that we have no control over such websites and that we are not responsible for the accuracy, content, legality or any other aspect of any linked website. In no event shall any reference to any third party, third party website or third party product or service be construed as an approval or endorsement by us of that third party, third party website or of any product or service provided by a third party.</p>
                        <h3 className="sub-heading my-3">Indemnification</h3>
                        <p>By using this Site, you agree to indemnify, hold harmless and defend Elite Nannies their respective officers, directors, employees, successors, agents, subsidiaries, partners, contractors, representatives and affiliates from any claims, damages losses, liabilities, and all costs and expenses of defense, including but not limited to, attorneys’ fees, resulting directly or indirectly from a claim (including without imitation, claims made by third parties for infringement of intellectual property rights) by a third party that arises in connection with (1) your use or misuse of the Site; (2) your breach of the Terms of Use; or (3) your violation of any law or the rights of a third party. You agree to cooperate as fully as reasonably required in the defense of any claim. Elite Nannies reserves the right to assume the exclusive defense and control of any matter otherwise subject to indemnification by you.</p>
                        <h3 className="sub-heading my-3">Limitation of Liability</h3>
                        <p>IN NO EVENT SHALL ELITE NANNIES OR THEIR RESPECTIVE OFFICERS, DIRECTORS, EMPLOYEES, AGENTS, SUCCESSORS, SUBSIDIARIES, DIVISIONS, AFFILIATES OR THIRD PARTIES PROVIDING INFORMATION ON THIS SITE BE LIABLE TO ANY USER OF THE SITE OR ANY OTHER PERSON OR ENTITY FOR ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, PUNITIVE, CONSEQUENTIAL OR EXEMPLARY DAMAGES (INCLUDING, BUT NOT LIMITED TO DAMAGES FOR LOSS OF PROFITS, LOSS OF DATA OR LOSS OF USE) ARISING OUT OF THE USE OR INABILITY TO USE THE SITE OR OTHERWISE, EVEN IF ELITE NANNIES HAS BEEN ADVISED OF OR SHOULD HAVE KNOWN OF THE POSSIBILITY OF SUCH DAMAGES OR LOSS. IN NO EVENT SHALL THE TOTAL LIABILITY OF ELITE NANNIES OR THEIR RESPECTIVE OFFICERS, DIRECTORS, EMPLOYEES, AGENTS, SUCCESSORS, SUBSIDIARIES, DIVISIONS, DISTRIBUTORS, SUPPLIERS, AFFILIATES OR THIRD PARTIES PROVIDING INFORMATION ON THIS SITE TO YOU FOR ALL DAMAGES, LOSSES, AND CAUSES OF ACTION RESULTING FROM YOUR USE OF THIS SITE WHETHER IN CONTRACT, TORT (INCLUDING BUT NOT LIMITED TO NEGLIGENCE) OR OTHERWISE EXCEED THE AMOUNT YOU PAID TO ELITE NANNIES IN CONNECTION WITH THE APPLICABLE EVENT, PROMOTION OR BOUTIQUE GIVING RISE TO SUCH LIABILITY. WITHOUT LIMITING THE FOREGOING, IN NO EVENT SHALL ELITE NANNIES OR THEIR RESPECTIVE OFFICERS DIRECTORS, EMPLOYEES, AGENTS, SUCCESSORS, SUBSIDIARIES, DIVISIONS, AFFILIATES OR THIRD PARTIES PROVIDING INFORMATION ON THIS SITE HAVE ANY LIABILITY FOR ANY DAMAGES OR LOSSES ARISING OUT OF OR OTHERWISE INCURRED IN CONNECTION WITH THE LOSS OF ANY DATA OR INFORMATION CONTAINED IN YOUR ACCOUNT OR OTHERWISE STORED BY OR ON BEHALF OF ELITE NANNIES.</p>
                        <p>You hereby acknowledge that the preceding paragraph shall apply to all content, merchandise and services available through the Site.</p>
                        <h3 className="sub-heading my-3">International Use</h3>
                        <p>We make no representation that information on this Site is appropriate or available for use outside the United States. Those who choose to access this Site from outside the United States do so on their own initiative and are responsible for compliance with local laws, if and to the extent local laws are applicable.</p>
                        <h3 className="sub-heading my-3">Unavailability of Site; Termination; Fraud</h3>
                        <p>We may alter, suspend, or discontinue this Site in whole or in part, at any time and for any reason, without notice or cost. We may in our sole discretion, terminate or suspend your use or access to all or part of the Site, or your account or membership for any reason, including without limitation, breach of these Terms of Use. If at any time, we notify you that your access to and/or use of the Site or your account is terminated, you must cease and desist from all such access and/or use immediately. In the event these Terms of Use or your membership or account are terminated the restrictions regarding intellectual property matters, the representations and warranties, indemnities, and limitations of liabilities set forth herein (as well as any other of your obligations which by their nature should survive termination) will survive termination.</p>
                        <h3 className="sub-heading my-3">Copyright Infringement</h3>
                        <p>Notice and Take Down Procedures: If you believe that any materials on this Site infringe your copyright, you may request that they be removed. This request must bear a signature of the copyright holder or an authorized representative and must : (a) identify the allegedly infringing materials; (b) indicate where on the Site the infringing materials are located; (c) provide your name and contact information; (d) state that you have a good faith belief that the materials are infringing; (e) state that the information in your claim is accurate; and (f) indicate that “under penalty of perjury” you are the lawful copyright owner or are authorized to act on the owner’s behalf. Our contact for copyright issues relating to this Site is: Copyright Agent: Elite Nannies, 4470 W Sunset Blvd Ste 195, Los Angeles, CA 90027, <a href="mailto:info@elitenannies.com">info@elitenannies.com</a></p>
                        <h3 className="sub-heading my-3">Choice of Law and Forum</h3>
                        <p>These Terms of Use and any dispute of any sort that might arise between you and Elite Nannies or its parent, subsidiaries or affiliates shall be governed by and construed in accordance with the laws of the state of California, without regard to its conflict of law principles, as it is applied to agreements entered into and performed therein. Any action brought to enforce this Agreement or matters related to the Site will be brought in either the state or federal courts in Los Angeles County, California, provided, however, that notwithstanding anything contained in these Terms of Use to the contrary we shall have the right to institute judicial proceedings against you or anyone acting by, through or under you, in order to enforce our rights hereunder through reformation of contract specific performance, injunction or similar equitable relief. You consent to the jurisdiction of such courts and irrevocably waive any objection including without limitation any objection to the laying of venue or based on the grounds of forum non conveniens which you may now or hereafter have to the bringing of any such action or proceeding in such jurisdiction. Any claim or cause of action you have with respect to use of the Site must be commenced within one (1) year after the claim arises.</p>
                        <h3 className="sub-heading my-3">Miscellaneous</h3>
                        <p>Unless otherwise specified herein, these Terms of Use, together with the Privacy Policy, constitute the entire agreement between you and Eliite Nannies with respect to the Site and supersede all prior or contemporaneous communications and proposals (whether oral, written, or electronic) between you and Elite Nannies with respect to the Site. If any part of these Terms of Use is held invalid or unenforceable, that portion shall be construed in a manner consistent with applicable law to reflect, as nearly as possible the original intentions of the parties, and the remaining portions shall remain in full force and effect. The failure of Elite Nannies to act with respect to a breach by you or others does not waive Elite Nannies’ right to act with respect to subsequent or similar breaches. One Elite Nannies’ failure to exercise or enforce any right or provision of these Terms of Use shall not constitute a waiver of such right or provision. The section headings contained in these Terms of Use are included for convenience only and shall not limit or otherwise affect the terms of these Terms of Use.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
            )
  }
}
export default TermsComponent;