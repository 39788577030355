import React, {Component, Fragment} from 'react';
import M from "materialize-css";
import {connect} from "react-redux";
import history from '../../helpers/history';
import LandingSlickCarousel from "./../skill-landing-pages/shared/LandingSlickCarousel";
// Images
import ImgCityBanner from "../../assets/images/city-banner.jpg";
import NewYorkOurrigorous from "../../assets/images/new-york-our-rigorous.jpg";
import ImgWhychooseus from "../../assets/images/Whychooseus.jpg";
import ImgNewYorkFacilitate from "../../assets/images/new-york-we-facilitate.png";
import ImgFooterBanner from "../../assets/images/new-york-thankyoufamily.jpg";
import ImgAvatar from '../../assets/images/new-york-client.png';
import logo from '../../assets/images/logo-white.svg';
import SvgLearn from '../../assets/images/svg/learn.svg';
import SvgSeek from '../../assets/images/svg/ic-cityseek.svg';
import SvgScreen from '../../assets/images/svg/screen.svg';
import SvgSupport from '../../assets/images/svg/support.svg';
import Svglocation from '../../assets/images/svg/location.svg';
import SingleSkill from "../skill-landing-pages/shared/SkillCard";
// import LandingSlickCarousel from "../skill-landing-pages/ForeignLanguageTeacher";


class NewYorkAgency extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    M.Parallax.init(this.Parallax1);
    M.Parallax.init(this.Parallax2);
    M.Parallax.init(this.Parallax3);
  }

  handleOnSearch = () => {
    const {isLoggedIn} = this.props.user;
    history.push(isLoggedIn ? '/family/preferences' : '/family/registration');
  };

  render() {
    const {skills} = this.props;
    return (
      <div className="page-content">
        <SectionBanner para1={Parallax => {
          this.Parallax1 = Parallax;
        }} onSearchClick={this.handleOnSearch}/>
        <SectionNewYorkOurrigorous/>
        <SectionWhyChooseUs para2={Parallax => {
          this.Parallax2 = Parallax;
        }}/>
        <SectionWeFacility onSearchClick={this.handleOnSearch}/>
        <SectionWeNannyQualifications skills={skills}/>
        <SectionFooter para3={Parallax => {
          this.Parallax3 = Parallax;
        }} onSearchClick={this.handleOnSearch}/>
      </div>
    )
  }
}

const SectionBanner = ({para1, onSearchClick}) => (
  <section className="parallax-container section">
    <div ref={para1} className="parallax">
      <img src={ImgCityBanner}/>
    </div>
    <div className="container">
      <div className="row align-items-center wow fadeInUp">
        <div className="col-12 col-lg-10 col-xl-8 col-xll-8 mx-auto">
          <div className="banner-caption">
            <div className="text-white text-center">
            <img src={logo} className="img-fluid mb-4 mb-lg-5" alt="Footer Logo" width="256" height="72" />
              <h2 className="heading text-white mb-3 text-uppercase">Elite Nannies in New York</h2>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-10 col-xl-8 col-xll-7 mx-auto">
          <div className="banner-caption">
            <div className="text-white text-center">
              <p className="mb-5 px-xll-3">Elite Nannies, maintaining quintessential living in your New York home.
              As a prestigious New York City nanny agency, we specialize in career nannies {/* & sitters */} who are also trained and qualified in an additional profession to childcare.</p>
              <button className="btn btn-outline-primary btn-lg text-uppercase text-white" onClick={onSearchClick}>search local nannies</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

const SectionNewYorkOurrigorous = () => (
  <section className="section">
    <div className="container-fluid">
      <div className="row align-items-center">
        <div className="col-12 col-lg-6 col-xl-6 wow fadeIn order-2 order-lg-1" data-wow-delay=".8s">
          <div className="row">
            <div className="col-12 col-md-10 col-xl-9 offset-xl-2 col-xll-8 offset-xll-3">
              <div className="incredibly-content pl-xl-4">
                <p className="sub-heading">Our rigorous matching process ensures your family is matched with the perfect
                  fit.</p>
                <div className="pr-xl-5 mr-xl-5">
                  <p>Once we have a firm grasp on your childcare philosophies, we'll put forward a shortlist of up to 5
                    incredibly well-suited candidates. </p>
                  <ul className="list-inline list-incredibly no-gutters">
                    <li className="list-inline-item">
                      <img src={SvgLearn} className="img-fluid" alt=""/>
                      <span className="incredibly-text">Learn</span>
                    </li>
                    <li className="list-inline-item list-line"></li>
                    <li className="list-inline-item">
                      <img src={SvgSeek} className="img-fluid" alt=""/>
                      <span className="incredibly-text">Seek</span>
                    </li>
                    <li className="list-inline-item list-line"></li>
                    <li className="list-inline-item">
                      <img src={SvgScreen} className="img-fluid" alt=""/>
                      <span className="incredibly-text">Screen</span>
                    </li>
                    <li className="list-inline-item list-line"></li>
                    <li className="list-inline-item">
                      <img src={SvgSupport} className="img-fluid" alt=""/>
                      <span className="incredibly-text">Support</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-6 col-xl-6 px-md-0 wow fadeInLeft  order-1 order-lg-2" data-wow-delay=".5s">
          <img src={NewYorkOurrigorous} className="w-100 img-fluid float-right mb-lg-0 mb-4" alt=""/>
        </div>
      </div>
    </div>
  </section>
);

const SectionWhyChooseUs = ({para2}) => (
  <section className="parallax-container section section-padding section-bg">
    <div ref={para2} className="parallax">
      <img src={ImgWhychooseus}/>
    </div>
    <div className="container-fluid">
      <div className="row align-items-center">
        <div className="col-12 col-lg-6 col-xl-6 wow fadeIn order-2 order-lg-1" data-wow-delay=".8s">
          <div className="row">
            <div className="col-12 col-md-10 col-xl-9 offset-xl-2 col-xll-8 offset-xll-3">
              <div className="incredibly-content pl-lg-4 pr-lg-5">
                <p className="sub-heading">Why choose us?</p>
                <p className="become-info">
                  New York Elite Nannies are professional nannies who are also skilled in other areas. These include
                  Teachers, Nurses, Foreign Language Teachers, Massage Therapists, Personal Trainers, Hair Stylists,
                  Beauty Therapists, Photographers and more.
                </p>
                <p className="become-info">
                  Our candidates here in New York understand the importance of not only keeping your children safe and
                  making them feel loved, but helping cultivate them into inspiring, intelligent and creative little
                  people.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

const SectionWeFacility = ({onSearchClick}) => (
  <section className="section">
    <div className="container-fluid">
      <div className="row align-items-center">
        <div className="col-12 col-lg-6 col-xl-6 px-md-0 wow fadeInLeft" data-wow-delay=".5s">
          <img src={ImgNewYorkFacilitate} className="w-100 img-fluid float-right mb-4 mb-lg-0" alt=""/>
        </div>
        <div className="col-12 col-lg-6 col-xl-6 wow fadeIn" data-wow-delay=".8s">
          <div className="row">
            <div className="col-12 col-xll-6 offset-xll-2 col-lg-11 offset-lg-1 pl-xll-0">
              <div className="incredibly-content">
                <p className="sub-heading">We facilitate the entire process, so you can have peace of mind.</p>
                <p className="mt-4">Not only do we match your unique family with an elite nanny, but once you have made
                  your hire, we can also assist you with tax and benefit information, so you can worry about the
                  important things! </p>
                <button className="btn btn-outline-primary btn-lg text-uppercase btn-250 mt-4" onClick={onSearchClick}>GET STARTED</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

const SectionWeNannyQualifications = ({skills}) => (
  <Fragment>
    <section className="section wow fadeInUp pt-0 pb-0">
      <div className="container px-xll-0">
        <div className="row">
          <div className="col-12 col-xl-7 col-xll-6 mx-auto">
            <div className="text-center wow fadeInUp incredibly-content mb-5">
              <h2 className="sub-heading mb-4">Nanny Qualifications</h2>
              <p className="px-lg-2">We only recruit honourable, educated and refined New York nannies that help families
                live an exceptional life. All of our nannies are CPR and First Aid trained and have a minimum of 3 years
                professional {/*nanny/sitter*/} nanny experience. They are incredibly well-versed in child-related safety measures -
                we want you to be able to leave your house each day with complete ease knowing your children are in the
                safest possible hands.</p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="section wow fadeInUp skills-slider pt-0 pb-0">
      <LandingSlickCarousel skills={skills} city={'new-york'}/>
    </section>

    <section className="section wow fadeInUp pt-0">
      <div className="container">
        <div className="mt-5 bg-light-5 section">
          <div className="row">
            <div className="col-12 col-xl-7 col-xll-6 mx-auto">
              <div className="px-3 px-lg-0">
                <div className="text-center">
                  <img src={ImgAvatar} width="14" className="img-fluid client-avatar mb-4" alt=""/>
                  <p>"Elite Nannies were incredibly personable. We met with Roxanne where she left us feeling completely
                    at ease about employing a nanny for the first time. The whole process went really smoothly. Four
                    months in, we're so happy with our nanny, she's become a part of our family."</p>
                  <p className="font-bold mb-1">Molly S. - HR Manager / Mom</p>
                  <p className="font-bold text-primary mb-0">
                    <img src={Svglocation} width="14" className="img-fluid" alt=""/> Manhattan, NY</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Fragment>
);

const SectionFooter = ({para3, onSearchClick}) => (
  <section className="parallax-container section mb-5 section-padding">
    <div ref={para3} className="parallax">
      <img src={ImgFooterBanner}/>
    </div>
    <div className="container">
      <div className="row align-items-center wow fadeInUp">
        <div className="col-12 col-lg-10 col-xl-8 col-xll-8 mx-auto">
          <div className="banner-caption incredibly-content">
            <div className="text-white text-center">
              <h2 className="sub-heading text-white mb-5 text-uppercase">There’s nothing more important than your
                family.</h2>
              <button className="btn btn-outline-primary btn-lg text-uppercase text-white btn-250" onClick={onSearchClick}>GET STARTED</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

const MapStateToProps = (state) => {
  return {
    skills: state.skills,
    user: state.user,
  }
};

export default connect(MapStateToProps, null)(NewYorkAgency);
