import React, {Component} from 'react'
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {isEmpty} from "lodash";
/* SERVICES */
import PageLoader from "../../components/PageLoader";
// Images
import location from '../../assets/images/svg/location.svg';

class FamilyAccountComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {user} = this.props.user;

    if (isEmpty(user)) {
      return <PageLoader/>
    }

    return (
        <div className="page-content page-details">
          <div className="container">
            <section className="section bg-lightdark">
              <div className="row">
                <div className="col-12 col-lg-8 col-xl-8 col-xll-10 mx-auto wow fadeInUp">
                  <div className="nannies-details">
                    <h2 className="heading">{user.first_name} {user.last_name}
                      <Link className="btn btn-edit float-right" to={`/family/update`}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 469.331 469.331">
                          <path fill="#ffffff"
                                d="M438.931 30.403c-40.4-40.5-106.1-40.5-146.5 0l-268.6 268.5c-2.1 2.1-3.4 4.8-3.8 7.7l-19.9 147.4c-.6 4.2.9 8.4 3.8 11.3 2.5 2.5 6 4 9.5 4 .6 0 1.2 0 1.8-.1l88.8-12c7.4-1 12.6-7.8 11.6-15.2-1-7.4-7.8-12.6-15.2-11.6l-71.2 9.6 13.9-102.8 108.2 108.2c2.5 2.5 6 4 9.5 4s7-1.4 9.5-4l268.6-268.5c19.6-19.6 30.4-45.6 30.4-73.3s-10.8-53.7-30.4-73.2zm-141.3 33l45.1 45.1-245.1 245.1-45.1-45.1 245.1-245.1zm-136.7 353.4l-44.1-44.1 245.1-245.1 44.1 44.1-245.1 245.1zm263.9-264.4l-107.9-107.9c13.7-11.3 30.8-17.5 48.8-17.5 20.5 0 39.7 8 54.2 22.4s22.4 33.7 22.4 54.2c0 18.1-6.2 35.1-17.5 48.8z"/>
                        </svg>
                      </Link>
                    </h2>
                    <div className="d-flex">
                      <ul className="list-inline d-flex mb-4 mb-md-5">
                        <li className="d-flex flex-wrap mr-3">
                          <img height="18" className="mr-1" src={location}/>
                          {user.location.address}
                        </li>
                        {/*
                        {
                          user.family.skills.map((x, idx) => {
                            return <li key={idx} className="d-flex flex-wrap mr-2">
                              <Thumbnail height={18} source={x.icon.name}/>&nbsp;{x.title}
                            </li>
                          })
                        }
                        */}
                      </ul>
                    </div>
                  </div>
                  <div className="nannies-contact-info">
                    <div className="row">
                      <div className="col-12 col-lg-4">
                        <div className="contact-info-item b-right mb-4 mb-md-5">
                          <span>Telephone</span>
                          <strong>{user.mobile_number}</strong>
                        </div>
                      </div>
                      <div className="col-12 col-lg-4">
                        <div className="contact-info-item">
                          <span>Email</span>
                          <strong>{user.email}</strong>
                        </div>
                      </div>
                      {/*
                      <div className="w-100"></div>
                      <div className="col-12 col-lg-4">
                        <div className="contact-info-item b-right">
                          <span>Place of work</span>
                          <strong>{user.family.place_of_work.address}</strong>
                        </div>
                      </div>
                      <div className="col-12 col-lg-4">
                        <div className="contact-info-item">
                          <span>How to soon to start</span>
                          <strong>{user.family.how_soon_start}</strong>
                        </div>
                      </div>
                      */}
                    </div>
                  </div>
                </div>
              </div>

            </section>
          </div>
        </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(FamilyAccountComponent);
