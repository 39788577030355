import React, {Component, Fragment} from 'react';
import {Helmet} from "react-helmet";
import ImgWhyElite from '../../../src/assets/images/why-choose-elite-nannies.jpg';

class WhyChooseComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentWillUnmount() {
    document.title = "Elite Nannies";
  }

  render() {
    return (
      <Fragment>
        <Helmet>
          <title>Why Choose Elite Nannies</title>
          <meta name="title" content="Why Choose Elite Nannies"/>
          <meta name="description"
                content="We’re passionate about finding our nannies and sitters the right family, because as former nannies ourselves, we know how important it is to have the right fit. "/>
          <meta property="og:type" content="website"/>
          <meta property="og:url" content="https://www.elitenannies.com/nanny/why-choose"/>
          <meta property="og:title" content="Why Choose Elite Nannies"/>
          <meta property="og:description"
                content="We’re passionate about finding our nannies and sitters the right family, because as former nannies ourselves, we know how important it is to have the right fit. "/>
          <meta property="og:image"
                content="https://www.elitenannies.com/static/media/why-choose-elite-nannies.1fcfd791.jpg"/>
          <meta property="twitter:card" content="summary_large_image"/>
          <meta property="twitter:url" content="https://www.elitenannies.com/nanny/why-choose"/>
          <meta property="twitter:title" content="Why Choose Elite Nannies"/>
          <meta property="twitter:description"
                content="We’re passionate about finding our nannies and sitters the right family, because as former nannies ourselves, we know how important it is to have the right fit. "/>
          <meta property="twitter:image"
                content="https://www.elitenannies.com/static/media/why-choose-elite-nannies.1fcfd791.jpg"/>
        </Helmet>
        <div className="page-content mt-0">
          <SectionWhyNanny/>
          <SectionWhyNannyContent/>
        </div>
      </Fragment>
    )
  }
}

const SectionWhyNanny = () => (
  <section className="section">
    <div className="container px-xll-0">
      <div className="row align-items-center">
        <div className="col-12 col-lg-6 mb-5 mb-lg-0 wow fadeIn">
          <div className="row">
            <div className="col-12 col-xl-11 col-xll-9">
              <h2 className="heading">WHY CHOOSE ELITE NANNIES</h2>
              <h3 className="sub-heading my-4">Elite is focused on enriching lives – both for families and
                nannies.</h3>
              <p>We’re passionate about finding our nannies {/* and sitters*/} the right family, because as former nannies
                ourselves, we know how important it is to have the right fit. We want you to feel appreciated and
                valued by your employer – this ultimately creates a happy work / life balance. And, of course, you can
                be certain that you’ll be compensated for your hard work and commitment.</p>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-6 wow fadeInRight">
          <img src={ImgWhyElite} className="img-fluid w-100" alt=""/>
        </div>
      </div>
    </div>
  </section>
);

const SectionWhyNannyContent = () => (
  <section className="section pt-0 section-why">
    <div className="container px-xll-0">
      <div className="row wow fadeInUp">
        <div className="col-12 col-lg-6 col-xl-6 mb-5">
          <div className="pr-xll-5">
            <h4 className="">Elite Nannies Deserve Elite Families</h4>
            <p>We only hire the top 5% of nannies because we offer the most prestigious positions in the country. With
              these positions, you can expect better work conditions and better rates of pay.</p>
          </div>
        </div>
        <div className="col-12 col-lg-6 col-xl-6 mb-5">
          <div className="pl-xl-4">
            <h4 className="">Elite Representation</h4>
            <p>You can be assured that we will support you every step of the way. We’re here to answer your questions,
              prep you for interviews and get a great understanding of your personal and professional needs, so we
              find the right family for you.</p>
          </div>
        </div>
        <div className="col-12 col-lg-6 col-xl-6 mb-5 mb-lg-0 mt-lg-4">
          <div className="pr-xll-5">
            <h4 className="">You’re Worth It!</h4>
            <p>We know child care is important work, so you should be more than adequately compensated. When you’re
              ready to accept a job offer, we’ll handle the negotiation on your behalf to ensure your compensation meets
              industry standards. We’re happy to negotiate compensation packages that include holiday, sick pay and bonuses
              too.</p>
          </div>
        </div>
        <div className="col-12 col-lg-6 col-xl-6 mb-lg-5 mb-lg-0 mt-lg-4">
          <div className="pl-xl-4">
            <h4 className="">No Cost To You</h4>
            <p>With Elite Nannies, you can access exclusive positions without paying a thing. We will never deduct
              from your pay. You take home what you earn!</p>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default WhyChooseComponent;
