import localForage from "localforage";

localForage.config({
  driver: localForage.WEBSQL, // Force WebSQL; same as using setDriver()
  name: 'elite-nanny',
  version: 1.0,
  size: 4980736, // Size of database, in bytes. WebSQL-only for now.
  storeName: 'keyvaluepairs', // Should be alphanumeric, with underscores.
  description: 'This database will hold gift information'
});

const authentication = localForage.createInstance({
  name: "auth"
});

/* KEY-VALUES */
export const setRecord = (key, value) => {
  return authentication.setItem(key, value);
};

export const getRecord = (key) => {
  return authentication.getItem(key);
};

export const removeRecord = (key) => {
  return authentication.removeItem(key);
};
