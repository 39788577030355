import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {Formik} from 'formik';
import toast from '../../Toast';
import {bool, object, string} from 'yup';
import TextInput from "../../components/UI/TextInput";
/* SERVICES */
import {PATCH} from "../../services/rest.service";
import {connect} from "react-redux";
import {APPEND_NANNY_REGISTRATION} from "../../redux/nanny-registration/NannyRegistrationReducer";
import {HIDE_LOADING, SHOW_LOADING} from "../../redux/loader/LoaderActions";
import {NUMBER_ONLY} from "../../helpers/constants";

const AuthorizationForm = (props) => (
    <Formik
        initialValues={{
          social_security_number: props.values.social_security_number,
          isAgreed: !!props.values.social_security_number
        }}
        onSubmit={(values) => {
          props.onSubmit(values);
        }}
        validationSchema={object().shape({
          social_security_number: string()
              // .required("Please enter a valid SSN")
              .matches(NUMBER_ONLY, "Please enter a valid SSN number")
              .max(9, "Please enter a valid SSN number"),
          isAgreed: bool()
              .test(
                  'isAgreed',
                  'Please tick the checkbox to continue!',
                  value => value === true
              )
              .required(
                  'Please tick the checkbox to continue!'
              ),
        })}>
      {renderProps => {
        const {values, touched, errors, handleChange, handleBlur, handleSubmit} = renderProps;
        const details = JSON.parse(localStorage.getItem('reg_auth'));
        return (
            <form onSubmit={handleSubmit}>
             <div className="form-group col-12">
               <label className="form-label" htmlFor="social_security_number">Social Security Number</label>
               <input type="number"
                      min={0}
                      name="social_security_number"
                      id="social_security_number"
                      placeholder="Enter your social security number"
                      className={"form-control " + (touched.social_security_number && errors.social_security_number && 'is-invalid')}
                      value={values.social_security_number}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      onKeyPress={(e) => {
                          if((e.target.value.length+1) <= 9){
                            return true;
                          } else {
                            e.preventDefault();
                            e.stopPropagation();
                            return false;
                          }
                        }
                      }
               />
               {touched.social_security_number && errors.social_security_number && <div className="invalid-feedback">{errors.social_security_number}</div>}
             </div>
              <div className="form-group col-12">
                <div className="custom-control custom-checkbox">
                  <input type="checkbox"
                         value={values.isAgreed}
                         onChange={handleChange}
                         onBlur={handleBlur}
                         className={`custom-control-input ${errors.isAgreed && touched.isAgreed && 'is-invalid'}`}
                         id="isAgreed" name="isAgreed"/>
                  <label className="custom-control-label" htmlFor="isAgreed">
                    <span>I, {props.nanny.first_name && props.nanny.first_name} {props.nanny.last_name && props.nanny.last_name + ','}</span> hereby authorize Elite Nannies LLC to investigate my
                    background and qualifications for purposes of evaluating whether I am qualified for the
                    position for which I am applying. I understand that Elite Nannies will utilize an outside firm
                    or firms to assist it in checking such information, and I specifically authorize such an
                    investigation by information services and outside entities of the company's choice.
                  </label>
                  <div className="invalid-feedback">{touched.isAgreed && errors.isAgreed}</div>
                </div>
              </div>

              <div className="form-group text-center my-5">
                <button type="submit" className="btn btn-outline-primary">SUBMIT APPLICATION</button>
              </div>
            </form>
        )
      }}
    </Formik>
);

class NannyRegAuthComponent extends Component {

  handleSubmit = (values) => {
    const payload = {
      ...this.state.personalDetails,
      social_security_number: values.social_security_number.toString()
    };
    this.props.doShowLoading('Adding SSN');
    PATCH('/auth/user/nanny_register/step5', payload)
        .then(res => {
          localStorage.setItem('reg_auth', JSON.stringify({email: res.data.email, _id: res.data._id, first_name: res.data.first_name, last_name: res.data.last_name, _confirmed: res.data.isEmailConfirmed}));
          this.props.history.push('/nanny/thank-you-nanny-registration');
          toast.success('Social Security number has beed added to your profile.');
          this.props.doSetSecurityNumber({social_security_number: values.social_security_number});
          this.props.doHideLoading();
        })
        .catch(err => {
          this.props.doHideLoading();
          toast.error(err.message);
        })
  };

  constructor(props) {
    super(props);
    this.state = {
      personalDetails: JSON.parse(localStorage.getItem('reg_auth'))
    }
  }

  render() {
    const {social_security_number} = this.props.nanny;
    return (
        <div className="page-content page-auth">
          <div className="container">
            <section className="section bg-auth">
              <div className="row row-20">
                <div className="col-12 col-lg-9 col-xl-7 col-xll-6 mx-auto wow fadeInUp">
                  <div className="text-center mb-5">
                    <h2 className="heading">
                      <Link to={`/nanny/references`} className="btn btn-back">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 268.8 268.8">
                          <path
                              d="M3.7 143.3l80 80c2.4 2.4 5.6 3.7 8.8 3.7s6.4-1.2 8.8-3.7c4.9-4.9 4.9-12.8 0-17.7l-58.7-58.7h213.7c6.9 0 12.5-5.6 12.5-12.5s-5.6-12.5-12.5-12.5H42.7l58.7-58.7c4.9-4.9 4.9-12.8 0-17.7-4.9-4.9-12.8-4.9-17.7 0l-80 80c-4.9 5-4.9 12.9 0 17.8z"
                              fill="#313131"/>
                        </svg>
                      </Link>
                      CREATE PROFILE
                    </h2>
                    <p className="text-note">SSN and background check authorization.</p>
                  </div>
                  <AuthorizationForm nanny={this.props.nanny} values={{social_security_number}} onSubmit={this.handleSubmit}/>
                </div>
              </div>
            </section>
          </div>
        </div>
    )
  }
}

const MapStateToProps = (state) => {
  return {
    nanny: state.nanny
  }
};

const MapDispatchToProps = (dispatch) => {
  return {
    doSetSecurityNumber: (data) => {
      dispatch({
        type: APPEND_NANNY_REGISTRATION,
        payload: data
      })
    },
    doShowLoading: (title) => {
      dispatch({
        type: SHOW_LOADING,
        payload: {
          title,
          isLoading: true,
        }
      })
    },
    doHideLoading: () => {
      dispatch({
        type: HIDE_LOADING,
        payload: {
          title: '',
          isLoading: false,
        }
      })
    }
  }
};

export default connect(MapStateToProps, MapDispatchToProps)(NannyRegAuthComponent);
