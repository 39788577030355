import React, {Component} from 'react';
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import history from '../../helpers/history';
import ImgSkillPrivateTeacher from '../../assets/images/skill-private-teacher.png';

class PrivateTeacherDetails extends Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  goBack = () => {
    history.push('/our-nannies');
  };

  render() {
    const {isLoggedIn, user} = this.props.user;

    return (
      <div className="page-content mt-0">
        <section className="section section-skills">
          <div className="container mt-2 mt-md-4 mt-lg-5 px-xll-0">
            <div className="row">
              <div className="col-12 w-100 wow fadeInRight">
                <button className="btn btn-clear btn-back-skills p-0" onClick={this.goBack}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="26" viewBox="0 0 31.494 31.494">
                    <path
                      d="M10.273 5.009a1.112 1.112 0 0 1 1.587 0 1.12 1.12 0 0 1 0 1.571l-8.047 8.047h26.554c.619 0 1.127.492 1.127 1.111s-.508 1.127-1.127 1.127H3.813l8.047 8.032c.429.444.429 1.159 0 1.587a1.112 1.112 0 0 1-1.587 0L.321 16.532a1.12 1.12 0 0 1 0-1.571l9.952-9.952z"
                      fill="#7f7f7f"/>
                  </svg>
                </button>
              </div>
              <div className="col-12 col-lg-6 wow fadeInLeft">
                <h2 className="heading mb-4 d-block d-lg-none">PRIVATE EDUCATOR </h2>
                <img src={ImgSkillPrivateTeacher} className="w-100 img-fluid" alt=""/>
              </div>
              <div className="col-12 col-lg-6 col-xl-5 ml-auto wow fadeIn">
                <div className="skill-content pt-0 pt-5 py-md-5">
                  <h2 className="heading mb-4 d-none d-lg-block">PRIVATE EDUCATOR </h2>
                  <p>
                    All of our nannies are CPR and first aid trained with a minimum of 3 years professional nanny
                    experience. The Private Educator at Elite Nannies hold a Bachelor of Education or equivalent,
                    specializing in various education styles including Montessori, Waldorf and Classical. These nannies
                    are incredibly specialized, honing in on your child's schooling requirements where they'll make
                    informed and intelligent decisions about practice to achieve various outcomes with and for your
                    child as their 'student'. They make judgments about how best to help your child learn in the
                    environments in which they are teaching. Our Private Educator are perfectly suited to families with
                    children that require additional tutoring and families that require some level of home-schooling,
                    whether it be full-time home-schooling or educating your children whilst your travel.
                  </p>
                  <p>
                    If you are traveling frequently, our nannies are incredibly flexible to travel with you for extended
                    periods of time so that you aren't separated from your children and they can continue their
                    schooling. Our Private Educator research your travel itinerary in advance, so they can tailor the
                    learning experience. Imagine, your children are learning about modern history as they explore ruins
                    in Italy.
                  </p>
                </div>
              </div>
              {/* <div className="col-12 w-100 mt-0 mt-lg-5 text-center wow fadeInUp"> */}
                {/*<Link to={'/search/nannies?skills=Private%20Educators'} className="btn btn-skill btn-lg">START YOUR SEARCH</Link>*/}
                {/* <Link to={'/family/registration'} className="btn btn-skill btn-lg">START YOUR SEARCH</Link> */}
              {/* </div> */}
            </div>
          </div>
        </section>
        <section className="section section-applynow bg-light">
          <div className="container">
            <div className="banner-caption">
              <div className="row wow fadeInUp">
                <div className="col-12 col-xl-9 col-xll-8 mx-auto">
                  <div className="banner-text">
                    <h2 className="heading">Want A Qualified Educator As Your Nanny?</h2>
                    <Link to={!isLoggedIn ? '/family/registration' : '/family/preferences'} className="btn btn-outline-primary">START YOUR SEARCH</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }
}

const MapStateToProps = (state) => {
  return {
    user: state.user
  }
};

export default connect(MapStateToProps, null)(PrivateTeacherDetails);
