import React, {Component} from 'react';
import {Redirect, Route, Switch, withRouter} from 'react-router-dom'
import {connect} from "react-redux";
import classnames from 'classnames';
import {cssTransition, toast, ToastContainer} from 'react-toastify';
/* ACTIONS */
import {getLoggedInUser} from "./redux/user/UserActions";
import {getAllSkills} from "./redux/skills/SkillsActions";
/* PARTIALS */
import FooterComponent from "./partials/Footer";
import HeaderComponent from "./partials/Header";
import AppLoader from "./components/AppLoader";
/* PAGES */
// Public and Non Auth Pages
import HomeComponent from "./containers/HomeComponent";
import ContactComponent from "./containers/ContactComponent";
import ForgotPasswordComponent from "./containers/forgot/ForgotPassword";
import ResetPasswordComponent from "./containers/reset-password/ResetPasswordComponent";
import VerifyComponent from "./containers/VerifyComponent"
import HowItWorksComponent from "./containers/nannies/HowItWorksComponent";
import RequirementsComponent from "./containers/nannies/RequirementsComponent";
import SafeSitterProcessComponent from "./containers/nannies/SafeSitterProcessComponent";
import AboutUsComponent from "./containers/nannies/AboutUsComponent";
import WhyChooseComponent from "./containers/nannies/WhyChooseComponent";
import MatchingProcessComponent from "./containers/nannies/MatchingProcessComponent";
import OurNanniesComponent from "./containers/nannies/OurNanniesComponent";
import PrivacyComponent from "./containers/PrivacyComponent";
import TermsComponent from "./containers/TermsComponent";
import NannyPricingComponent from "./containers/family/NannyPricingComponent";
import SearchComponent from "./containers/search/SearchComponent";
import IndividualJobComponent from './containers/nannies/IndividualJobComponent';
import PlacementFeesComponent from "./containers/nannies/PlacementFeesComponent";
import SitterPricingComponent from "./containers/family/SitterPricingComponent";
import RequestNowComponent from "./containers/family/RequestNowComponent";
import FamilyRequestSitterComponent from "./containers/family/FamilyRequestSitterComponent";
import FamilySitterAppConf from "./containers/family/FamilySitterAppConf";
import GetStarted from "./containers/GetStarted";
import SignIn from "./containers/Signin";
import NotFound from "./containers/NotFound";
// Family
import FamilyLoginComponent from "./containers/family/FamilyLoginComponent";
import FamilyRegPerDetailsComponent from "./containers/family/FamilyRegPerDetailsComponent";
import FamilyRegPreferencesComponent from "./containers/family/FamilyRegPreferencesComponent";
import FamilyRegConfirmComponent from "./containers/family/FamilyRegConfirmComponent";
import FamilyEditComponent from "./containers/family/FamilyEditComponent";
import FamilyAccountComponent from "./containers/family/FamilyAccountComponent";
import FamilyLookingForComponent from "./containers/family/FamilyLookingForComponent";
import FamilyNannyAppConf from "./containers/family/FamilyNannyAppConf";
import MembershipComponent from "./containers/family/MembershipComponent";
import MembershipPayment from "./containers/family/MembershipPayment";
import FamilyOneTimeSubscription from "./containers/family/FamilyOneTimeSubscription";
import RegistrationFees from "./containers/family/RegistrationFees";
// Nanny
import NannyAccountComponent from "./containers/nannies/NannyAccountComponent";
import NannyLoginComponent from "./containers/nannies/NannyLoginComponent";
import NannyViewComponent from "./containers/nannies/NannyViewComponent";
import NannyRegPerDetailsComponent from "./containers/nannies/NannyRegPerDetailsComponent";
import NannyRegProfileComponent from "./containers/nannies/NannyRegProfileComponent";
import NannyRegSkillsComponent from "./containers/nannies/NannyRegSkillsComponent";
import NannyRegAuthComponent from "./containers/nannies/NannyRegAuthComponent";
import NannyRegConfirmationComponent from "./containers/nannies/NannyRegConfirmationComponent";
import NannyRegThankYouComponent from "./containers/nannies/NannyRegThankYouComponent";
import NannyRegRefsComponent from "./containers/nannies/NannyRegRefsComponent";
import NannyRegTypeComponent from "./containers/nannies/NannyRegTypeComponent";
import NannyAccountUpdateComponent from "./containers/nannies/NannyAccountUpdateComponent";
import JobListingsComponent from "./containers/nannies/JobListingsComponent";
import JobThankYouComponent from "./containers/nannies/JobThankYouComponent";
// Agency pages
import NewYorkAgency from "./containers/nanny-agency/NewYorkAgency";
import LosAngelesAgency from "./containers/nanny-agency/LosAngelesAgency";
import LandingFooter from './containers/nanny-agency/LandingFooter';
// Skill Details Pages
import PrivateTeacherDetails from "./containers/skill-detail/PrivateTeacherDetails";
import GovernessDetails from "./containers/skill-detail/GovernessDetails";
import ChefDetails from "./containers/skill-detail/ChefDetails";
import SeamstressDetails from "./containers/skill-detail/SeamstressDetails";
import MassageTherapistDetails from "./containers/skill-detail/MassageTherapistDetails";
import PersonalTrainerDetails from "./containers/skill-detail/PersonalTrainerDetails";
import NurseDetails from "./containers/skill-detail/NurseDetails";
import FamilyAssistantDetails from "./containers/skill-detail/FamilyAssistantDetails";
import NewbornCareSpecialistDetails from "./containers/skill-detail/NewbornCareSpecialistDetails";
import BeautyTherapistDetails from "./containers/skill-detail/BeautyTherapistDetails";
import HairStylistDetails from "./containers/skill-detail/HairStylistDetails";
import ForeignLanguageTeacher from "./containers/skill-landing-pages/ForeignLanguageTeacher";
import ChineseMandarinTeacher from "./containers/nannies/ChineseMandarinTeacher";
import ForeignLanguageTeacherDetails from "./containers/skill-detail/ForeignLanguageTeacherDetails";
// Skill Landing Pages
import BeautyTherapist from "./containers/skill-landing-pages/BeautyTherapist";
import HairStylist from "./containers/skill-landing-pages/HairStylist";
import Seamstress from "./containers/skill-landing-pages/Seamstress";
import PersonalTrainer from "./containers/skill-landing-pages/PersonalTrainer";
import MassageTherapist from "./containers/skill-landing-pages/MassageTherapist";
import Chef from "./containers/skill-landing-pages/Chef";
import NewBornCareSpecialist from "./containers/skill-landing-pages/NewBornCareSpecialist";
// For wor js
import WOW from 'wowjs';
import jquery from 'jquery';
import SanFranciscoAgency from './containers/nanny-agency/SanFranciscoAgency';

window.$ = window.jQuery = jquery;

/* CONSTANTS */
const slide = cssTransition({
  enter: 'fadeIn',
  exit: 'fadeOut',
});

export const PrivateRoute = ({component: Component, ...rest}) => {
  return (
    <Route {...rest} render={props => (
      localStorage.getItem('token')
        ? <Component {...props} />
        : <Redirect to={{pathname: '/', state: {from: props.location}}}/>
    )}/>
  )
};

class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      headerLessPage: false,
    };
  }

  componentDidMount() {
    const {dispatch} = this.props;
    if (localStorage.getItem('token')) {
      dispatch(getLoggedInUser());
    }
    dispatch(getAllSkills());
    new WOW.WOW().init();

    // Restricting header from displaying into landing pages
    this.checkIfHeaderLessPage(window.location.pathname);


    this.unlisten = this.props.history.listen((location, action) => {
      this.checkIfHeaderLessPage(location.pathname);
    });
  }

  checkIfHeaderLessPage = (route) => {
    const routes = route.split('/');

    // For Footer
    if (!routes.includes('nanny-agency') && (routes.includes('los-angeles') || routes.includes('new-york-city')) || routes.includes('san-francisco')) {
      this.setState({
        isDefaultFooter: true,
      })
    } else {
      this.setState({
        isDefaultFooter: false,
      })
    }

    // For Header
    if (routes.includes('nanny-agency') || routes.includes('los-angeles') || routes.includes('new-york-city') || routes.includes('san-francisco')) {
      this.setState({
        headerLessPage: true,
      })
    } else {
      this.setState({
        headerLessPage: false,
      })
    }
  };


  render() {
    const {user, isLoggedIn, type} = this.props.user;
    const {headerLessPage} = this.state;

    const WrapperClasses = classnames(
      'page-wrapper',
      {
        'pt-0 landing-pages': headerLessPage,
      }
    );

    return (
      <div className="app-layout">
        {this.props.loader.isLoading && <AppLoader title={this.props.loader.title}/>}
        {!headerLessPage && <HeaderComponent/>}
        <div className={WrapperClasses}>
          <Switch>
            <Route exact path="/" component={HomeComponent}/>
            <Route exact path="/get-started" component={GetStarted}/>
            <Route exact path="/sign-in" component={SignIn}/>

            <Route exact path="/contact-us" component={ContactComponent}/>

            <Route exact path="/our-nannies" component={OurNanniesComponent}/>
            <Redirect from={'/nanny/our-nannies'} to={`/our-nannies`} />

            <Route
              path="/search"
              render={({match: {url}}) => {
                return (
                  <Switch>
                    <Route path={`${url}/nannies`} component={SearchComponent}/>
                    <Route path={`${url}/sitters`} component={SearchComponent}/>
                    <Route exact path="**" component={NotFound}/>
                  </Switch>
                )
              }}/>

            <Route exact path="/about-us" component={AboutUsComponent}/>

            <Route
              path="/nanny-agency"
              render={({match: {url}}) => {
                return (
                  <Switch>
                    <Route path={`${url}/los-angeles`} component={LosAngelesAgency}/>
                    <Route path={`${url}/san-francisco`} component={SanFranciscoAgency}/>
                    <Route path={`${url}/new-york-city`} component={NewYorkAgency}/>
                    <Route exact path="**" component={NotFound}/>
                  </Switch>
                )
              }}
            />

            <Route
              path="/san-francisco"
              render={({match: {url}}) => {
                return (
                  <Switch>
                    <Route path={`${url}/beauty-therapist-nanny`} component={BeautyTherapist}/>
                    <Route path={`${url}/chef-nanny`} component={Chef}/>
                    <Route path={`${url}/foreign-language-teacher-nanny`} component={ForeignLanguageTeacher}/>
                    <Route path={`${url}/governess-nanny`} component={GovernessDetails}/>
                    <Route path={`${url}/hair-stylist-nanny`} component={HairStylist}/>
                    <Route path={`${url}/massage-therapist-nanny`} component={MassageTherapist}/>
                    <Route path={`${url}/newborn-care-specialist-nanny`} component={NewBornCareSpecialist}/>
                    <Route path={`${url}/nurse-nanny`} component={NurseDetails}/>
                    <Route path={`${url}/personal-family-assistant-nanny`} component={FamilyAssistantDetails}/>
                    <Route path={`${url}/personal-trainer-nanny`} component={PersonalTrainer}/>
                    <Route path={`${url}/private-educator-nanny`} component={PrivateTeacherDetails}/>
                    <Route path={`${url}/seamstress-nanny`} component={Seamstress}/>
                    <Route path={`${url}/chinese-mandarin-teacher`} component={ChineseMandarinTeacher}/>
                    <Route exact path="**" component={NotFound}/>
                  </Switch>
                )
              }}
            />

            <Route
              path="/los-angeles"
              render={({match: {url}}) => {
                return (
                  <Switch>
                    <Route path={`${url}/beauty-therapist-nanny`} component={BeautyTherapist}/>
                    <Route path={`${url}/chef-nanny`} component={Chef}/>
                    <Route path={`${url}/foreign-language-teacher-nanny`} component={ForeignLanguageTeacher}/>
                    <Route path={`${url}/governess-nanny`} component={GovernessDetails}/>
                    <Route path={`${url}/hair-stylist-nanny`} component={HairStylist}/>
                    <Route path={`${url}/massage-therapist-nanny`} component={MassageTherapist}/>
                    <Route path={`${url}/newborn-care-specialist-nanny`} component={NewBornCareSpecialist}/>
                    <Route path={`${url}/nurse-nanny`} component={NurseDetails}/>
                    <Route path={`${url}/personal-family-assistant-nanny`} component={FamilyAssistantDetails}/>
                    <Route path={`${url}/personal-trainer-nanny`} component={PersonalTrainer}/>
                    <Route path={`${url}/private-educator-nanny`} component={PrivateTeacherDetails}/>
                    <Route path={`${url}/seamstress-nanny`} component={Seamstress}/>
                    <Route path={`${url}/chinese-mandarin-teacher`} component={ChineseMandarinTeacher}/>
                    <Route exact path="**" component={NotFound}/>
                  </Switch>
                )
              }}
            />

            <Route
              path="/new-york-city"
              render={({match: {url}}) => {
                return (
                  <Switch>
                    <Route path={`${url}/beauty-therapist-nanny`} component={BeautyTherapist}/>
                    <Route path={`${url}/chef-nanny`} component={Chef}/>
                    <Route path={`${url}/foreign-language-teacher-nanny`} component={ForeignLanguageTeacher}/>
                    <Route path={`${url}/governess-nanny`} component={GovernessDetails}/>
                    <Route path={`${url}/hair-stylist-nanny`} component={HairStylist}/>
                    <Route path={`${url}/massage-therapist-nanny`} component={MassageTherapist}/>
                    <Route path={`${url}/newborn-care-specialist-nanny`} component={NewBornCareSpecialist}/>
                    <Route path={`${url}/nurse-nanny`} component={NurseDetails}/>
                    <Route path={`${url}/personal-family-assistant-nanny`} component={FamilyAssistantDetails}/>
                    <Route path={`${url}/personal-trainer-nanny`} component={PersonalTrainer}/>
                    <Route path={`${url}/private-educator-nanny`} component={PrivateTeacherDetails}/>
                    <Route path={`${url}/seamstress-nanny`} component={Seamstress}/>
                    <Route path={`${url}/chinese-mandarin-teacher`} component={ChineseMandarinTeacher}/>
                    <Route exact path="**" component={NotFound}/>
                  </Switch>
                )
              }}
            />

            <Route
              path="/family"
              render={({match: {url}}) => {
                return (
                  <Switch>
                    {/* NON AUTH PAGES */}
                    <Route path={`${url}/login`} component={FamilyLoginComponent}/>
                    <Route path={`${url}/registration`} component={FamilyRegPerDetailsComponent}/>

                    {/* PUBLIC PAGES */}
                    <Route path={`${url}/placement-fees`} component={PlacementFeesComponent} />
                    <Route path={`${url}/screening-process`} component={SafeSitterProcessComponent}/>
                    <Route path={`${url}/nanny-pricing`} component={NannyPricingComponent}/>
                    <Route path={`${url}/sitter-pricing`} component={SitterPricingComponent}/>

                    {/*PROTECTED ROUTES*/}
                    <PrivateRoute path={`${url}/request-a-sitter`} component={SafeSitterProcessComponent}/>
                    <PrivateRoute path={`${url}/request-now`} component={RequestNowComponent}/>
                    <PrivateRoute path={`${url}/request-sitter`} component={FamilyRequestSitterComponent}/>
                    <PrivateRoute path={`${url}/thank-you-sitter-request`} component={FamilySitterAppConf}/>
                    <PrivateRoute path={`${url}/thank-you-nanny-request`} component={FamilyNannyAppConf}/>
                    <PrivateRoute path={`${url}/looking-for`} component={FamilyLookingForComponent}/>
                    <PrivateRoute path={`${url}/preferences`} component={FamilyRegPreferencesComponent}/>
                    <PrivateRoute path={`${url}/confirmation`} component={FamilyRegConfirmComponent}/>
                    <PrivateRoute path={`${url}/account`} component={FamilyAccountComponent}/>
                    <PrivateRoute path={`${url}/update`} component={FamilyEditComponent}/>
                    <PrivateRoute path={`${url}/membership`} component={MembershipComponent}/>
                    <PrivateRoute path={`${url}/payments`} component={MembershipPayment}/>
                    <PrivateRoute path={`${url}/buy-nanny-package`} component={FamilyOneTimeSubscription}/>
                    <PrivateRoute exact path={`${url}/nanny-package/payment`} component={RegistrationFees}/>

                    {/*MANUAL REDIRECT*/}
                    {/*<Redirect from={`${url}`} to={`${url}/login`} />*/}

                    {/*Not found*/}
                    <Route exact path="**" component={NotFound}/>
                  </Switch>
                )
              }}
            />

            <Route
              path="/nanny-services"
              render={({match: {url}}) => {
                return (
                  <Switch>
                    {/* PUBLIC PAGES */}
                    <Route path={`${url}/beauty-therapist`} component={BeautyTherapistDetails}/>
                    <Route path={`${url}/chef`} component={ChefDetails}/>
                    <Route path={`${url}/foreign-language-teacher`} component={ForeignLanguageTeacherDetails}/>
                    <Route path={`${url}/governess`} component={GovernessDetails}/>
                    <Route path={`${url}/hair-stylist`} component={HairStylistDetails}/>
                    <Route path={`${url}/massage-therapist`} component={MassageTherapistDetails}/>
                    <Route path={`${url}/newborn-care-specialist`} component={NewbornCareSpecialistDetails}/>
                    <Route path={`${url}/nurse`} component={NurseDetails}/>
                    <Route path={`${url}/personal-family-assistant`} component={FamilyAssistantDetails}/>
                    <Route path={`${url}/personal-trainer`} component={PersonalTrainerDetails}/>
                    <Route path={`${url}/private-educator`} component={PrivateTeacherDetails}/>
                    <Route path={`${url}/seamstress`} component={SeamstressDetails}/>
                    <Route path={`${url}/chinese-mandarin-teacher`} component={ChineseMandarinTeacher}/>
                    <Route exact path="**" component={NotFound}/>
                  </Switch>
                )
              }}
            />

            <Route
              path="/nanny"
              render={({match: {url}}) => {
                return (
                  <Switch>
                    {/* NON AUTH PAGES */}
                    <Route path={`${url}/login`} component={NannyLoginComponent}/>
                    <Route path={`${url}/registration`} component={NannyRegPerDetailsComponent}/>

                    <Route path={`${url}/type`} component={NannyRegTypeComponent}/>
                    <Route path={`${url}/profile`} component={NannyRegProfileComponent}/>
                    <Route path={`${url}/skills`} component={NannyRegSkillsComponent}/>
                    <Route path={`${url}/references`} component={NannyRegRefsComponent}/>
                    <Route path={`${url}/authorization`} component={NannyRegAuthComponent}/>
                    <Route path={`${url}/thank-you-nanny-registration`} component={NannyRegThankYouComponent}/>
                    <Route path={`${url}/confirmation`} component={NannyRegConfirmationComponent}/>
                    <Route path={`${url}/view/:id`} component={NannyViewComponent}/>


                    <Route path={`${url}/how-it-works`} component={HowItWorksComponent}/>
                    <Route path={`${url}/requirements`} component={RequirementsComponent}/>
                    <Route path={`${url}/why-choose`} component={WhyChooseComponent}/>
                    <Route path={`${url}/matching-process`} component={MatchingProcessComponent}/>

                    <Route path={`${url}/jobs/:type`} component={JobListingsComponent}/>
                    {/* <Route path={`${url}/jobs/los-angeles`} component={JobListingsComponent}/> */}
                    <Route path={`${url}/jobs`} component={JobListingsComponent}/>

                    <Route path={`${url}/jobs-thankyou`} component={JobThankYouComponent}/>
                    <Route path={`${url}/job-posting/:type`} component={IndividualJobComponent}/>



                    {/*PROTECTED ROUTES*/}
                    <PrivateRoute path={`${url}/account`} component={NannyAccountComponent}/>
                    <PrivateRoute path={`${url}/update`} component={NannyAccountUpdateComponent}/>

                    {/*MANUAL REDIRECT*/}
                    {/*<Redirect from={`${url}`} to={`${url}/login`} />*/}

                    {/*Not found*/}
                    <Route exact path="**" component={NotFound}/>
                  </Switch>
                )
              }}
            />
            <Route exact path="/forgot/:type" component={ForgotPasswordComponent}/>
            <Route exact path="/verification" component={VerifyComponent}/>
            <Route exact path="/reset-password" component={ResetPasswordComponent}/>
            <Route exact path="/privacy-policy" component={PrivacyComponent}/>
            <Route exact path="/terms-of-use" component={TermsComponent}/>
            <Route exact path="**" component={NotFound}/>
          </Switch>
          {!headerLessPage ? <FooterComponent/> : <LandingFooter/>}
        </div>
        <ToastContainer transition={slide}
                        autoClose={4000}
                        position={toast.POSITION.BOTTOM_RIGHT}
                        closeOnClick={true}
                        hideProgressBar={true}/>
      </div>
    );
  }
}

const MapStateToProps = (state) => {
  return {
    loader: state.loading,
    user: state.user
  }
};

export default withRouter(connect(MapStateToProps, null)(App));
