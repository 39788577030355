import React, {Component, Fragment} from 'react';
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet";


class HowItWorksComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentWillUnmount() {
    document.title = "Elite Nannies";
  }

  render() {
    return (
      <Fragment>
        <Helmet>
          <title>Elite Nannies Application & Hiring Process</title>
          <meta name="title" content="Elite Nannies Application & Hiring Process"/>
          <meta name="description"
                content="If you are passionate about working with children, and are qualified in a specialty field, then apply now to become an Elite Nanny."/>
          <meta property="og:type" content="website"/>
          <meta property="og:url" content="https://www.elitenannies.com/nanny/how-it-works"/>
          <meta property="og:title" content="Elite Nannies Application & Hiring Process"/>
          <meta property="og:description"
                content="If you are passionate about working with children, and are qualified in a specialty field, then apply now to become an Elite Nanny."/>
          <meta property="og:image" content="https://www.elitenannies.com/static/media/why-elite-nanny.64304e6f.jpg"/>
          <meta property="twitter:card" content="summary_large_image"/>
          <meta property="twitter:url" content="https://www.elitenannies.com/nanny/how-it-works"/>
          <meta property="twitter:title" content="Elite Nannies Application & Hiring Process"/>
          <meta property="twitter:description"
                content="If you are passionate about working with children, and are qualified in a specialty field, then apply now to become an Elite Nanny."/>
          <meta property="twitter:image"
                content="https://www.elitenannies.com/static/media/why-elite-nanny.64304e6f.jpg"/>
        </Helmet>
        <div className="page-content">
          <SectionBanner/>
          <SectionProcess/>
          <SectionApplyNow/>
        </div>
      </Fragment>
    )
  }
}

const SectionBanner = () => (
  <section className="page-banner section bg-lightdark">
    <div className="container">
      <div className="banner-caption">
        <div className="row">
          <div className="col-12 col-xl-8 col-xll-7 mx-auto wow fadeInUp">
            <div className="banner-text">
              <h2 className="heading page-heading">How It works</h2>
              <h3 className="sub-heading my-2">Love children? Want a fulfilling career?</h3>
              <p className="mb-0 px-xll-3">If you are passionate about working with children and are qualified in a
              specialty field, <Link to={'/nanny/registration'}><b>apply now</b></Link> to
                become an Elite Nanny!</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

const SectionProcess = () => (
  <section className="section">
    <div className="container">
      <div className="text-center">
        <h2 className="heading text-inherit">The Application and<br/> Hiring Process</h2>
      </div>
      <div className="row process-list mt-3 px-xll-3">
        <div className="col-12 col-xl-8 col-xll-7 mx-auto wow fadeInUp">
          <div className="processitem">
            <div className="media">
              <span className="mr-4 process-number">1</span>
              <div className="media-body">
                <h5 className="">We Get To Know You</h5>
                <p>Firstly, we’ll review your resume to ensure you have the right level of experience required to become
                  an Elite Nanny. We’ll then invite you to attend a face-to-face interview, so we can get to know you
                  and understand your career goals.</p>
              </div>
            </div>
          </div>
          <div className="processitem">
            <div className="media">
              <span className="mr-4 process-number">2</span>
              <div className="media-body">
                <h5 className="">The Serious Stuff</h5>
                <p>Once you are successful in the interview process, there will be a comprehensive reference check of
                  both your nanny{/*nanny/sitter*/} reviews and your additional qualification.</p>
              </div>
            </div>
          </div>
          <div className="processitem">
            <div className="media">
              <span className="mr-4 process-number">3</span>
              <div className="media-body">
                <h5 className="">We Match You</h5>
                <p className="mb-3">If you’ve applied to our nanny platform, we will search for families that require
                  your unique skills and put forward your profile for consideration. If you are selected as a potential
                  nanny, we will then schedule and coordinate meetings with families. We provide ongoing support to you
                  along the entire journey to ensure you are matched with the perfect family.</p>
                {/*<p>If you’ve applied to our sitter platform, your profile will be added to our database of sitters so*/}
                  {/*that potential families can begin contacting you and vice versa.</p>*/}
              </div>
            </div>
          </div>
          <div className="processitem">
            <div className="media">
              <span className="mr-4 process-number">4</span>
              <div className="media-body">
                <h5 className="">Your Rate of Pay</h5>
                <p className="mb-3">When you’re hired by a family as a nanny, we’ll ensure that you receive the appropriate gross hourly rate and the best package possible. We will also go over your employment contract to ensure it’s fair for both parties.</p>
                {/*<p>As a sitter, families will pay you directly at the end of each sitter job.</p>*/}
              </div>
            </div>
          </div>
          <div className="processitem">
            <div className="media">
              <span className="mr-4 process-number">5</span>
              <div className="media-body">
                <h5 className="">We Are Your Support Network</h5>
                <p>Once you have commenced your new career, should you need it, we will provide ongoing support. We will
                  always be available to answer any questions or address any concerns.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

const SectionApplyNow = () => (
  <section className="section section-applynow bg-light">
    <div className="container">
      <div className="banner-caption">
        <div className="row">
          <div className="col-12 col-xl-8 col-xll-7 mx-auto wow fadeInUp">
            <div className="banner-text">
              <h2 className="heading heading-36">Apply Now To Become An Elite Nanny</h2>
              <Link to={'/nanny/registration'} className="btn btn-outline-primary">Join Our Team</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default HowItWorksComponent;
