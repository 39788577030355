import React, { Component } from 'react';
import { Formik } from "formik";
import { POST } from "../services/rest.service";
import { toast } from "react-toastify";
import { object, string } from "yup";
import { connect } from "react-redux";
import { hideLoading, showLoading } from "../redux/loader/LoaderActions";


export const SubscriptionForm = (props) => (
  <Formik
    initialValues={{
      email: "",
      uesrType: 'Family'
    }}
    onSubmit={(values, { resetForm }) => {
      props.onSubmit(values);
      resetForm({ email: "" });
    }}
    validationSchema={object().shape({
      email: string().email("Enter a valid email").required("Email is required"),
    })}>
    {renderProps => {
      const { values, errors, handleChange, handleBlur, handleSubmit, touched } = renderProps;
      return (
        <form onSubmit={handleSubmit}>
          <div className="row no-gutters">
            <div className="col-12 col-md-6 col-lg-6">
              <div className="form-group  pt-3 mb-0 mx-2">
                <input type="email" name="email" id="email"
                  placeholder="Enter your email"
                  className={"form-control " + (errors.email && 'is-invalid')}
                  value={values.email}
                  onBlur={handleBlur}
                  onChange={handleChange} />
                {touched.email && errors.email && <div className={'invalid-feedback'}>{errors.email}</div>}
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-3">
              <div className="form-group pt-3 mb-0 mx-2">

                <select className="form-control icon-down" name="uesrType" value={values.uesrType} onChange={handleChange}>
                  <option value="Family" label="Family">Family</option>
                  <option value="Nanny" label="Nanny">Nanny</option>
                </select>

              </div>
            </div>
            <div className="col-12 col-lg-3">
              <div className="form-group text-center pt-3 mb-0 mx-2">
                <button className="btn btn-outline-primary btn-lg mx-auto text-uppercase" type="submit">Subscribe</button>
              </div>
            </div>
          </div>
        </form>
      )
    }}
  </Formik>
);


class NewsletterComponent extends Component {

  handleSubmit = (value) => {
    const { dispatch } = this.props;
    dispatch(showLoading());
    POST('/general/blog/newsletter/subscribe', value)
      .then(res => {
        toast.success('Thank you for subscribing to our newsletter');
        dispatch(hideLoading());
      })
      .catch(err => {
        toast.error('Something went wrong! Please try again later');
        dispatch(hideLoading());
      })
  };

  render() {
    return (
      <section className="section section-newsletter">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-10 col-xl-8 col-xll-7 mx-auto wow fadeInUp">
              <div className="text-center">
                <h2 className="heading mt-1 mb-3">Sign up to our newsletter</h2>
                <p className="mb-4">Keep up to date with our latest nannies, news and blog posts.</p>
              </div>

              <SubscriptionForm onSubmit={this.handleSubmit} />
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default connect()(NewsletterComponent);
