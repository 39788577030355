import React, {Component} from 'react';
import {connect} from "react-redux";
import {isEmpty,get} from 'lodash';
import config from '../../config';
// Components
import PageLoader from "../../components/PageLoader";
// IMAGES
import location from '../../assets/images/svg/location.svg';
import acrobat from '../../assets/images/svg/acrobat.svg';
import Thumbnail from "../../components/ThumbnailComponent";
import {Link} from "react-router-dom";
import attachment from '../../assets/images/svg/attachment.svg';


class NannyAccountComponent extends Component {
  render() {

    const {user} = this.props.user;

    if (isEmpty(user)) {
      return <PageLoader/>
    }

    return (
        <div className="page-content page-details page-account">
          <div className="container">
            <section className="section py-0">
              <div className="row">
                <div className="col-12 col-lg-4 col-xl-4 wow fadeInUp">
                  <div className="nannies-profile-avatar bg-lightdark py-5">
                    <Thumbnail width={244} height={244} source={user.nanny.profile_picture.name}
                               className="nannies-profile-image"/>
                  </div>
                </div>
                <div className="col-12 col-lg-8">
                  <div className="ml-0 ml-lg-5 mt-5 mt-lg-0">
                    <div className="wow fadeInUp" data-wow-delay=".5s">
                      <div className="nannies-details">
                        <h2 className="heading sub-heading font-muli">
                          {user.first_name} {user.last_name}
                          {user.nanny && !isEmpty(user.nanny.resume) &&
                          
                          <>
                              <img height="18" className="ml-2 mr-1" src={attachment} alt="location"/>

                              <a href={`${config.api.URL}${get(user,'nanny.resume.document.name')}`} rel="noopener" rel="noreferrer" target="_blank">
                              {get(user,'nanny.resume.resumeFileName')}                                          </a>


                              

                            </>}


                          <Link to={'/nanny/update'} className="btn btn-edit float-right">
                            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15"
                                 viewBox="0 0 469.331 469.331">
                              <path fill="#ffffff"
                                    d="M438.931 30.403c-40.4-40.5-106.1-40.5-146.5 0l-268.6 268.5c-2.1 2.1-3.4 4.8-3.8 7.7l-19.9 147.4c-.6 4.2.9 8.4 3.8 11.3 2.5 2.5 6 4 9.5 4 .6 0 1.2 0 1.8-.1l88.8-12c7.4-1 12.6-7.8 11.6-15.2-1-7.4-7.8-12.6-15.2-11.6l-71.2 9.6 13.9-102.8 108.2 108.2c2.5 2.5 6 4 9.5 4s7-1.4 9.5-4l268.6-268.5c19.6-19.6 30.4-45.6 30.4-73.3s-10.8-53.7-30.4-73.2zm-141.3 33l45.1 45.1-245.1 245.1-45.1-45.1 245.1-245.1zm-136.7 353.4l-44.1-44.1 245.1-245.1 44.1 44.1-245.1 245.1zm263.9-264.4l-107.9-107.9c13.7-11.3 30.8-17.5 48.8-17.5 20.5 0 39.7 8 54.2 22.4s22.4 33.7 22.4 54.2c0 18.1-6.2 35.1-17.5 48.8z"/>
                            </svg>
                          </Link>
                        </h2>
                        <div className="d-flex">
                          <ul className="list-inline d-flex">
                            <li className="d-flex flex-wrap mr-3">
                              <img height="18" className="mr-1" src={location} alt="location"/>
                              {user.location.address}
                            </li>
                           {/* {user.nanny && !isEmpty(user.nanny.resume) && <li className="d-flex flex-wrap mr-3">
                              <img height="18" className="mr-1" src={attachment} alt="location"/>

                              <a href={`${config.api.URL}${get(user,'nanny.resume.document.name')}`} rel="noopener" rel="noreferrer" target="_blank">
                              {get(user,'nanny.resume.resumeFileName')}                                          </a>


                              

                            </li>} */}
                          </ul>
                        </div>
                      </div>
                      <div className="nannies-contact-info">
                        <div className="row">
                          <div className="col-12 col-lg-4">
                            <div className="contact-info-item b-right">
                              <span>Telephone</span>
                              <strong>{user.mobile_number}</strong>
                            </div>
                          </div>
                          <div className="col-12 col-lg-4">
                            <div className="contact-info-item">
                              <span>Email</span>
                              <strong>{user.email}</strong>
                            </div>
                          </div>
                          <div className="w-100"></div>
                          <div className="col-12">
                            <div className="contact-info-item">
                              <span>Elite Skills</span>
                              <div className="d-flex account-skill-list">
                                <ul className="list-inline mb-0">
                                  {
                                    user.nanny.skills.length > 0
                                        ? user.nanny.skills.map((x, index) => {
                                          return <li key={index} className="list-inline-item mr-3 mb-3">
                                            <Thumbnail height={30}
                                                       className="mr-2 align-middle"
                                                       source={x.skill_id.icon.name}
                                                       alt={x.skill_id.title}/>&nbsp;{x.skill_id.title}
                                          </li>
                                        })
                                        : <p>Not yet added <br/> <Link to={'/nanny/update'}>Add Skills</Link></p>
                                  }
                                  {
                                    user.nanny.other_skills.map((x, index) => {
                                      return (<li key={index} className="list-inline-item mr-3 mb-3">
                                            {x.title}
                                          </li>
                                      )
                                    })
                                  }
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr/>
                    </div>
                    <div className="wow fadeInUp" data-wow-delay="1s">
                      <h2 className="heading pt-4">certificate</h2>
                      <div className="nannies-contact-info">
                        <div className="row">
                          {
                            (user.nanny.skills.length > 0)
                                && user.nanny.skills.map((x, index) => {
                                  return (
                                      <div key={index} className="col-12 col-lg-5">
                                        <div className="contact-info-item">
                                          {/*<span className="mb-2">{x.skill_id.title}</span>*/}
                                        {x.document&&  <a href={`${config.api.URL}${x.document.name}`} rel="noopener" rel="noreferrer" target="_blank">
                                            <img height="30" className="mr-2 align-middle" src={acrobat} alt="acrobat"/>
                                          </a>}
                                          <strong>{x.skill_id.title}</strong>
                                        </div>
                                      </div>
                                  )
                                })
                          }

                          {
                            user.nanny.other_skills.map((x, index) => {
                              return (
                                  <div key={index} className="col-12 col-lg-5">
                                    <div className="contact-info-item">
                                      {/*<span className="mb-2">{x.skill_id.title}</span>*/}
                                     {x.document&& <a href={`${config.api.URL}${x.document.name}`} target="_blank" rel="noopener" rel="noreferrer">
                                        <img height="30" className="mr-2 align-middle" src={acrobat} alt="acrobat"/>
                                      </a>}
                                      <strong>{x.title}</strong>
                                    </div>
                                  </div>
                              )
                            })
                          }


                        </div>
                      </div>
                      <hr/>
                    </div>
                    <div className="wow fadeInUp" data-wow-delay="1.5s">
                      <h2 className="heading pt-4">REFERENCES</h2>
                      <div className="nannies-contact-info">
                        <div className="row">
                          {
                            user.nanny.reference.length > 0
                                ? user.nanny.reference.map((ref, index) => {
                                  return <div key={index} className="col-12 col-lg-5">
                                    <div className="contact-info-item b-right">
                                      <strong className="mb-2">{ref.name}</strong>
                                      <span>{ref.phone_number}</span>
                                    </div>
                                  </div>
                                })
                                : <div className="col-12 text-center"><h5>Not yet added</h5> <Link to={'/nanny/update'}>Add
                                  References</Link></div>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
    )
  }
}

const MapStateToProps = (state) => {
  return {
    user: state.user
  }
};

export default connect(MapStateToProps, null)(NannyAccountComponent);
