import React, {Component, Fragment} from 'react';
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet";

class RequirementsComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentWillUnmount() {
    document.title = "Elite Nannies";
  }

  render() {
    return (
      <Fragment>
        <Helmet>
          <title>Requirements to Become an Elite Nanny</title>
          <meta name="title" content="Requirements to Become an Elite Nanny"/>
          <meta name="description" content="If you hold at least 2 years experience in childcare and are also qualified in an additional field of practice, we’d love for you to apply now."/>
          <meta property="og:type" content="website"/>
          <meta property="og:url" content="https://www.elitenannies.com/nanny/requirements"/>
          <meta property="og:title" content="Requirements to Become an Elite Nanny"/>
          <meta property="og:description" content="If you hold at least 2 years experience in childcare and are also qualified in an additional field of practice, we’d love for you to apply now."/>
          <meta property="og:image" content="https://www.elitenannies.com/static/media/alina-kovalchuk.b9612e17.jpg"/>
          <meta property="twitter:card" content="summary_large_image"/>
          <meta property="twitter:url" content="https://www.elitenannies.com/nanny/requirements"/>
          <meta property="twitter:title" content="Requirements to Become an Elite Nanny"/>
          <meta property="twitter:description" content="If you hold at least 2 years experience in childcare and are also qualified in an additional field of practice, we’d love for you to apply now."/>
          <meta property="twitter:image" content="https://www.elitenannies.com/static/media/alina-kovalchuk.b9612e17.jpg"/>
        </Helmet>
        <div className="page-content">
          <SectionBanner/>
          <SectionProcess/>
          <SectionApplyNow/>
        </div>
      </Fragment>
    )
  }
}

const SectionBanner = () => (
  <section className="page-banner section bg-lightdark">
    <div className="container">
      <div className="banner-caption">
        <div className="row wow fadeInUp">
          <div className="col-12">
            <div className="banner-text">
              <h2 className="heading page-heading">Requirements</h2>
              <p className="mb-0">To become an Elite Nanny, you must fulfill the following requirements :</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

const SectionProcess = () => (
  <section className="section">
    <div className="container px-xll-0">
      <div className="row">
        <div className="col-12 col-lg-6 col-xl-6 wow fadeInUp">
          <ul className="list-group list-icon border-right border-right border-md-0 border-c-light pr-5">
            <li className="list-group-item">A minimum of 3 years
              professional childcare experience (at least 1 of which must be as a professional nanny for a private
              family).
            </li>
            <li className="list-group-item">Incredibly shining childcare references</li>
            <li className="list-group-item">High school diploma</li>
            <li className="list-group-item">Bachelor degree (or equivalent), associate’s degree, certification or
              extensive experience in additional skill
            </li>
            <li className="list-group-item">Legal status to work in the USA</li>
            <li className="list-group-item mb-lg-0">Completely proficient in English (reading, writing & speaking)</li>
          </ul>
        </div>
        <div className="col-12 col-lg-6 col-xl-6 wow fadeInUp">
          <ul className="list-group list-icon pl-lg-4">
            <li className="list-group-item">No criminal record</li>
            <li className="list-group-item">Valid driver’s license with a strong driving record</li>
            <li className="list-group-item">CPR & First Aid Certificate (or willing to complete certification at own
              expense)
            </li>
            <li className="list-group-item">At least 21 years of age</li>
            <li className="list-group-item">Valid passport</li>
            <li className="list-group-item">Non-smoker</li>
          </ul>
        </div>
      </div>
    </div>
  </section>
);

const SectionApplyNow = () => (
  <section className="section section-applynow bg-light">
    <div className="container">
      <div className="banner-caption">
        <div className="row wow fadeInUp">
          <div className="col-12 col-xl-9 col-xll-8 mx-auto">
            <div className="banner-text">
              <h2 className="heading">Want To Become An Elite Nanny?</h2>
              <Link to={'/nanny/registration'} className="btn btn-outline-primary">SIGN UP NOW</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default RequirementsComponent;
