import React, {Component} from 'react';
import {Formik} from "formik";
import queryString from "query-string";
import {Select} from 'semantic-ui-react';
import history from '../../helpers/history';
import {array, date, object, string} from 'yup';
import AsyncSelect from 'react-select/lib/Async';
import TimePicker from 'rc-time-picker';
import moment from 'moment';
import {isArray, isEmpty, isNumber, isObject, isString, isUndefined} from 'lodash';
import {connect} from "react-redux";
import toast from '../../Toast';
// Components
import MultipleDatePicker from '../../components/multiple-date-picker';
import FormikPlaceSearch from "../../components/UI/FormikPlaceSearch";
// Services
import {POST, PUT} from "../../services/rest.service";
import {hideLoading, showLoading} from "../../redux/loader/LoaderActions";
// Others
import {CARE_OPTIONS, customStyles, PET_OPTIONS} from '../../helpers/constants';

class FamilyRequestSitterComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      currentStep: 1,
      nannies: [],
      userName: "",
      preferredSitters: [],
    };
  }

  changeStep = (step) => {
    this.setState({currentStep: step});
  };

  handleSubmit = (data) => {
    const {dispatch} = this.props;
    dispatch(showLoading());
    PUT('/auth/user/family_register/request-sitter', data)
        .then((res) => {
          history.push('/family/thank-you-sitter-request');
          dispatch(hideLoading());
        })
        .catch(err => {
          dispatch(hideLoading());
          toast.error(err.message);
        })
  };

  setInitialSkillsValue = (skills) => {
    let arr = [];
    if (isEmpty(skills) || isUndefined(skills)) {
      skills = arr;
    } else if (!isArray(skills) && isString(skills)) {
      arr.push(skills);
      skills = arr;
    }
    return skills;
  };

  promiseOptions = inputValue =>
      new Promise(resolve => {
        const parsed = queryString.parse(this.props.location.search);
        parsed.skills = this.setInitialSkillsValue(parsed.skills);
        POST(`/users/search/keyword/sitter`, {...parsed, searchTerm: inputValue})
            .then((res) => {
              let data = res.data.map((x, index) => {
                return {
                  id: index,
                  value: x.user_id,
                  label: `${x.first_name} ${x.last_name}`
                }
              });
              this.setState({preferredSitters:  data});
              resolve(data);
            })
            .catch((err) => {
              toast.error(err.message);
            })
      });

  componentDidMount() {
    const parsed = queryString.parse(this.props.location.search);
    if(!isEmpty(parsed)) {
       this.setState({
         userName: parsed.usr_source,
       })
    }
  }

  handleBack = () => {
    this.changeStep(this.state.currentStep - 1)
  };


  render() {
    const {currentStep, preferredSitters, userName} = this.state;
    const format = "hh:mm a";

    const BackButton = (
          <a className="btn btn-back btn-back-inline" href="javascript:void(0);" onClick={this.handleBack}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 268.8 268.8">
            <path d="M3.7 143.3l80 80c2.4 2.4 5.6 3.7 8.8 3.7s6.4-1.2 8.8-3.7c4.9-4.9 4.9-12.8 0-17.7l-58.7-58.7h213.7c6.9 0 12.5-5.6 12.5-12.5s-5.6-12.5-12.5-12.5H42.7l58.7-58.7c4.9-4.9 4.9-12.8 0-17.7-4.9-4.9-12.8-4.9-17.7 0l-80 80c-4.9 5-4.9 12.9 0 17.8z"
            fill="#f1d1cc"/>
            </svg>
          </a>
    )
    return (
        <div className="page-content page-auth">
          <div className="container">
            <section className="section section-request-sitter bg-auth">
              <div className="row align-items-center">
                <div className="col-12 col-md-11 col-lg-10 col-xl-7 mx-auto wow fadeInUp">
                  <div className="text-center mb-5">
                    <h2 className="heading text-uppercase">
                      {currentStep >= 2 && BackButton}
                      BOOK {userName ? userName : 'A SITTER'}
                    </h2>
                  </div>
                  <Formik initialValues={{
                            preferred: preferredSitters,
                            required_date: [],
                            needed_start_time: moment(),
                            needed_end_time: moment(),
                            types_of_care: '',
                            service_zipcode: '',
                            transport_children: '',
                            easy_free_parking: '',
                            no_of_children: '',
                            children_age: '',
                            message: '',
                            do_have_pets: ''
                          }} onSubmit={(values) => {
                            this.handleSubmit(values);
                          }}
                          validationSchema={object().shape({
                            preferred: array(),
                            required_date: array(),
                            needed_start_time: date(),
                            needed_end_time: date(),
                            types_of_care: string(),
                            service_zipcode: string(),
                            transport_children: string(),
                            easy_free_parking: string(),
                            no_of_children: string().required("Please mention number of children you have"),
                            children_age: string(),
                            message: string(),
                            do_have_pets: string(),
                          })}>
                    {renderProps => {
                      const {values, touched, errors, handleChange, setFieldValue, handleSubmit} = renderProps;

                      return (
                          <form className="row request-sitter-form" onSubmit={handleSubmit}>
                            <div className={'col-12 ' + (currentStep === 1 ? 'block' : 'd-none')}>
                              <div className="form-group mb-0">
                                <h4>Neighborhood</h4>
                                <label className="form-label" htmlFor="service_zipcode">
                                  Let your sitter know which area of Los Angeles they will be travelling to and
                                  providing care:
                                </label>
                                <FormikPlaceSearch id="service_zipcode"
                                                   name="service_zipcode"
                                                   type="search"
                                                   label=""
                                                   placeholder="Select your location"
                                                   error={touched.service_zipcode && errors.service_zipcode}
                                                   value={isObject(values.service_zipcode) ? values.service_zipcode.address : values.service_zipcode}
                                                   size={''}
                                                   onChange={(e) => {
                                                     const place = JSON.parse(localStorage.getItem('location'));
                                                     setFieldValue('service_zipcode', place);
                                                     setTimeout(() => {
                                                       localStorage.removeItem('location')
                                                     }, 100)
                                                   }}/>
                              </div>
                            </div>
                            <div className={'col-12 ' + (currentStep === 2 ? 'block' : 'd-none')}>
                              <div className="form-group">
                                <h4>Number of children</h4>
                                <label className="form-label">How many children for this sitter? For safety, we recommend a sitter to child ratio of 1:3 or 1:4 depending on children's
                                ages.</label>
                                <input className={"form-control"}
                                       type="number"
                                       min={1}
                                       id="no_of_children"
                                       name="no_of_children"
                                       value={values.no_of_children}
                                       placeholder="Number of children"
                                       onChange={handleChange}/>
                                {errors.no_of_children &&
                                <div className="invalid-feedback">{errors.no_of_children}</div>}
                              </div>
                              <div className="form-group">
                                <h4 className="mt-4">Ages of children</h4>
                                <label className="form-label">List the age range including the youngest child and oldest child - separated by a comma, such as 1,4,7 or list an age
                                range, such as 1-7.</label>
                                <input className="form-control"
                                       type="text"
                                       id="children_age"
                                       name="children_age"
                                       value={values.children_age}
                                       placeholder="Example: 3-5"
                                       onChange={handleChange}/>
                                {errors.children_age && <div className="invalid-feedback">{errors.children_age}</div>}
                              </div>
                            </div>

                            <div className={'form-group col-12 ' + (currentStep === 3 ? 'block' : 'd-none')}>
                              <h4>Do you have pets on site?</h4>
                              <Select placeholder={'-- Select pets on site --'}
                                      selection
                                      options={PET_OPTIONS}
                                      values={values.do_have_pets}
                                      onChange={(e, {value}) => {
                                        setFieldValue('do_have_pets', value)
                                      }}/>
                            </div>
                            <div className={'form-group col-12 ' + (currentStep === 4 ? 'block' : 'd-none')}>
                              <h4>Do you have a preferred sitter? (Optional)</h4>
                              <label htmlFor="preferred" className="form-label">If you have a favourite
                                sitter, or would like to reconnect with sitters you’ve used before, list then here
                                to send a priority alert:</label>
                              <AsyncSelect
                                  styles={customStyles}
                                  isMulti={true}
                                  cacheOptions
                                  defaultOptions
                                  onChange={(e) => {
                                    let values = e.map(x => x.value);
                                    setFieldValue('preferred', values)
                                  }}
                                  theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 0,
                                    colors: {
                                      ...theme.colors,
                                      primary25: '#e6aead',
                                      primary: '#e6aead',
                                    },
                                  })}
                                  className="auto-select"
                                  loadOptions={this.promiseOptions}>
                              </AsyncSelect>
                            </div>
                            <div className={'col-12 ' + (currentStep === 5 ? 'block' : 'd-none')}>
                              <div className="form-group multidate-control">
                                <h4>Date/s needed</h4>
                                <label className="form-label d-block">Sitters will check their availability for the
                                  dates listed:</label>
                                <MultipleDatePicker onSubmit={dates => setFieldValue('required_date', dates)}/>
                              </div>
                              <div className="form-group">
                                <h4 className="mt-4">Times needed</h4>
                                <label className="form-label">Please try to list both a start and finish time, or
                                  request a flexible finish so sitters can avoid conflicts with their other
                                  bookings:</label>
                                <div className="row cus-timepicker">
                                  <div className="col-6">
                                    <TimePicker
                                        style={{width: '100%'}}
                                        value={values.needed_start_time}
                                        defaultValue={moment()}
                                        minuteStep={5}
                                        className="form-control"
                                        use12Hours
                                        format={format}
                                        showSecond={false}
                                        allowEmpty={false}
                                        onChange={(e) => setFieldValue('needed_start_time', moment(e))}
                                    />
                                  </div>
                                  <div className="col-6">
                                    <TimePicker
                                        style={{width: '100%'}}
                                        value={values.needed_end_time}
                                        defaultValue={moment()}
                                        minuteStep={5}
                                        className="form-control"
                                        format={format}
                                        use12Hours
                                        showSecond={false}
                                        allowEmpty={false}
                                        onChange={(e) => setFieldValue('needed_end_time', moment(e))}
                                    />
                                  </div>
                                  {values.needed_start_time > values.needed_end_time &&
                                      <div className="col-12"><small className="text-danger">Start time and end time are not valid</small></div>}
                                </div>
                              </div>
                            </div>

                            <div className={'col-12 ' + (currentStep === 6 ? 'block' : 'd-none')}>
                              <div className="form-group">
                                <h4>TYPES OF CARE NEEDED</h4>
                                <Select placeholder={'-- Select Types of care --'}
                                        selection
                                        options={CARE_OPTIONS}
                                        values={values.types_of_care}
                                        onChange={(e, {value}) => {
                                          setFieldValue('types_of_care', value)
                                        }}/>
                              </div>
                              <div className="form-group">
                                <h4 className="mt-4">Driving children required?</h4>
                                <div className="custom-control custom-radio">
                                  <input id="yes"
                                         name="transport_children"
                                         type="radio"
                                         value="Yes"
                                         checked={values.transport_children == 'Yes'}
                                         className="custom-control-input"
                                         onChange={handleChange}/>

                                  <label className="custom-control-label" htmlFor="yes">
                                    Yes
                                  </label>
                                </div>
                                <div className="custom-control custom-radio">
                                  <input id="no"
                                         name="transport_children"
                                         type="radio"
                                         value="No"
                                         checked={values.transport_children == 'No'}
                                         className="custom-control-input"
                                         onChange={handleChange}/>
                                  <label className="custom-control-label" htmlFor="no">
                                    No
                                  </label>
                                </div>
                                <div className="custom-control custom-radio">
                                  <input id="sometimes"
                                         name="transport_children"
                                         type="radio"
                                         value="Maybe sometimes"
                                         checked={values.transport_children == 'Maybe sometimes'}
                                         className="custom-control-input"
                                         onChange={handleChange}/>
                                  <label className="custom-control-label" htmlFor="sometimes">
                                    Maybe sometimes
                                  </label>
                                </div>
                              </div>
                              <div className="form-group">
                                <h4>Can the sitter easily find free parking?</h4>
                                <div className="custom-control custom-radio">
                                  <input id="freeParkingYes"
                                         name="easy_free_parking"
                                         type="radio"
                                         value="Yes"
                                         checked={values.easy_free_parking == 'Yes'}
                                         className="custom-control-input"
                                         onChange={handleChange}/>
                                  <label className="custom-control-label" htmlFor="freeParkingYes">
                                    Yes
                                  </label>
                                </div>
                                <div className="custom-control custom-radio">
                                  <input id="freeParkingNo"
                                         name="easy_free_parking"
                                         type="radio"
                                         value="No"
                                         checked={values.easy_free_parking == 'No'}
                                         className="custom-control-input"
                                         onChange={handleChange}/>
                                  <label className="custom-control-label" htmlFor="freeParkingNo">
                                    No
                                  </label>
                                </div>
                                <div className="custom-control custom-radio">
                                  <input id="freeParkingNotFamiliar"
                                         name="easy_free_parking"
                                         type="radio"
                                         value="I’m not familiar with the location"
                                         checked={values.easy_free_parking == 'I’m not familiar with the location'}
                                         className="custom-control-input"
                                         onChange={handleChange}/>
                                  <label className="custom-control-label" htmlFor="freeParkingNotFamiliar">
                                    I’m not familiar with the location
                                  </label>
                                </div>
                              </div>
                            </div>


                            <div className={'form-group col-12 ' + (currentStep === 7 ? 'block' : 'd-none')}>
                              <h4>Message</h4>
                              <label className="form-label">Let the sitters know a little more about you and your
                                family and the job requirements.</label>
                              <textarea name="message" className="form-control" id="message"
                                        value={values.message} onChange={handleChange}/>
                            </div>

                            <div className="form-group col-12 mt-3 mb-0 text-center">
                              {
                                currentStep == 1 &&
                                <button className="btn btn-signup" disabled={isEmpty(values.service_zipcode)}
                                        onClick={() => this.changeStep(2)}>
                                  Next
                                </button>
                              }
                              {
                                currentStep == 2 &&
                                <button className="btn btn-signup"
                                        disabled={!isNumber(values.no_of_children) || values.no_of_children < 0 || isEmpty(values.children_age)}
                                        onClick={() => this.changeStep(3)}>
                                  Next
                                </button>
                              }
                              {
                                currentStep == 3 &&
                                <button className="btn btn-signup"
                                        disabled={isEmpty(values.do_have_pets)}
                                        onClick={() => this.changeStep(4)}>
                                  Next
                                </button>
                              }
                              {
                                currentStep == 4 &&
                                <button className="btn btn-signup" onClick={() => this.changeStep(5)}>
                                  Next
                                </button>
                              }
                              {
                                currentStep == 5 &&
                                <button className="btn btn-signup"
                                        disabled={!isArray(values.required_date) ||
                                        values.required_date.length == 0 ||
                                        isEmpty(values.needed_start_time) ||
                                        isEmpty(values.needed_end_time) ||
                                        values.needed_start_time > values.needed_end_time}
                                        onClick={() => this.changeStep(6)}>
                                  Next
                                </button>
                              }
                              {
                                currentStep == 6 &&
                                <button className="btn btn-signup"
                                        disabled={isEmpty(values.types_of_care) || isEmpty(values.easy_free_parking) || isEmpty(values.transport_children)}
                                        onClick={() => this.changeStep(7)}>
                                  Next
                                </button>
                              }
                              {
                                currentStep == 7 &&
                                <button type={'submit'}
                                        disabled={isEmpty(values.message)}
                                        onClick={handleSubmit}
                                        className="btn btn-signup bg-primary text-white btn-lg mb-3">
                                  Submit Request
                                </button>
                              }
                            </div>
                          </form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </section>
          </div>
        </div>
    )
  }
}

export default connect()(FamilyRequestSitterComponent);
