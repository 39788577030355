import React, { Component, Fragment } from 'react';
import toast from '../Toast';
import history from '../helpers/history';
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import moment from 'moment';
import config from "../config";
import { Helmet } from "react-helmet";
import Slider from "react-slick";
import M from "materialize-css";
// Services
import { GET } from "../services/rest.service";
// Components
import Thumbnail from "../components/ThumbnailComponent";
// Images
import ImgWhyElite from '../../src/assets/images/why-elite-nanny.jpg';
import Nannyhireprocess from '../../src/assets/images/alina-kovalchuk.jpg';
import RoxannePeake from '../../src/assets/images/roxanne-dominic.jpg';
import Processlearn from '../../src/assets/images/svg/learn.svg';
import ProcessSeek from '../../src/assets/images/svg/seek.svg';
import ProcessInterview from '../../src/assets/images/svg/interview.svg';
import ProcessEmploy from '../../src/assets/images/svg/employ.svg';
import ProcessSupport from '../../src/assets/images/svg/support.svg';
import NewsletterComponent from "./NewsletterComponent";
import Image1 from "../assets/images/banner-quote.jpg";
import Image2 from "../assets/images/become-nanny-bg.jpg";
import ImgAvatar from '../assets/images/testimonial.jpg';
import Svglocation from '../assets/images/svg/location.svg';
// Others
import { stringToSlug } from "../helpers/helper";

class HomeComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      topNannies: [],
      blogs: [],
      place: '',
      sitter:null,
      topNanniesFlag:null,
    };
  }

  handlePlaceSearch = (e) => {
    const location = JSON.parse(localStorage.getItem('location'));
    const city = e.split(',')[0];
    const long = location.longitude;
    const lat = location.latitude;
    history.push(`/search/nannies?location=${city}&long=${long}&lat=${lat}`);
  };

  componentDidMount() {
    let nannies = [];
    GET('/users/get/top/nannies')
      .then(res => {
        nannies = res.data;
        return GET('/general/blog/get');
      })
      .then(blogRes => {
        this.setState({
          topNannies: nannies,
          blogs: blogRes.data,
        })
      })
      .catch(err => {
        toast.error(err.message);
      });

    GET('/admin/appConfiguration')
      .then(({data})=>{
        this.setState({
          sitter:data.configuration.sitter,
          topNanniesFlag:data.configuration.topNannies
        })
      })
      .catch(err => {
        toast.error(err.message);
      });



    M.Parallax.init(this.Parallax1);
    M.Parallax.init(this.Parallax2);
  }

  render() {
    const { topNannies, blogs, place,sitter,topNanniesFlag } = this.state;
    const { skills } = this.props;
    const { isLoggedIn } = this.props.user;
    return (
      <Fragment>
        <Helmet>
          <title>Elite Nannies | Nannies {/*& Sitters*/} with Specialized Skills</title>
          <meta name="description"
            content="Elite Nannies is a prestigious nanny agency specializing in career nannies & sitters who are also trained and qualified in an additional profession to childcare." />
          <meta name="title" content="Elite Nannies | Nannies & Sitters with Specialized Skills" />
        </Helmet>
        <div className="page-content page-home">
          <SectionIntro place={place} isLoggedIn={isLoggedIn} onPlaceSearch={this.handlePlaceSearch} sitter={sitter}/>
          <SectionQuote para1={Parallax => {
            this.Parallax1 = Parallax;
          }} />
          <SectionWhyElite />
          <SectionSkills skills={skills} />
{topNanniesFlag &&          <SectionTopNannies nannies={topNannies} />
}
          <SectionProcess />
          <SectionWeOnlyHire />
          {/* <Sectionfounder /> */}
          <NannyBlog blogs={blogs} />
          <SectionWrite />
          <SectionNannyRegister para2={Parallax => {
            this.Parallax2 = Parallax;
          }} />
          <NewsletterComponent />
          {/*<EliteNanny/>*/}
        </div>
      </Fragment>
    )
  }
}

const SectionQuote = ({ para1 }) => (
  <section className="parallax-container section sectionquote">
    <div ref={para1} className="parallax">
      <img src={Image1} />
    </div>

    <div className="container">
      <div className="row">
        <div className="col-12 col-lg-10 col-xl-11 col-xll-10 mx-auto">
          <div className="text-center px-xll-4 wow fadeIn" data-wow-delay=".5s">
            <span className="icon-quote icon-q-start">“</span>
            <p>
                Elite Nannies will consistently impress. Your nanny not only educates, cultivates, cares and nurtures, they enrich your whole family’s life. From cooking an exquisite degustation meal to teaching your child French or Mandarin, some even know how to ride, wash and groom a horse.
            </p>
            <span className="icon-quote icon-q-end">“</span>
          </div>
        </div>
      </div>
    </div>
  </section>
);

const SectionIntro = (props) => (
  <section className="page-banner section">
    <div className="container">
      <div className="banner-caption">
        <div className="row wow fadeInUp">
          <div className="col-12 col-lg-9 col-xl-8 col-xll-7 mx-auto">
            <div className="banner-text px-lg-3">
              <span>ELITE NANNIES</span>
              <h2 className="heading page-heading mt-2 mb-2 mb-sm-0">Enriching<br />your family life</h2>
              <p className="mb-3 px-lg-5">
                Elite Nannies is a prestigious nanny agency specializing in career nannies {/*& sitters*/} in Los Angeles
                and New
                York City, who are also trained and qualified in an additional profession to childcare.
              </p>
              <h4 className="mt-5 mb-4">Find an Elite Nanny</h4>
            </div>
          </div>
          <div className="col-12 col-md-11 col-lg-8 col-xl-7 col-xll-6 mx-auto px-xll-0">
            <div className="search text-center">
              {/*<FormikPlaceSearch*/}
              {/*placeholder="Search for an elite nanny in your area"*/}
              {/*value={props.place}*/}
              {/*onChange={props.onPlaceSearch} />*/}
              <button className={'btn btn-primary text-white text-uppercase btn-lg'}
                onClick={() => history.push(props.isLoggedIn ? '/family/preferences' : '/family/registration')}>
                {props.isLoggedIn ? 'Get Started' : props.sitter ? 'SEARCH NANNIES & SITTERS' : 'START YOUR SEARCH'}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

const SectionWhyElite = () => (
  <section className="section pb-0 py-lg-0">
    <div className="container-fluid">
      <div className="box">
        <div className="row align-items-center">
          <div className="col-12 col-md-12 col-lg-6">
            <div className="row pb-5 pt-3 py-lg-5">
              <div className="col-12 col-lg-11 col-xll-7 offset-xll-3 col-xl-9 offset-xl-2">
                <div className="pl-xl-2 pl-xll-4 wow fadeIn" data-wow-delay=".8s">
                  <h2 className="heading mb-2">Why Elite?</h2>
                  <h3 className="sub-heading mb-4">Our nannies will continually exceed your expectations.</h3>
                  <p>
                  We strictly present only the top 5% of nanny candidates who apply through our Elite Nanny screening process, so you can be assured your children will be offered unparalleled care.
                  </p>
                  <p>
                    We carefully match our nannies to unique family values, care philosophies and lifestyle, so everything flows. Our rigorous matching process guarantees your family life will remain harmonious.
                  </p>
                  <p>
                    Looking after your children is a privilege our nannies do not take for granted. They’re honoured to be part of your family, long or short term.
                  </p>
                  <Link to={'/nanny/why-choose'} className="arrow-link mt-2">See how we pair
                    you with the perfect nanny
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-12 col-lg-6 px-0 wow fadeInRight" data-wow-delay=".5s">
            <img src={ImgWhyElite} className="img-fluid float-right" alt="" />
          </div>
        </div>
      </div>
    </div>
  </section>
);

const SectionTopNannies = ({ nannies }) => (
  <section className="section bg-light-5 top-nannies">
    <div className="container px-xll-0">
      <div className="row wow fadeIn">
        <div className="col-12 mx-auto">
          <div className="text-center">
            <h2 className="heading my-3 my-lg-0">Top Nannies</h2>
          </div>
        </div>
      </div>
      <div className="row row-15 nannies-list justify-content-center wow fadeInUp" data-wow-delay=".5s">
        {
          nannies.map((nanny, index) => {
            return <SingleNanny key={index} nanny={nanny} />
          })
        }
      </div>
      <div className="text-center wow fadeInUp" data-wow-delay=".5s">
      <button className="btn btn-skill btn-lg" onClick={() => history.push('/search/nannies')}>BROWSE TOP NANNIES</button>
      </div>
    </div>
  </section>
);

const SectionProcess = () => (
  <section className="section">
    <div className="container px-xll-0">
      <div className="row">
        <div className="col-12">
          <h2 className="heading mb-4 pb-4 text-center text-lg-left">Our Process</h2>
        </div>
        <div className="col-12 wow fadeInUp" data-wow-delay=".5s">
          <div className="row process-list">
            <div className="col-12 col-md-6 col-lg-4">
              <div className="process-item">
                <div className="d-block">
                  <img src={Processlearn} className="mr-4" alt="" width="70" />
                  <div className="media-body mt-3">
                    <h4 className="mt-0">Learn</h4>
                    <p>Firstly, we get to know you and your family. We ask the right questions so we understand your
                      lifestyle and your unique family needs.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              <div className="process-item">
                <div className="d-block">
                  <img src={ProcessSeek} className="mr-4" alt="" width="63" />
                  <div className="media-body mt-3">
                    <h4 className="mt-0">Seek</h4>
                    <p>We carefully match an Elite Nanny to your family - shortlisting up to five nannies to give
                      families the opportunity to choose the perfect fit.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              <div className="process-item">
                <div className="d-block">
                  <img src={ProcessInterview} className="mr-4" alt="" width="68" />
                  <div className="media-body mt-3">
                    <h4 className="mt-0">Interview</h4>
                    <p>We schedule the initial interviews with prospective nannies.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              <div className="process-item">
                <div className="d-block">
                  <img src={ProcessEmploy} className="mr-4" alt="" width="57" />
                  <div className="media-body mt-3">
                    <h4 className="mt-0">Employ</h4>
                    <p>Once you have found your dream nanny, we facilitate the hiring process.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              <div className="process-item mb-0">
                <div className="d-block">
                  <img src={ProcessSupport} className="mr-4" alt="" width="64" />
                  <div className="media-body mt-3">
                    <h4 className="mt-0">Support</h4>
                    <p>We are only a phone call away – get in touch at any time.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

const SectionWeOnlyHire = () => (
  <section className="section pt-0 py-5 py-md-0">
    <div className="container-fluid">
      <div className="box box-gradient">
        <div className="row align-items-center">
          <div className="col-12 col-lg-6 col-xl-6 order-2 order-lg-1 wow fadeIn" data-wow-delay=".8s">
            <div className="row">
              <div className="col-12 offset-sm-0 col-md-11 offset-md-1 col-xl-9 offset-xl-3 col-xll-7 offset-xll-4">
                <div className="pl-xl-4 pl-xll-5 py-3 py-lg-0">
                  {/* <p className="text-26-33 my-3">We only hire the top 5% of nanny candidates who apply
                    through our <img
                      src={LogoSitter} width="121" alt="" className="mr-2" /> process.</p> */}

                  <p className="text-26-33 my-3">We only select the top 5% of nanny candidates who apply through our Elite Nanny screening process.</p>
                  <Link to={'/family/screening-process'} className="btn btn-skill btn-lg mt-3 mb-sm-5">VIEW OUR
                    PROCESS</Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-6 col-xl-6 px-md-0 order-1 order-lg-2 wow fadeInRight" data-wow-delay=".5s">
            <img src={Nannyhireprocess} className="w-100 img-fluid mt-lg-0 mb-lg-0 mb-3" alt="" />
          </div>
        </div>
      </div>
    </div>
  </section>
);

export const Sectionfounder = () => (
  <section className="section secion-founder pt-0 pb-0">
    <div className="container-fluid">
      <div className="box">
        <div className="row align-items-center">
          <div className="col-12 col-lg-6 col-xl-6 px-md-0 wow fadeInLeft" data-wow-delay=".5s">
            <img src={RoxannePeake} className="w-100 img-fluid float-right mb-5 mb-lg-0" alt="" />
          </div>
          <div className="col-12 col-lg-6 col-xl-6 wow fadeIn" data-wow-delay=".8s">
            <div className="row">
              <div className="col-12 col-xll-8 offset-xll-2 col-lg-11 offset-lg-1 pl-xll-0 text-center-sm">
                <h3 className="heading mb-0 pt-lg-4 pt-0">Word from Our Founder</h3>
                <div className="py-4 pr-xl-5">
                  <p className="quotemsg">
                    <span className="icon-quote icon-q-start"></span>
                    {/* <span className="quote-divider"> </span> */}
                    I never quite understood the level of worry that came with leaving your child with hired help, and in most mother's cases, this starts out as a ‘stranger’. Now that I am a mom, I completely understand the great significance and value in what it means to hire a nanny. You really want to ensure that you are hiring a loving, trustworthy and reliable caregiver, someone who can invest the same interest in your child that you possess, because, let's face it, your child is the utmost important thing in your life. I wanted to create a village where, in their absence, moms alike can rest assure that their children will not only be kept safe, they will be educated at a high level, cultivated with curiosity and creativity, and above all nurtured.
                    <span className="icon-quote icon-q-end"></span>
                  </p>
                </div>
                <h5 className="font-16">Roxanne Peake</h5>
                <p>Founder / Pediatric Registered Nurse / Former Nanny / Mom</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

const NannyBlog = ({ blogs }) => (
  <section className="section section-blog">
    <div className="container px-xll-0">
      <div className="row justify-content-center">
        <div className="col-12 mb-4 wow fadeInUp">
          <div className="text-center">
            <h2 className="heading mb-3">Our Blog</h2>
            <h3 className="sub-heading mb-4 font-normal">Tips and tricks to being a great family, and a great
              nanny.</h3>
          </div>
        </div>
        {
          blogs.map((x, index) => {
            return (
              <div key={index} className="col-md-6 col-lg-4 col-xl-4 b-i-mb-5 wow fadeInUp" data-wow-delay=".5s">
                <div className="blogitem">
                  <div className="card">
                    {x._embedded['wp:featuredmedia'] &&
                      <img className="card-img-top" src={x._embedded['wp:featuredmedia']['0'].source_url}
                        alt="Card image cap" />}
                    {!x._embedded['wp:featuredmedia'] &&
                      <img className="card-img-top" src="https://eduaid.net/web/visatype/default.png"
                        alt="Card image cap" />}
                    <div className="card-body">
                      <p className="card-text">{moment(x.date).format('MM/DD/YYYY')}</p>
                      {/*<p class="card-text" dangerouslySetInnerHTML={{ __html: x._embedded }} />*/}
                      <a className="blog-link mb-3 d-block" rel="noopener" rel="noreferrer" href={x.link}
                        target={'_blank'}>
                        <h5 className="card-title" dangerouslySetInnerHTML={{ __html: x.title.rendered }} />
                      </a>
                      <p className="card-text" dangerouslySetInnerHTML={{ __html: x.excerpt.rendered }} />
                      <a className="blog-link" rel="noopener" rel="noreferrer" href={x.link} target={'_blank'}>Read
                        more</a>
                    </div>
                  </div>
                </div>
              </div>
            )
          })
        }
      </div>
      <div className="col-12">
        <div className="text-center wow fadeInUp" data-wow-delay=".8s">
          <a rel="noopener" rel="noreferrer" href="https://blog.elitenannies.com" target={'_blank'}
            className="btn btn-skill btn-lg mt-2 mb-4 mb-sm-0">VIEW OUR
            BLOG</a>
        </div>
      </div>
    </div>
  </section>
);

const SectionWrite = () => (
  <section className="section wow fadeInUp pt-0">
    <div className="container px-0">
      <div className="bg-light-5 section">
        <div className="row">
          <div className="col-12 col-xl-7 col-xll-6 mx-auto">
            <div className="px-3 px-lg-0">
              <div className="text-center">
                <img src={ImgAvatar} width="14" className="img-fluid client-avatar mb-4" alt="" />
                <p>"Roxanne made us feel incredibly comfortable about hiring a nanny. As a first time mom, she made me
                  feel at ease - critical for a successful transition back to work after maternity leave. It was amazing
                  to see how quickly our nanny became a part of our family - now we couldn’t imagine life without
                  her"</p>
                <p className="font-bold mb-1">Molly S. - HR Manager / Mom</p>
                <p className="font-bold text-primary mb-0">
                  <img src={Svglocation} width="14" className="img-fluid" alt="" /> Manhattan, NY</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

const SingleNanny = ({ nanny }) => (
  <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 c-pointer"
    onClick={() => history.push('/nanny/view/' + nanny._id)}>
    <div className="nannies-item">
      <div className="nannies-image">
        <Thumbnail source={nanny.nannies.profile_picture.name} className="nannies-avatar" />
      </div>
      <h3 className="nannies-name">{nanny.first_name} {nanny.last_name}</h3>
      <p className="nannies-info my-2">
        {
          nanny.nannies.skills.map((x, idx) => {
            return <Thumbnail width={'20px'} key={idx} source={x.icon.name} className="nanny-dashboard-skills mr-2" tooltip={x.title} />
          })
        }
      </p>
      <span className="location-icon mt-0">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 485.6 485.6">
          <path
            d="M242.8 0C148.7 0 72.4 76.3 72.4 170.4c0 7.2.6 14.2 1.5 21.2 14.6 133.1 157.9 286.5 157.9 286.5 2.6 2.9 5 4.6 7.4 5.9l.1.1 4.8 1.5 4.8-1.5.1-.1c2.4-1.3 4.8-3.1 7.4-5.9 0 0 141.3-153.7 155.5-286.8.9-6.9 1.4-13.8 1.4-20.9C413.2 76.3 336.9 0 242.8 0zm0 280c-60.4 0-109.6-49.2-109.6-109.6S182.4 60.8 242.8 60.8 352.4 110 352.4 170.4 303.2 280 242.8 280z" />
        </svg>
      </span>
      <h6 className="nannies-city">{nanny.location.address.split(',')[0]}</h6>
    </div>
  </div>
);

const SectionSkills = ({ skills }) => {
  return (
    <Fragment>
      <section className="section wow fadeInUp pb-0">
        <div className="container px-xll-0">
          <div className="row">
            <div className="col-12 col-lg-10 col-xl-7 col-xll-7 mx-auto">
              <div className="text-center wow fadeInUp">
                <h2 className="heading mb-3">Specialized Skills</h2>
                <p className="mb-5 px-xll-5">Elite Nannies are professional nannies who are also skilled in other areas.
                  These include Teachers, Nurses, Foreign Language Teachers, Massage Therapists, Personal Trainers, Hair
                Stylists, Beauty Therapists, Photographers and more.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section wow fadeInUp skills-slider pt-0">
        <SkillsCarousel skills={skills} />
      </section>
    </Fragment>
  )
};

export const SkillsCarousel = ({ skills }) => {
  const LeftArrow = ({ className, style, onClick }) => (
    <div
      className={className}
      style={{ ...style }}
      onClick={onClick}>
      <span className="slider-arrow">
        <svg xmlns="http://www.w3.org/2000/svg" height="58" width="58" viewBox="0 0 58 58"><path d="M29 58C13 58 0 45 0 29S13 0 29 0s29 13 29 29-13 29-29 29zm0-56C14.1 2 2 14.1 2 29s12.1 27 27 27 27-12.1 27-27S43.9 2 29 2zm0 0" /><path d="M25.4 39.2c-.3 0-.5-.1-.7-.3-.4-.4-.4-1 0-1.4l8.9-8.9-8.9-8.9c-.4-.4-.4-1 0-1.4.4-.4 1-.4 1.4 0l9.6 9.6c.4.4.4 1 0 1.4l-9.6 9.6c-.2.2-.5.3-.7.3zm0 0" /></svg>
      </span>
    </div>
  );

  const RightArrow = ({ className, style, onClick }) => (
    <div
      className={className}
      style={{ ...style }}
      onClick={onClick}>
      <span className="slider-arrow">
        <svg xmlns="http://www.w3.org/2000/svg" height="58" width="58" viewBox="0 0 58 58"><path d="M29 0c16 0 29 13 29 29S45 58 29 58 0 45 0 29 13 0 29 0zm0 56c14.9 0 27-12.1 27-27S43.9 2 29 2 2 14.1 2 29s12.1 27 27 27zm0 0" /><path d="M32.6 18.8c.3 0 .5.1.7.3.4.4.4 1 0 1.4l-8.9 8.9 8.9 8.9c.4.4.4 1 0 1.4-.4.4-1 .4-1.4 0l-9.6-9.6c-.4-.4-.4-1 0-1.4l9.6-9.6c.2-.2.5-.3.7-.3zm0 0" /></svg>
      </span>
    </div>
  );

  const settings = {
    infinite: true,
    speed: 1000,
    dots: false,
    autoplay: true,
    slidesToShow: 6,
    slidesToScroll: 1,
    nextArrow: <LeftArrow />,
    prevArrow: <RightArrow />,
    responsive: [
      {
        breakpoint: 1441,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        }
      },
      {
        breakpoint: 1080,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ]
  };

  return (
    <Fragment>
      <div className="container-fluid px-0">
        <div className="nannyskill-list specialised-list" data-wow-delay=".5s">
          <Slider {...settings}>
            {
              skills.map((x, index) => {
                return <SingleSkill key={index} skill={x} />
              })
            }
          </Slider>
        </div>
      </div>
      <div className="container">
        <div className="text-center mt-5 wow fadeInUp" data-wow-delay=".5s">
          <button className="btn btn-skill btn-lg" onClick={() => history.push('/our-nannies')}>See other Elite Skillsets</button>
        </div>
      </div>
    </Fragment>
  )
};

export const SingleSkill = ({ skill }) => (
  <div className="skill-box c-pointer" onClick={() => history.push(`/nanny-services/${stringToSlug(skill.title)}`)}>
    <div className="card">
      <img src={`${config.api.URL}${skill.poster.name}`} className="card-img-top" alt="" />
      <div className="card-body">
        <Thumbnail className="skills-icon" source={skill.icon.name} />
        <h5 className="card-title">{skill.title}</h5>
        <p className="card-description">{skill.description}</p>
      </div>
    </div>
  </div>
);

const SectionNannyRegister = ({ para2 }) => (
  <section className="parallax-container section section-padding section-bg become-nanny">
    <div ref={para2} className="parallax">
      <img src={Image2} />
    </div>
    <div className="container px-xll-0">
      <div className="row">
        <div className="col-12 col-lg-6 col-xl-5 col-xll-5 wow fadeIn">
          <div className="text-left pr-xll-5 mr-xll-3">
            <h2 className="heading mb-4">Become an<br />Elite Nanny.</h2>
            <p className="become-info">
              We match professional, career-driven nannies {/*& sitters*/} with wonderful families. If you hold at least
              three
              years’ experience in childcare and are also qualified in an
              additional field of practice, we’d love for you to
              <span className="font-bold c-pointer"
                onClick={() => history.push('/nanny/registration')}> apply now.</span>
            </p>
            <p className="become-info">
              Take a look at some of the other professions our candidates are qualified in, or contact us with your area
              of expertise - no skill is off the table.
            </p>
            <p className="become-info">
              We only hire the top 5% who apply to Elite Nannies, so if you have what it takes to join the Elite,
              <span className="font-bold c-pointer"
                onClick={() => history.push('/nanny/registration')}> apply now.</span>
            </p>
            <button className="btn btn-register btn-lg mt-4" onClick={() => history.push('/nanny/registration')}>JOIN
              THE ELITE
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
);
/*
const EliteNanny = () => (
    <section className="section py-0 section-instagram">
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="col-12 mb-4">
            <div className="text-center">
              <h2 className="heading mb-3">@ELITENANNIES</h2>
            </div>
          </div>
        </div>
      </div>
      <div className="nanny-instagram-list">
        <div className="col-item">
          <img className="card-img-top" src={Nannies1} alt="Card image cap"/>
        </div>
        <div className="col-item">
          <img className="card-img-top" src={Nannies2} alt="Card image cap"/>
        </div>
        <div className="col-item">
          <img className="card-img-top" src={Nannies3} alt="Card image cap"/>
        </div>
        <div className="col-item">
          <img className="card-img-top" src={Nannies4} alt="Card image cap"/>
        </div>
        <div className="col-item">
          <img className="card-img-top" src={Nannies5} alt="Card image cap"/>
        </div>
        <div className="col-item">
          <img className="card-img-top" src={Nannies6} alt="Card image cap"/>
        </div>
        <div className="col-item">
          <img className="card-img-top" src={Nannies7} alt="Card image cap"/>
        </div>
        <div className="col-item">
          <img className="card-img-top" src={Nannies8} alt="Card image cap"/>
        </div>
        <div className="col-item">
          <img className="card-img-top" src={Nannies7} alt="Card image cap"/>
        </div>
        <div className="col-item">
          <img className="card-img-top" src={Nannies8} alt="Card image cap"/>
        </div>
      </div>
    </section>
);
*/

const MapStateToProps = (state) => {
  return {
    skills: state.skills,
    user: state.user,
  }
};

export default connect(MapStateToProps, null)(HomeComponent);
