import React, {Component} from 'react'
import {connect} from "react-redux";
import {Link, Redirect} from "react-router-dom";
import {toast} from "react-toastify";

// Services
import PersonalDetailsForm from "../../components/PersonalDetailsForm";
import {APPEND_FAMILY_REGISTRATION} from "../../redux/family-registeration/FamilyRegistrationReducer";
import {HIDE_LOADING, SHOW_LOADING} from "../../redux/loader/LoaderActions";
import {GET, POST} from "../../services/rest.service";
import {LOGIN_SUCCESS} from "../../redux/user/UserActions";
import {isEmpty} from "lodash";


class FamilyRegPerDetailsComponent extends Component {

  handleRegistrationClick = (formData) => {
    const {location} = this.props.family;
    const payload = {
      ...formData,
      location: location ? location : JSON.parse(localStorage.getItem('location')),
    };
    if(!isEmpty(payload.location)){
      this.props.showLoading();
      POST('/auth/user/family_register/create', payload)
          .then((res) => {
            this.props.hideLoading();
            localStorage.setItem('token', res.access_token);
            localStorage.setItem('email', res.data.email);
            localStorage.removeItem('location');
            toast.success('Registration successful! Please verify your account for full experience');
            this.props.history.push('/family/looking-for');
            // this.props.history.push('/family/preferences?utmSrc=pmts');
            return GET('/users/me');
          })
          .then((data) => {
            this.props.authenticateUser(data);
          })
          .catch((err) => {
            toast.error(err.message);
            this.props.hideLoading();
          });
    } else {
      toast.error('Please fill all the required fields');
    }
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let {first_name, last_name, email, password, mobile_number, location} = this.props.family;
    const {isLoggedIn} = this.props.user;
    const {url} = this.props.match;

    if(isLoggedIn) {
      return <Redirect from={url} to={'/'} />
    }

    return (
        <div className="page-content page-auth">
          <div className="container">
            <section className="section bg-auth">
              <div className="row wow fadeInUp">
                <div className="col-12 col-lg-8 col-xl-7 col-xll-7 mx-auto">
                  <div className="text-center">
                    <h2 className="heading">FAMILY SIGN UP</h2>
                    <p>
                      Here at Elite Nannies, we get to know you and your family in order to understand your unique
                      needs. We endeavour to learn exactly what you are looking for in the perfect nanny {/*or sitter*/} in terms of
                      disposition, hours required, duties expected, and the compensation you are prepared to offer your
                      new household employee.</p>
                  </div>
                  <div className="mt-3 mt-sm-5">
                    <PersonalDetailsForm values={{first_name, last_name, email, password, mobile_number, location}}
                                         type={"family"}
                                         onSubmit={this.handleRegistrationClick}/>
                  </div>
                  <div className="form-group text-center">
                    <p>Already have an account? <Link className="link-auth" to={`/family/login`}>Sign In</Link></p>
                  </div>
                </div>
                <div className="col-12 col-lg-8 col-xl-7 col-xll-7 mx-auto mt-3">
                  <div className="text-center">
                    <p className="font-small">At Elite Nannies we hire only the most qualified, experienced and
                      outstanding nannies around. All
                      of our nannies undergo a very thorough interview process as well as Social Security Number Skip
                      Trace, Multi-Jurisdictional Criminal Records Search including but not limited to violent and sex
                      offender registries, County Criminal Records Search, Statewide County Criminal Records Search, a
                      review by a NAPBS (National Association of Professional Background Screeners) certified
                      representative, and Motor Vehicle Records Check. All of our nannies are TrustLine registered
                      childcare providers. In addition to the above mandatory screening, their credentials and
                      references are all verified, and every nanny must be up-to-date with their First Aid and CPR
                      training.</p>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
    )
  }
}

const MapStateToProps = (state) => {
  return {
    family: state.family,
    user: state.user,
  }
};

const MapDispatchToProps = (dispatch) => {
  return {
    doSetRegistration: (data) => {
      dispatch({
        type: APPEND_FAMILY_REGISTRATION,
        payload: data
      })
    },
    showLoading: (title) => {
      dispatch({
        type: SHOW_LOADING,
        payload: {
          title,
          isLoading: true
        }
      })
    },
    hideLoading: () => {
      dispatch({
        type: HIDE_LOADING,
      })
    },
    authenticateUser: (res) => {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: {
          isLoggedIn: true,
          user: res.data,
          type: res.data.type,
          message: 'Logged in Successfully'
        }
      })
    }
  }
};

export default connect(MapStateToProps, MapDispatchToProps)(FamilyRegPerDetailsComponent);
