import React, {Component} from 'react';
import {Link} from "react-router-dom";
import ImgSkillPersonalAssistant from '../../assets/images/personal-family-assistant.jpg';
import history from '../../helpers/history';
import {connect} from "react-redux";

class FamilyAssistantDetails extends Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  goBack = () => {
    history.push('/our-nannies');
  };

  render() {
    const { isLoggedIn } = this.props.user;

    return (
      <div className="page-content mt-0">
        <section className="section section-skills">
          <div className="container mt-2 mt-md-4 mt-lg-5 px-xll-0">
            <div className="row">
              <div className="col-12 w-100 wow fadeInRight">
                <button className="btn btn-clear btn-back-skills p-0" onClick={this.goBack}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="26" viewBox="0 0 31.494 31.494">
                    <path
                      d="M10.273 5.009a1.112 1.112 0 0 1 1.587 0 1.12 1.12 0 0 1 0 1.571l-8.047 8.047h26.554c.619 0 1.127.492 1.127 1.111s-.508 1.127-1.127 1.127H3.813l8.047 8.032c.429.444.429 1.159 0 1.587a1.112 1.112 0 0 1-1.587 0L.321 16.532a1.12 1.12 0 0 1 0-1.571l9.952-9.952z"
                      fill="#7f7f7f"/>
                  </svg>
                </button>
              </div>
              <div className="col-12 col-lg-6 wow fadeInLeft">
                <h2 className="heading mb-4 d-block d-lg-none">Personal Family Assistant</h2>
                <img src={ImgSkillPersonalAssistant} className="w-100 img-fluid" alt=""/>
              </div>
              <div className="col-12 col-lg-6 col-xl-5 ml-auto wow fadeIn">
                <div className="skill-content pt-5 pb-0 pb-lg-5">
                  <h2 className="heading mb-4 d-none d-lg-block">Personal Family Assistant</h2>
                  <p>
                    The Family Assistant – think Mary Poppins, the Nanny that can do simply anything and everything.
                    Arguably one of our most popular roles, Family Assistants are absolutely essential for busy
                    families.
                  </p>
                  <p>
                    Family Assistants combine the role of nanny and personal assistant by providing care for the
                    children and helping you with any pertinent household tasks. Your children are their utmost concern,
                    but they will take on the role of first point of contact for your family, dealing with
                    correspondence and phone calls, managing diaries – whether it be yours or your children’s,
                    organizing appointments, pet care, booking and arranging personal or family travel, transport and
                    accommodation for vacations or business trips.
                  </p>
                  <p>
                    They will keep your home in the highest order, including organization of any household repairs,
                    grocery shopping, meal preparation, car cleaning and servicing, and running errands of any kind
                    (think, dry-cleaning collection, and not to mention the dreaded post office and DMV visits).
                  </p>
                  <p>
                    For these roles, we search for candidates who have a mixture of education ranging from a Bachelor of
                    Business / Marketing / Arts, associates’ degree or experience as a personal assistant, receptionist,
                    secretary or other relevant profession where they’ve gained outstanding organizational experience.
                    These nannies are naturally organized, super efficient and love to stay busy!
                  </p>
                </div>
              </div>
            </div>
            {/* <div className="row">
              <div className="col-12 w-100 mt-0 mt-lg-5 text-center wow fadeInUp">
                <Link to={'/family/registration'} className="btn btn-skill btn-lg">START YOUR SEARCH</Link>
                <Link to={'/search/nannies?skills=Personal%20Family%20Assistant'} className="btn btn-skill btn-lg">START YOUR SEARCH</Link>
              </div>
            </div> */}
          </div>
        </section>
        <section className="section section-applynow bg-light">
          <div className="container">
            <div className="banner-caption">
              <div className="row wow fadeInUp">
                <div className="col-12 col-xl-10 col-xll-9 mx-auto">
                  <div className="banner-text">
                    <h2 className="heading">Want A Personal Assistant Nanny?</h2>
                    <Link to={!isLoggedIn ? '/family/registration' : '/family/preferences'} className="btn btn-outline-primary">START YOUR SEARCH</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }
}

const MapStateToProps = (state) => {
  return {
    user: state.user
  }
};

export default connect(MapStateToProps, null)(FamilyAssistantDetails);
