import React, { Component, Fragment } from 'react';
import history from "../../helpers/history";

class FamilyOneTimeSubscription extends Component {
  render() {
    return (
      <Fragment>
        <div className="page-content page-auth">
          <SectionPackage />
        </div>
        <div className="page-content page-auth">
          <SectionReferralPackage />
        </div>
      </Fragment>
    )
  }
}
const SectionPackage = () => (
  <div className="container">
    <section className="section bg-auth">

      <div className="row">
        <div className="col-12 col-lg-9 col-xl-7 col-xll-6 mx-auto wow fadeInUp">
          <div className="text-center mb-5">
            <h2 className="heading">REQUEST NANNY</h2>
            <p className="text-note px-xl-5">In order to find you the perfect fit, <strong>we require a $500 registration fee</strong>, and upon hire, we apply that registration fee to the referral fee.</p>
          </div>
        </div>
      </div>

      <div className="row row-15 membership-card select-membership pricingpackages justify-content-center">
        <div className="col-12 col-md-6 col-lg-4 col-xl-4 mb-4 wow fadeInUp">
          <div className="select-membership-item">
            <div className="membership-item">
              <h4 className="membership-heading">Registration</h4>
              <h5 className="membership-price">
                $500
              </h5>
              <div className="membership-divider"></div>
              <div className="text-left my-5">
                <h6>Your investment includes:</h6>
                <ul className="list-investment">
                  <li>Consultation Between You And Roxanne (CEO)</li>
                  <li>Your Requirements Audit</li>
                  <li>Initial Nanny Background Check</li>
                  <li>Nanny References Check</li>
                  <li>Personal Nanny Interview By Roxanne (CEO)</li>
                  <li>Up to 5 Nanny Recommendation in Document</li>
                  <li>You and Nanny Face-to-Face Interviews</li>
                </ul>
              </div>
              <div className="packages-footer mt-5 px-5">
                <button className="btn btn-outline-primary text-uppercase btn-lg btn-block" onClick={() => history.push('/family/nanny-package/payment')}>PAY $500 NOW </button>
                {/*<a className="w-100 d-block mt-2" href="javascript:;">Read our terms & conditions</a>*/}
                <a className="w-100 d-block mt-2" href="javascript:void(0);" onClick={() => history.push('/family/thank-you-nanny-request')}>Skip for now</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
);
const SectionReferralPackage = () => (
  <div className="container">
    <section className="section bg-auth bg-light-5">

      <div className="row">
        <div className="col-12 col-lg-9 col-xl-7 col-xll-6 mx-auto wow fadeInUp">
          <div className="text-center mb-5">
            <h2 className="heading">NANNY REFERRAL PACKAGES</h2>
            <p className="text-note px-xl-5">Once you have found your perfect nanny you will then choose from one of our exclusive pricing packages.</p>
          </div>
        </div>
      </div>
      <div className="row row-20 membership-card select-membership pricingpackages justify-content-center">
        <div className="col-12 col-md-6 col-lg-4 col-xl-4 mb-4 wow fadeInUp">
          <div className="select-membership-item">
            <div className="membership-item">
              <h4 className="membership-heading">Classic</h4>
              <h5 className="membership-price">
                15%
                    <span>of candidate’s gross annual compensation</span>
              </h5>
              <div className="membership-divider"></div>
              <p className="membership-content text-left">
                We match you with an Elite Nanny at 15% of the Candidate’s Total Gross Annual Compensation.
                  </p>
              <div className="text-left">
                <h6>Your investment includes:</h6>
                <ul className="list-investment">
                  <li>Criminal Background Investigation</li>
                  <li>DMV Driving Record</li>
                  <li>Social Security Number Verification</li>
                  <li>Sex Offender Registry Check</li>
                  <li>Professional Employment Agreement</li>
                  <li>Candidate replacement (one only), if needed, within 2 months from first day of employment.</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-6 col-lg-4 col-xl-4 mb-4 wow fadeInUp">
          <div className="select-membership-item">
            <div className="membership-item">
              <h4 className="membership-heading">Elite</h4>
              <h5 className="membership-price">
                18%
                    <span>of candidate’s gross annual compensation</span>
              </h5>
              <div className="membership-divider"></div>
              <p className="membership-content text-left">
                We match you with an Elite Nanny at 18% of the Candidate’s Total Gross Annual Compensation.
                  </p>
              <div className="text-left">
                <h6>Your investment includes:</h6>
                <ul className="list-investment">
                  <li>Criminal Background Investigation</li>
                  <li>DMV Driving Record</li>
                  <li>Social Security Number Verification</li>
                  <li>Sex Offender Registry Check</li>
                  <li>Civil Restraining Orders Check</li>
                  <li>National Wants and Warrants</li>
                  <li>Professional Employment Agreement</li>
                  <li>Professional Confidentiality Agreement</li>
                  <li>Ongoing monthly membership to our database of sitters</li>
                  <li>Candidate replacement (one only), if needed, within 3 months from first day of employment.</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
);

export default FamilyOneTimeSubscription;
