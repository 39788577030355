import React, {Component} from 'react';
import {Link} from "react-router-dom";
import toast from '../../Toast';
/* SERVICES */
import {PATCH} from "../../services/rest.service";
import {HIDE_LOADING, SHOW_LOADING} from "../../redux/loader/LoaderActions";
import {APPEND_NANNY_REFERENCE} from "../../redux/nanny-registration/NannyRegistrationReducer";
/* COMPS */
import ReferencesForm from "../../components/ReferencesForm";
import {connect} from "react-redux";


class NannyRegRefsComponent extends Component {

  handleSubmit = (values) => {

    const payload = {
      ...this.state.personalDetails,
      reference: []
    };
    if (values.ref1name && values.ref1phone_number && values.ref1email) {
      payload.reference.push({name: values.ref1name, phone_number: values.ref1phone_number, email: values.ref1email});
    }
    if (values.ref2name && values.ref2phone_number && values.ref2email) {
      payload.reference.push({name: values.ref2name, phone_number: values.ref2phone_number, email: values.ref2email});
    }
    if (values.ref3name && values.ref3phone_number && values.ref3email) {
      payload.reference.push({name: values.ref3name, phone_number: values.ref3phone_number, email: values.ref3email});
    }
    if (values.ref4name && values.ref4phone_number && values.ref4email) {
      payload.reference.push({name: values.ref4name, phone_number: values.ref4phone_number, email: values.ref4email});
    }
    if (values.ref5name && values.ref5phone_number && values.ref5email) {
      payload.reference.push({name: values.ref5name, phone_number: values.ref5phone_number, email: values.ref5email});
    }
    this.props.doShowLoading('Adding References');
    PATCH('/auth/user/nanny_register/step4', payload)
        .then(res => {
          localStorage.setItem('reg_auth', JSON.stringify({email: res.data.email, _id: res.data._id, first_name: res.data.first_name, last_name: res.data.last_name}));
          this.props.history.push('/nanny/authorization');
          toast.success('Reference details updated.');
          this.props.doSetReferences(payload);
          this.props.doHideLoading();
        })
        .catch(err => {
          toast.error(err.message);
          this.props.doHideLoading();
        })
  };

  constructor(props) {
    super(props);
    this.state = {
      personalDetails: JSON.parse(localStorage.getItem('reg_auth'))
    };
  }

  render() {
    const {reference} = this.props.nanny;
    return (
        <div className="page-content page-auth">
          <div className="container">
            <section className="section bg-auth">
              <div className="row row-20">
                <div className="col-12 col-lg-10 col-xl-8 col-xll-7 mx-auto wow fadeInUp">
                  <div className="text-center mb-5">
                    <h2 className="heading">
                      <Link to={`/nanny/skills`} className="btn btn-back">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 268.8 268.8">
                          <path
                              d="M3.7 143.3l80 80c2.4 2.4 5.6 3.7 8.8 3.7s6.4-1.2 8.8-3.7c4.9-4.9 4.9-12.8 0-17.7l-58.7-58.7h213.7c6.9 0 12.5-5.6 12.5-12.5s-5.6-12.5-12.5-12.5H42.7l58.7-58.7c4.9-4.9 4.9-12.8 0-17.7-4.9-4.9-12.8-4.9-17.7 0l-80 80c-4.9 5-4.9 12.9 0 17.8z"
                              fill="#313131"/>
                        </svg>
                      </Link>
                      CREATE PROFILE
                    </h2>
                    <p className="text-note">Add up to 5 references</p>
                  </div>
                  <ReferencesForm values={reference}
                                  showSubmit={true}
                                  onSubmit={this.handleSubmit}/>
                </div>
              </div>
            </section>
          </div>
        </div>
    )
  }
}

const MapStateToProps = (state) => {
  return {
    nanny: state.nanny
  }
};

const MapDispatchToProps = (dispatch) => {
  return {
    doSetReferences: (data) => {
      dispatch({
        type: APPEND_NANNY_REFERENCE,
        payload: data
      })
    },
    doShowLoading: (title) => {
      dispatch({
        type: SHOW_LOADING,
        payload: {
          title,
          isLoading: true,
        }
      })
    },
    doHideLoading: () => {
      dispatch({
        type: HIDE_LOADING,
        payload: {
          title: '',
          isLoading: false,
        }
      })
    }
  }
};

export default connect(MapStateToProps, MapDispatchToProps)(NannyRegRefsComponent);
