import React, {Component} from 'react';
import {Tab} from 'semantic-ui-react'
import Imgpanes1 from '../../../src/assets/images/svg/meeting.svg';


const panes = [
  {
    menuItem: '1',
    render: () =>
        <Tab.Pane attached='top'>
          <div className="process-item">
            <img src={Imgpanes1} width="150" className="d-block mx-auto" alt=""/>
            <h4 className="mt-4 mb-2 font-24 text-black">Getting to know you</h4>
            <p>We arrange a face-to-face meeting so we can gain a thorough understanding of your family wants and needs –
              child care, philosophies, goals and lifestyle. Finding the perfect fit for your family is our priority.</p>
          </div>
        </Tab.Pane>
  },
  {
    menuItem: '2',
    render: () =>
        <Tab.Pane attached='top'>
          <div className="process-item">
            <img src={Imgpanes1} width="150" className="d-block mx-auto" alt=""/>
            <h4 className="mt-4 mb-2 font-24 text-black ">Screen and background check</h4>
            <p>Every successful applicant is thoroughly screened to ensure your child is in safe hands. We conduct a full
              background and criminal check, review social media accounts and require a minimum of 5 references – also
              screening these referees to ensure they’re upstanding members of our community.</p>
            <p>From our own network and beyond, we seek out educated, nurturing nannies {/*and sitters*/} with years of experience,
              skills, and the utmost integrity. When we find these potential candidates, we always interview them in
              person.</p>
          </div>
      </Tab.Pane>
  },
  {
    menuItem: '3',
    render: () =>
        <Tab.Pane attached='top'>
          <div className="process-item">
            <img src={Imgpanes1} width="150" className="d-block mx-auto" alt=""/>
            <h4 className="mt-4 mb-2 font-24 text-black">Family Interviews</h4>
            <p>Once we have a good grasp on your family’s values, goals and schedule, we’ll give you a list of candidates. We
              only ever put forward candidates, we’re confident will enrich your family’s lifestyle. We will then arrange the
              interview/s for our nannies {/*nannies/sitters */} to meet and get to know your family. </p>
          </div>
        </Tab.Pane>
  },
  {
    menuItem: '4',
    render: () =>
        <Tab.Pane attached='top'>
          <div className="process-item">
            <img src={Imgpanes1} width="150" className="d-block mx-auto" alt=""/>
            <h4 className="mt-4 mb-2 font-24 text-black">Hiring your nanny{/* / sitter*/}</h4>
            <p>Once you have made your decision to hire a nanny {/*or sitter*/}, you can select to trial the candidate to make sure
              your expectations are being met and it will be a good fit, long term.</p>
            <p>If you decide you are happy, we’ll help you make it official. Should you decide it’s not the right fit, we’ll
              ensure you are matched with a more appropriate nanny. {/*or sitter.*/}</p>
          </div>
        </Tab.Pane>
  },
  {
    menuItem: '5',
    render: () =>
        <Tab.Pane attached='top'>
          <div className="process-item">
            <img src={Imgpanes1} width="150" className="d-block mx-auto" alt=""/>
            <h4 className="mt-4 mb-2 font-24 text-black">Congratulations on your hire!</h4>
            <p>From here, we walk you through the hiring process, making sure you understand your responsibilities as an
              employer. We conduct background checks in accordance with applicable laws, with all our nannies registering with
              TrustLine when they’re hired.</p>
            <p>Nannies {/*and sitters*/} are paid by their families, not by Elite Nannies, so we will ensure you are 100% educated
              with this process.</p>
          </div>
        </Tab.Pane>
  },
];


class MatchingProcessComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
        <div className="page-content">
          <SectionBanner/>
          <SectionProcessStep/>
          <SectionWhyElite/>
        </div>
    )
  }
}

const SectionBanner = () => (
    <section className="page-banner section bg-lightdark">
      <div className="container">
        <div className="banner-caption">
          <div className="row wow fadeInUp">
            <div className="col-12">
              <div className="banner-text">
                <span>ELITE</span>
                <h2 className="heading page-heading">MATCHING PROCESS</h2>
                <p className="mb-0">Our rigorous, personalised matching process ensures all your needs are met.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
);

const SectionProcessStep = () => (
    <section className="section">
      <div className="container">
        <div className="row justify-content-center wow fadeInUp">
          <div className="col-12 col-xl-8 col-xll-7 mx-auto">
            <div className="process-card">
              <Tab menu={{attached: 'bottom'}} panes={panes}/>
            </div>
          </div>
        </div>
      </div>
    </section>
);

const SectionWhyElite = () => (
    <section className="section bg-light-5">
      <div className="container px-xll-0">
        <div className="row align-items-center wow fadeInUp">
          <div className="col-12 col-lg-8 col-xl-8 mx-auto wow fadeInUp">
            <div className="banner-text text-center mb-5">
              <h2 className="heading heading-36">Why Elite?</h2>
            </div>
          </div>
        </div>
        <div className="row justify-content-center wow fadeInUp text-center">
          <div className="col-12 col-md-6 col-lg-4 col-xl-3 mb-5">
            <div className="why-elite-item">
              <h6 className="mt-4 mb-3">Experienced</h6>
              <p className="font-14">Our nannies are trained, reliable and proactive with a minimum of 3 years professional childcare
                experience.</p>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-4 col-xl-3 mb-5">
            <div className="why-elite-item">
              <h6 className="mt-4 mb-3">References</h6>
              <p>Your child’s safety is our number one priority. We strictly hire only the top 5% of nanny candidates
                through the screening process, which involves thorough background checks. Every Elite Nanny has
                impeccable references.</p>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-4 col-xl-3 mb-5">
            <div className="why-elite-item">
              <h6 className="mt-4 mb-3">Education</h6>
              <p>Not only are our nannies formally trained as child care workers, they have many other qualifications,
                including teaching, nursing and more.</p>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-4 col-xl-3 mb-5">
            <div className="why-elite-item">
              <h6 className="mt-4 mb-3">Passion</h6>
              <p>For our nannies, caring for children isn’t a job, it’s a vocation. They treasure being part of your
                family life and watching your children reach milestones.</p>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-4 col-xl-3 mb-5 mb-lg-0">
            <div className="why-elite-item">
              <h6 className="mt-4 mb-3">Modern</h6>
              <p>Being tech savvy is critical in any industry today, including child care. Our nannies will use
                technology to keep you up to date with your children’s progress, day and night.</p>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-4 col-xl-3 mb-0">
            <div className="why-elite-item">
              <h6 className="mt-4 mb-3">Character</h6>
              <p>An impressive CV isn’t enough to become an Elite Nanny. We only select respectful, dependable and loyal
                nannies that hold their families in the highest esteem. Our nannies are more than just caregivers,
                they’re role models, who help shape your family life.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
);

export default MatchingProcessComponent;
