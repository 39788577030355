export const PHONE_REGULAR_EXPRESSION = /((?:\+|00)[17](?: |\-)?|(?:\+|00)[1-9]\d{0,2}(?: |\-)?|(?:\+|00)1\-\d{3}(?: |\-)?)?(0\d|\([0-9]{3}\)|[1-9]{0,3})(?:((?: |\-)[0-9]{2}){4}|((?:[0-9]{2}){4})|((?: |\-)[0-9]{3}(?: |\-)[0-9]{4})|([0-9]{7}))/;
export const PERSON_NAME = /^[a-z ,.'-]+$/i;
export const NUMBER_ONLY = /^[0-9\s]*$/;

export const DURATION_OPTIONS = [
  {text:"Within a week", key:"Within a week", value:"Within a week"},
  {text:"Within a month", key:"Within a month", value:"Within a month"},
  {text:"Within 3 months", key:"Within 3 months", value:"Within 3 months"},
  {text:"Within 6 months", key:"Within 6 months", value:"Within 6 months"},
  {text:"Within a year", key:"Within a year", value:"Within a year"}
];

export const VALID_CITIES = [
  {text:"New York", key:"Within a week", value:"Within a week"},
  {text:"Within a month", key:"Within a month", value:"Within a month"},
  {text:"Within 3 months", key:"Within 3 months", value:"Within 3 months"},
  {text:"Within 6 months", key:"Within 6 months", value:"Within 6 months"},
  {text:"Within a year", key:"Within a year", value:"Within a year"}
];

export const AVAILABILITY_OPTIONS = [
  {text:"Full time", key:"Full time", value:"Full time"},
  {text:"Part time", key:"Part time", value:"Part time"},
  {text:"Casual", key:"Casual", value:"Casual"},
];

export const TRANSPORTATION_OPTIONS = [
  {text:"Yes - in their own vehicle", key:"Own", value:"Own"},
  {text:"Yes - we will provide the vehicle", key:"Provided", value:"Provided"},
  {text:"No",  key:"No", value:"No"},
];

export const PET_OPTIONS = [
  {text:"No pets", key:"0", value:"No pets"},
  {text:"Yes, cat/s", key:"1", value:"Yes, cat/s"},
  {text:"Yes, small dog/s", key:"2", value:"Yes, small dog/s"},
  {text:"Yes, large dog/s", key:"3", value:"Yes, large dog/s"},
  {text:"Yes, other small animal (hamster, mice, snakes)", key:"4", value:"Yes, other small animal (hamster, mice, snakes)"},
  {text:"Yes, our house is a zoo! (combination of above)", key:"5", value:"Yes, our house is a zoo! (combination of above)"},
  {text:"Other", key:"6", value:"Other"}
];

export const CARE_OPTIONS = [
  {text:"In a home", key:"0", value:"In a home"},
  {text:"At a hotel", key:"1", value:"At a hotel"},
  {text:"At a wedding or event", key:"2", value:"At a wedding or event"},
  {text:"At a theme park", key:"3", value:"At a theme park"},
  {text:"While travelling / vacation", key:"4", value:"While travelling / vacation"},
  {text:"On set / while filming", key:"5", value:"On set / while filming"},
  {text:"Other", key:"6", value:"Other"}
];

export const JOB_LOCATION_CITIES = [
  {text:"- All Locations -", key:"1", value:"All"},
  {text:"Austin, TX", key:"2", value:"Austin"},
  {text:"New York, NY", key:"3", value:"New York"},

  {text:"Los Angeles, CA", key:"4", value:"Los Angeles"},
  

];

// export const customStyles = {
//   option: (provided, state) => ({
//     ...provided,
//     borderBottom: '1px dotted pink',
//     color: state.isSelected ? 'black' : 'black',
//     padding: 20,
//   }),
//   control: () => ({
//     // none of react-select's styles are passed to <Control />
//     width: 800,
//   }),
//   singleValue: (provided, state) => {
//     const opacity = state.isDisabled ? 0.5 : 1;
//     const transition = 'opacity 300ms';
//
//     return {...provided, opacity, transition};
//   }
// };

export const customStyles = {
  control: () => ({
    padding: '0.608rem 0.9286rem',
    border: '2px solid transparent',
    boxShadow: '16px 19px 41.85px 3.15px rgba(0, 0, 0, 0.04)',
    fontSize: '1rem',
    lineHeight: '1.5',
    color: '#555657',
    backgroundColor: '#fff',
    backgroundClip: 'padding-box',
    borderRadius: '4px',
    width: '100%',
    display: 'flex',
  }),
  placeholder: () => ({
    color: "#000000",
    fontSize: "16px",
    opacity: "0.3",
  }),
  multiValueLabel: () => ({
    borderRadius: '2px',
    border: '1px solid rgba(230, 174, 173, 0.5)',
    borderRight: 'none',
    padding: '7px 5px 7px 9px',
    backgroundColor: '#fdf9f9',
  }),
  menuList: () => ({
    width: '100%',
  }),
  multiValueRemove: () => ({
    color: "#e6aead",
    backgroundColor: '#fdf9f9',
    paddingTop: "7px",
    border: '1px solid rgba(230, 174, 173, 0.5)',
    borderLeft: 'none',
    paddingRight: '7px',
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    paddingRight: '0',
  }),
  clearIndicator: (provided, state) => ({
    ...provided,
    paddingLeft: '0',
  }),
};
