import React, {Component} from 'react';
import history from '../../helpers/history';
// images
import toast from '../../Toast';

import nanny from '../../assets/images/svg/nanny.svg';
import sitterImg from '../../assets/images/svg/sitter.svg';
import { GET } from '../../services/rest.service';

class FamilyLookingForComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      looking_for: '',
      sitter:null,
    };
  }

  handleChange = (event) => {
    this.setState({looking_for: event.target.value})
  };

  handleForward = () => {
    if (this.state.looking_for === 'Nanny') {
      history.push('/family/preferences?utmSrc=pmts');
    } else {
      history.push('/family/membership');
    }
  };

  skipForNow = () => {
    history.push('/');
    localStorage.removeItem('email');
  };
  componentWillMount(){
    GET('/admin/appConfiguration')
    .then(({data})=>{
      this.setState({
        sitter:data.configuration.sitter
      })
    })
    .catch(err => {
      toast.error(err.message);
    });
  }

  render() {
    const {sitter}=this.state
    return (
      <div className="page-content page-auth">
        <div className="container">
          <section className="section bg-auth">
            <div className="row row-20">
              <div className="col-12 col-lg-9 col-xl-7 col-xll-6 mx-auto wow fadeInUp">
                <div className="text-center">
                  <h2 className="heading">I WANT TO HIRE A</h2>
                </div>
                <div className="mt-5">
                  <div className="row select-skills justify-content-center">
                    <div className="col-6 col-md-4 col-lg-4 col-xl-3 col-lg-4">
                      <input type="radio"
                             name={'looking_for'}
                             id={'nanny'}
                             value="Nanny"
                             checked={this.state.looking_for == 'Nanny'}
                             className="select-item-chk"
                             onChange={this.handleChange}/>
                      <label className="select-skills-item" htmlFor={'nanny'}>
                        <img src={nanny} className="img-fluid select-skills-icon" alt={'nanny'}/>
                        <span className="select-skills-name">Nanny</span>
                      </label>
                    </div>
                   {sitter&& <div className="col-6 col-md-4 col-lg-4 col-xl-3 col-lg-4">
                      <input type="radio"
                             name={'looking_for'}
                             id={'sitter'}
                             value="Sitter"
                             checked={this.state.looking_for == 'Sitter'}
                             className="select-item-chk"
                             onChange={this.handleChange}/>
                      <label className="select-skills-item" htmlFor={'sitter'}>
                        <img src={sitterImg} className="img-fluid select-skills-icon" alt={'sitter'}/>
                        <span className="select-skills-name">Sitter</span>
                      </label>
                    </div>}
                    <div className={"col col-12"}>
                      <p className={"text-center"}>
                        <a href="javascript:void(0);" onClick={this.skipForNow}>Skip For Now</a>
                      </p>
                    </div>
                    <div className="col-12 form-group text-center mt-5 mb-0">
                      {this.state.looking_for != '' &&
                      <button className="btn btn-outline-primary" onClick={this.handleForward}>Next</button>}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    )
  }
}

export default FamilyLookingForComponent;
