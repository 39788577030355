import React, {Component} from 'react';
import { toast } from 'react-toastify';
import creditcard from '../../assets/images/svg/credit-card.svg';
import {
  CardCVCElement,
  CardExpiryElement,
  CardNumberElement,
  Elements,
  injectStripe,
  StripeProvider
} from "react-stripe-elements";
import {showLoading, hideLoading} from "../../redux/loader/LoaderActions";
import {connect} from "react-redux";

const style = {
  base: {
    color: '#555657',
    fontFamily: '"Muli", sans-serif',
    fontSize: '14px',
    fontWeight: '400',
    '::placeholder': {
      color: '#b2b2b2'
    }
  },
  invalid: {
    color: '#cf3100'
  }
};

class PaymentForm extends Component {

  handleSubmit = (ev) => {
    ev.preventDefault();
    const {dispatch} = this.props;
    dispatch(showLoading('Your payment is processing, Please wait. Don\'t close or refresh your browser'));
    if (this.props.stripe) {
      this.props.stripe
          .createToken()
          .then((payload) => {
            if(payload.token && payload.hasOwnProperty('token'))  {
              this.props.onSubmit(payload.token);
            } else if (payload.error && payload.hasOwnProperty('error')) {
              toast.error(payload.error.message);
              dispatch(hideLoading());
            } else {
              toast.error('Something went wrong while processing your payment, please try again later.');
              dispatch(hideLoading());
            }
          })
          .catch(err => {
            dispatch(hideLoading())
          })
    } else {
      toast.error("Payment gateway is not ready yet.");
      dispatch(hideLoading());
    }
  };

  render() {
    return (
        <form onSubmit={this.handleSubmit}>
          <div className="form-group form-group-btn">
            <div className="d-flex justify-content-between align-items-center mb-2">
              <label className="mb-0" htmlFor="inputAddress">Card Number</label>
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 486.733 486.733"><path fill="#b9b9b9" d="M403.88 196.563h-9.484v-44.388c0-82.099-65.151-150.681-146.582-152.145-2.225-.04-6.671-.04-8.895 0C157.486 1.494 92.336 70.076 92.336 152.175v44.388h-9.485c-14.616 0-26.538 15.082-26.538 33.709v222.632c0 18.606 11.922 33.829 26.539 33.829H403.88c14.616 0 26.539-15.223 26.539-33.829V230.272c0-18.626-11.922-33.709-26.539-33.709zM273.442 341.362v67.271c0 7.703-6.449 14.222-14.158 14.222H227.45c-7.71 0-14.159-6.519-14.159-14.222v-67.271c-7.477-7.36-11.83-17.537-11.83-28.795 0-21.334 16.491-39.666 37.459-40.513 2.222-.09 6.673-.09 8.895 0 20.968.847 37.459 19.179 37.459 40.513-.002 11.258-4.355 21.435-11.832 28.795zm58.444-144.799H154.847v-44.388c0-48.905 39.744-89.342 88.519-89.342 48.775 0 88.521 40.437 88.521 89.342v44.388z"/></svg>
            </div>
            <div className="input-group append-left">
              <div className="input-group-append">
                <button className="btn btn-clear btn-lg text-primary px-4 border-0" type="button">
                  <img width="22" height="22" src={creditcard} alt="search"/>
                </button>
              </div>
              <CardNumberElement placeholder="**** **** **** ****" style={style} className="form-control form-placeholder"/>
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-8">
              <label htmlFor="inputEmail4">Expiry Date</label>
              <CardExpiryElement style={style} className="form-control"/>
            </div>
            <div className="form-group col-md-4">
              <label htmlFor="inputPassword4">CVC</label>
              <CardCVCElement placeholder="***" style={style} className="form-control form-placeholder"/>
            </div>
            <div className="form-group col-12 text-center mt-3 mb-0">
              <button className="btn btn-outline-primary">pay ${this.props.amount} now</button>
            </div>
          </div>
        </form>
    )
  }
}

export default injectStripe(connect()(PaymentForm));
