import React, {Component, Fragment} from 'react';
import history from '../../helpers/history';
import {connect} from "react-redux";
import M from "materialize-css";
import {Helmet} from "react-helmet";
// Components
import {SkillsCarousel} from "../HomeComponent";
import Testimonial from "./shared/Testimonials";
// Images
import ImgmassageBanner from "../../assets/images/massage-banner.jpg";
import QualifiedChefs from "../../assets/images/massage-qualified.jpg";
import SvgSwedishMassage from "../../assets/images/svg/swedish-massage.svg";
import SvgThaiMassage from "../../assets/images/svg/thai-massage.svg";
import SvgSportsMassage from "../../assets/images/svg/sports-massage.svg";
import SvgReflexology from "../../assets/images/svg/reflexology.svg";
import ImgWhychooseus from "../../assets/images/Whychooseus.jpg";
import ImgChefFacilitate from "../../assets/images/massage-we-facilitate.jpg";
import ImgFooterBanner from "../../assets/images/new-york-thankyoufamily.jpg";

class MassageTherapistDetails extends Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  handleOnSearch = () => {
    const {isLoggedIn} = this.props.user;
    history.push(isLoggedIn ? '/family/preferences' : '/family/registration');
  };

  componentDidMount() {
    M.Parallax.init(this.Parallax1);
    M.Parallax.init(this.Parallax2);
    M.Parallax.init(this.Parallax3);
  }

  componentWillUnmount() {
    document.title = "Elite Nannies";
  }

  render() {
    const {isLoggedIn} = this.props.user;
    const {skills} = this.props;

    return (
      <Fragment>
        <Helmet>
          <title>Massage Therapist Nannies</title>
          <meta name="title" content="Massage Therapist Nannies"/>
          <meta name="description"
                content="Our massage therapist nannies combine their extensive knowledge and experience as a masseuse, with years of professional nanny experience. "/>
          <meta property="og:type" content="website"/>
          <meta property="og:url" content="https://www.elitenannies.com/nanny-services/massage-therapist"/>
          <meta property="og:title" content="Massage Therapist Nannies"/>
          <meta property="og:description"
                content="Our massage therapist nannies combine their extensive knowledge and experience as a masseuse, with years of professional nanny experience. "/>
          <meta property="og:image" content="https://www.elitenannies.com/static/media/massage-qualified.d3d839f9.jpg"/>
          <meta property="twitter:card" content="summary_large_image"/>
          <meta property="twitter:url" content="https://www.elitenannies.com/nanny-services/massage-therapist"/>
          <meta property="twitter:title" content="Massage Therapist Nannies"/>
          <meta property="twitter:description"
                content="Our massage therapist nannies combine their extensive knowledge and experience as a masseuse, with years of professional nanny experience. "/>
          <meta property="twitter:image"
                content="https://www.elitenannies.com/static/media/massage-qualified.d3d839f9.jpg"/>
        </Helmet>
        <div className="page-content skill-detail-page">
          <SectionBanner para1={Parallax => {
            this.Parallax1 = Parallax;
          }} onSearchClick={this.handleOnSearch}/>
          <SectionQualifiedChefs onSearchClick={this.handleOnSearch}/>
          <SectionSkillFeatures/>
          <SectionWhyChooseUs para2={Parallax => {
            this.Parallax2 = Parallax;
          }}/>
          <SectionWeFacility onSearchClick={this.handleOnSearch}/>
          <SectionWeNannyQualifications skills={skills}/>
          <SectionFooter para3={Parallax => {
            this.Parallax3 = Parallax;
          }} onSearchClick={this.handleOnSearch}/>
        </div>
      </Fragment>
    )
  }
}


const SectionBanner = ({para1, onSearchClick}) => (
  <section className="parallax-container section section-padding">
    <div ref={para1} className="parallax">
      <img src={ImgmassageBanner}/>
    </div>
    <div className="container position-relative">
      <button className="btn btn-clear btn-back-skills p-0 btn-back-right"
              onClick={() => history.push('/our-nannies')}>
        <svg xmlns="http://www.w3.org/2000/svg" width="26" viewBox="0 0 31.494 31.494">
          <path
            d="M10.273 5.009a1.112 1.112 0 0 1 1.587 0 1.12 1.12 0 0 1 0 1.571l-8.047 8.047h26.554c.619 0 1.127.492 1.127 1.111s-.508 1.127-1.127 1.127H3.813l8.047 8.032c.429.444.429 1.159 0 1.587a1.112 1.112 0 0 1-1.587 0L.321 16.532a1.12 1.12 0 0 1 0-1.571l9.952-9.952z"
            fill="#e6aead"/>
        </svg>
      </button>
      <div className="row align-items-center wow fadeInUp">
        <div className="col-12 col-lg-10 col-xl-8 col-xll-8 mx-auto">
          <div className="banner-caption">
            <div className="text-white text-center">
              <h2 className="heading text-white mb-3 text-uppercase">MASSAGE THERAPIST NANNIES</h2>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-10 col-xl-8 col-xll-7 mx-auto">
          <div className="banner-caption">
            <div className="text-white text-center">
              <p className={'sub-heading font-normal mb-4 px-xll-3 text-white'}>
                As a prestigious nanny agency, we specialize in career nannies {/*& sitters*/} who are also trained
                and qualified in an additional profession to childcare.
              </p>
              <p className="mb-4 px-xll-3">
                Our massage therapists combine their extensive knowledge and experience with an intuitive, healing touch
                to melt away aches and pains that you never knew you had. The bodyworkers at Elite Nannies perform
                specialized techniques that help relax and manipulate tired parents after a long, hard day.
              </p>
              <button className="btn btn-outline-primary btn-lg text-uppercase text-white"
                      onClick={onSearchClick}>search MASSAGE THERAPIST NANNIES
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

const SectionQualifiedChefs = ({onSearchClick}) => (
  <section className="section">
    <div className="container-fluid">
      <div className="row align-items-center">
        <div className="col-12 col-lg-6 col-xl-6 wow fadeIn order-2 order-lg-1" data-wow-delay=".8s">
          <div className="row">
            <div className="col-12 col-md-10 col-xl-9 offset-xl-2 col-xll-8 offset-xll-3">
              <div className="incredibly-content pl-xl-4">
                <p className="sub-heading">Qualified Massage Therapists. Experienced Nannies.</p>
                <div className="pr-xl-5 mr-xl-5">
                  <p>All of our massage therapists nannies have the relevant qualifications and training, with 3+ years’
                    experience as a masseuse, trained in different styles and techniques of the art of massage.</p>
                  <p>All of our massage therapist nannies are CPR and first aid trained with a minimum of 3 years
                    professional nanny experience for high profile New York families.</p>
                  <button className="btn btn-outline-primary btn-lg text-uppercase btn-250 mt-4"
                          onClick={onSearchClick}>GET STARTED
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-6 col-xl-6 px-md-0 wow fadeInLeft  order-1 order-lg-2" data-wow-delay=".5s">
          <img src={QualifiedChefs} className="w-100 img-fluid float-right mb-lg-0 mb-4" alt=""/>
        </div>
      </div>
    </div>
  </section>
);

const SectionSkillFeatures = () => (
  <section className="section pt-0">
    <div className="container">
      <div className="incredibly-content text-center" data-wow-delay=".8s">
        <div className="row align-items-center">
          <div className="col-12 col-lg-6 col-xl-10 wow fadeIn mx-auto">
            <p className="sub-heading">Nannies, specializing in massage!</p>
          </div>
          <div className="w-100"></div>
          <div className="col-12 col-lg-6 col-xl-5 my-4 wow fadeIn mx-auto">
            <div className="px-xl-4">
              <p>No matter what type of massage you prefer, our nanny masseuses will help you recharge and
                re-energize.</p>
            </div>
          </div>
          <div className="col-12 col-lg-11 mx-auto pt-4">
            <div className="row no-gutters">
              <div className="col-12 col-md-6 col-lg-3">
                <div className="sec-cheflist p-4">
                  <img src={SvgSwedishMassage} className="img-fluid" alt=""/>
                  <span className="cheflist-text">Swedish Massage</span>
                  <p>The primary goal of the Swedish massage technique is to relax the entire body.</p>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-3">
                <div className="sec-cheflist p-4">
                  <img src={SvgThaiMassage} className="img-fluid" alt=""/>
                  <span className="cheflist-text">Thai Massage</span>
                  <p>An ancient massage technique introducted into Thailand about 2500 years ago.</p>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-3">
                <div className="sec-cheflist p-4">
                  <img src={SvgSportsMassage} className="img-fluid" alt=""/>
                  <span className="cheflist-text">Sports Massage</span>
                  <p>Geared towards sports professionals or athletes, to relieve pain or discomfort.</p>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-3">
                <div className="sec-cheflist p-4">
                  <img src={SvgReflexology} className="img-fluid" alt=""/>
                  <span className="cheflist-text">Reflexology</span>
                  <p>Our reflexologist nannies push certain points on hands and feets to help alleviate stress.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

const SectionWhyChooseUs = ({para2}) => (
  <section className="parallax-container section section-padding section-bg">
    <div ref={para2} className="parallax">
      <img src={ImgWhychooseus}/>
    </div>
    <div className="container-fluid">
      <div className="row align-items-center">
        <div className="col-12 col-lg-6 col-xl-6 wow fadeIn order-2 order-lg-1" data-wow-delay=".8s">
          <div className="row">
            <div className="col-12 col-md-10 col-xl-9 offset-xl-2 col-xll-8 offset-xll-3">
              <div className="incredibly-content pl-lg-4 pr-lg-5">
                <p className="sub-heading">Why choose us?</p>
                <p className="become-info">
                  Elite Nannies are professional nannies who are also skilled in other areas. These include
                  Teachers, Nurses, Foreign Language Teachers, Massage Therapists, Personal Trainers, Hair Stylists,
                  Beauty Therapists, Photographers and more.
                </p>
                <p className="become-info">
                  Our candidates understand the importance of not only keeping your children safe and
                  making them feel loved, but helping cultivate them into inspiring, intelligent and creative little
                  people.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

const SectionWeFacility = ({onSearchClick}) => (
  <section className="section">
    <div className="container-fluid">
      <div className="row align-items-center">
        <div className="col-12 col-lg-6 col-xl-6 px-md-0 wow fadeInLeft" data-wow-delay=".5s">
          <img src={ImgChefFacilitate} className="w-100 img-fluid float-right mb-4 mb-lg-0" alt=""/>
        </div>
        <div className="col-12 col-lg-6 col-xl-6 wow fadeIn" data-wow-delay=".8s">
          <div className="row">
            <div className="col-12 col-xll-6 offset-xll-2 col-lg-11 offset-lg-1 pl-xll-0">
              <div className="incredibly-content">
                <p className="sub-heading">We facilitate the entire process, so you can have peace of mind.</p>
                <p className="mt-4">
                  Not only do we match your unique family with an elite nanny, but once you have made your hire, we can
                  also assist you with tax and benefit information, so you can worry about the important things!
                </p>
                <button className="btn btn-outline-primary btn-lg text-uppercase btn-250 mt-4"
                        onClick={onSearchClick}>GET STARTED
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

const SectionWeNannyQualifications = ({ skills }) => (
  <Fragment >
    <section className="section wow fadeInUp pt-0 pb-0">
      <div className="container px-xll-0">
        <div className="row">
          <div className="col-12 col-xl-7 col-xll-6 mx-auto">
            <div className="text-center wow fadeInUp incredibly-content mb-5">
              <h2 className="sub-heading mb-4">Other Nanny Qualifications</h2>
              <p className="px-xl-4 pl-xll-2">We only recruit honourable, educated and refined nannies that help
                families live an exceptional life. All of our nannies are CPR and First Aid trained and have a minimum of
              3 years professional {/*nanny/sitter*/} nanny experience. They are incredibly well-versed in child-related safety
                  measures - we want you to be able to leave your house each day with complete ease knowing your children
              are in the safest possible hands.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="section wow fadeInUp skills-slider pt-0 pb-0">
      <SkillsCarousel skills={skills} />
    </section>
    <section className="section wow fadeInUp pt-0">
      <div className="container">
        <Testimonial />
      </div>
    </section>
  </Fragment >
);

const SectionFooter = ({para3, onSearchClick}) => (
  <section className="parallax-container section mb-5 section-padding">
    <div ref={para3} className="parallax">
      <img src={ImgFooterBanner}/>
    </div>
    <div className="container">
      <div className="row align-items-center wow fadeInUp">
        <div className="col-12 col-lg-10 col-xl-8 col-xll-8 mx-auto">
          <div className="banner-caption incredibly-content">
            <div className="text-white text-center">
              <h2 className="sub-heading text-white mb-5 text-uppercase">BEAUTIFIED, SIMPLIFIED.</h2>
              <button className="btn btn-outline-primary btn-lg text-uppercase text-white btn-250"
                      onClick={onSearchClick}>GET STARTED
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

const MapStateToProps = (state) => {
  return {
    user: state.user,
    skills: state.skills,
  }
};

export default connect(MapStateToProps, null)(MassageTherapistDetails);
