import React, {Component, Fragment} from 'react';
import {connect} from "react-redux";
import {Link, Redirect} from "react-router-dom";

// Services
import {login, LOGIN_SUCCESS, LOGIN_FAILURE} from "../../redux/user/UserActions";

// Components
import LoginForm from "../../components/LoginForm";
import { POST, GET } from '../../services/rest.service';
import { setRecord } from '../../services/indexdb.service';
import { toast } from 'react-toastify';
import { APPEND_NANNY_REGISTRATION } from '../../redux/nanny-registration/NannyRegistrationReducer';
import history from '../../helpers/history';
import queryString from 'query-string'
import {isEmpty } from "lodash"
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
import { showLoading, hideLoading } from '../../redux/loader/LoaderActions';

class NannyLoginComponent extends Component {

  handleLogin = (formData) => {
    const payload = {
        ...formData,
      type: 'Nanny'
    };
    const {dispatch} = this.props;
    // dispatch(login(payload));
    POST('/auth/user/login', payload)
    .then(({access_token, data}) => {
      if(data.isEmailConfirmed){
      if (data.register_last_step > 0) {
        // setting a token for later use when the user is able to login completely
        setRecord('TT', access_token); // TT = Temporary token

        // Setting this so app would know that user is continuing the registration process which was partially completed
        setRecord('LCAR', true); // LCAR = Login Continuation After Registration

        // saving user data to use later
        setRecord('UD', data); // UD = User Data

        const step = data.register_last_step;
        toast.success('Pick up where you left of to complete your profile');
        localStorage.setItem('reg_auth', JSON.stringify({email: data.email, _id: data._id}));
        dispatch({
          type: APPEND_NANNY_REGISTRATION,
          payload: {
            first_name: data.first_name,
            last_name: data.last_name,
            email: data.email,
            location: data.location,
            mobile_number: data.mobile_number,
            _id: data._id
          }
        });
        switch (step) {
          case 1:
            history.push('/nanny/profile');
            break;
          case 2:
            history.push('/nanny/skills');
            break;
          case 3:
            history.push('/nanny/references');
            break;
          case 4:
            history.push('/nanny/authorization');
            break;
          default:
            history.push('/nanny/login');
            break;
        }
      } else {
        // writing down the token for auth communications
        localStorage.setItem('token', access_token);
        dispatch({
          type: LOGIN_SUCCESS,
          payload: {
            isLoggedIn: true,
            user: data,
            type: data.type,
            message: 'logged in successfully'
          }
        });


        const parsed = queryString.parse(window.location.search);

        if(!isEmpty(parsed) && parsed.redirectUrl) {
          history.push(parsed.redirectUrl);
        }

        if(isEmpty(parsed)) {
          history.push(`/`);
          // history.push(`/${data.type == 'Family' ? 'family' : 'nanny'}/account`);
        }
      }
    }

      else{
        // toast.error("Not Verify");
        this.handleClick(data)


      }



    })
    .catch(err => {
      dispatch({
        type: LOGIN_FAILURE,
        payload: {
          isLoggedIn: false,
          user: {},
          type: undefined,
          message: err.message
        }
      });
      toast.error(err.message);
    });
  };
   handleClick = (data) => {
    this.setState({
      open:true,
      userData:data,
    })
  };

   handleClose = () => {
    this.setState({
      open:false,
      userData:null
    })
  };
  state={
    open:false,
    userData:null
  }

  resendConfirmationMail = () => {
    const {dispatch}=this.props;
    dispatch(showLoading());
    const { userData } = this.state;
    GET(`/auth/user/resend/${userData._id}/email-confirmation`)
      .then(res => {
        this.handleClose()
        toast.success('A message with a confirmation link has been sent to your email address. Please follow the link to activate your account.');
        dispatch(hideLoading());
      })
      .catch(err => {
        dispatch(hideLoading());
        toast.error(err.message);
      })
  };
  render() {
    const {isLoggedIn, type, user, message} = this.props.user;
    const {url} = this.props.match;
    function Alert(props) {
      return <MuiAlert elevation={6} variant="filled" {...props} />;
    }
   
    if(isLoggedIn) {
      return <Redirect from={url} to={'/'} />
    }

    const classes = makeStyles(theme => ({
      root: {
        width: "100%",
        "& > * + *": {
          marginTop: theme.spacing(2)
        }
      }
    }));
    

    return (
      <Fragment className={classes.root}>
        <div className="page-content page-auth">
          <div className="container">
            <section className="section bg-light">
              <div className="row">
                <div className="col-12 col-lg-7 col-xl-6 col-xll-5 mx-auto px-xll-4 px-xl-4 wow fadeInUp">
                  <div className="text-center">
                    <h2 className="heading">ELITE LOGIN</h2>
                    <p>Login to your account.</p>
                  </div>
                  <LoginForm onSubmit={this.handleLogin} type="nanny"/>
                  <div className="form-group text-center">
                    <p>Want to become an elite nanny? <Link className="link-auth" to={'/nanny/registration'}>Sign up now!</Link></p>
                  </div>
                </div>
              </div>

            </section>
          </div>
        </div>
        <Snackbar open={this.state.open} autoHideDuration={6000} onClose={this.handleClose}>
        <Alert onClose={this.handleClose} severity="error">
        Your account is inactive as you have not verified your email address.
                  <a className="btn text-white font-bold py-0" href="javascript:void(0);"
                    onClick={this.resendConfirmationMail}>Resend Verification Email</a>
              
        </Alert>
      </Snackbar>
        </Fragment>
    )
  }
}

const MapStateToProps = (state) => {
  return {
    user: state.user
  }
};

export default connect(MapStateToProps, null)(NannyLoginComponent);
