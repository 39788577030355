import React, {Component} from 'react';
import {Link, Redirect} from "react-router-dom";
import {toast} from "react-toastify";
import {connect} from "react-redux";
/* Services */
import {POST} from "../../services/rest.service";
import {HIDE_LOADING, SHOW_LOADING} from "../../redux/loader/LoaderActions";
/* Components */
import PersonalDetailsForm from "../../components/PersonalDetailsForm";
import {APPEND_NANNY_REGISTRATION} from "../../redux/nanny-registration/NannyRegistrationReducer";

class NannyRegPerDetailsComponent extends Component {

  handleRegistrationClick = (formData) => {
    const payload = {
      ...formData,
      location: JSON.parse(localStorage.getItem('location')),
    };

    const register$ = POST('/auth/user/nanny_register/step1', payload);
    this.props.doShowLoading('Please wait...');
    register$
        .then(({access_token, data}) => {
          toast.success('Registration successful. Please continue with the following steps to create your account.');
          localStorage.setItem('reg_auth', JSON.stringify({email: data.email, _id: data._id, first_name: data.first_name, last_name: data.last_name}));
          localStorage.removeItem('location');
          this.props.doHideLoading();
          this.props.history.push('/nanny/profile');
          this.props.doSetPersonalDetails({
            first_name: data.first_name,
            last_name: data.last_name,
            email: data.email,
            location: data.location,
            mobile_number: data.mobile_number,
            _id: data._id
          });
        })
        .catch(err => {
          toast.error(err.message);
          this.props.doHideLoading();
        })
  };

  render() {
    let {first_name, last_name, email, password, mobile_number, location} = this.props.nanny;
    const {isLoggedIn} = this.props.user;
    const {url} = this.props.match;

    if(isLoggedIn) {
      return <Redirect from={url} to={'/'} />
    }

    return (
        <div className="page-content page-auth">
          <div className="container">
            <section className="section bg-auth">
              <div className="row row-20">
                <div className="col-12 col-lg-8 col-xl-7 col-xll-7 mx-auto wow fadeInUp">
                  <div className="text-center">
                    <h2 className="heading">JOIN THE ELITE</h2>
                    <p className="px-4 px-md-5">Congratulations on taking your first step to becoming an Elite Nanny. {/*or sitter*/}<br/>Let's begin by adding your personal details.</p>
                  </div>
                  <div className="mt-5">
                    <PersonalDetailsForm values={{first_name, last_name, email, password, mobile_number, location}}
                                         type={"nanny"}
                                         onSubmit={this.handleRegistrationClick} />
                  </div>
                  <div className="form-group text-center">
                    <p className="mb-0">Already have an Elite Nanny {/*or sitter*/}account? <Link className="link-auth" to={`/nanny/login`}>Sign In</Link></p>
                    <p><Link className="link-auth" to={`/family/registration`}>Or, sign up as a family here.</Link></p>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
    )
  }
}

const MapStateToProps = (state) => {
  return {
    nanny: state.nanny,
    user: state.user,
  }
};

const MapDispatchToProps = (dispatch) => {
  return {
    doSetPersonalDetails: (data) => {
      dispatch({
        type: APPEND_NANNY_REGISTRATION,
        payload: data
      })
    },
    doShowLoading: (title) => {
      dispatch({
        type: SHOW_LOADING,
        payload: {
          title,
          isLoading: true,
        }
      })
    },
    doHideLoading: () => {
      dispatch({
        type: HIDE_LOADING,
        payload: {
          title: '',
          isLoading: false,
        }
      })
    }
  }
};

export default connect(MapStateToProps, MapDispatchToProps)(NannyRegPerDetailsComponent);
