import React from 'react';
import {Formik} from "formik";
import {object, string, ref} from 'yup';

import PasswordInput from "../../components/UI/PasswordInput";

const ResetPasswordForm = (props) => (
    <Formik initialValues={{password: '', confirm_password: ''}}
            onSubmit={(values) => {
              props.onSubmit(values);
            }}
            validationSchema={object().shape({
              password: string("")
                  .required("Enter your password")
                  .min(6, "Password must contain at least 6 characters"),
              confirm_password: string().required("Please confirm your password").oneOf([ref("password")], "Passwords does not match")
            })}>
      {renderProps => {
        const {values, touched, errors, handleChange, handleBlur, handleSubmit} = renderProps;

        return(
            <form className={'row'} onSubmit={handleSubmit}>
              <PasswordInput id="password"
                             size="col-12"
                             label="Password"
                             placeholder="Password"
                             error={touched.password && errors.password}
                             value={values.password}
                             onChange={handleChange}
                             onBlur={handleBlur}/>

              <PasswordInput id="confirm_password"
                             size="col-12"
                             label="Confirm Password"
                             placeholder="Confirm new Password"
                             error={touched.confirm_password && errors.confirm_password}
                             value={values.confirm_password}
                             onChange={handleChange}
                             onBlur={handleBlur}/>

              <div className="form-group col-12 text-center my-5">
                <button type="submit" className="btn btn-outline-primary">Reset Password</button>
              </div>
            </form>
        )
      }}
    </Formik>
);

export default ResetPasswordForm;
