import React, {Component} from 'react';
import {Link} from "react-router-dom";
import history from "../../helpers/history";
import {GET} from "../../services/rest.service";
import toast from '../../Toast';
import PageLoader from "../../components/PageLoader";
import { connect } from 'react-redux';

const MemberShipCard = ({item, value, checked, onChange}) => (
    <div className="col-12 col-md-6 col-lg-6 col-xl-3 mb-4 wow fadeInUp">
      <input type="radio" id={item._id} name="plans" value={item._id} checked={item._id == value} className="membership-chk" onChange={onChange}/>
      <label htmlFor={item._id} className="select-membership-item">
        <div className="membership-item">
          <h4 className="membership-heading">{item.title}</h4>
          <h5 className="membership-price"> ${item.price_usd} <span>{`${item.duration === 1 ? 'per' : 'for'}`} {item.duration > 1 && item.duration} {item.duration === 1 ? 'month' : 'months'}</span></h5>
          <div className="membership-divider"></div>
          <p className="membership-content" dangerouslySetInnerHTML={{__html: item.description}}></p>
        </div>
      </label>
    </div>
);

class MembershipComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      plans: [],
      selectedPlan: ''
    };
  }

  handleSelectedPlanChange = (e) => {
    this.setState({selectedPlan: e.target.value})
  };

  handleSelectPlan = () => {
    const selectedPlanDetails = this.state.plans.find(x => x._id == this.state.selectedPlan);
    localStorage.setItem('plan', this.state.selectedPlan);
    localStorage.setItem('amount', selectedPlanDetails.price_usd);
    history.push('/family/payments');
  };

  componentDidMount() {
    GET('/user/stripe/payments/plans')
        .then(res => this.setState({plans: res.plans, selectedPlan: res.plans[0]._id}))
        .catch(err => toast.error(err.message))
  }


  render() {
    const selectedPlanDetails = this.state.plans.find(x => x._id == this.state.selectedPlan);
    const { isLoggedIn } = this.props.user;

    if(this.state.plans.length == 0) {
      return <PageLoader />
    }

    return (
        <div className="page-content page-auth">
          <div className="container">
            <section className="section bg-auth">
              <div className="row row-20">
                <div className="col-12 col-lg-9 col-xl-7 col-xll-6 mx-auto wow fadeInUp">
                  <div className="text-center mb-5">
                    <h2 className="heading">
                      {
                        !isLoggedIn && (
                          <Link to={`/family/looking-for`} className="btn btn-back btn-back-inline">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 268.8 268.8">
                              <path
                                  d="M3.7 143.3l80 80c2.4 2.4 5.6 3.7 8.8 3.7s6.4-1.2 8.8-3.7c4.9-4.9 4.9-12.8 0-17.7l-58.7-58.7h213.7c6.9 0 12.5-5.6 12.5-12.5s-5.6-12.5-12.5-12.5H42.7l58.7-58.7c4.9-4.9 4.9-12.8 0-17.7-4.9-4.9-12.8-4.9-17.7 0l-80 80c-4.9 5-4.9 12.9 0 17.8z"
                                  fill="#f1d1cc"/>
                            </svg>
                          </Link>
                        )
                      }
                      SIGN UP
                    </h2>
                    <p className="text-note">Select your membership preference</p>
                  </div>
                </div>
              </div>
              <div className="row row-20 membership-card select-membership">
                {
                  this.state.plans.map((item, index) => {
                   return <MemberShipCard
                                   item={item}
                                   value={this.state.selectedPlan}
                                   onChange={this.handleSelectedPlanChange}
                                   key={index}/>
                  })
                }
              </div>
              <div className="text-center mt-4 pt-2">
                <button className="btn btn-outline-primary px-3" onClick={this.handleSelectPlan}>
                  CONTINUE WITH {selectedPlanDetails.title}
                </button>
              </div>
            </section>
          </div>
        </div>
    )
  }
}

const MapStateToProps = (state) => {
  return {
    user: state.user,
  }
}

export default connect(MapStateToProps, null)(MembershipComponent);
