import React from 'react';
import nanny from "../assets/images/svg/get-started-nanny.svg";
import sitter from "../assets/images/svg/get-started-family.svg";
import {Link} from "react-router-dom";
import history from '../helpers/history';

const styles = {
  card: {padding: "27px 15px", cursor: 'pointer'}
};

const SignIn = () => (
  <div className="page-content page-auth">
    <div className="container">
      <section className="section bg-auth">
        <div className="row row-20">
          <div className="col-12 col-lg-9 col-xl-7 col-xll-6 mx-auto wow fadeInUp">
            <div className="text-center">
              <h2 className="heading">SIGN IN AS</h2>
            </div>
            <div className="mt-5">
              <div className="row select-skills justify-content-center">
                <div className="col-6 col-md-4 col-lg-4 col-xl-3 col-lg-4">
                  <div className="select-skills-item" style={styles.card} onClick={() => history.push('/family/login')}>
                    <img src={sitter} className="img-fluid select-skills-icon" alt={'family'}/>
                    <span className="select-skills-name">Family</span>
                  </div>
                </div>
                <div className="col-6 col-md-4 col-lg-4 col-xl-3 col-lg-4">
                  <div className="select-skills-item" style={styles.card} onClick={() => history.push('/nanny/login')}>
                    <img src={nanny} className="img-fluid select-skills-icon max-h-40" alt={'nanny'}/>
                    <span className="select-skills-name">Nanny{/*or Sitter*/}</span>
                  </div>
                </div>
                <div className={"col col-12"}>
                  <p className={"text-center"}>
                    Don't yet have an account? <Link to={'/get-started'}>Sign up now</Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
);

export default SignIn;
