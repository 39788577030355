import React, { Component } from 'react';
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { find, findIndex, isEmpty } from 'lodash';
import toast from '../../Toast';
// Services
import config from '../../config';
import { PATCH, POST } from "../../services/rest.service";
import { convertJsonToFormData } from "../../helpers/helper";
import { HIDE_LOADING, SHOW_LOADING } from "../../redux/loader/LoaderActions";
import { APPEND_NANNY_REGISTRATION } from "../../redux/nanny-registration/NannyRegistrationReducer";
// IMAGES
import attachment from '../../assets/images/svg/attachment.svg';
import other from '../../assets/images/svg/favorites-button.svg';

// Functional Components
const Checkbox = (props) => (
  <div className="col-6 col-md-4 col-lg-4 col-xl-3 col-lg-4">
    <input type="checkbox" name={props.name} id={props.id} value={props.uniqueId} checked={props.checked}
      className="select-item-chk"
      onChange={props.onChange} />
    <label className="select-skills-item" htmlFor={props.id}>
      <img src={config.api.URL + props.image} className="img-fluid select-skills-icon" alt={props.name} />
      <span className="select-skills-name">{props.label}</span>
    </label>
  </div>
);

class NannyRegSkillsComponent extends Component {

  constructor(props) {
    super(props);
    const otherSkill = props.nanny.other_skills;
    this.state = {
      selectedSkills: this.props.nanny.skills,
      isOtherAvailable: otherSkill.length > 0,
      otherSkillDetails: {
        title: otherSkill.length > 0 ? otherSkill[0].title : '',
        document: otherSkill.length > 0 ? otherSkill[0].document : '',
        name: otherSkill.length > 0 ? otherSkill[0].title : '',
      },
      resumeData: !isEmpty(this.props.nanny.resume) ? {
        uploadedresumeData: this.props.nanny.resume.document,
        resumeFileName: this.props.nanny.resume.resumeFileName
      } : {},
      personalDetails: JSON.parse(localStorage.getItem('reg_auth'))
    };
  }

  handleSkillCheck = (e) => {
    const value = {
      name: e.target.name,
      skill_id: e.target.value,
      document: {}
    };
    const preValues = this.state.selectedSkills;
    if (e.target.checked) {
      this.setState({
        selectedSkills: [...preValues, value]
      })
    } else {
      let index = preValues.findIndex(x => x.skill_id === e.target.value);
      this.setState({
        selectedSkills: [
          ...preValues.slice(0, index),
          ...preValues.slice(index + 1)
        ]
      })
    }
  };

  handleOtherSkillCheck = (e) => {
    this.setState({
      isOtherAvailable: !this.state.isOtherAvailable,
      otherSkillDetails: {
        title: '',
        document: ''
      }
    });
  };

  handleOtherFileChange = (e) => {
    const document = e.target.files[0];
    const payload = convertJsonToFormData({ skill_document: document });
    POST('/auth/user/nannyFileUpload', payload)
      .then(res => {
        this.setState({
          otherSkillDetails: {
            title: this.state.otherSkillDetails.title,
            document: res.data.document,
            name: document.name
          }
        });
      })
      .catch(err => {
        toast.error(err.message);
      })

  };

  handleFileChange = (event) => {
    const id = event.target.name;
    const document = event.target.files[0];
    const preValues = this.state.selectedSkills;

    // THIS IS THE SKILL
    let skill = find(preValues, (x) => x.skill_id === id);

    // THIS IS THE INDEX OF THE SELECTED SKILL
    const index = findIndex(preValues, (x) => x.skill_id === id);

    // Uploading File Document
    if (document) {
      if (document.size < 10000000) {
        const payload = convertJsonToFormData({ skill_document: document });
        POST('/auth/user/nannyFileUpload', payload)
          .then(res => {
            // Adding doc file to skill
            skill.document = res.data.document;
            skill.documentName = document.name;
            this.setState({
              selectedSkills: Object.assign([], preValues, { [index]: skill })
            });
          })
          .catch(err => {
            toast.error(err.message);
          });

      } else {
        toast.error('This document is too large, please upload another. Maximum allowed size is 10MB');
      }
    }
  };
  handleResumeChange = (event) => {
    const document = event.target.files[0];

    // Uploading File Document
    if (document) {
      if (document.size < 10000000) {
        const payload = convertJsonToFormData({ skill_document: document });
        POST('/auth/user/nannyFileUpload', payload)
          .then(res => {
            // Adding doc file to skill
            const temp_data = {
              uploadedresumeData: res.data.document,
              resumeFileName: document.name
            }
            this.setState({
              resumeData: temp_data
            })

          })
          .catch(err => {
            toast.error(err.message);
          });
      } else {
        toast.error('This document is too large, please upload another. Maximum allowed size is 10MB');
      }
    }
  };

  removeDocument = (id) => {
    const preValues = this.state.selectedSkills;

    // THIS IS THE SKILL
    let skill = find(preValues, (x) => x.skill_id === id);

    // THIS IS THE INDEX OF THE SELECTED SKILL
    const index = findIndex(preValues, (x) => x.skill_id === id);

    // Adding doc file to skill
    skill.document = {};
    skill.documentName = '';
    this.setState({
      selectedSkills: Object.assign([], preValues, { [index]: skill })
    });
  };

  handleSkillSubmit = () => {
    const { resumeData } = this.state;

    if (!isEmpty(resumeData)) {
      const resume = {
        resumeFileName: resumeData.resumeFileName,
        document: resumeData.uploadedresumeData
      }

      const payload = {
        ...this.state.personalDetails,
        skills: this.state.selectedSkills,
        other_skills: [],
        resume
      };
      payload.other_skills = this.state.isOtherAvailable ? [{
        title: this.state.otherSkillDetails.title,
        document: this.state.otherSkillDetails.document
      }] : [];

      PATCH('/auth/user/nanny_register/step3', payload)
        .then(res => {
          localStorage.setItem('reg_auth', JSON.stringify({
            email: res.data.email,
            _id: res.data._id,
            first_name: res.data.first_name,
            last_name: res.data.last_name
          }));
          this.props.doHideLoading();
          toast.success('Skill set has been updated');
          this.props.history.push('/nanny/references');
          this.props.doSetSkills(payload);
        })
        .catch(err => {
          this.props.doHideLoading();
          toast.error(err.message);
        })
    }
    else {
      toast.error("Please upload resume!");

    }
  };

  render() {
    const { resumeData } = this.state;
    return (
      <div className="page-content page-auth">
        <div className="container">
          <section className="section bg-auth">
            <div className="row row-20">
              <div className="col-12 col-lg-9 col-xl-7 col-xll-6 mx-auto wow fadeInUp">
                <div className="text-center mb-5">
                  <h2 className="heading">
                    <Link to={`/nanny/type`} className="btn btn-back btn-back-inline">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 268.8 268.8">
                        <path
                          d="M3.7 143.3l80 80c2.4 2.4 5.6 3.7 8.8 3.7s6.4-1.2 8.8-3.7c4.9-4.9 4.9-12.8 0-17.7l-58.7-58.7h213.7c6.9 0 12.5-5.6 12.5-12.5s-5.6-12.5-12.5-12.5H42.7l58.7-58.7c4.9-4.9 4.9-12.8 0-17.7-4.9-4.9-12.8-4.9-17.7 0l-80 80c-4.9 5-4.9 12.9 0 17.8z"
                          fill="#313131" />
                      </svg>
                    </Link>
                      CREATE PROFILE
                    </h2>
                  <p className="text-note">
                    Select your skills (if any) and add your resume.
                    </p>
                </div>
                <label className="form-label">ELITE SKILLS</label>
                <p className="font-weight-light">Select your elite skills. You can select more than one.</p>
                <div className="row select-skills">
                  {
                    this.props.skills.map((x, index) => {
                      return <Checkbox key={index}
                        uniqueId={x._id}
                        id={x.title.toLowerCase()}
                        checked={this.state.selectedSkills.findIndex(skill => skill.skill_id === x._id) > -1}
                        image={x.icon.name}
                        onChange={this.handleSkillCheck}
                        name={x.title.toLowerCase()}
                        label={x.title} />
                    })
                  }
                  {
                    <div className="col-6 col-md-4 col-lg-4 col-xl-3 col-lg-4">
                      <input type="checkbox" name="other_skill" id="other_skill" value={this.state.isOtherAvailable}
                        checked={this.state.isOtherAvailable}
                        className="select-item-chk" onChange={this.handleOtherSkillCheck} />
                      <label className="select-skills-item" htmlFor="other_skill">
                        <img src={other} className="img-fluid select-skills-icon" alt="other_skill" />
                        <span className="select-skills-name">Other</span>
                      </label>
                    </div>
                  }
                </div>
                {
                  this.state.selectedSkills.map((document, index) => {
                    return <div key={index} className="form-group form-group-btn">
                      <label className="form-label">{document.name}</label>
                      <div className="input-group append-left">
                        <div className="input-group-append">
                          <button className="btn btn-clear btn-lg text-primary px-4" type="button">
                            <img width="15" height="19" src={attachment} alt="attachment" />
                          </button>
                        </div>
                        <input type="file" className="form-control d-none" id={document.skill_id}
                          name={document.skill_id} onChange={this.handleFileChange} />
                        <label htmlFor={document.skill_id} className="form-control"><span>
                          {document.document.name ? document.documentName : 'Upload your credential document'}</span></label>
                        {document.document.name && <button className="btn btn-clear min-w-auto py-0 px-2"
                          onClick={() => this.removeDocument(document.skill_id)}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="12px" height="12px" viewBox="0 0 64 64">
                            <path fill="#D80027"
                              d="M28.941 31.786L.613 60.114c-.787.787-.787 2.062 0 2.849.393.394.909.59 1.424.59.516 0 1.031-.196 1.424-.59l28.541-28.541 28.541 28.541c.394.394.909.59 1.424.59.515 0 1.031-.196 1.424-.59.787-.787.787-2.062 0-2.849L35.064 31.786 63.41 3.438c.787-.787.787-2.062 0-2.849-.787-.786-2.062-.786-2.848 0L32.003 29.15 3.441.59C2.654-.196 1.38-.196.593.59c-.787.787-.787 2.062 0 2.849l28.348 28.347z" />
                          </svg>
                        </button>}
                        <small className="mt-2 d-block">*In cases where a credential certificate does not exist, please upload a cover letter relevant
                        to that skill/profession.
                          </small>
                      </div>
                    </div>
                  })
                }
                {
                  this.state.isOtherAvailable &&
                  <div className="row">
                    <div className="col-12 col-md-5 form-group">
                      <label className="form-label">Other Skill's Name</label>
                      <input className="form-control" value={this.state.otherSkillDetails.title}
                        onChange={(e) => this.setState({ otherSkillDetails: { title: e.target.value } })} />
                    </div>
                    <div className="col-12 col-md-7 form-group form-group-btn">
                      <label className="form-label">Other Skill's Document</label>
                      <div className="input-group append-left">
                        <div className="input-group-append">
                          <button className="btn btn-clear btn-lg text-primary px-4" type="button">
                            <img width="15" height="19" src={attachment} alt="attachment" />
                          </button>
                        </div>
                        <input type="file" className="form-control d-none" name="other" id="otherSkill"
                          onChange={this.handleOtherFileChange} />
                        <label htmlFor="otherSkill" className="form-control"><span>
                          {this.state.otherSkillDetails.document ? this.state.otherSkillDetails.name : 'Upload your credential document'}</span></label>
                        <button className="btn btn-clear min-w-auto py-0 px-2" onClick={() => this.setState({ otherSkillDetails: { ...this.state.otherSkillDetails, document: '', name: '' } })}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="12px" height="12px" viewBox="0 0 64 64">
                            <path fill="#D80027"
                              d="M28.941 31.786L.613 60.114c-.787.787-.787 2.062 0 2.849.393.394.909.59 1.424.59.516 0 1.031-.196 1.424-.59l28.541-28.541 28.541 28.541c.394.394.909.59 1.424.59.515 0 1.031-.196 1.424-.59.787-.787.787-2.062 0-2.849L35.064 31.786 63.41 3.438c.787-.787.787-2.062 0-2.849-.787-.786-2.062-.786-2.848 0L32.003 29.15 3.441.59C2.654-.196 1.38-.196.593.59c-.787.787-.787 2.062 0 2.849l28.348 28.347z" />
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                }

                <label className="form-label">RESUME</label>
                <div className="form-group form-group-btn">
                  <div className="input-group append-left">
                    <div className="input-group-append">
                      <button className="btn btn-clear btn-lg text-primary px-4" type="button">
                        <img width="15" height="19" src={attachment} alt="attachment" />
                      </button>
                    </div>
                    <input type="file" className="form-control d-none" id={"resume"}
                      name={"resume"} onChange={this.handleResumeChange} />
                    <label htmlFor={"resume"} className="form-control"><span>
                      {!isEmpty(resumeData) ? resumeData.resumeFileName : 'Upload your resume'}</span></label>
                  </div>
                </div>

                <div className="form-group text-center mt-5 mb-0">
                  <button className="btn btn-outline-primary"
                    onClick={this.handleSkillSubmit}>NEXT
                    </button>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    )
  }
}

const MapStateToProps = (state) => {
  return {
    skills: state.skills,
    nanny: state.nanny
  }
};

const MapDispatchToProps = (dispatch) => {
  return {
    doSetSkills: (data) => {
      dispatch({
        type: APPEND_NANNY_REGISTRATION,
        payload: data
      })
    },
    doShowLoading: (title) => {
      dispatch({
        type: SHOW_LOADING,
        payload: {
          title,
          isLoading: true,
        }
      })
    },
    doHideLoading: () => {
      dispatch({
        type: HIDE_LOADING,
        payload: {
          title: '',
          isLoading: false,
        }
      })
    }
  }
};

export default connect(MapStateToProps, MapDispatchToProps)(NannyRegSkillsComponent);
