import React, {Component} from 'react';
import {GET} from "../../services/rest.service";
import toast from '../../Toast';
import history from "../../helpers/history";
import {connect} from "react-redux";

class PlacementFeesComponent extends Component {

  handleClick = (e, titleProps) => {
    const {index} = titleProps;
    const {activeIndex} = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({activeIndex: newIndex})
  };

  handleGetStarted = () => {
    const {isLoggedIn} = this.props.user;
    if (isLoggedIn) {
      history.push('/family/request-sitter');
    } else {
      history.push('/family/registration');
    }
  };

  constructor(props) {
    super(props);
    this.state = {
      activeIndex: 0, plans: []
    };
  }

  componentDidMount() {
    GET('/user/stripe/payments/plans')
      .then(res => this.setState({plans: res.plans}))
      .catch(err => toast.error(err.message))
  }

  render() {

    const {activeIndex, plans} = this.state;

    return (
      <div className="page-content">
        <section className="page-banner mb-5 section bg-lightdark">
          <div className="container">
            <div className="banner-caption">
              <div className="row">
                <div className="col-12 col-lg-10 col-xl-10 mx-auto wow fadeInUp">
                  <div className="banner-text">
                    <h2 className="heading page-heading">SITTER PRICING PACKAGES</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container  mt-4">
            <div className="row align-items-center">
              <div className="col-12 col-lg-10 col-xl-10 col-xll-9 mx-auto wow fadeInUp">
                <div className="banner-text text-center px-xll-3 px-0">
                  <p className="sub-heading font-normal mb-5">Elite Nannies also provides a professional Elite Sitter
                    Service
                    providing in-home care, whilst traveling, at hotels, events or whilst on vacation. All of our
                    sitters have
                    at least 3 years child care experience and are 21 years+. They comply with the same strict hiring
                    process,
                    having undergone a thorough background check, interviewing process and are registered with
                    Trustline.</p>
                  <p className="font-14 px-xll-5 px-0 mx-xll-5 mx-0">To use our Elite Sitter service, you will need to
                    obtain
                    a membership, granting you full access to our extensive database of Elite Sitters. Our online
                    platform
                    will allow you to communicate with our hand selected, pre-screened network of elite sitters where
                    you can
                    coordinate your own schedules and needs.</p>
                </div>
              </div>
            </div>
            <div
              className="row row-15 membership-card select-membership pricingpackages s-p-packges justify-content-center">
              {
                plans.map((item, index) => <MemberShipCard item={item} key={index}
                                                           onGetStarted={this.handleGetStarted}/>)
              }
            </div>
          </div>
        </section>
        {/* <SectionThoroughBackground plans={plans}/> */}
      </div>
    )
  }
}


// const SectionThoroughBackground = ({ plans }) => (
//   <section className="section bg-light-5 my-5">
//     <div className="container">
//       <div className="row align-items-center">
//         <div className="col-12 col-lg-10 col-xl-8 col-xll-9 mx-auto wow fadeInUp">
//           <div className="banner-text text-center px-xll-3 px-0">
//             <p className="sub-heading font-normal mb-5">Elite Nannies also provides a professional Elite Sitter Service providing in-home care, whilst traveling, at hotels, events or whilst on vacation. All of our sitters have at least 3 years child care experience and are 21 years+. They comply with the same strict hiring process, having undergone a thorough background check, interviewing process and are registered with Trustline.</p>
//             <p className="font-14 px-xll-5 px-0 mx-xll-5 mx-0">To use our Elite Sitter service, you will need to obtain a membership, granting you full access to our extensive database of Elite Sitters. Our online platform will allow you to communicate with our hand selected, pre-screened network of elite sitters where you can coordinate your own schedules and needs.</p>
//           </div>
//         </div>
//       </div>
//       <div className="row row-15 membership-card select-membership pricingpackages s-p-packges justify-content-center">
//         {
//           plans.map((item, index) => {
//             return <MemberShipCard
//               item={item}
//               key={index} />
//           })
//         }
//       </div>
//     </div>
//   </section>
// );

const MemberShipCard = ({item, onGetStarted}) => (
  <div className="col-12 col-md-6 col-lg-6 col-xl-3 mb-4 wow fadeInUp">
    <div className="select-membership-item">
      <div className="membership-item">
        <h4 className="membership-heading">{item.title}</h4>
        <h5 className="membership-price">
          ${item.price_usd}
          <span>{`${item.duration === 1 ? 'per' : 'for'}`} {item.duration > 1 && item.duration} {item.duration === 1 ? 'month' : 'months'}</span>
        </h5>
        <div className="membership-divider"></div>
        <p className="membership-content text-center text-md-left" dangerouslySetInnerHTML={{__html: item.description}}></p>
        <div className="packages-footer">
          <button className="btn btn-outline-primary text-uppercase btn-lg btn-block"
                  onClick={onGetStarted}>Get Started
          </button>
        </div>
      </div>
    </div>
  </div>
);

const MapStateToProps = (state) => {
  return {
    user: state.user
  }
};

export default connect(MapStateToProps, null)(PlacementFeesComponent);
