import {combineReducers} from 'redux';

import LoaderReducer from './loader/LoaderReducer';
import UserReducer from './user/UserReducer';
import FamilyRegistrationReducer from './family-registeration/FamilyRegistrationReducer';
import SkillsReducer from './skills/SkillsReducer';
import NannyRegistrationReducer from "./nanny-registration/NannyRegistrationReducer";

const rootReducer = combineReducers({
  loading: LoaderReducer,
  user: UserReducer,
  family: FamilyRegistrationReducer,
  nanny: NannyRegistrationReducer,
  skills: SkillsReducer
});

export default rootReducer;