import React, {Component} from 'react'
import Select from 'react-select';
import {connect} from "react-redux";
import {debounce, isArray, isEmpty, isNull, isString, isUndefined} from 'lodash';
import toast from '../../Toast';
import queryString from 'query-string';
import history from '../../helpers/history';
//Components
import Thumbnail from "../../components/ThumbnailComponent";
import FormikPlaceSearch from "../../components/UI/FormikPlaceSearch";
// Services
import {POST, GET} from "../../services/rest.service";
// Images
import location from '../../assets/images/svg/location.svg';
import placeholder from '../../assets/images/search-placeholder.svg';
import {Link} from "react-router-dom";
import PageLoader from "../../components/PageLoader";
import {DURATION_OPTIONS} from "../../helpers/constants";

const ages = [
  {value: "18-25", label: "18-25"},
  {value: "26-30", label: "26-30"},
  {value: "30-100", label: "30+"}
];
const experience = [
  {value: 3, label: '3+'},
  {value: 5, label: '5+'},
  {value: 10, label: '10+'}
];

const customStyles = {
  control: () => ({
    // none of react-select's styles are passed to <Control />
    padding: '0.608rem 0.9286rem',
    border: '2px solid #fff',
    boxShadow: '16px 19px 41.85px 3.15px rgba(0, 0, 0, 0.04)',
    fontSize: '1rem',
    lineHeight: '1.5',
    color: '#555657',
    backgroundColor: '#fff',
    backgroundClip: 'padding-box',
    borderRadius: '4px',
    width: '100%',
    display: 'flex',
  }),
  placeholder: () => ({
    color: "#000000",
    fontSize: "16px",
    opacity: "0.3",
  }),
  multiValueLabel: () => ({
    borderRadius: '2px',
    border: '1px solid rgba(230, 174, 173, 0.5)',
    borderRight: 'none',
    padding: '7px 5px 7px 9px',
    backgroundColor: '#fdf9f9',
  }),
  multiValueRemove: () => ({
    color: "#e6aead",
    backgroundColor: '#fdf9f9',
    paddingTop: "7px",
    border: '1px solid rgba(230, 174, 173, 0.5)',
    borderLeft: 'none',
    paddingRight: '7px',
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    paddingRight: '0',
  }),
  clearIndicator: (provided, state) => ({
    ...provided,
    paddingLeft: '0',
  }),
};

const NannyCard = ({nanny, type, onViewHandle}) => (
    <div className="nannies-list-item c-pointer" onClick={() => onViewHandle(nanny)}>
      <div className="media flex-wrap">
        <Thumbnail className="img-fluid item-nannies-avatar mb-3 mb-lg-0" source={nanny.profile_picture.name}/>
        <div className="media-body">
          <div className="d-flex justify-content-between align-items-center mb-4 flex-wrap">
            <h5 className="item-nannies-name">{nanny.first_name} {nanny.last_name}</h5>
            <div className="nannies-details">
              <div className="d-flex">
                <ul className="list-inline d-flex mb-0">
                  <li className="d-flex flex-wrap align-items-center">
                    {nanny.location.address.split(',')[0]}
                    <img height="18" className="ml-1" src={location}/>
                  </li>
                </ul>

              </div>
            </div>
          </div>
          <div>
            <ul className="list-inline d-flex flex-wrap">
              {
                nanny.skills.map((x, idx) => {
                  return (
                      <li key={idx} className="d-flex align-items-center mr-3 nannies-skill-list">
                        <Thumbnail height="18px" width="18px" className="mr-1" source={x.skillDetails[0].icon.name}
                                   alt={x.skillDetails[0].title}/>
                        {x.skillDetails[0].title}
                      </li>
                  )
                })
              }
            </ul>
          </div>
          {nanny && nanny.about_nanny &&
          <p className={"mb-0 text-dark"}>{nanny.about_nanny}</p>}
          <div className="nannies-contact-info pt-3">
            <div className="row">
              <div className="col-6">
                <div className="contact-info-item b-right">
                  <span>Years of Experience</span>
                  <strong>{nanny.nanny_experience ? nanny.nanny_experience : '0'} Year(s)</strong>
                </div>
              </div>
              <div className="col-6">
                <div className="contact-info-item">
                  <span>Availability</span>
                  <strong>Part-time</strong>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
);

class SearchComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      hideMoreFilters: true,
      records: null,
      page: 1,
      totalPages: 0,
      total: 0,
      searchType: "",
      filters: {
        location: '',
        skills: [],
        age: 0,
        experience: '1',
        lat: "",
        long: "",
      }
    };
  }

  toggleHideFilters = () => this.setState({hideMoreFilters: !this.state.hideMoreFilters});

  handleNannyView = (record) => {
    const parsed = queryString.parse(this.props.location.search);
    parsed.sitter_id = record.user_id;

    // if (this.state.searchType == 'nannies') {
    history.push(`/nanny/view/${record.user_id}`);
    // } else {
    //   history.push(`/family/request-sitter?${queryString.stringify(parsed)}`);
    // }
  };

  loadMore = () => {
    const parsed = queryString.parse(this.props.location.search);
    this.setState({
      page: this.state.page + 1
    }, () => {
      this.getPage(this.state.page, parsed)
    })
  };

  getPage = (page, filters) => {
    const sType = this.props.match.url.split('/').pop();
    filters.type = sType == 'nannies' ? 'Nanny' : 'Sitter';
    if (!isArray(filters.skills) && filters.skills && isString(filters.skills)) {
      let arr = [];
      arr.push(filters.skills);
      filters.skills = arr;
    }
    const records = this.state.records;

    POST(`/users/search/nannies/${page}`, filters)
        .then((res) => {
          this.setState({
            records: isNull(records) ? res.data : records.concat(res.data),
            page: page,
            total: res.total,
            totalPages: res.page,
          });
        })
        .catch((err) => {
          toast.error(err.message);
        })
  };

  handlePlaceChange = debounce((e) => {
    const location = JSON.parse(localStorage.getItem('location'));
    const city = e.split(',')[0];
    const long = location.longitude;
    const lat = location.latitude;
    const parsed = queryString.parse(this.props.location.search);
    parsed.location = city;
    parsed.long = long;
    parsed.lat = lat;

    history.push({
      pathname: `/search/${this.state.searchType}`,
      search: queryString.stringify(parsed)
    });
    this.setState({
      page: 1,
      records: [],
      filters: {
        ...this.state.filters,
        location: city,
        long,
        lat,
      }
    }, () => {
      this.getPage(this.state.page, parsed);
    });
  }, 1000);

  handleExpChange = (e) => {
    const parsed = queryString.parse(this.props.location.search);
    parsed.experience = e.value;

    history.push({
      pathname: `/search/${this.state.searchType}`,
      search: queryString.stringify(parsed)
    });
    this.setState({
      filters: {
        ...this.state.filters,
        experience: e,
      }
    }, () => {
      this.getPage(this.state.page, parsed);
    });
  };

  handleAgeChange = (e) => {
    const parsed = queryString.parse(this.props.location.search);
    parsed.age = e.value;

    history.push({
      pathname: `/search/${this.state.searchType}`,
      search: queryString.stringify(parsed)
    });
    this.setState({
      filters: {
        ...this.state.filters,
        age: e,
      }
    }, () => {
      this.getPage(this.state.page, parsed);
    });
  };

  handleSkillChange = (e) => {
    const simplifiedSkills = e.map(x => x.value);
    const parsed = queryString.parse(this.props.location.search);
    parsed.skills = simplifiedSkills;

    history.push({
      pathname: `/search/${this.state.searchType}`,
      search: queryString.stringify(parsed)
    });

    this.setState({
      page: 1,
      records: [],
      filters: {
        ...this.state.filters,
        skills: e,
      }
    }, () => {
      this.getPage(this.state.page, parsed);
    });
  };

  handleClear = () => {
    history.push({
      pathname: `/search/${this.state.searchType}`,
    });
    window.location.reload();
  };

  setInitialSkillsValue = (skills) => {
    if (skills) {
      if (isArray(skills)) {
        skills = skills.map((x) => {
          return {value: x, label: x}
        })
      } else if (!isUndefined(skills)) {
        let arr = [];
        arr.push({value: skills, label: skills});
        skills = arr;
      }
    } else {
      skills = this.state.filters.skills;
    }
    return skills;
  };

  setInitialAgeValue = (age) => {
    if (age) {
      age = {value: age, label: age}
    } else {
      age = this.state.filters.age
    }

    return age;
  };

  setInitialExpValue = (exp) => {
    if (exp) {
      exp = {value: exp, label: exp}
    } else {
      exp = this.state.filters.experience
    }

    return exp;
  };

  componentDidMount() {
    

    GET('/admin/appConfiguration')
    .then(({data})=>{


     


      const parsed = queryString.parse(this.props.location.search);
    let sType = this.props.match.url.split('/').pop();
    sType = sType === 'nannies' ? 'nannies' : 'sitters';

    if(sType==="nannies" && data.configuration.topNannies)
    
{
    const location = JSON.parse(localStorage.getItem('location'));
    if (!isEmpty(parsed)) {
      this.setState({
        filters: {
          location: parsed.location ? parsed.location : this.state.filters.location,
          lat: parsed.lat? parsed.lat: this.state.filters.lat,
          long: parsed.long ? parsed.long : this.state.filters.long,
          skills: this.setInitialSkillsValue(parsed.skills),
          age: this.setInitialAgeValue(parsed.age),
          experience: this.setInitialExpValue(parsed.experience),
        },
        searchLocation: location,
        searchType: sType,
      }, () => {
        this.getPage(1, parsed);
      })
    } else {
      this.setState({
        searchType: sType
      }, () => {
        this.getPage(1, parsed);
      })
    }
  }
  else if(sType==="sitters"&& data.configuration.sitter )
  {
    const location = JSON.parse(localStorage.getItem('location'));
    if (!isEmpty(parsed)) {
      this.setState({
        filters: {
          location: parsed.location ? parsed.location : this.state.filters.location,
          lat: parsed.lat? parsed.lat: this.state.filters.lat,
          long: parsed.long ? parsed.long : this.state.filters.long,
          skills: this.setInitialSkillsValue(parsed.skills),
          age: this.setInitialAgeValue(parsed.age),
          experience: this.setInitialExpValue(parsed.experience),
        },
        searchLocation: location,
        searchType: sType,
      }, () => {
        this.getPage(1, parsed);
      })
    } else {
      this.setState({
        searchType: sType
      }, () => {
        this.getPage(1, parsed);
      })
    }
  }
  else{
    history.push('/')
  }


    })

    

  }

  componentWillUnmount() {
    localStorage.removeItem('location');
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.location.pathname != this.props.location.pathname) {
      let sType = this.props.match.url.split('/').pop();
      sType = sType === 'nannies' ? 'nannies' : 'sitters';

      const parsed = queryString.parse(this.props.location.search);
      this.getPage(1, parsed);
      this.setState({
        filters: {
          location: '',
          skills: [],
          age: 0,
          experience: '1',
        },
        searchType: sType,
      })
    }
  }

  render() {
    if (isNull(this.state.records)) {
      return <PageLoader/>
    }

    return (
        <div className="page-content page-nannies">
          <div className="container px-xll-0">
            <section className="section pt-0">
              <div className="row">
                <div className="col-12 col-lg-5 col-xl-4">
                  <div className="sidebar bg-lightdark wow fadeInUp">
                    <form>
                      <div className="d-flex justify-content-between">
                        <h3 className="mb-0 heading">Search by</h3>
                        <button type="reset" className="btn btn-clear text-primary p-0 w-auto" onClick={this.handleClear}>
                          CLEAR
                        </button>
                      </div>

                      <div className="row">
                        <div className="col-12">
                          <FormikPlaceSearch id="location"
                                             name="location"
                                             type="search"
                                             label="Location"
                                             className="cus-control"
                                             placeholder="Select your city"
                                             error={''}
                                             value={this.state.filters.location}
                                             onChange={(e) => this.handlePlaceChange(e)}
                          />
                          {/*<Select placeholder={'Please select an option'}*/}
                          {/*        selection*/}
                          {/*        options={DURATION_OPTIONS}*/}
                          {/*        value={values.how_soon_to_start}*/}
                          {/*        onChange={(e, {value}) => setFieldValue('how_soon_to_start', value)}/>*/}
                        </div>
                      </div>
                      <div className="form-group">
                        <label className="form-label">Skill</label>
                        <Select
                            styles={customStyles}
                            isMulti={true}
                            closeMenuOnSelect={false}
                            onChange={this.handleSkillChange}
                            value={this.state.filters.skills}
                            options={this.props.skills.map((x, index) => {
                              return {'label': x.title, 'value': x.title}
                            })}
                            theme={(theme) => ({
                              ...theme,
                              borderRadius: 0,
                              colors: {
                                ...theme.colors,
                                primary25: '#e6aead',
                                primary: '#e6aead',
                              },
                            })}
                        />
                      </div>
                      {/*
                      <div className="row">
                        <div className="col-12 col-lg-6">
                          <div className="form-group">
                            <label className="form-label" htmlFor="experience">years of experience</label>
                            <Select styles={customStyles}
                                    value={this.state.filters.experience}
                                    onChange={this.handleExpChange}
                                    options={experience}
                                    theme={(theme) => ({
                                      ...theme,
                                      borderRadius: 0,
                                      colors: {
                                        ...theme.colors,
                                        primary25: '#e6aead',
                                        primary: '#e6aead',
                                      },
                                    })}
                            />

                          </div>
                        </div>
                        <div className="col-12 col-lg-6">
                          <div className="form-group">
                            <label className="form-label">Age</label>
                            <Select styles={customStyles}
                                    options={ages} onChange={this.handleAgeChange}
                                    value={this.state.filters.age}
                                    theme={(theme) => ({
                                      ...theme,
                                      borderRadius: 0,
                                      colors: {
                                        ...theme.colors,
                                        primary25: '#e6aead',
                                        primary: '#e6aead',
                                      },
                                    })}
                            />
                          </div>
                        </div>
                      </div>
                      */}
                    </form>
                  </div>
                </div>
                <div className="col-12 col-lg-7 col-xl-8">
                  <div className="nannies-contact pl-0 pl-xl-5 mb-4 mb-lg-0 mt-5 mt-lg-0">
                    {
                      (!this.state.filters.location && this.state.filters.age < 18 && this.state.filters.skills.length <= 0)
                      &&
                      <div className="bg-lightdark text-center welcome-content wow fadeInUp">
                        <div className="row">
                          <div className="col-12 col-lg-12 col-xl-9 col-xll-7 mx-auto">
                            <h3 className="heading">Meet Our Nannies</h3>
                            <p>Browse some of our top Elite Nannies from the list below, and find your perfect fit.</p>
                          </div>
                        </div>
                      </div>
                    }
                    {this.state.filters.location && <h3 className="heading mt-5 mb-2 text-center">
                      NANNIES FOUND IN <span className="text-primary text-lowercase"><span
                        className="text-capitalize"> “{this.state.filters.location}”</span></span>
                    </h3>}

                    <div className="nannies-list wow fadeInUp">
                      {
                        !isNull(this.state.records) && this.state.records.length > 0
                            ? this.state.records.map((x, index) => <NannyCard key={index}
                                                                              type={this.state.searchType}
                                                                              onViewHandle={this.handleNannyView}
                                                                              nanny={x}/>)
                            : (
                                <div className="row">
                                  <div className="col-12 col-lg-9 col-xl-8 mx-auto">
                                    <div className="my-5 text-center">
                                      <img className="img-fluid img-middle" src={placeholder} alt="no-results-found"/>
                                      <h4 className="heading mt-4">WE CURRENTLY ONLY HAVE NANNIES IN THE LOS ANGELES REGION.</h4>
                                      <p>However, please check back at a later time or <br/> <Link to={'/contact-us'}>contact us</Link> to discuss options.</p>
                                    </div>
                                  </div>
                                </div>
                            )
                      }
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <div className="my-5 text-center">
                          {
                            this.state.totalPages > this.state.page && this.state.records.length > 0
                            && <button className="btn btn-outline-primary text-uppercase" onClick={this.loadMore}>
                              Load More
                            </button>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
    )
  }
}

const MapStateToProps = (state) => {
  return {
    skills: state.skills
  }
};

export default connect(MapStateToProps, null)(SearchComponent);
