import React, {Component, Fragment} from 'react';
import {Link} from 'react-router-dom';
import toast from '../Toast';
import moment from 'moment';
import Svg404 from '../assets/images/svg/404.svg';
// Services
import {GET} from "../services/rest.service";

class NotFound extends Component {
  constructor(props) {
    super(props);
    this.state = {
      blogs: []
    };
  }

  componentDidMount() {
    GET('/general/blog/get')
      .then(blogRes => {
        this.setState({
          blogs: blogRes.data,
        })
      })
      .catch(err => {
        toast.error(err.message);
      });
  }

  render() {
    const {blogs} = this.state;
    return (
      <Fragment>
        <div className="page-content page-error">
          <SectionErrorBanner/>
          <NannyBlog blogs={blogs}/>
        </div>
      </Fragment>
    )
  }
}

const NannyBlog = ({blogs}) => (
  <section className="section section-blog">
    <div className="container px-xll-0">
      <div className="row justify-content-center">
        {
          blogs.map((x, index) => {
            return (
              <div key={index} className="col-md-6 col-lg-4 col-xl-4 wow fadeInUp b-i-mb-5" data-wow-delay=".5s">
                <div className="blogitem">
                  <div className="card">
                    {x._embedded['wp:featuredmedia'] &&
                    <img className="card-img-top" src={x._embedded['wp:featuredmedia']['0'].source_url}
                         alt="Card image cap"/>}
                    {!x._embedded['wp:featuredmedia'] &&
                    <img className="card-img-top" src="https://eduaid.net/web/visatype/default.png"
                         alt="Card image cap"/>}
                    <div className="card-body">
                      <p className="card-text">{moment(x.date).format('MM/DD/YYYY')}</p>
                      {/*<p class="card-text" dangerouslySetInnerHTML={{ __html: x._embedded }} />*/}
                      <a className="blog-link mb-3 d-block" rel="noopener" rel="noreferrer" href={x.link}
                         target={'_blank'}>
                        <h5 className="card-title" dangerouslySetInnerHTML={{__html: x.title.rendered}}/>
                      </a>
                      <p className="card-text" dangerouslySetInnerHTML={{__html: x.excerpt.rendered}}/>
                      <a className="blog-link" rel="noopener" rel="noreferrer" href={x.link} target={'_blank'}>Read
                        more</a>
                    </div>
                  </div>
                </div>
              </div>
            )
          })
        }
      </div>
    </div>
  </section>
);

const SectionErrorBanner = () => (
  <section className="section section-error-banner">
    <div className="container px-0">
      <div className="incredibly-content" data-wow-delay=".8s">
        <div className="row">
          <div className="col-12 col-lg-6 col-xl-8 wow fadeIn mx-auto">
            <h1 className="title-404">
              <lottie-player src="https://assets7.lottiefiles.com/packages/lf20_ek5zZg.json" class="lottie-player-404"  background="transparent"  speed="1"  style={{width: '70%'}} loop autoplay>
              </lottie-player>
            </h1>
            <p className="heading">Oopsy daisies!</p>
          </div>
        </div>
      </div>
      <div className="col-12 col-lg-6 col-xl-5 mt-3 wow fadeIn mx-auto">
        <div className="text-center">
          <p className="sub-heading">Sorry... but that page seems to be missing.</p>
          <p className="px-xl-1 px-xll-4 mb-0">We’ve been informed about it and will fix it straight away. In the
            meantime why not read our latest blog posts, </p>
          <Link to="/" className="text-primary">or crawl on back to homepage.</Link>
        </div>
      </div>
    </div>
  </section>
);

export default NotFound;
