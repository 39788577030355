import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import toast from '../../Toast';
import {debounce, isEmpty, isObject, isUndefined} from 'lodash';
/* SERVICES */
import {PERSON_NAME} from "../../helpers/constants";
import {USER} from "../../redux/user/UserActions";
import {HIDE_LOADING, SHOW_LOADING} from "../../redux/loader/LoaderActions";
import {GET, PATCH, PUT} from "../../services/rest.service";
/* COMPONENTS */
import ReactTelInput from 'react-telephone-input';
import FormikPlaceSearch from "../../components/UI/FormikPlaceSearch";
import ChangePasswordForm from "../../components/ChangePasswordForm";
import TextInput from "../../components/UI/TextInput";
import PageLoader from "../../components/PageLoader";
/* IMAGES */
import autofill from '../../assets/images/svg/arrow-left-fill.svg';
import location from '../../assets/images/svg/location.svg';
import flag from '../../assets/images/flags.png';

class FamilyEditComponent extends Component {

  handleLocationChange = debounce((e) => {
    const place = JSON.parse(localStorage.getItem('location'));
    this.setState({
      [e.name]: place
    }, () => {
      setTimeout(() => {
        localStorage.removeItem('location');
      }, 500);
    });
  }, 100);

  constructor(props) {
    super(props);
    this.state = {
      first_name: '',
      last_name: '',
      email: '',
      mobile_number: '',
      location: '',
      errors: {}
    };
  }

  updateProfile = () => {
    const {user} = this.props.user;
    const {errors, ...payload} = this.state;
    this.props.showLoading('Updating Profile...');
    PUT(`/users/${user._id}/family/edit`, payload)
        .then(() => {
          return GET('/users/me');
        })
        .then(res => {
          toast.success('Profile Updated');
          this.props.hideLoading();
          this.props.doUpdateUser(res);
        })
        .catch(err => {
          this.props.hideLoading();
          toast.error(err.message);
        })
  };

  handleChangePassword = (passwords) => {
    this.props.showLoading('Please wait...');
    PATCH(`user/changePassword`, passwords)
        .then((res) => {
          localStorage.setItem('token', res.access_token);
          this.props.hideLoading();
          this.props.history.push('/family/account');
          toast.success('Password changed successfully!')
        })
        .catch(err => {
          this.props.hideLoading();
          toast.error(err.message)
        })
  };

  handleValidation = () => {
    let {first_name, last_name, email, mobile_number, location, skills, place_of_work, how_soon_start} = this.state;

    let errors = {};
    let formIsValid = true;

    // First Name
    if (!first_name) {
      formIsValid = false;
      errors['first_name'] = "First name can't be empty";
    }

    // Last Name
    if (!last_name) {
      formIsValid = false;
      errors['last_name'] = "Last name can't be empty";
    }

    if (first_name && !first_name.match(PERSON_NAME)) {
      formIsValid = false;
      errors["first_name"] = "Only valid letters";
    }

    if (last_name && !last_name.match(PERSON_NAME)) {
      formIsValid = false;
      errors["last_name"] = "Only valid letters";
    }

    //Email
    if (!email) {
      formIsValid = false;
      errors["email"] = "Email can't be empty";
    }

    if (email) {
      let lastAtPos = email.lastIndexOf('@');
      let lastDotPos = email.lastIndexOf('.');

      if (!(lastAtPos < lastDotPos && lastAtPos > 0 && email.indexOf('@@') == -1 && lastDotPos > 2 && (email.length - lastDotPos) > 2)) {
        formIsValid = false;
        errors["email"] = "Email is not valid";
      }
    }

    // mobile_number
    if (!mobile_number) {
      formIsValid = false;
      errors["mobile_number"] = "Mobile number can't be empty";
    }

    // location
    if (!location || isEmpty(location) || isUndefined(location)) {
      formIsValid = false;
      errors["location"] = "Location can't be empty";
    }


    if (formIsValid) {
      this.setState({
        errors: {}
      }, () => {
        this.updateProfile();
      });
    } else {
      this.setState({errors: errors});
    }
  };

  handleChange = (e) => {
    if (e.target) {
      this.setState({
        [e.target.name]: e.target.value
      });
    } else {
      this.setState({
        [e.name]: e.value
      });
    }
  };

  handleSkillCheck = (e) => {
    const value = e.target.value;
    const preValues = this.state.skills;
    if (e.target.checked) {
      this.setState({
        skills: [...preValues, value]
      })
    } else {
      let index = preValues.findIndex(x => x === value);
      this.setState({
        skills: [
          ...preValues.slice(0, index),
          ...preValues.slice(index + 1)
        ]
      })
    }
  };

  handleDurationChange = (e, {value}) => this.setState({how_soon_start: value});

  setStateFromProps(values) {
    this.setState({
      first_name: values.first_name,
      last_name: values.last_name,
      email: values.email,
      mobile_number: values.mobile_number,
      location: values.location

    })
  }

  componentDidMount() {
    const {user, isLoggedIn} = this.props.user;
    if (!isEmpty(user) && isLoggedIn === true) {
      this.setStateFromProps(user);
    }
  }

  componentWillReceiveProps(nextProps) {
    const {user, isLoggedIn} = nextProps.user;
    if (!isEmpty(user) && isLoggedIn === true) {
      this.setStateFromProps(user);
    }
  }

  render() {
    let {user, isLoggedIn} = this.props.user;

    if (isEmpty(user) && !isLoggedIn) {
      return <PageLoader/>
    }

    return (
        <div className="page-content page-auth page-family-detail">
          <div className="container">
            <div className="mb-4 mb-sm-5 bp-2 bp-2">
              <Link className="text-primary font-14" to={'/family/account'}>
                <img className="align-middle mr-2" width="24" height="24" src={autofill}/>
                Back to profile
              </Link>
            </div>
            <section className="section bg-lightdark">
              <div className="row row-20">
                <div className="col-12 col-lg-8 col-xl-7 col-xll-6 mx-auto">
                  <div className="wow fadeInUp">
                    <div className="text-center">
                      <h2 className="heading">personal details</h2>
                    </div>
                    <div className="row mt-5">
                      <TextInput id="first_name"
                                 name="first_name"
                                 type="text"
                                 size="col-12 col-md-6"
                                 label="First Name"
                                 placeholder="Please enter first name"
                                 error={this.state.errors.first_name}
                                 value={this.state.first_name}
                                 onChange={this.handleChange}/>

                      <TextInput id="last_name"
                                 name="last_name"
                                 type="text"
                                 size="col-12 col-md-6"
                                 label="Last Name"
                                 placeholder="Please enter last name"
                                 error={this.state.errors.last_name}
                                 value={this.state.last_name}
                                 onChange={this.handleChange}
                      />

                      <TextInput id="email"
                                 type="email"
                                 name="email"
                                 size="col-12 col-md-6"
                                 label="Email"
                                 placeholder="Please enter your email"
                                 error={this.state.errors.email}
                                 value={this.state.email}
                                 onChange={this.handleChange}
                      />

                      <div className="form-group col-12 col-md-6">
                        <label className="form-label">Mobile Number</label>
                        <ReactTelInput defaultCountry="us"
                                       name="mobile_number"
                                       value={this.state.mobile_number}
                                       flagsImagePath={flag}
                                       onChange={(e) => this.handleChange({name: 'mobile_number', value: e})}/>
                        {this.state.errors.mobile_number &&
                        <div className="error-message col-12 mb-4">{this.state.errors.mobile_number}</div>}
                      </div>
                      <div className="form-group col-12 col-md-6">
                        <FormikPlaceSearch id="location"
                                           name="location"
                                           type="search"
                                           label="Location"
                                           placeholder="Select your location"
                                           error={this.state.errors.location}
                                           value={isObject(this.state.location) ? this.state.location.address : this.state.location}
                                           onChange={(e) => this.handleLocationChange({name: 'location', value: e})}/>
                      </div>
                    </div>
                    <div className="form-group col-12 text-center mt-5">
                      <button className="btn btn-outline-primary" onClick={this.handleValidation}>SAVE</button>
                    </div>
                  </div>

                </div>
              </div>
            </section>
            <section className="section bg-light my-5">
              <div className="row row-20">
                <div className="col-12 col-xl-7 col-xll-6 mx-auto wow fadeInUp">
                  <div className="text-center">
                    <h2 className="heading">Change Password</h2>
                  </div>
                  <div className="mt-5">
                    <ChangePasswordForm onSubmit={this.handleChangePassword}/>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
    )
  }
}

const MapStateToProps = (state) => {
  return {
    user: state.user,
    skills: state.skills,
  }
};

const MapDispatchToProps = (dispatch) => {
  return {
    doUpdateUser: (res) => {
      dispatch({
        type: USER,
        payload: {
          user: res.data,
          type: res.data.type,
        }
      })
    },
    showLoading: (title) => {
      dispatch({
        type: SHOW_LOADING,
        payload: {
          title,
          isLoading: true
        }
      })
    },
    hideLoading: () => {
      dispatch({
        type: HIDE_LOADING,
      })
    }
  }
};

export default connect(MapStateToProps, MapDispatchToProps)(FamilyEditComponent);
