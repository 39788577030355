import {isObject, isString, isArray} from 'lodash';
import moment from 'moment';

export const convertJsonToFormData = (data) => {
  const formData = new FormData();
  for (let field in data) {
    const value = data[field];
    if (isString(value)) {
      formData.append(field, value);
    }
    if (isObject(value)) {
      formData.append(field, value, value.name);
    }
    if(isArray(value)) {
      formData.append(field, JSON.stringify(value));
    }
  }

  return formData;
};

export const stringToSlug = (str) => {
  return str
      .toLowerCase()
      .replace(/ /g,'-')
      .replace(/[^\w-]+/g,'');
};

export const unsimplify = (str) => {
  return str.replace(/\-/g, ' ');
};

export const currentYear = () =>{
 return moment().year()
}
