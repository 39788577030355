import React from 'react';
import {Formik} from 'formik';
import {object, ref, string} from 'yup';
import PasswordInput from "./UI/PasswordInput";

const ChangePasswordForm = (props) => (
      <Formik initialValues={{
              oldPassword: '',
              newPassword: '',
              confirmNewPassword: ''
            }}
            onSubmit={(values) => {
              props.onSubmit(values);
            }}
            validationSchema={object().shape({
              oldPassword: string("").required("Please enter your current password"),
              newPassword: string("")
                  .required("Enter your password")
                  .min(6, "Password must contain at least 6 characters"),
              confirmNewPassword: string().required("Please confirm your password").oneOf([ref("newPassword")], "Passwords does not match")
            })}>
      {renderProps => {
        const {values, touched, errors, handleChange, handleBlur, handleSubmit} = renderProps;
        return (
            <form className="row" onSubmit={handleSubmit}>
              {/*Old Password*/}
              <PasswordInput id="oldPassword"
                             size="col-12"
                             label="Old Password"
                             placeholder="Password"
                             error={touched.oldPassword && errors.oldPassword}
                             value={values.oldPassword}
                             onChange={handleChange}
                             onBlur={handleBlur}/>

              {/*New Password*/}
              <PasswordInput id="newPassword"
                             size="col-12 col-md-6"
                             label="New Password"
                             placeholder="Password"
                             error={touched.newPassword && errors.newPassword}
                             value={values.newPassword}
                             onChange={handleChange}
                             onBlur={handleBlur}/>

              {/*Confirm New Password*/}
              <PasswordInput id="confirmNewPassword"
                             size="ccol-12 col-md-6"
                             label="Repeat New Password"
                             placeholder="Password"
                             error={touched.confirmNewPassword && errors.confirmNewPassword}
                             value={values.confirmNewPassword}
                             onChange={handleChange}
                             onBlur={handleBlur}/>

              {/*Submit Button*/}
              <div className="form-group col-12 text-center mt-5">
                <button type="submit" className="btn btn-outline-primary">Change Password</button>
              </div>
            </form>
        )
      }}
    </Formik>
);

export default ChangePasswordForm;