import React, {Component} from 'react'
import {connect} from "react-redux";
import {APPEND_NANNY_REGISTRATION} from "../../redux/nanny-registration/NannyRegistrationReducer";
import {HIDE_LOADING, SHOW_LOADING} from "../../redux/loader/LoaderActions";
import {Link} from "react-router-dom";
import nanny from "../../assets/images/svg/nanny.svg";
import sitter from "../../assets/images/svg/sitter.svg";
import {isEmpty, isUndefined} from "lodash";
import {PATCH} from "../../services/rest.service";
import {toast} from "react-toastify";

class NannyRegTypeComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      type: this.initializeType(props.nanny.type)
    };
  }

  handleSkillSubmit = () => {
    let type = this.state.type.length === 2 ? 'Both' : this.state.type[0];
    const payload = {
      ...JSON.parse(localStorage.getItem('reg_auth')),
      type,
    };
    PATCH('/auth/user/nanny_register/type', payload)
        .then(res => {
          localStorage.setItem('reg_auth', JSON.stringify({
            email: res.data.email,
            _id: res.data._id,
            first_name: res.data.first_name,
            last_name: res.data.last_name
          }));
          this.props.doHideLoading();
          toast.success('Your profile type has been updated');
          this.props.history.push('/nanny/skills');
          this.props.doSetType(payload);
        })
        .catch(err => {
          this.props.doHideLoading();
          toast.error(err.message);
        })
  };

  initializeType = (type) => {
    if (type === 'Both') {
      return ['Nanny', 'Sitter'];
    } else if (isUndefined(type) || type.length === 0) {
      return [];
    } else {
      let arr = [];
      arr.push(type);
      return arr;
    }
  };

  handleTypeChange = (e) => {
    const value = e.target.checked;
    if (value) {
      const nextValue = this.state.type.concat(e.target.value);
      this.setState({type: nextValue});
    } else {
      const nextValue = this.state.type.filter(value => value !== e.target.value);
      this.setState({type: nextValue});
    }
  };

  render() {
    return (
        <div className="page-content page-auth">
          <div className="container">
            <section className="section bg-auth">
              <div className="row row-20">
                <div className="col-12 col-lg-9 col-xl-7 col-xll-6 mx-auto wow fadeInUp">
                  <div className="text-center mb-5">
                    <h2 className="heading">
                      <Link to={`/nanny/profile`} className="btn btn-back btn-back-inline">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 268.8 268.8">
                          <path
                              d="M3.7 143.3l80 80c2.4 2.4 5.6 3.7 8.8 3.7s6.4-1.2 8.8-3.7c4.9-4.9 4.9-12.8 0-17.7l-58.7-58.7h213.7c6.9 0 12.5-5.6 12.5-12.5s-5.6-12.5-12.5-12.5H42.7l58.7-58.7c4.9-4.9 4.9-12.8 0-17.7-4.9-4.9-12.8-4.9-17.7 0l-80 80c-4.9 5-4.9 12.9 0 17.8z"
                              fill="#313131"/>
                        </svg>
                      </Link>
                      CREATE PROFILE
                    </h2>
                    <p className="text-note">Choose your type</p>
                  </div>
                  <div className="row select-skills justify-content-center">
                    <div className="col-6 col-md-4 col-lg-4 col-xl-3 col-lg-4">
                      <input type="checkbox" name={'type'} id={'nanny'} value={'Nanny'} className="select-item-chk"
                             checked={this.state.type.includes('Nanny')}
                             onChange={this.handleTypeChange}/>
                      <label className="select-skills-item" htmlFor={'nanny'}>
                        <img src={nanny} className="img-fluid select-skills-icon" alt={'nanny'}/>
                        <span className="select-skills-name">Nanny</span>
                      </label>
                    </div>
                    <div className="col-6 col-md-4 col-lg-4 col-xl-3 col-lg-4">
                      <input type="checkbox" name={'type'} id={'sitter'} value={'Sitter'} className="select-item-chk"
                             checked={this.state.type.includes('Sitter')}
                             onChange={this.handleTypeChange}/>
                      <label className="select-skills-item" htmlFor={'sitter'}>
                        <img src={sitter} className="img-fluid select-skills-icon" alt={'sitter'}/>
                        <span className="select-skills-name">Sitter</span>
                      </label>
                    </div>
                  </div>

                  <div className="form-group text-center mt-5 mb-0">
                    <button className="btn btn-outline-primary"
                            disabled={isEmpty(this.state.type)}
                            onClick={this.handleSkillSubmit}>NEXT
                    </button>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
    )
  }
}

const MapStateToProps = (state) => {
  return {
    skills: state.skills,
    nanny: state.nanny
  }
};

const MapDispatchToProps = (dispatch) => {
  return {
    doSetType: (data) => {
      dispatch({
        type: APPEND_NANNY_REGISTRATION,
        payload: data
      })
    },
    doShowLoading: (title) => {
      dispatch({
        type: SHOW_LOADING,
        payload: {
          title,
          isLoading: true,
        }
      })
    },
    doHideLoading: () => {
      dispatch({
        type: HIDE_LOADING,
        payload: {
          title: '',
          isLoading: false,
        }
      })
    }
  }
};

export default connect(MapStateToProps, MapDispatchToProps)(NannyRegTypeComponent);
