export const APPEND_NANNY_REGISTRATION = 'APPEND_NANNY_REGISTRATION';
export const FRESH_NANNY_REGISTRATION = 'FRESH_NANNY_REGISTRATION';
export const APPEND_NANNY_REFERENCE = 'APPEND_NANNY_REFERENCE';

const initialState = {
  first_name: '',
  email: localStorage.getItem('reg_auth') ? JSON.parse(localStorage.getItem('reg_auth')).email : '',
  _id: localStorage.getItem('reg_auth') ? JSON.parse(localStorage.getItem('reg_auth'))._id : '',
  last_name: '',
  password: '',
  mobile_number: '',
  location: '',
  about_me: '',
  profile_picture: '',
  skills: [],
  other_skills: [],
  reference: [

  ],
  social_security_number: '',
  type: [],
  date_of_birth: '',
};

const NannyRegistrationReducer = (state = initialState, {type, payload}) => {
  switch (type) {
    case APPEND_NANNY_REGISTRATION:
      return {
        ...state,
        ...payload
      };

    case APPEND_NANNY_REFERENCE:
      return {
        ...state,
        reference: [
          ...payload.reference
        ]
      };

    case FRESH_NANNY_REGISTRATION:
      return initialState;


    default:
      return state;
  }
};

export default NannyRegistrationReducer;
