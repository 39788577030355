import React, { Component, Fragment } from 'react';
import { unsimplify } from "../../helpers/helper";
import { startCase } from 'lodash';
import { connect } from "react-redux";
import M from "materialize-css";
import { Helmet } from "react-helmet";
import history from '../../helpers/history';
// Components
import Testimonial from "./shared/Testimonial";
import LandingSlickCarousel from "./shared/LandingSlickCarousel";
// Images
import ImgCityBanner from "../../assets/images/newborncare-specialists.jpg";
import QualifiedNewborn from "../../assets/images/qualified-newborn.jpg";
import ImgWhychooseus from "../../assets/images/Whychooseus.jpg";
import ImgNewBornWeFacilitate from "../../assets/images/newborn-we-facilitate.jpg";
import ImgFooterBanner from "../../assets/images/new-york-thankyoufamily.jpg";
import logo from '../../assets/images/logo-white.svg';
import SvgSupportAssistant from "../../assets/images/svg/maternity.svg";
import SvgNightCare from "../../assets/images/svg/cot.svg";
import SvgFeeding from "../../assets/images/svg/baby-bottle.svg";
import SvgBathTub from "../../assets/images/svg/bathtub.svg";

class NewBornCareSpecialist extends Component {

  constructor(props) {
    super(props);
    this.state = {
      city: '',
    };
  }

  handleOnSearch = () => {
    const { isLoggedIn } = this.props.user;
    history.push(isLoggedIn ? '/family/preferences' : '/family/registration');
  };

  componentDidMount() {
    M.Parallax.init(this.Parallax1);
    M.Parallax.init(this.Parallax2);
    M.Parallax.init(this.Parallax3);
    let [root, cityName, skillName] = this.props.match.url.split('/');
    cityName = startCase(unsimplify(cityName));
    this.setState({
      city: cityName
    })
  }

  render() {
    const { city } = this.state;
    const { skills } = this.props;

    return (
      <Fragment>
        <Helmet>
          <title>Newborn Care Specialists</title>
          <meta name="title" content="Newborn Care Specialists" />
          <meta name="description"
            content="All of our newborn care specialists are highly and specially trained nannies who focuses on the complete care of babies in the first 6 months of life." />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://www.elitenannies.com/nanny-services/beauty-therapist" />
          <meta property="og:title" content="Newborn Care Specialists" />
          <meta property="og:description"
            content="All of our newborn care specialists are highly and specially trained nannies who focuses on the complete care of babies in the first 6 months of life." />
          <meta property="og:image" content="https://www.elitenannies.com/static/media/qualified-newborn.531f654b.jpg" />
          <meta property="twitter:card" content="summary_large_image" />
          <meta property="twitter:url" content="https://www.elitenannies.com/nanny-services/beauty-therapist" />
          <meta property="twitter:title" content="Newborn Care Specialists" />
          <meta property="twitter:description"
            content="All of our newborn care specialists are highly and specially trained nannies who focuses on the complete care of babies in the first 6 months o /f life." />
          <meta property="twitter:image"
            content="https://www.elitenannies.com/static/media/qualified-newborn.531f654b.jpg" />
        </Helmet>
        <div className="page-content skill-detail-page">
          <SectionBanner para1={Parallax => {
            this.Parallax1 = Parallax;
          }} onSearchClick={this.handleOnSearch} city={city} />
          <SectionQualifiedNewborn onSearchClick={this.handleOnSearch} city={city} />
          <SectionSkillFeatures />
          <SectionWhyChooseUs para2={Parallax => {
            this.Parallax2 = Parallax;
          }} city={city} />
          <SectionWeFacility onSearchClick={this.handleOnSearch} />
          <SectionWeNannyQualifications skills={skills} city={city} />
          <SectionFooter para3={Parallax => {
            this.Parallax3 = Parallax;
          }} onSearchClick={this.handleOnSearch} />
        </div>
      </Fragment>
    )
  }
}


const SectionBanner = ({ para1, onSearchClick, city }) => (
  <section className="parallax-container section">
    <div ref={para1} className="parallax">
      <img src={ImgCityBanner} />
    </div>
    <div className="container">
      <div className="row align-items-center wow fadeInUp">
        <div className="col-12 col-lg-10 col-xl-8 col-xll-8 mx-auto">
          <div className="banner-caption">
            <div className="text-white text-center">
              <img src={logo} className="img-fluid mb-4 mb-lg-5" alt="Footer Logo" width="256" height="72" />
              <h2 className="heading text-white mb-3 text-uppercase">NEWBORN CARE SPECIALISTS</h2>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-10 col-xl-8 col-xll-7 mx-auto">
          <div className="banner-caption">
            <div className="text-white text-center">
              <p className={'sub-heading font-normal mb-4 px-xll-3 text-white'}>
                As a prestigious {city} based nanny agency, we specialize in career nannies {/* & sitters */} who are also
                trained
                and qualified in an additional profession to childcare.
              </p>
              <p className="mb-4 px-xll-3">
                Our Newborn Care Specialist nannies will provide assistance and education after you bring your baby home
                from the hospital, night shifts managing the baby’s care while you sleep restfully, as well as a
                nurturing and stimulating environment during waking hours.
              </p>
              <button className="btn btn-outline-primary btn-lg text-uppercase text-white"
                onClick={onSearchClick}>SEARCH NEWBORN CARE SPECIALISTS
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

const SectionQualifiedNewborn = ({ onSearchClick, city }) => (
  <section className="section">
    <div className="container-fluid">
      <div className="row align-items-center">
        <div className="col-12 col-lg-6 col-xl-6 wow fadeIn order-2 order-lg-1" data-wow-delay=".8s">
          <div className="row">
            <div className="col-12 col-md-10 col-xl-9 offset-xl-2 col-xll-8 offset-xll-3">
              <div className="incredibly-content pl-xl-4">
                <p className="sub-heading">Qualified & Experienced Newborn Care Specialists.</p>
                <div className="pr-xl-5 mr-xl-5">
                  <p>All of our {city} based newborn care specialists are highly and specially trained nannies who
                    focuses on the complete care of babies in the first 6 months of life. </p>
                  <p>Our newborn care specialists have extensive training involving optimal sleep habits for newborns,
                    caring for multiples, and promoting best practices for the health and safety of a newborn, along
                    with being CPR and first aid trained.</p>
                  <button className="btn btn-outline-primary btn-lg text-uppercase btn-250 mt-4"
                    onClick={onSearchClick}>GET STARTED
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-6 col-xl-6 px-md-0 wow fadeInLeft  order-1 order-lg-2" data-wow-delay=".5s">
          <img src={QualifiedNewborn} className="w-100 img-fluid float-right mb-lg-0 mb-4" alt="" />
        </div>
      </div>
    </div>
  </section>
);

const SectionSkillFeatures = ({ onSearchClick }) => (
  <section className="section pt-0">
    <div className="container">
      <div className="incredibly-content text-center" data-wow-delay=".8s">
        <div className="row align-items-center">
          <div className="col-12 col-lg-6 col-xl-10 wow fadeIn mx-auto">
            <p className="sub-heading">Nannies, specializing in newborns!</p>
          </div>
          <div className="w-100"></div>
          <div className="col-12 col-lg-6 col-xl-5 my-4 wow fadeIn mx-auto">
            <div className="px-xl-5">
              <p>Our newborn care specialists bring specialized training to help you seamlessly step into
                motherhood.</p>
            </div>
          </div>
          <div className="col-12 col-lg-11 mx-auto pt-4">
            <div className="row no-gutters">
              <div className="col-12 col-md-6 col-lg-3">
                <div className="sec-cheflist p-4">
                  <img src={SvgSupportAssistant} className="img-fluid" alt="" />
                  <span className="cheflist-text">Support & Assistance</span>
                  <p>Physical, emotional and educational support through proper methods of care.</p>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-3">
                <div className="sec-cheflist p-4">
                  <img src={SvgNightCare} className="img-fluid" alt="" />
                  <span className="cheflist-text">Night Care</span>
                  <p>Overnight care, including feeding, burping, soothing, changing and dressing.</p>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-3">
                <div className="sec-cheflist p-4">
                  <img src={SvgFeeding} className="img-fluid" alt="" />
                  <span className="cheflist-text">Feeding</span>
                  <p>Whether it’s bottled breast milk or formula, your nanny will have your baby fed.</p>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-3">
                <div className="sec-cheflist p-4">
                  <img src={SvgBathTub} className="img-fluid" alt="" />
                  <span className="cheflist-text">Scheduling</span>
                  <p>Creating and maintaining care logs where you can keep note of feeding and sleeping patterns.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

const SectionWhyChooseUs = ({ para2, city }) => (
  <section className="parallax-container section section-padding section-bg">
    <div ref={para2} className="parallax">
      <img src={ImgWhychooseus} />
    </div>
    <div className="container-fluid">
      <div className="row align-items-center">
        <div className="col-12 col-lg-6 col-xl-6 wow fadeIn order-2 order-lg-1" data-wow-delay=".8s">
          <div className="row">
            <div className="col-12 col-md-10 col-xl-9 offset-xl-2 col-xll-8 offset-xll-3">
              <div className="incredibly-content pl-lg-4 pr-lg-5">
                <p className="sub-heading">Why choose us?</p>
                <p className="become-info">
                  {city} Elite Nannies are professional nannies who are also skilled in other areas. These include
                  Teachers, Nurses, Foreign Language Teachers, Massage Therapists, Personal Trainers, Hair Stylists,
                  Beauty Therapists, Photographers and more.
                </p>
                <p className="become-info">
                  Our candidates here in {city} understand the importance of not only keeping your children safe and
                  making them feel loved, but helping cultivate them into inspiring, intelligent and creative little
                  people.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

const SectionWeFacility = ({ onSearchClick }) => (
  <section className="section">
    <div className="container-fluid">
      <div className="row align-items-center">
        <div className="col-12 col-lg-6 col-xl-6 px-md-0 wow fadeInLeft" data-wow-delay=".5s">
          <img src={ImgNewBornWeFacilitate} className="w-100 img-fluid float-right mb-4 mb-lg-0" alt="" />
        </div>
        <div className="col-12 col-lg-6 col-xl-6 wow fadeIn" data-wow-delay=".8s">
          <div className="row">
            <div className="col-12 col-xll-6 offset-xll-2 col-lg-11 offset-lg-1 pl-xll-0">
              <div className="incredibly-content">
                <p className="sub-heading">We facilitate the entire process, so you can have peace of mind.</p>
                <p className="mt-4">Not only do we match your unique family with an elite nanny, but once you have made
                  your hire, we can also assist you with tax and benefit information, so you can worry about the
                  important things! </p>
                <button className="btn btn-outline-primary btn-lg text-uppercase btn-250 mt-4"
                  onClick={onSearchClick}>GET STARTED
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

const SectionWeNannyQualifications = ({ skills, city }) => (
  <Fragment>
    <section className="section wow fadeInUp pt-0 pb-0">
      <div className="container px-xll-0">
        <div className="row">
          <div className="col-12 col-xl-7 col-xll-6 mx-auto">
            <div className="text-center wow fadeInUp incredibly-content mb-5">
              <h2 className="sub-heading mb-4">Other Nanny Qualifications</h2>
              <p className="px-xl-4 pl-xll-2">We only recruit honourable, educated and refined {city} nannies that help
                families live an exceptional life. All of our nannies are CPR and First Aid trained and have a minimum of
              3 years professional {/*nanny/sitter*/} nanny experience. They are incredibly well-versed in child-related
                safety
                measures - we want you to be able to leave your house each day with complete ease knowing your children
              are in the safest possible hands.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="section wow fadeInUp skills-slider pt-0 pb-0">
      <LandingSlickCarousel skills={skills} />
    </section>
    <section className="section wow fadeInUp pt-0">
      <div className="container">
        <Testimonial />
      </div>
    </section>
  </Fragment>
);

const SectionFooter = ({ para3, onSearchClick }) => (
  <section className="parallax-container section mb-5 section-padding">
    <div ref={para3} className="parallax">
      <img src={ImgFooterBanner} />
    </div>
    <div className="container">
      <div className="row align-items-center wow fadeInUp">
        <div className="col-12 col-lg-10 col-xl-8 col-xll-8 mx-auto">
          <div className="banner-caption incredibly-content">
            <div className="text-white text-center">
              <h2 className="sub-heading text-white mb-5 text-uppercase">BEAUTIFIED, SIMPLIFIED.</h2>
              <button className="btn btn-outline-primary btn-lg text-uppercase text-white btn-250"
                onClick={onSearchClick}>GET STARTED
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

const MapStateToProps = (state) => {
  return {
    skills: state.skills,
    user: state.user,
  }
};

export default connect(MapStateToProps, null)(NewBornCareSpecialist);
