import React, {Component} from 'react';
import {Link} from "react-router-dom";
import ImgSkillGoverness from '../../assets/images/skill-governess.jpg';

import history from '../../helpers/history';
import {connect} from "react-redux";

class GovernessDetails extends Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  goBack = () => {
    history.push('/our-nannies');
  };

  render() {
    const {isLoggedIn} = this.props.user;

    return (
      <div className="page-content mt-0">
        <section className="section section-skills">
          <div className="container mt-2 mt-md-4 mt-lg-5 px-xll-0">
            <div className="row">
              <div className="col-12 w-100 wow fadeInRight">
                <button className="btn btn-clear btn-back-skills p-0" onClick={this.goBack}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="26" viewBox="0 0 31.494 31.494">
                    <path
                      d="M10.273 5.009a1.112 1.112 0 0 1 1.587 0 1.12 1.12 0 0 1 0 1.571l-8.047 8.047h26.554c.619 0 1.127.492 1.127 1.111s-.508 1.127-1.127 1.127H3.813l8.047 8.032c.429.444.429 1.159 0 1.587a1.112 1.112 0 0 1-1.587 0L.321 16.532a1.12 1.12 0 0 1 0-1.571l9.952-9.952z"
                      fill="#7f7f7f"/>
                  </svg>
                </button>
              </div>
              <div className="col-12 col-lg-6 wow fadeInLeft">
                <h2 className="heading mb-4 d-block d-lg-none">Governess</h2>
                <img src={ImgSkillGoverness} className="w-100 img-fluid" alt=""/>
              </div>
              <div className="col-12 col-lg-6 col-xl-5 ml-auto wow fadeIn">
                <div className="skill-content pt-0 pt-5 py-md-5">
                  <h2 className="heading mb-4 d-none d-lg-block">Governess</h2>
                  <p>All of our nannies are CPR and first aid trained with a minimum of 3 years professional nanny
                    experience. In addition to providing a safe, caring, nurturing and stimulating environment in
                    which your children will thrive and develop, our Governesses will ensure that your children follow
                    an incredibly well-structured routine that focuses on balance - homework, educational activities
                    and play, so that every day runs smoothly. Our Governesses ensure children reach educational
                    milestones, teach them manners and etiquette and provide love, care and support.</p>
                  <p>Most families select a Governess to help educate their children aged 5 years or older, however
                    some start as early as 3 years old.
                    They may be able to teach specialized subjects such as language, history or a musical instrument
                    while ensuring manners and etiquette are first and foremost. Our wonderful Governesses can even
                    take on all liaising with your child's school (if required). If you wish to solely employ a
                    governess, as opposed to a Private Teacher, they are adept with preparing children for exams and
                    school assessments whilst always encouraging the children to behave appropriately and complete
                    their studies.</p>
                  <p>If you frequently travel, the flexibility of our Governesses is invaluable. They devote their
                    precious time to ensuring every milestone is met. </p>
                </div>
              </div>
              {/* <div className="col-12 w-100 mt-0 mt-lg-5 text-center wow fadeInUp">
                      <Link to={'/search/nannies?skills=Governess'} className="btn btn-skill btn-lg">START YOUR SEARCH</Link>
                      <Link to={'/family/registration'} className="btn btn-skill btn-lg">START YOUR SEARCH</Link>
                    </div> */}
            </div>
          </div>
        </section>
        <section className="section section-applynow bg-light">
          <div className="container">
            <div className="banner-caption">
              <div className="row wow fadeInUp">
                <div className="col-12 col-xl-9 col-xll-8 mx-auto">
                  <div className="banner-text">
                    <h2 className="heading">Want A Governess For Your Child?</h2>
                    <Link to={!isLoggedIn ? '/family/registration' : '/family/preferences'} className="btn btn-outline-primary">START YOUR
                      SEARCH</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }
}

const MapStateToProps = (state) => {
  return {
    user: state.user
  }
};

export default connect(MapStateToProps, null)(GovernessDetails);
