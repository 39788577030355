import React, {Component} from 'react';
import {Link, Redirect} from "react-router-dom";
import {connect} from "react-redux";
import {capitalize, isUndefined} from "lodash";
/* SERVICES */
import {GET} from "../../services/rest.service";
import {hideLoading, showLoading} from "../../redux/loader/LoaderActions";
/* COMPONENTS */
import ForgotPasswordForm from "./ForgotPasswordForm";

const ForgotPasswordSuccess = ({message}) => (
    <div className="text-center mb-5">
      <h4>{message}</h4>
    </div>
);

const ForgotPasswordFailure = ({message, onReset}) => (
    <div className="text-center mb-5">
      <h4>{message}</h4>
      <br/>
      <button className="btn btn-primary" onClick={onReset}>Retry</button>
    </div>
);

class ForgotPasswordComponent extends Component {

  handleForgotPassword = (formData) => {
    const payload = {
        ...formData,
      type: this.state.type
    };
    const {dispatch} = this.props;
    const forgot$ = GET('/users/forgotPassword', payload);
    dispatch(showLoading('Sending Reset Mail...'));
    forgot$
        .then(res => {
          dispatch(hideLoading());
          this.setState({
            status: true,
            message: 'Reset mail sent successfully, please follow the instructions in mail to reset your password'
          })
        })
        .catch(err => {
          dispatch(hideLoading());
          this.setState({status: false, message: err.message})
        })
  };

  handleReset = () => this.setState({message: '', status: undefined});

  constructor(props) {
    super(props);
    this.state = {
      message: '',
      status: undefined,
      type: '',
      isInvalidRoute: false
    };
  }

  componentDidMount() {
    const type = this.props.match.params.type;
    if (type) {
      this.setState({type: capitalize(type)})
    } else {
      this.setState({
        isInvalidRoute: true
      })
    }
  }

  render() {
    const {url} = this.props.match;
    const {isLoggedIn} = this.props.user;

    if(isLoggedIn) {
      return <Redirect from={url} to={'/'} />
    }

    if (this.state.isInvalidRoute) {
      return <Redirect from={url} to={'/'}/>
    }


    let forgotContent = null;
    if (!isUndefined(this.state.status) && this.state.status === true) {
      forgotContent = <ForgotPasswordSuccess message={this.state.message}/>
    } else if (this.state.status === false) {
      forgotContent = <ForgotPasswordFailure message={this.state.message} onReset={this.handleReset}/>
    } else {
      forgotContent = <ForgotPasswordForm onSubmit={this.handleForgotPassword}/>;
    }

    return (
        <div className="page-content page-auth">
          <div className="container">
            <section className="section bg-light">
              <div className="row">
                <div className="col-12 col-lg-7 col-xl-6 col-xll-5 mx-auto px-xll-4 px-xl-4 wow fadeInUp">
                  <div className="text-center">
                    <h2 className="heading mb-5">FORGOT PASSWORD</h2>
                  </div>

                  {forgotContent}

                  <div className="form-group text-center">
                    <p>Already have an account? <Link className="link-auth" to={`/${this.state.type.toLowerCase()}/login`}>Sign In</Link></p>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
    )
  }
}

const MapStateToProps = (state) => {
  return {
    user: state.user
  }
};

export default connect(MapStateToProps, null)(ForgotPasswordComponent);
