import React from 'react';
import {Formik} from 'formik';
import {object, string} from 'yup';
import TextInput from "./UI/TextInput";
import FormikObserver from 'formik-observer';
import ReactTelInput from 'react-telephone-input';
import flag from '../assets/images/flags.png';

const ReferencesForm = (props) => (
    <Formik initialValues={{
              ref1name: props.values[0] ? props.values[0].name : '', ref1phone_number: props.values[0] ? props.values[0].phone_number : '',ref1email: props.values[0] ? props.values[0].email : '',
              ref2name: props.values[1] ? props.values[1].name : '', ref2phone_number: props.values[1] ? props.values[1].phone_number : '',ref2email: props.values[1] ? props.values[1].email : '',
              ref3name: props.values[2] ? props.values[2].name : '', ref3phone_number: props.values[2] ? props.values[2].phone_number : '',ref3email: props.values[2] ? props.values[2].email : '',
              ref4name: props.values[3] ? props.values[3].name : '', ref4phone_number: props.values[3] ? props.values[3].phone_number : '',ref4email: props.values[3] ? props.values[3].email : '',
              ref5name: props.values[4] ? props.values[4].name : '', ref5phone_number: props.values[4] ? props.values[4].phone_number : '',ref5email: props.values[4] ? props.values[4].email : '',
            }}
            onSubmit={(values) => {
              props.onSubmit(values);
            }}
            validationSchema={object().shape({
              ref1name: string(),
              ref1phone_number: string(),
              ref1email: string(),
              ref2name: string(),
              ref2phone_number: string(),
              ref2email: string(),
              ref3name: string(),
              ref3email: string(),
            })}>
      {renderProps => {
        const {values, touched, errors, handleChange, isValid, handleBlur, setFieldValue, handleSubmit,setFieldTouched,setFieldError} = renderProps;

        return (
            <form onSubmit={handleSubmit}>
              <div className="row">
                <label className="form-label col-12">FIRST REFERENCE</label>
                <TextInput id="ref1name"
                           type="text"
                           size="col-12 col-md-4"
                           placeholder="Name"
                           error={touched.ref1name && errors.ref1name}
                           value={values.ref1name}
                           onChange={handleChange}
                           onBlur={handleBlur}/>

                <div className="form-group col-12 col-md-4">
                  <ReactTelInput defaultCountry="us"
                                 value={values.ref1phone_number}
                                 flagsImagePath={flag}
                                 onChange={(e) => setFieldValue('ref1phone_number', e)}
                                 onBlur={value => {
                                  setFieldTouched('ref1phone_number', true);
                                  setFieldError('ref1phone_number', value.error);
                                  }}
                                //  onBlur={handleBlur}
                  />
                </div>
                 <TextInput id="ref1email"
                           type="email"
                           size="col-12 col-md-4"
                           placeholder="Email"
                           error={touched.ref1email && errors.ref1email}
                           value={values.ref1email}
                           onChange={handleChange}
                           onBlur={handleBlur}/>
              </div>
              <div className="row">
                <label className="form-label col-12">SECOND REFERENCE</label>
                <TextInput id="ref2name"
                           type="text"
                           size="col-12 col-md-4"
                           placeholder="Name"
                           error={touched.ref2name && errors.ref2name}
                           value={values.ref2name}
                           onChange={handleChange}
                           onBlur={handleBlur}/>
                <div className="form-group col-12 col-md-4">
                  <ReactTelInput defaultCountry="us"
                                 value={values.ref2phone_number}
                                 flagsImagePath={flag}
                                 onChange={(e) => setFieldValue('ref2phone_number', e)}
                                //  onBlur={handleBlur}
                                onBlur={value => {
                                  setFieldTouched('ref2phone_number', true);
                                  setFieldError('ref2phone_number', value.error);
                                  }}
                  />
                </div>
                 <TextInput id="ref2email"
                           type="email"
                           size="col-12 col-md-4"
                           placeholder="Email"
                           error={touched.ref2email && errors.ref2email}
                           value={values.ref2email}
                           onChange={handleChange}
                           onBlur={handleBlur}/>
              </div>
              <div className="row">
                <label className="form-label col-12">THIRD REFERENCE</label>
                <TextInput id="ref3name"
                           type="text"
                           size="col-12 col-md-4"
                           placeholder="Name"
                           error={touched.ref3name && errors.ref3name}
                           value={values.ref3name}
                           onChange={handleChange}
                           onBlur={handleBlur}/>
                <div className="form-group col-12 col-md-4">
                  <ReactTelInput defaultCountry="us"
                                 value={values.ref3phone_number}
                                 flagsImagePath={flag}
                                 onChange={(e) => setFieldValue('ref3phone_number', e)}
                                //  onBlur={handleBlur}
                                onBlur={value => {
                                  setFieldTouched('ref3phone_number', true);
                                  setFieldError('ref3phone_number', value.error);
                                  }}
                  />
                </div>
                 <TextInput id="ref3email"
                           type="email"
                           size="col-12 col-md-4"
                           placeholder="Email"
                           error={touched.ref3email && errors.ref3email}
                           value={values.ref3email}
                           onChange={handleChange}
                           onBlur={handleBlur}/>
              </div>

              <div className="row">
                <label className="form-label col-12">FORTH REFERENCE</label>
                <TextInput id="ref4name"
                           type="text"
                           size="col-12 col-md-4"
                           placeholder="Name"
                           error={touched.ref4name && errors.ref4name}
                           value={values.ref4name}
                           onChange={handleChange}
                           onBlur={handleBlur}/>
                <div className="form-group col-12 col-md-4">
                  <ReactTelInput defaultCountry="us"
                                 value={values.ref4phone_number}
                                 flagsImagePath={flag}
                                 onChange={(e) => setFieldValue('ref4phone_number', e)}
                                //  onBlur={handleBlur}
                                onBlur={value => {
                                  setFieldTouched('ref4phone_number', true);
                                  setFieldError('ref4phone_number', value.error);
                                  }}
                  />
                </div>
                 <TextInput id="ref4email"
                           type="email"
                           size="col-12 col-md-4"
                           placeholder="Email"
                           error={touched.ref4email && errors.ref4email}
                           value={values.ref4email}
                           onChange={handleChange}
                           onBlur={handleBlur}

                           />
              </div>

              <div className="row">
                <label className="form-label col-12">FIFTH REFERENCE</label>
                <TextInput id="ref5name"
                           type="text"
                           size="col-12 col-md-4"
                           placeholder="Name"
                           error={touched.ref5name && errors.ref5name}
                           value={values.ref5name}
                           onChange={handleChange}
                           onBlur={handleBlur}/>
                <div className="form-group col-12 col-md-4">
                  <ReactTelInput defaultCountry="us"
                                 value={values.ref5phone_number}
                                 flagsImagePath={flag}
                                 onChange={(e) => setFieldValue('ref5phone_number', e)}
                                //  onBlur={handleBlur}
                                onBlur={value => {
                                  setFieldTouched('ref5phone_number', true);
                                  setFieldError('ref5phone_number', value.error);
                                  }}
                  />
                </div>
                <TextInput id="ref5email"
                           type="email"
                           size="col-12 col-md-4"
                           placeholder="Email"
                           error={touched.ref5email && errors.ref5email}
                           value={values.ref5email}
                           onChange={handleChange}
                           onBlur={handleBlur}/>
              </div>
              <FormikObserver debounce={1000} onChange={({values}) => {
                if (isValid && props.onFormChange) {
                  props.onFormChange(values)
                }
              }}/>
              {props.showSubmit && <div className="form-group text-center mt-5 mb-0">
                <button type="submit" className="btn btn-outline-primary">NEXT</button>
              </div>}
            </form>
        )
      }}
    </Formik>
);

export default ReferencesForm;
