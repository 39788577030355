import React, {Component} from 'react'
import {connect} from "react-redux";
import {Redirect} from "react-router";
import {Link} from "react-router-dom";

// Actions
import {login} from '../../redux/user/UserActions';

// Components
import LoginForm from "../../components/LoginForm";

class FamilyLoginComponent extends Component {
  handleLogin = (formData) => {
    const payload = {
        ...formData,
      type: 'Family'
    };
    const {dispatch} = this.props;
    dispatch(login(payload));
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {isLoggedIn, type, user, message} = this.props.user;

    // if(isLoggedIn) {
    //   return <Redirect from={'/family/login'} to={'/'} />
    // }

    return (
        <div className="page-content page-auth">
          <div className="container">
            <section className="section bg-light">
              <div className="row">
                <div className="col-12 col-lg-7 col-xl-6 col-xll-5 mx-auto px-xll-4 px-xl-4 wow fadeInUp">
                  <div className="text-center">
                    <h2 className="heading">FAMILY LOGIN</h2>
                    <p>Access your account to request a nanny {/*or hire a sitter.*/}
                    <Link className="d-block" to={'/nanny/login'}>Login as a nanny instead.</Link></p>
                  </div>
                  <LoginForm onSubmit={this.handleLogin} type="family"/>
                  <div className="form-group text-center">
                    <p>New to Elite Nannies? <Link className="link-auth" to={`/family/registration`}>Sign up now!</Link></p>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
    )
  }
}

const MapStateToProps = (state) => {
  return {
    user: state.user
  }
};

export default connect(MapStateToProps, null)(FamilyLoginComponent);
