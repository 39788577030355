const dev = {
  api: {
    URL: 'https://34ba15918b91.ngrok.io/v1'
    // URL: `http://192.168.0.114:5000/v1`
  },
  auth: {
    CLIENT_ID: 'vY6QAr19496Im9nGxMlGXCy4T',
    CLIENT_SECRET: '66W1Tp3M1ZyRQDgCSr4XSv5t97Pyp7875hOCCHV1w3438098zi'
  },
  PAYMENTS: {
    STRIPE_API_KEY: 'pk_test_HCjf8eYn5IU56jJ8jjKRpPew'
  }
};

const prod = {
  api: {
    URL: 'https://www.elitenannies.com/api'
  },
  auth: {
    CLIENT_ID: 'vY6QAr19496Im9nGxMlGXCy4T',
    CLIENT_SECRET: '66W1Tp3M1ZyRQDgCSr4XSv5t97Pyp7875hOCCHV1w3438098zi'
  },
  PAYMENTS: {
    STRIPE_API_KEY: 'pk_live_kbCn7rfIImQVu1e7n5e5a45k'
  }
};

const config = process.env.REACT_APP_STAGE === 'prod'
    ? prod
    : dev;

export default {
  ...config
};
