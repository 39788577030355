/* eslint-disable no-undef */
import React, {Component, Fragment} from 'react';
import Script from 'react-load-script';
import classnames from 'classnames';
// Images
import location from '../../assets/images/svg/location.svg';
import search from '../../assets/images/svg/search.svg';

class FormikPlaceSearch extends Component {

  handleScriptLoad = () => {
    let options = {
      types: ['(cities)'],
    };
    // const node = .current;
    /*global google*/ // To disable any eslint 'google not defined' errors
    this.autocomplete = new google.maps.places.Autocomplete(
        this.googleSearch,
        options,
    );
    this.autocomplete.addListener('place_changed', this.handlePlaceSelect);
  };

  handlePlaceSelect = () => {

    let addressObject = this.autocomplete.getPlace();
    let address = addressObject.address_components;

    if (address) {
      const lat = addressObject.geometry.location.lat();
      const lng = addressObject.geometry.location.lng();

      this.setState({
        locality: addressObject.formatted_address
      });

      let fullAddress = {latitude: lat, longitude: lng, city: '', state: '', country: '', zipcode: '', address: this.state.locality};
      for (let result of address) {
        if ((result.types.indexOf('locality') > -1) && (result.types.indexOf('political') > -1)) {
          fullAddress.city = result.long_name;
        } else if ((result.types.indexOf('administrative_area_level_1') > -1) && (result.types.indexOf('political') > -1)) {
          fullAddress.state = result.long_name;
        } else if ((result.types.indexOf('country') > -1) && (result.types.indexOf('political') > -1)) {
          fullAddress.country = result.long_name;
        } else if ((result.types.indexOf('postal_code') > -1) && (result.types.indexOf('postal_code') > -1)) {
          fullAddress.zipcode = result.long_name;
        }
      }
      localStorage.setItem('location', JSON.stringify(fullAddress));
      this.props.onChange(this.state.locality);
    }
  };

  handleChange = (e) => {
    this.setState({locality: e.target.value});
    // this.props.onChange(this.state.locality);
  };

  constructor(props) {
    super(props);
    this.state = {
      locality: ''
    };
    this.googleSearch = React.createRef();
  }

  render() {
    const {label, error, isBig, subText, placeholder, value, onChange, className, ...props} = this.props;
    const classes = classnames(
        'form-control cus-control',
        {
          'is-invalid': !!error,
        },
        className
    );
    return (
        <Fragment>
          <div className="form-group form-group-btn">
            {isBig && <h4>{label}</h4>}
            {subText && <label className="form-label">{subText}</label>}
            {!isBig && label && <label className="form-label">{label}</label>}
            <div className="input-group">
              <input ref={(el) => this.googleSearch = el}
                     className={classes}
                     placeholder={placeholder}
                     value={this.state.locality ? this.state.locality : value}
                     onFocus={(e) => e.target.select()}
                     onChange={this.handleChange}/>
              <div className="input-group-append">
                <button className="btn btn-clear btn-lg text-primary px-4 border-0" type="button">
                  <img width="15" height="19" src={location} alt="search"/>
                </button>
              </div>
              {error && <div className="invalid-feedback">{error}</div>}
            </div>
          </div>
          <Script url="https://maps.googleapis.com/maps/api/js?key=AIzaSyAfPK2JvZ_Y5Jc1lq-DFFxPKHkY3nOxNYk&libraries=places" onLoad={this.handleScriptLoad}/>
        </Fragment>
    );
  }
}

export default FormikPlaceSearch;
