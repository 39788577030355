import React, {Component, Fragment} from 'react';
import {isEmpty, get} from 'lodash';
import {connect} from "react-redux";
import DatePicker from "react-datepicker";
import {Select} from "semantic-ui-react";
import moment from 'moment';
import {Formik} from "formik";
import {object, string} from 'yup'
import history from '../../helpers/history';
// Services
import {DURATION_OPTIONS} from "../../helpers/constants";
import {GET, POST} from "../../services/rest.service";
import {hideLoading, showLoading} from "../../redux/loader/LoaderActions";
// Components
import TextareaInput from "../../components/UI/TextareaInput";
import PageLoader from "../../components/PageLoader";
import Thumbnail from "../../components/ThumbnailComponent";
// Images
import back from '../../assets/images/svg/arrow-left-fill.svg';
import location from '../../assets/images/svg/location.svg';
import toast from '../../Toast';


function createMarkup(markup) {
  return {__html: markup};
}

const ContactForm = (props) => {
  const initialValues = {
    start_date: '',
    comment: '',
    how_soon_to_start: '',
  };
  return (
    <Formik initialValues={initialValues} onSubmit={(values, {resetForm}) => {
      props.onSubmit(values);
      resetForm(initialValues);
    }} validationSchema={object().shape({
      start_date: string(),
      how_soon_to_start: string(),
      comment: string().required("Please leave a message for nanny"),
    })}>
      {renderProps => {
        const {values, touched, errors, handleChange, setFieldValue, handleBlur, handleSubmit} = renderProps;
        const today = new Date();
        return (
          <form className="row" onSubmit={handleSubmit}>
            <div className="form-group col-12 mt-2 pb-2">
              <h4 className="font-baskervilleregular text-capitalize">request as a nanny</h4>
            </div>
            <div className="form-group col-12 col-lg-5">
              <label className="form-label d-block">Start Date</label>
              <DatePicker
                className={"form-control cus-control"}
                selected={today}
                placeholderText={"Select Start Date"}
                minDate={today}
                value={values.start_date}
                onChange={(value) => setFieldValue('start_date', moment(value).format('MM/DD/YYYY'))}
              />
            </div>
            <div className="form-group col-12 col-lg-2 d-flex align-items-center justify-content-center mb-md-0">
              <strong>- OR -</strong>
            </div>
            <div className="form-group col-12 col-lg-5">
              <label className="form-label d-block">How soon to start</label>
              <Select placeholder={'Please select an option'}
                      selection
                      options={DURATION_OPTIONS}
                      value={values.how_soon_to_start}
                      onChange={(e, {value}) => setFieldValue('how_soon_to_start', value)}/>
            </div>
            <TextareaInput id="comment"
                           size="col-12"
                           label="Comments"
                           className="cus-control"
                           placeholder="Write a message for this nanny"
                           error={touched.comment && errors.comment}
                           value={values.comment}
                           onChange={handleChange}
                           onBlur={handleBlur}/>
            <div className="col-12 my-4">
              <div className="form-group text-right pb-5">
                <button type="submit" className="btn btn-outline-primary">EXPRESS INTEREST</button>
              </div>
            </div>
          </form>
        )
      }}
    </Formik>
  )
};


class NannyViewComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      nanny: {},
      nannyView: false,
      sitter:null

    };
  }

  doContactNanny = (formData) => {
    const {user} = this.props.user;
    const {dispatch} = this.props;
    const payload = {
      ...formData,
      start_date: formData.start_date ? moment(formData.start_date).format('YYYY-MM-DD') : '',
      user_id: user._id,
      nanny_id: this.props.match.params.id
    };

    dispatch(showLoading());
    POST('/users/nannies/contact', payload)
      .then((res) => {
        dispatch(hideLoading());
        toast.success(`Your message has been sent to ${this.state.nanny.first_name} ${this.state.nanny.last_name}`);
      })
      .catch(err => {
        dispatch(hideLoading());
        toast.error(err.message);
      })

  };

  toggleNannyView = () => {
    const {isLoggedIn} = this.props.user;
    if (isLoggedIn) {
      this.setState({nannyView: !this.state.nannyView});
    } else {
      history.push(`/family/login?redirectUrl=/nanny/view/${this.props.match.params.id}`);
    }
  };

  handleBookSitter = () => {
    const {isLoggedIn} = this.props.user;
    if (isLoggedIn) {
      history.push(`/family/request-sitter?usr_source=${this.state.nanny.first_name + ' ' + this.state.nanny.last_name}&sitter_id=${this.state.nanny._id}`);
    } else {
      history.push(`/family/login?redirectUrl=/nanny/view/${this.props.match.params.id}`);
    }
  };

  handleContactNanny = () => {
    history.push(`/family/login?redirectUrl=/nanny/view/${this.props.match.params.id}`);
  };

  componentDidMount() {
    const id = this.props.match.params.id;
    GET(`user/get_by_id/${id}`)
      .then(res => {
        this.setState({nanny: res.data, nannyView: res.data.type === 'Nanny'});
      })
      .catch(err => {
        toast.error(err.message);
      })

      GET('/admin/appConfiguration')
      .then(({data})=>{
        this.setState({
          sitter:data.configuration.sitter
        })
      })
      .catch(err => {
        toast.error(err.message);
      });
  }

  goBack = () => {
    history.goBack();
  };

  render() {
    const {nanny, nannyView,sitter} = this.state;
    const {user, isLoggedIn} = this.props.user;

    if (isEmpty(nanny)) {
      return <PageLoader/>
    }

    return (
      <div className="page-content page-details page-account">
        <div className="container px-xll-0">
          <div className="mb-4 mb-sm-5 bp-2">
            <a className="text-primary font-14" href="javascript:void(0);" onClick={this.goBack}>
              <img className="align-middle mr-2" width="24" height="24" src={back}/>
              Back to search
            </a>
          </div>
          <section className="section nanny-detail bg-lightdark">
            <div className="row">
              <div className="col-12 col-lg-4 col-xl-4">
                <div className="nannies-profile-avatar">
                  <Thumbnail source={nanny.nanny.profile_picture.name} className="nannies-profile-image"/>
                </div>
              </div>
              <div className="col-12 col-lg-8 col-xl-7">

                <div className="nannies-details">
                  <h2 className="heading">{nanny.first_name} {nanny.last_name}</h2>
                  <div className="d-flex">
                    <ul className="list-inline d-flex flex-wrap mb-0">
                      <li className="d-flex mr-3 mb-3">
                        <img height="18" className="mr-2" src={location}/>
                        {nanny.location.address.split(',')[0]}
                      </li>
                      {
                        nanny.nanny.skills.map((x, index) => {
                          return (
                            <li key={index} className="d-flex mr-3 mb-3">
                              <Thumbnail height={'20px'} source={x.skill_id.icon.name}/>&nbsp;{x.skill_id.title}
                            </li>
                          )
                        })
                      }
                    </ul>
                  </div>
                  {
                    nanny.nanny.about_nanny && (
                      <div className="form-group">
                        <label className="form-label font-bold">ABOUT</label>
                        <div dangerouslySetInnerHTML={createMarkup(nanny.nanny.about_nanny)}></div>
                        {/*<p>*/}
                        {/*  {nanny.nanny.about_nanny}*/}
                        {/*</p>*/}
                      </div>
                    )
                  }

                  <hr className="my-4"/>

                  {
                    nanny.type !== 'Nanny' && (
                      <Fragment>
                        <div className="py-2 text-center text-md-left">
                          {sitter &&(nanny.type === 'Both' || nanny.type === 'Sitter') &&
                          <button onClick={this.handleBookSitter} className="btn btn-outline-primary btn-200">BOOK THIS
                            SITTER</button>}
                          {sitter && nanny.type === 'Both' &&
                          <strong className="mx-4 d-block d-md-inline-block my-md-0 my-3">- OR -</strong>}
                          {(nanny.type === 'Both' || nanny.type === 'Nanny') &&
                          <button className="btn btn-outline-primary btn-200" onClick={this.toggleNannyView}>REQUEST
                            NANNY</button>}
                        </div>
                        <hr className="my-4"/>
                      </Fragment>
                    )
                  }

                  {
                    !isEmpty(user)
                      ? (nannyView && <ContactForm onSubmit={this.doContactNanny}/>)
                      : <div className="my-5">
                        <h5>
                          You must <a className={'text-primary c-pointer'} onClick={this.handleContactNanny}>login</a> to
                          contact a nanny.
                        </h5>
                      </div>
                  }
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    )
  }
}

const MapStateToProps = (state) => {
  return {
    user: state.user
  }
};

export default connect(MapStateToProps, null)(NannyViewComponent);
