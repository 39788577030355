import React, { Component } from 'react';
import AboutusImg from '../../../src/assets/images/about-us.jpeg';
import { Helmet } from 'react-helmet'
import { Sectionfounder } from '../HomeComponent';

class AboutUsComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentWillUnmount() {
    document.title = "Elite Nannies";
  }

  render() {
    return (
      <div className="page-content">
        <Helmet>
          <title>About Elite Nannies</title>
          <meta name="title" content="About Elite Nannies" />
          <meta name="description"
            content="Founder, Roxanne Peake, created the boutique agency, Elite Nannies, to enrich the lives of busy families. Located in Los Angeles and New York. " />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://www.elitenannies.com/about-us" />
          <meta property="og:title" content="About Elite Nannies" />
          <meta property="og:description"
            content="Founder, Roxanne Peake, created the boutique agency, Elite Nannies, to enrich the lives of busy families. Located in Los Angeles and New York. " />
          <meta property="og:image" content="https://www.elitenannies.com/static/media/about-us.38e1edd4.jpeg" />
          <meta property="twitter:card" content="summary_large_image" />
          <meta property="twitter:url" content="https://www.elitenannies.com/about-us" />
          <meta property="twitter:title" content="About Elite Nannies" />
          <meta property="twitter:description"
            content="Founder, Roxanne Peake, created the boutique agency, Elite Nannies, to enrich the lives of busy families. Located in Los Angeles and New York. " />
          <meta property="twitter:image"
            content="https://www.elitenannies.com/static/media/about-us.38e1edd4.jpeg" />
        </Helmet>
        <SectionBanner />
        <SectionAboutUs />
        <Sectionfounder/>
      </div>
    )
  }
}

const SectionBanner = () => (
  <section className="page-banner section bg-lightdark">
    <div className="container">
      <div className="banner-caption">
        <div className="row wow fadeInUp">
          <div className="col-12 col-xl-8 col-xll-7 mx-auto">
            <div className="banner-text">
              <h2 className="heading page-heading">ABOUT US</h2>
              <p className="mb-0">Danielle Grettler and Pauline Thimas are the owners of <strong>Elite Nannies</strong>! Please take a moment to read about the exciting new adventure <strong>Elite Nannies</strong> is embarking upon whilst under Danielle's and Pauline's ownership. Here is a little background on the two former professional nannies who are now at the helm of <strong>Elite Nannies</strong>.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

const SectionAboutUs = () => (
  <section className="section pt-lg-0">
    <div className="container-fluid">
      <div className="box">
        <div className="row align-items-center">
          <div className="col-12 col-md-12 col-lg-6 wow fadeIn order-2 order-md-12">

                <div className="mb-4 mb-lg-0 pl-xl-2">
                  <p>Danielle and Pauline met while working together as co-nannies in Los Angeles. Both have more than 10 years experience in the business, and clicked immediately once they discovered they shared the same passion for childcare, combined with a profound commitment to everything ‘nanny and family’ related. Danielle and Pauline are dedicated to ensuring that all the families served by <strong>Elite Nannies</strong> hire the perfect candidate, whether it's a nanny who can teach your child a foreign language, a nanny who is also a licensed nurse, or a nanny who is chef-trained, ensuring your entire family is always provided with the utmost healthy and delicious meals.</p>
                  <p>Danielle and Pauline share more than just a passion for kids. They are both college graduates from New England who love days spent by the ocean. They have worked with many different families, having had the opportunity to instill creativity and curiosity in children of all ages, ranging from newborn to teenaged. Danielle and Pauline have also had the unique opportunity to travel widely as nannies. They understand the intricacies of traveling with children, and, should the need ever arise, they know just how important it is to ensure that the candidate you are matched with is comfortable and experienced in varying locales.</p>
                  <p><strong>Elite Nannies</strong> believes that being a nanny is not solely a profession -- it is a calling! It is Danielle and Pauline’s goal to pair all clients with a nanny who is not just a professional caregiver, but someone who will ultimately become a valued part of your family.</p>
                  <p>Danielle and Pauline have achieved a long-held dream by investing in <strong>Elite Nannies</strong>. Please feel free to contact them at your earliest convenience for a confidential consultation, because nothing is more important to you -- and to Danielle and Pauline -- than finding the right nanny for your family. It is a journey they look forward to undertaking with you, one that concludes with the best nanny looking after your children. Danielle and Pauline welcome you to <strong>Elite Nannies</strong>. Come join the Elite!</p>
                </div>

          </div>
          <div className="col-12 col-md-12 col-lg-6 px-md-0 wow fadeInRight order-1 order-md-12">
            <img src={AboutusImg} className="img-fluid float-right" alt="" />
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default AboutUsComponent;
