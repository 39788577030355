import React, {Component, Fragment} from 'react';
import {Formik} from "formik";
import {object, string} from 'yup';
import toast from '../Toast';
import {Helmet} from 'react-helmet'
// Components
import TextareaInput from "../components/UI/TextareaInput";
import NewsletterComponent from "./NewsletterComponent";
import ReactTelInput from 'react-telephone-input';
import TextInput from "../components/UI/TextInput";
// Services
import {POST} from "../services/rest.service";
import {PERSON_NAME} from "../helpers/constants";
// Assets
import flag from '../assets/images/flags.png';

const ContactForm = (props) => {
  const initialValues = {email: '', name: '', phoneNumber: '+1 ', message: ''};
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, {resetForm}) => {
        props.onSubmit(values);
        resetForm(initialValues);
      }}
      validationSchema={object().shape({
        email: string().email("Enter a valid email").required("Please enter your email"),
        name: string().required("Please enter Name").matches(PERSON_NAME, 'Please enter a valid name'),
        phoneNumber: string().required("Please enter your phone number"),
        message: string().required("Please enter your message"),
      })}>
      {(renderProps) => {
        const {values, touched, errors, handleChange, handleBlur, setFieldValue, handleSubmit,setFieldTouched,setFieldError} = renderProps;
        return (
          <form className="row" onSubmit={handleSubmit}>
            <TextInput id="name"
                       type="text"
                       size="col-12"
                       label=""
                       placeholder="Your name"
                       error={touched.name && errors.name}
                       value={values.name}
                       onChange={handleChange}
                       onBlur={handleBlur}/>

            <TextInput id="email"
                       type="email"
                       size="col-12"
                       label=""
                       placeholder="Your email address"
                       error={touched.email && errors.email}
                       value={values.email}
                       onChange={handleChange}
                       onBlur={handleBlur}/>

            <div className="form-group col-12">
              <ReactTelInput defaultCountry="us"
                             value={values.phoneNumber}
                             flagsImagePath={flag}
                             onChange={(e) => setFieldValue('phoneNumber', e)}
                            //  onBlur={handleBlur}
                             onBlur={value => {
                              setFieldTouched('phoneNumber', true);
                              setFieldError('phoneNumber', value.error);
                              }}
              />
            </div>

            <TextareaInput id="message"
                           size="col-12"
                           label=""
                           placeholder="Your message"
                           error={touched.message && errors.message}
                           value={values.message}
                           onChange={handleChange}
                           onBlur={handleBlur}/>
            <div className="form-group col-12 mb-0">
              <button type="submit" className="btn btn-signup btn-lg mt-3 mt-lg-4 btn-200">Submit</button>
            </div>
          </form>
        )
      }}
    </Formik>
  )
};

class ContactComponent extends Component {

  handleContactSubmit = (formData) => {
    POST('/general/contact/save', formData)
      .then(res => {
        toast.success('Your message has been received. We shall contact you back soon');
      })
      .catch(err => {
        toast.error(err.message);
      })
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentWillUnmount() {
    document.title = "Elite Nannies";
  }

  render() {
    return (
      <Fragment>

        <Helmet>
          <title>Contact Elite Nannies</title>
          <meta name="title" content="Contact Elite Nannies"/>
          <meta name="description" content="We carefully match our nannies to unique family values, care philosophies and lifestyle, so everything flows. Contact us today!"/>
          <meta property="og:type" content="website"/>
          <meta property="og:url" content="https://www.elitenannies.com/contact-us"/>
          <meta property="og:title" content="Contact Elite Nannies"/>
          <meta property="og:description" content="We carefully match our nannies to unique family values, care philosophies and lifestyle, so everything flows. Contact us today!"/>
          <meta property="og:image" content="https://www.elitenannies.com/static/media/portugueselanguageteacher.16cde35b.jpg"/>
          <meta property="twitter:card" content="summary_large_image"/>
          <meta property="twitter:url" content="https://www.elitenannies.com/contact-us"/>
          <meta property="twitter:title" content="Contact Elite Nannies"/>
          <meta property="twitter:description" content="We carefully match our nannies to unique family values, care philosophies and lifestyle, so everything flows. Contact us today!"/>
          <meta property="twitter:image" content="https://www.elitenannies.com/static/media/portugueselanguageteacher.16cde35b.jpg"/>
        </Helmet>

        <div className="page-content page-contact">

          <section className="page-banner section bg-lightdark">
            <div className="container">
              <div className="banner-caption wow fadeInUp">
                <div className="row">
                  <div className="col-12 col-lg-10 col-xl-8 col-xll-7 mx-auto">
                    <div className="banner-text text-center pb-3">
                      <h2 className="heading page-heading pt-0">Contact</h2>
                    </div>
                  </div>
                  <div className="col-12 col-md-11 col-lg-11 col-xl-9 col-xll-8 mx-auto">
                    <div className="row mt-4 mt-lg-5 pb-2">
                      <div className="col-12 col-lg-7 col-xl-7">
                        <ContactForm onSubmit={this.handleContactSubmit}/>
                      </div>
                      <div className="col-12 col-lg-4 col-xl-4 offset-xl-1 offset-lg-1 mt-5 mt-lg-0">
                        <div className="contact-info">
                          <h4 className="contact-title">phone</h4>
                          <p className="contact-desc mb-2"><a href="tel:+1 (310)-955-4820">+1 (310)-955-4820</a></p>
                          <h4 className="contact-title">Email</h4>
                          <p className="contact-desc text-lowercase mb-0"><a
                            href="mailto:info@elitenannies.com">info@elitenannies.com</a></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <NewsletterComponent/>
        </div>
      </Fragment>
    )
  }
}

export default ContactComponent;
