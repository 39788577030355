import React, {Component, Fragment} from 'react';
import ArrowRight from '../../../src/assets/images/svg/arrow-right-dark.svg';

import history from '../../helpers/history';
import {connect} from "react-redux";
import config from "../../config";
import {Helmet} from "react-helmet";

class OurNanniesComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  navigateToSkill = (name) => {
    history.push(`/nanny-services/${name}`);
  };

  componentWillUnmount() {
    document.title = "Elite Nannies";
  }

  render() {
    const {skills} = this.props;

    return (
      <Fragment>
        <Helmet>
          <title>Degree Qualified Nannies</title>
          <meta name="title" content="Degree Qualified Nannies"/>
          <meta name="description"
                content="Devoted to supporting your family’s success, our nannies obtain other skills including nursing, teaching, massage, personal training, and various other fields."/>
          <meta property="og:type" content="website"/>
          <meta property="og:url" content="https://www.elitenannies.com/our-nannies"/>
          <meta property="og:title" content="Degree Qualified Nannies"/>
          <meta property="og:description"
                content="Devoted to supporting your family’s success, our nannies obtain other skills including nursing, teaching, massage, personal training, and various other fields."/>
          <meta property="og:image"
                content="https://www.elitenannies.com/static/media/Imgforeignlanguageteacher.1666b38f.jpg"/>
          <meta property="twitter:card" content="summary_large_image"/>
          <meta property="twitter:url" content="https://www.elitenannies.com/our-nannies"/>
          <meta property="twitter:title" content="Degree Qualified Nannies"/>
          <meta property="twitter:description"
                content="Devoted to supporting your family’s success, our nannies obtain other skills including nursing, teaching, massage, personal training, and various other fields."/>
          <meta property="twitter:image"
                content="https://www.elitenannies.com/static/media/Imgforeignlanguageteacher.1666b38f.jpg"/>
        </Helmet>
        <div className="page-content mt-0">
          <section className="section">
            <div className="container pb-5">
              <div className="row align-items-center">
                <div className="col-12 wow fadeInUp">
                  <div className="row">
                    <div className="col-12 col-xl-8 col-xll-7 mx-auto text-center">
                      <h2 className="heading mb-3">OUR NANNIES</h2>
                      <p>We only recruit honourable, educated and refined nannies that help families live an exceptional
                        life. Whole-heartedly devoted to supporting your family’s
                        success, our nannies are skilled in many areas, including nursing, teaching, massage, personal
                        training, and various other fields. All of our nannies are CPR
                        and First Aid trained and have a minimum of 3 years professional {/*nanny/sitter*/} nanny experience. They
                        are incredibly well-versed in child-related safety measures - we
                        want you to be able to leave your house each day with complete ease knowing your children are in
                        the safest possible hands.</p>
                      <p className="font-bold mb-0">
                        “Tell me and I’ll forget.<br/>
                        Teach me and I’ll learn.<br/>
                        Involve me and I’ll remember.”
                      </p>
                      <p>- Benjamin Franklin</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container mt-3 px-xl-4 px-xll-0">
              <div className="row row-15 wow fadeInUp nannyskill-list">
                {
                  skills.map((x, index) => {
                    return (
                      <div key={index} className="col-12 col-sm-6 col-lg-4 wow fadeInUp">
                        <div className="skill-box" onClick={() => this.navigateToSkill(x.slug)}>
                          <div className="card">
                            <img src={`${config.api.URL}${x.poster.name}`} className="card-img-top" alt=""/>
                            <div className="card-body">
                              <img src={`${config.api.URL}${x.icon.name}`} className="img-fluid" alt={x.title}/>
                              <h5 className="card-title">{x.title}</h5>
                              <button className="btn btn-clear">
                                <img src={ArrowRight} alt="" width="33"/>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })
                }

              </div>
            </div>
          </section>
        </div>
      </Fragment>
    )
  }
}

const MapStateToProps = (state) => {
  return {
    skills: state.skills
  }
};

export default connect(MapStateToProps, null)(OurNanniesComponent);
