import React, { Component, Fragment } from 'react';
import { Link } from "react-router-dom";
import history from "../../helpers/history";
import M from "materialize-css";
import { connect } from "react-redux";
import { startCase } from "lodash";
import { unsimplify } from "../../helpers/helper";
// Components
import Testimonial from "./shared/Testimonial";
import LandingSlickCarousel from "./shared/LandingSlickCarousel";
// Images
import ChineseTeacherBanner from "../../assets/images/chineseteacher-banner.jpg";
import ImgForeignLanguageTeacher from '../../assets/images/foreignlanguageualified.jpg';
import ImgForeignLanguageTeacher1 from '../../assets/images/foreignlanguageteacher.jpg';
import ArabicTeacherBanner from "../../assets/images/arabiclanguageteacher.jpg";
import FrenchTeacherBanner from "../../assets/images/frenchlanguageteacher.jpg";
import HindiTeacherBanner from "../../assets/images/hindilanguageteacher.jpg";
import ItalianTeacherBanner from "../../assets/images/italianlanguageteacher.jpg";
import JapaneseTeacherBanner from "../../assets/images/japaneselanguageteacher.jpg";
import PortugueseTeacherBanner from "../../assets/images/portugueselanguageteacher.jpg";
import RussianTeacherBanner from "../../assets/images/russianlanguageteacher.jpg";
import SpanishTeacherBanner from "../../assets/images/spanishlanguageteacher.jpg";
import ImgCityBanner from "../../assets/images/foreignlanguageteacher-banner.jpg";
import ImgAofLRL from "../../assets/images/advantagesoflearningaForeignlanguage.jpg";
import SvgImprovedMemory from "../../assets/images/svg/brain.svg";
import SvgProblemSolving from "../../assets/images/svg/test.svg";
import SvgDivergentThinking from "../../assets/images/svg/creative.svg";
import SvgCareer from "../../assets/images/svg/eiffeltower.svg";
import logo from "../../assets/images/logo-white.svg";
import ImgWhychooseus from "../../assets/images/Whychooseus.jpg";
import foreignWeFacilytate from "../../assets/images/foreign-we-facility.jpg";
import ImgFooterBanner from "../../assets/images/new-york-thankyoufamily.jpg";


class ForeignLanguageTeacher extends Component {

  constructor(props) {
    super(props);
    this.state = {
      city: ''
    };
  }

  componentDidMount() {
    M.Parallax.init(this.Parallax1);
    M.Parallax.init(this.Parallax2);
    M.Parallax.init(this.Parallax3);
    M.Parallax.init(this.Parallax4);
    let [root, cityName, skillName] = this.props.match.url.split('/');
    cityName = startCase(unsimplify(cityName));
    this.setState({
      city: cityName
    })
  }

  goBack = () => {
    history.push('/our-nannies');
  };

  handleOnSearch = () => {
    const { isLoggedIn } = this.props.user;
    history.push(isLoggedIn ? '/family/preferences' : '/family/registration');
  };

  render() {
    const { isLoggedIn } = this.props.user;
    const { skills } = this.props;
    const { city } = this.state;
    return (
      <div className="page-content skill-detail-page">
        <SectionBanner para1={Parallax => {
          this.Parallax1 = Parallax;
        }} onSearchClick={this.handleOnSearch} city={city} />
        <section className="section">
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-12 col-lg-6 col-xl-6 wow fadeIn order-2 order-lg-1" data-wow-delay=".8s">
                <div className="row">
                  <div className="col-12 col-md-10 col-xl-9 offset-xl-2 col-xll-8 offset-xll-3">
                    <div className="incredibly-content pl-xl-4">
                      <p className="sub-heading">Qualified Language Teachers. <br /> Experiences Nannies.</p>
                      <div className="pr-xl-5 mr-xl-5">
                        <p>All our language teacher nannies are completely proficient (reading, writing and speaking) in
                          their foreign language - with some having obtained an associate’s or bachelor's degree in a
                          foreign language, and for others it’s their first language.</p>
                        <p>Our nannies also are CPR and first aid trained with a minimum of 3 years professional nanny
                          experience for high profile families.</p>
                        <button className="btn btn-outline-primary btn-lg text-uppercase btn-250 mt-4"
                          onClick={this.handleOnSearch}>GET STARTED
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-6 col-xl-6 px-md-0 wow fadeInLeft  order-1 order-lg-2" data-wow-delay=".5s">
                <img src={ImgForeignLanguageTeacher} className="w-100 img-fluid float-right mb-lg-0 mb-4" alt="" />
              </div>
            </div>
          </div>
        </section>
        <SectionSkillFeatures />
        <section className="section section-skills pt-0">
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-12 col-lg-6 col-xl-6 px-md-0 wow fadeInLeft" data-wow-delay=".5s">
                <img src={ImgAofLRL} className="w-100 img-fluid float-right mb-4 mb-lg-0" alt="" />
              </div>
              <div className="col-12 col-lg-6 col-xl-6 wow fadeIn" data-wow-delay=".8s">
                <div className="row">
                  <div className="col-12 col-xll-6 offset-xll-2 col-lg-11 offset-lg-1 pl-xll-0">
                    <div className="skill-content pt-0 pt-5 py-md-5">
                      <h2 className="heading tt-none mb-4 d-none d-lg-block">Advantages of Learning a Foreign
                        Language</h2>
                      <p>
                        The many cognitive benefits of learning languages are undeniable. Children who speak more than
                        one language have improved memory, problem-solving and critical-thinking skills, enhanced
                        concentration, ability to multitask, and better listening skills.
                      </p>
                      <p>
                        Studies have proven that the receptive window for children to absorb a second language is
                        greatest between the ages of 8 months and 4 years, closing around the age of puberty (that’s not
                        to say you can’t learn a second language in your adolescence, it’s just a lot more difficult).
                      </p>
                      <p>Most families that employ a Foreign Language Teacher + Nanny, request that the nanny speaks to
                        the child solely in the second language. Structured daily lessons will also be incorporated into
                        the day where there is a strong focus on grammar, sentence structure and vocabulary.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <SectionThoroughBackground para1={Parallax => {
          this.Parallax2 = Parallax;
        }} />
        <SectionChineseTeacher />
        <SectionJapaneseTeacher />
        <SectionSpanishTeacher />
        <SectionFrenchTeacher />
        <SectionItalianTeacher />
        <SectionRussianTeacher />
        <SectionPortugueseTeacher />
        <SectionHindiTeacher />
        <SectionArabicTeacher />
        <SectionWhyChooseUs para2={Parallax => {
          this.Parallax3 = Parallax;
        }} />
        <SectionWeFacility onSearchClick={this.handleOnSearch} />
        <SectionWeNannyQualifications skills={skills} />
        <SectionFooter para3={Parallax => {
          this.Parallax4 = Parallax;
        }} onSearchClick={this.handleOnSearch} />
      </div>
    )
  }
}


const SectionBanner = ({ para1, onSearchClick, city }) => (
  <section className="parallax-container section">
    <div ref={para1} className="parallax">
      <img src={ImgCityBanner} />
    </div>
    <div className="container">
      <div className="row align-items-center wow fadeInUp">
        <div className="col-12 col-lg-10 col-xl-8 col-xll-8 mx-auto">
          <div className="banner-caption">
            <div className="text-white text-center">
              <img src={logo} className="img-fluid mb-4 mb-lg-5" alt="Footer Logo" width="256" height="72" />
              <h2 className="heading text-white mb-3 text-uppercase">FOREIGN LANGUAGE TEACHERS</h2>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-10 col-xl-8 col-xll-7 mx-auto">
          <div className="banner-caption">
            <div className="text-white text-center">
              <p className={'sub-heading font-normal mb-4 px-xll-3 text-white'}>
                As a prestigious {city} based nanny agency, we specialize in career nannies {/* & sitters */} who are also
                trained
                and qualified in an additional profession to childcare.
              </p>
              <p className="mb-4 px-xll-3">Bilingual children learn faster and easier, and have better improved problem
                solving skills. They are more creative, which leads to more career opportunities in later life. They
                find it easier to connect with other cultures, making them more open-minded and tolerant of diversity.
                It’s much easier to learn a second language at an early age - get started now!</p>
              <button className="btn btn-outline-primary btn-lg text-uppercase text-white"
                onClick={onSearchClick}>search LANGUAGE TEACHER NANNIES
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

const SectionSkillFeatures = () => (
  <section className="section pt-0">
    <div className="container">
      <div className="incredibly-content text-center" data-wow-delay=".8s">
        <div className="row align-items-center">
          <div className="col-12 col-lg-6 col-xl-10 wow fadeIn mx-auto">
            <p className="sub-heading">Nannies, giving the gift of language!</p>
          </div>
          <div className="w-100"></div>
          <div className="col-12 col-lg-6 col-xl-5 my-4 wow fadeIn mx-auto">
            <div className="px-xl-5">
              <p>Your nanny can speak to your child solely in a second language, which bring many benefits to your
                childs learning and development.</p>
            </div>
          </div>
          <div className="col-12 col-lg-11 mx-auto pt-4">
            <div className="row no-gutters">
              <div className="col-12 col-md-6 col-lg-3">
                <div className="sec-cheflist p-4">
                  <img src={SvgImprovedMemory} className="img-fluid" alt="" />
                  <span className="cheflist-text">Improved Memory</span>
                  <p>Research shows that a child who is bilingual has improved memory and executive function - larger
                    brains!</p>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-3">
                <div className="sec-cheflist p-4">
                  <img src={SvgProblemSolving} className="img-fluid" alt="" />
                  <span className="cheflist-text">Problem Solving</span>
                  <p>Children who learn a second language can grow up to be expert problem-solvers and creative
                    thinkers.</p>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-3">
                <div className="sec-cheflist p-4">
                  <img src={SvgDivergentThinking} className="img-fluid" alt="" />
                  <span className="cheflist-text">Divergent Thinking</span>
                  <p>Bilingual children see the world through different lenses, allowing them to think more
                    creatively.</p>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-3">
                <div className="sec-cheflist p-4">
                  <img src={SvgCareer} className="img-fluid" alt="" />
                  <span className="cheflist-text">Career & Culture</span>
                  <p>Cross-cultural friendships, broader career opportunities, and deeper insights into how others see
                    the world.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

const SectionThoroughBackground = ({ para1 }) => (
  <section className="parallax-container section">
    <div ref={para1} className="parallax">
      <img src={ImgForeignLanguageTeacher1} />
    </div>
    <div className="container">
      <div className="box">
        <div className="row align-items-center wow fadeInUp">
          <div className="col-12 col-lg-10 col-xl-9 col-xll-9 mx-auto">
            <div className="banner-text text-center px-xll-4 py-5">
              <p className="text-white sub-heading-italic"> A Foreign Language Teacher will transport your child to a
                far-off land without them needing to leave the country. Each day, your child will be immersed in
                language and culture that is unknown to most. </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

const SectionChineseTeacher = () => (
  <Fragment>
    <section className="section pb-0">
      <div className="container px-xll-0">
        <img src={ChineseTeacherBanner} alt="" className="img-fluid" />
      </div>
    </section>
    <section className="section section-teacher">
      <div className="container px-xll-0">
        <div className="row">
          <div className="col-12 col-lg-7 col-xll-7">
            <div className="wow fadeIn" data-wow-delay=".8s">
              <h2 className="heading mb-4 mt-0 mt-lg-4">CHINESE MANDARIN LANGUAGE TEACHER</h2>
              <h3 className="sub-heading mb-4">It’s common knowledge that China is currently the fastest growing
                economy, leading the way in business and science in many industries.</h3>
              <p>There are ample benefits for your child to be able to fluently speak Chinese Mandarin, both now and
                later in life as an adult. </p>
              <p>Firstly, the difficulty in learning the language helps substantially improve their learning ability,
                cognitive skills, maths skills and more. Learning tonal languages, such as Mandarin, is easier for young
                children, who are sensitive to the differences in sounds. Studies show, that the younger a child begins
                learning, the more accurately they can replicate these sounds, which can also actually helps cultivate
                musical ability in children. Furthermore, with China being such a strong nation, giving your child the
                gift of speaking Mandarin could lead to both business and job opportunities in their later life. </p>
              <Link to={'/nanny-services/chinese-mandarin-teacher'}
                className="btn btn-lg btn-skill btn-outline-primary mt-2">
                LEARN MORE
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Fragment>
);

const SectionJapaneseTeacher = () => (
  <section className="section section-teacher bg-light-5">
    <div className="container-fluid">
      <div className="row align-items-center">
        <div className="col-12 col-lg-6 col-xl-6 px-md-0 wow fadeInLeft" data-wow-delay=".5s">
          <img src={JapaneseTeacherBanner} className="w-100 img-fluid float-right mb-4 mb-lg-0" alt="" />
        </div>
        <div className="col-12 col-lg-6 col-xl-6 wow fadeIn" data-wow-delay=".8s">
          <div className="row">
            <div className="col-12 col-xll-7 offset-xll-2 col-lg-11 offset-lg-1 pl-xll-0">
              <h3 className="heading mb-4 pt-lg-4 pt-0">Japanese Language Teacher</h3>
              <p className="sub-heading">Japanese linguist Haruhiko Kindaichi believes that a country's national
                character is reflected in their language.</p>
              <p>For the Japanese, they try to avoid speaking directly, and there are very few offensive words that
                exist in the language. It's because they care for and are sensitive to other people's feelings.</p>
              <p>With Japan being the third largest economy in the world, after United States and China, and an industry
                leader in the technology field, especially robotics, learning Japanese will be very advantageous for
                your child’s future. </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

const SectionSpanishTeacher = () => (
  <section className="section">
    <div className="container-fluid">
      <div className="row align-items-center">
        <div className="col-12 col-lg-6 col-xl-6 wow fadeIn order-2 order-lg-1" data-wow-delay=".8s">
          <div className="row">
            <div className="col-12 col-md-10 col-xl-9 offset-xl-2 col-xll-7 offset-xll-3">
              <div className="pl-xl-2 pl-xll-4">
                <h3 className="heading mb-4 pt-lg-4 pt-0">Spanish Language Teacher</h3>
                <p className="sub-heading">With almost 20% of the world population, being almost 450 million people,
                  with Spanish as their first language, it’s an obvious choice for a second language for any child to
                  learn.</p>
                <p>It’s currently the second most spoken language in the world, and the official language of 21
                  countries. Spanish is one of the six official languages of the United Nations, and the third most used
                  language in the media, therefore learning Spanish will make your child not only more employable as an
                  adult, but also give them an advantage for world travel.</p>
                <p>Learning a second language, like Spanish, won’t only increase your child’s ability to focus, but it
                  will also help them understand cultures that are different to their own. According to NPR, “bilingual
                  children as young as age 3 have demonstrated a head start on tests of perspective-taking and theory of
                  mind — both of which are fundamental social and emotional skills.”</p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-6 col-xl-6 px-md-0 wow fadeInLeft  order-1 order-lg-2" data-wow-delay=".5s">
          <img src={SpanishTeacherBanner} className="w-100 img-fluid float-right mb-lg-0 mb-4" alt="" />
        </div>
      </div>
    </div>
  </section>
);

const SectionFrenchTeacher = () => (
  <section className="section section-teacher bg-light-5">
    <div className="container-fluid">
      <div className="row align-items-center">
        <div className="col-12 col-lg-6 col-xl-6 px-md-0 wow fadeInLeft" data-wow-delay=".5s">
          <img src={FrenchTeacherBanner} className="w-100 img-fluid float-right mb-4 mb-lg-0" alt="" />
        </div>
        <div className="col-12 col-lg-6 col-xl-6 wow fadeIn" data-wow-delay=".8s">
          <div className="row">
            <div className="col-12 col-xll-7 offset-xll-2 col-lg-11 offset-lg-1 pl-xll-0">
              <h3 className="heading mb-4 pt-lg-4 pt-0">French Language Teacher</h3>
              <p className="sub-heading">The language of love and reason, the language of culture, a language for
                travel, and a language for higher education - give your child the pleasure of learning a beautiful,
                rich, melodious language.</p>
              <p>Not only is French a beautiful sounding language, but it is a very analytical language that structures
                thought and develops critical thinking, which is a valuable skill for discussions and negotiations.</p>
              <p>More than 300 million people, across five continents, have French as their first language. French is
                the only language, alongside English, that is taught in every country of the world. And being the second
                most widely learned foreign language after English, and in the top ten most widely spoken globally, it
                would be a perfect additional language for your child to learn.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);
const SectionItalianTeacher = () => (
  <Fragment>
    <section className="section-teacher">
      <div className="container px-xll-0">
        <img src={ItalianTeacherBanner} alt="" className="img-fluid" />
      </div>
    </section>
    <section className="section pt-0">
      <div className="container px-xll-0">
        <div className="wow fadeIn" data-wow-delay=".8s">
          <div className="row">
            <div className="col-12 col-lg-8 col-xll-8">
              <h2 className="heading mb-4">Italian Language Teacher</h2>
            </div>
            <div className="col-12 col-lg-7 col-xll-7">
              <h3 className="sub-heading mb-4">The Italian language is spoken in 14 countries, with over 65 million
                people speaking it as their first language.</h3>
              <p>Beyond Italian cuisine (which we all know and love), there are many benefits for your child to learn
                the italian language. Italy is known for space engineering, ship building, robotics, manufacture of
                machine tools, transportation equipment, machinery for construction, electromechanical machinery,
                furniture design, interior design and of course, culinary arts - not to mention beautiful sports
                cars!</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Fragment>
);

const SectionRussianTeacher = () => (
  <section className="section bg-light-5 section-teacher">
    <div className="container-fluid">
      <div className="row align-items-center">
        <div className="col-12 col-lg-6 col-xl-6 wow fadeIn order-2 order-lg-1" data-wow-delay=".8s">
          <div className="row">
            <div className="col-12 col-md-10 col-xl-9 offset-xl-2 col-xll-7 offset-xll-3">
              <div className="pl-xl-2 pl-xll-4">
                <h3 className="heading mb-4 pt-lg-4 pt-0">Russian Language Teacher</h3>
                <p className="sub-heading">From the start of the new millennium, Russia has constantly been in the
                  process of positioning itself as a major economic force across a vast array of industries. </p>
                <p>With a growing economy showing no signs of slowing down, having your child learn Russian as their
                  second language could help align them for some very exciting and beneficial future opportunities.</p>
                <p>Russia, known mostly for The Red Army Choir, the Red Square, its vodka, its incomprehensible alphabet
                  and its beautiful looking people; it’s also a culture rich in literature, language and music. With
                  over 260 million people world-wide speaking Russian as their first language, it’s definitely a smart
                  choice for your child to have the ability to both write and speak.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-6 col-xl-6 px-md-0 wow fadeInLeft order-1 order-lg-2" data-wow-delay=".5s">
          <img src={RussianTeacherBanner} className="w-100 img-fluid float-right mb-4 mb-lg-0" alt="" />
        </div>
      </div>
    </div>
  </section>
);

const SectionPortugueseTeacher = () => (
  <section className="section">
    <div className="container-fluid">
      <div className="row align-items-center">
        <div className="col-12 col-lg-6 col-xl-6 px-md-0 wow fadeInLeft" data-wow-delay=".5s">
          <img src={PortugueseTeacherBanner} className="w-100 img-fluid float-right mb-4 mb-lg-0" alt="" />
        </div>
        <div className="col-12 col-lg-6 col-xl-6 wow fadeIn" data-wow-delay=".8s">
          <div className="row">
            <div className="col-12 col-xll-7 offset-xll-2 col-lg-11 offset-lg-1 pl-xll-0">
              <h3 className="heading mb-4 pt-lg-4 pt-0">Portuguese Language Teacher</h3>
              <p className="sub-heading">Portuguese is one of the so called romance family of languages, alongside
                Spanish, French and Italian.</p>
              <p>Portuguese is a beautiful language spoken by an array of countries with rich cultures, and is not only
                spoken in Portugal and Brazil, but also parts of Europe, Africa and Asia.</p>
              <p>With the looming economic rise of Brazil, being the largest Portuguese speaking nation on the planet,
                the country is already the world’s 8th largest economy. Brazil is a country with an enormous cache of
                natural resources, and a history of economic growth. With investor friendly policies between the USA and
                Brazil, giving your child the advantage of speaking this romantic language </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

const SectionHindiTeacher = () => (
  <section className="section bg-light-5 section-teacher">
    <div className="container-fluid">
      <div className="row align-items-center">
        <div className="col-12 col-lg-6 col-xl-6 wow fadeIn order-2 order-lg-1" data-wow-delay=".8s">
          <div className="row">
            <div className="col-12 col-md-10 col-xl-9 offset-xl-2 col-xll-7 offset-xll-3">
              <div className="pl-xl-2 pl-xll-4">
                <h3 className="heading mb-4 pt-lg-4 pt-0">Hindi Language Teacher</h3>
                <p className="sub-heading">With India growing quickly as a global economy, and being the fifth most
                  spoken language in the world, it is commonly associated with being the most important language to
                  speak in South-East Asia.</p>
                <p>Science shows that more parts of the brain are exercised when your child learns Hindi compared to
                  English. This is contributed to the way the Devanagari script is written, from its left to right
                  directional layout with the vowels positioned on top, below or on both sides of the consonants - what
                  this does is activate both the left and right hemispheres of the brain equally when reading the
                  syllabic script. For your child, this has huge advantages for brain growth and learning ability.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-6 col-xl-6 px-md-0 wow fadeInLeft order-1 order-lg-2" data-wow-delay=".5s">
          <img src={HindiTeacherBanner} className="w-100 img-fluid float-right mb-4 mb-lg-0" alt="" />
        </div>
      </div>
    </div>
  </section>
);

const SectionArabicTeacher = () => (
  <section className="section">
    <div className="container-fluid">
      <div className="row align-items-center">
        <div className="col-12 col-lg-6 col-xl-6 px-md-0 wow fadeInLeft" data-wow-delay=".5s">
          <img src={ArabicTeacherBanner} className="w-100 img-fluid float-right mb-4 mb-lg-0" alt="" />
        </div>
        <div className="col-12 col-lg-6 col-xl-6 wow fadeIn" data-wow-delay=".8s">
          <div className="row">
            <div className="col-12 col-xll-7 offset-xll-2 col-lg-11 offset-lg-1 pl-xll-0">
              <h3 className="heading mb-4 pt-lg-4 pt-0">Arabic Language Teacher</h3>
              <p className="sub-heading">Arabic is the fourth most spoken language in the world, with over 315 million
                people across 58 countries speaking Arabic as their first language.</p>
              <p>Learning a language is learning about the culture of the country where the language is spoken, and the
                Arab culture is both rich and interesting. With the demand for people who speak Arabic being quite high,
                and only a few people from the West attempting to learn the language, your child will have that
                advantage in later life.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

const SectionWhyChooseUs = ({ para2 }) => (
  <section className="parallax-container section section-padding section-bg">
    <div ref={para2} className="parallax">
      <img src={ImgWhychooseus} />
    </div>
    <div className="container-fluid">
      <div className="row align-items-center">
        <div className="col-12 col-lg-6 col-xl-6 wow fadeIn order-2 order-lg-1" data-wow-delay=".8s">
          <div className="row">
            <div className="col-12 col-md-10 col-xl-9 offset-xl-2 col-xll-8 offset-xll-3">
              <div className="incredibly-content pl-lg-4 pr-lg-5">
                <p className="sub-heading">Why choose us?</p>
                <p className="become-info">
                  Elite Nannies are professional nannies who are also skilled in other areas. These include Teachers,
                  Nurses, Foreign Language Teachers, Massage Therapists, Personal Trainers, Hair Stylists, Beauty
                  Therapists, Photographers and more.
                </p>
                <p className="become-info">
                  Our candidates understand the importance of not only keeping your children safe and making them feel
                  loved, but helping cultivate them into inspiring, intelligent and creative little people.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

const SectionWeFacility = ({ onSearchClick }) => (
  <section className="section">
    <div className="container-fluid">
      <div className="row align-items-center">
        <div className="col-12 col-lg-6 col-xl-6 px-md-0 wow fadeInLeft" data-wow-delay=".5s">
          <img src={foreignWeFacilytate} className="w-100 img-fluid float-right mb-4 mb-lg-0" alt="" />
        </div>
        <div className="col-12 col-lg-6 col-xl-6 wow fadeIn" data-wow-delay=".8s">
          <div className="row">
            <div className="col-12 col-xll-6 offset-xll-2 col-lg-11 offset-lg-1 pl-xll-0">
              <div className="incredibly-content">
                <p className="sub-heading">We facilitate the entire process, so you can have peace of mind.</p>
                <p className="mt-4">
                  Not only do we match your unique family with an elite nanny, but once you have made your hire, we can
                  also assist you with tax and benefit information, so you can worry about the important things!
                </p>
                <button className="btn btn-outline-primary btn-lg text-uppercase btn-250 mt-4"
                  onClick={onSearchClick}>GET STARTED
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

const SectionWeNannyQualifications = ({ skills }) => (
  <Fragment>
    <section className="section wow fadeInUp pt-0 pb-0">
      <div className="container px-xll-0">
        <div className="row">
          <div className="col-12 col-xl-7 col-xll-6 mx-auto">
            <div className="text-center wow fadeInUp incredibly-content mb-5">
              <h2 className="sub-heading mb-4">Other Nanny Qualifications</h2>
              <p className="px-xl-4 pl-xll-2">We only recruit honourable, educated and refined nannies that help families
                live an exceptional life. All of our nannies are CPR and First Aid trained and have a minimum of 3 years
              professional {/*nanny/sitter*/} nanny experience. They are incredibly well-versed in child-related safety
                measures -
                we want you to be able to leave your house each day with complete ease knowing your children are in the
              safest possible hands.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="section wow fadeInUp skills-slider pt-0 pb-0">
      <LandingSlickCarousel skills={skills} />
    </section>
    <section className="section wow fadeInUp pt-0">
      <div className="container">
        <Testimonial />
      </div>
    </section>
  </Fragment>
);

const SectionFooter = ({ para3, onSearchClick }) => (
  <section className="parallax-container section mb-5 section-padding">
    <div ref={para3} className="parallax">
      <img src={ImgFooterBanner} />
    </div>
    <div className="container">
      <div className="row align-items-center wow fadeInUp">
        <div className="col-12 col-lg-10 col-xl-8 col-xll-8 mx-auto">
          <div className="banner-caption incredibly-content">
            <div className="text-white text-center">
              <h2 className="sub-heading text-white mb-5 text-uppercase">ONE OF THE GREATEST GIFTS OF ALL - THE GIFT OF
                LANGUAGE</h2>
              <button className="btn btn-outline-primary btn-lg text-uppercase text-white btn-250"
                onClick={onSearchClick}>GET STARTED
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);


const MapStateToProps = (state) => {
  return {
    user: state.user,
    skills: state.skills,
  }
};

export default connect(MapStateToProps, null)(ForeignLanguageTeacher);
