import React, {Component} from 'react';
import history from '../../helpers/history';

class RequestNowComponent extends Component {
  render() {
    return(
        <div className="page-content page-auth">
          <div className="container">
            <section className="section bg-auth">
              <div className="row">
                <div className="col-12 col-lg-8 col-xl-7 col-xll-6 mx-auto wow fadeInUp">
                  <div className="text-center">
                    <h2 className="heading">Request a nanny or sitter now?</h2>
                  </div>
                  <div className="mt-5 request-buttons text-center">
                    <button className="btn btn-dark" onClick={() => history.push('/family/looking-for')}>
                      Yes, request now
                    </button>
                    <button className="btn btn-outline-primary ml-3" onClick={() => history.push('/')}>
                      No, do it later
                    </button>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
    )
  }
}

export default RequestNowComponent;