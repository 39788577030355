import React, { Component, Fragment } from 'react';
import { Link, NavLink, withRouter } from "react-router-dom";
import { isEmpty } from 'lodash';
import { Accordion } from 'semantic-ui-react';
import { connect } from "react-redux";
import history from '../helpers/history';
import { LOGOUT } from "../redux/user/UserActions";
import config from '../config';
import { stringToSlug } from "../helpers/helper";
import toast from '../Toast';
// import close from '../../src/assets/images/svg/close.svg';
import { GET } from "../services/rest.service";
import { HIDE_LOADING, SHOW_LOADING } from "../redux/loader/LoaderActions";

const menuItems = [
  { route: 'account', label: 'Profile' },
  // {route: 'update', label: 'Edit Profile'}
];
const LoggedOutMenu = (props) => (
  <ul className="nav ml-auto flexbox-c">
    <li className="list-inline-item">
      <Link to={'/sign-in'} className="nav-link text-primary text-uppercase">Sign In</Link>
    </li>
    <li>
      <Link to={'/get-started'} className="btn btn-outline-primary text-uppercase">Get Started</Link>
    </li>
  </ul>
);
const LoggedInMenu = (props) => (
  <ul className="nav ml-auto flexbox-c">
    {
      props.type == 'Family' && <li className="list-inline-item">
        <Link to={'/family/preferences?utmSrc=hdr'} className="btn btn-outline-primary btn-sm text-uppercase">request a
          nanny</Link>
      </li>
    }
    {
      props.sitter && props.type == 'Family' && <li className="list-inline-item">
        <a href="javascript:void(0);" onClick={props.onRequestSitter}
          className="btn btn-outline-primary btn-sm text-uppercase">book a sitter</a>
      </li>
    }
    <li className="list-inline-item shortcut-menu">
      <div className={"dropdown " + (props.dropDownMenu ? 'show' : '')}>
        <button className="btn btn-clear dropdown-toggle text-primary pl-xl-0" type="button"
          onClick={props.onDropdownClick}>
          {props.user.first_name} {props.user.last_name}
        </button>
        <div className={"dropdown-menu dropdown-menu-right " + (props.dropDownMenu ? 'show' : '')}>
          {
            menuItems.map((x, index) => {
              return <button key={index}
                className="btn dropdown-item btn-open-sidebar"
                onClick={(e) => props.onMenuItemClick(x.route)}>{x.label}</button>
            })
          }
          <button className="btn dropdown-item btn-open-sidebar" onClick={props.onLogout}>Logout</button>
        </div>
      </div>
    </li>
  </ul>
);

class HeaderComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showMenu: false,
      dropDownMenu: false,
      activeIndex: -1,
      sitter:null,
      topNanniesFlag:null
    };
  }
  componentWillMount(){
    GET('/admin/appConfiguration')
    .then(({data})=>{
      this.setState({
        sitter:data.configuration.sitter,
        topNanniesFlag:data.configuration.topNannies

      })
    })
    .catch(err => {
      toast.error(err.message);
    });
  }

  handleMenuToggle = () => this.setState({ showMenu: !this.state.showMenu });

  handleDropDownToggle = () => this.setState({ dropDownMenu: !this.state.dropDownMenu });

  handleMenuClick = (value) => {
    this.handleDropDownToggle();
    const { type } = this.props.user;
    if (type) {
      //history.push(`/${data.type == 'Family' ? 'family' : 'nanny'}/account`);
      this.props.history.push(`/${type == 'Family' ? 'family' : 'nanny'}/${value.toLowerCase()}`);
    }
  };

  handleRequest = () => {
    const { type, user } = this.props.user;

    if (user.payment_plan_id && !isEmpty(user.payment_plan_id)) {
      history.push('/family/request-sitter');
    } else {
      localStorage.setItem('email', user.email);
      history.push('/family/membership');
    }
  };

  handleAccordianClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex })
  };

  handleLogout = () => {
    this.handleDropDownToggle();
    localStorage.removeItem('token');
    this.props.history.replace('/');
    this.props.doLogout();
  };

  resendConfirmationMail = () => {
    this.props.showLoading();
    const { user } = this.props.user;
    GET(`/auth/user/resend/${user._id}/email-confirmation`)
      .then(res => {
        toast.success('Resending mail is successful. Please check you inbox and follow the mentioned steps.');
        this.props.hideLoading();
      })
      .catch(err => {
        this.props.hideLoading();
        toast.error(err.message);
      })
  };

  render() {
    const { isLoggedIn, type, user } = this.props.user;
    const {sitter,topNanniesFlag} =this.state;

    return (
      <div>
        <header className="header fixed-top">
          <nav className="nav">
            <div className="navbar-logo d-none d-md-block">
              <a onClick={() => history.push('/')} className="navbar-brand">
                <span className="brand-logo mobile"></span>
              </a>
            </div>
            <div className="navbar-logo d-block d-md-none">
              <a onClick={() => {
                history.push('/');
                if (this.state.showMenu) {
                  this.setState({ showMenu: false })
                }
              }} className="navbar-brand">
                <span className="brand-logo"></span>
              </a>
            </div>
            <ul className="nav navbar-left navbar-menu flexbox-c">
              <li className="list-inline-item sub-menu-item">
                <NavLink to={'/our-nannies'} className="nav-link">Our Nannies</NavLink>
                <ul className="sub-menu list-unstyled">
                  <li>
                    <div className="sub-menu-container">
                      <div className="row">
                        <div className="col-12">
                          <h5 className="submenu-heading">ELITE SKILLS</h5>
                          <div className="row submenu-list">
                            {
                              this.props.skills.map((skill, index) => {
                                return <div key={index} className="col-xl-4 px-0">
                                  <div className="submenu-item">
                                    <NavLink className="btn btn-clear w-100 text-left" to={`/nanny-services/${stringToSlug(skill.title)}`}>
                                      <img src={`${config.api.URL}${skill.icon.name}`} alt="" />
                                      {skill.title}
                                    </NavLink>
                                  </div>
                                </div>
                              })
                            }
                          </div>
                          {/*<div className="row submenu-list">*/}
                          {/*<div className="col-4">*/}
                          {/*<div className="submenu-item">*/}
                          {/*<Link to={'/search/nannies'} className="btn btn-outline-primary btn-block px-3">*/}
                          {/*BROWSE OUR NANNIES*/}
                          {/*</Link>*/}
                          {/*</div>*/}
                          {/*</div>*/}
                          {/*</div>*/}

                          <div className="row submenu-list text-uppercase">
                            {topNanniesFlag &&<div className="col-xl-3 pr-0">
                              <div className="submenu-item">
                                <Link to={'/search/nannies'} className="btn btn-outline-primary btn-block px-3">
                                  Browse Nannies
                                </Link>
                              </div>
                            </div>}
                            {sitter &&<div className="col-xl-3 pr-0">
                              <div className="submenu-item">
                                <Link to={'/search/sitters'} className="btn btn-outline-primary btn-block px-3">
                                Browse Sitters
                                </Link>
                              </div>
                            </div>}
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </li>
              <li className="list-inline-item sub-menu-item">
                <span className="nav-link c-pointer">FOR FAMILIES</span>
                <ul className="sub-menu list-unstyled">
                  <li>
                    <div className="sub-menu-container">
                      <div className="row">
                        <div className="col-12">
                          <h5 className="submenu-heading">FOR FAMILIES</h5>
                          <div className="row submenu-list">
                            <div className="col-xl-4 px-0">
                              <div className="submenu-item">
                                <Link to={'/family/nanny-pricing'} className="btn btn-clear w-100 text-left">
                                  Nanny Placement Package Fee
                                </Link>
                              </div>
                            </div>
                           {sitter&& <div className="col-xl-4 px-0">
                              <div className="submenu-item">
                                <Link to={'/family/sitter-pricing'} className="btn btn-clear w-100 text-left">
                                  Sitter Pricing Packages
                            </Link>
                              </div>
                            </div>}
                            <div className="col-xl-4 px-0">
                              <div className="submenu-item">
                                <Link to={'/family/screening-process'} className="btn btn-clear w-100 text-left">
                                  The Screening Process
                                </Link>
                              </div>
                            </div>
                            <div className="col-xl-4 px-0">
                              <div className="submenu-item">
                                <a href={'https://blog.elitenannies.com/category/family-blog/'}
                                  className="btn btn-clear w-100 text-left">
                                  Family Blog
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="row submenu-list">
                            <div className="col-xl-3 pr-0">
                              <div className="submenu-item">
                                <Link to={!isLoggedIn ? '/family/registration' : '/family/preferences?utmSrc=hdr'} className="btn btn-outline-primary btn-block px-3">
                                  START YOUR SEARCH
                                </Link>
                              </div>
                            </div>
                            {
                              !isLoggedIn && (
                                <div className="col-xl-3 pr-0">
                                  <div className="submenu-item">
                                    <Link to={'/family/login'} className="btn btn-outline-primary btn-block px-3">
                                      SIGN IN
                                    </Link>
                                  </div>
                                </div>
                              )
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </li>
              <li className="list-inline-item sub-menu-item">
                <span className="nav-link c-pointer">FOR NANNIES</span>
                <ul className="sub-menu list-unstyled">
                  <li>
                    <div className="sub-menu-container">
                      <div className="row">
                        <div className="col-12">
                          <h5 className="submenu-heading">FOR NANNIES</h5>
                          <div className="row submenu-list">
                            <div className="col-xl-4 px-0">
                              <div className="submenu-item">
                                <Link to={'/nanny/why-choose'} className="btn btn-clear w-100 text-left">
                                  Why Choose Elite Nannies
                                </Link>
                              </div>
                            </div>
                            <div className="col-xl-3">
                              <div className="submenu-item">
                                <Link to={'/nanny/how-it-works'} className="btn btn-clear w-100 text-left">
                                  How It Works
                                </Link>
                              </div>
                            </div>
                            <div className="col-xl-4">
                              <div className="submenu-item">
                                <Link to={'/nanny/requirements'} className="btn btn-clear w-100 text-left">
                                  Requirements
                                </Link>
                              </div>
                            </div>
                            <div className="col-xl-4 px-0">
                              <div className="submenu-item">
                                <Link to={'/nanny/jobs'} className="btn btn-clear w-100 text-left">
                                  Jobs
                                </Link>
                              </div>
                            </div>
                            <div className="col-xl-3">
                              <div className="submenu-item">
                                <a href={'https://blog.elitenannies.com/category/nanny-blog'} className="btn btn-clear w-100 text-left">
                                  Nanny Blog
                                </a>
                              </div>
                            </div>
                          </div>

                          {
                            !isLoggedIn && (
                              <Fragment>
                                <div className="row submenu-list">
                                  <div className="col-xl-3 pr-0">
                                    <div className="submenu-item">
                                      <Link to={'/nanny/registration'} className="btn btn-outline-primary btn-block px-3">
                                        APPLY NOW
                                      </Link>
                                    </div>
                                  </div>
                                  <div className="col-xl-3 pr-0">
                                    <div className="submenu-item">
                                      <Link to={'/nanny/login'} className="btn btn-outline-primary btn-block px-3">
                                        SIGN IN
                                      </Link>
                                    </div>
                                  </div>
                                </div>
                              </Fragment>
                            )
                          }
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </li>
              <li className="list-inline-item">
                <NavLink to={'/about-us'} className="nav-link">ABOUT US</NavLink>
              </li>
              <li className="list-inline-item">
                <NavLink to={'/nanny/jobs'} className="nav-link">JOBS</NavLink>
              </li>
              <li className="list-inline-item">
                <a href={'https://blog.elitenannies.com'} className="nav-link">BLOG</a>
              </li>
              <li className="list-inline-item">
                <NavLink to={'/contact-us'} className="nav-link">CONTACT</NavLink>
              </li>
              <li className="list-inline-item"></li>
            </ul>
            {
              isLoggedIn
                ? <LoggedInMenu
                sitter={sitter}
                  showMenu={this.state.showMenu}
                  type={type}
                  user={user}
                  dropDownMenu={this.state.dropDownMenu}
                  onLogout={this.handleLogout}
                  onDropdownClick={this.handleDropDownToggle}
                  onMenuItemClick={(val) => this.handleMenuClick(val)}
                  onRequestSitter={this.handleRequest}
                  onBugerClick={this.handleMenuToggle} />
                : <LoggedOutMenu showMenu={this.state.showMenu} onBugerClick={this.handleMenuToggle} />
            }
            <button
              className={"btn btn-clear px-0 hamburger hamburger--squeeze " + (this.state.showMenu ? 'is-active' : '')}
              onClick={this.handleMenuToggle}>
              <div className="hamburger-box">
                <div className="hamburger-inner"></div>
              </div>
            </button>
          </nav>
        </header>
        <section className={"sidebar-nav " + (this.state.showMenu ? 'visible' : '')}>
          <Accordion>
            {isLoggedIn &&
              <div>
                <Accordion.Title active={this.state.activeIndex === 4} index={4} onClick={this.handleAccordianClick}>
                  <span className="text-primary">{user.first_name} {user.last_name}</span>
                </Accordion.Title>
                <Accordion.Content active={this.state.activeIndex === 4}>
                  <div>
                    <p>
                      <a href="javascript:void(0);" onClick={() => {
                        history.push(`/${type == 'Family' ? 'family' : 'nanny'}/account`);
                        this.handleMenuToggle();
                      }}>Profile</a>
                    </p>
                    <p><a onClick={this.handleLogout} href="javascript:void(0);">Logout</a></p>
                  </div>
                </Accordion.Content></div>}
            <Accordion.Title active={this.state.activeIndex === 0} index={0} onClick={this.handleAccordianClick}>
              Our Nannies
            </Accordion.Title>
            <Accordion.Content active={this.state.activeIndex === 0}>
              <div>
                {
                  this.props.skills.map((skill, index) => {
                    return <p key={index} className="">
                      <a onClick={() => {
                        history.push(`/nanny-services/${stringToSlug(skill.title)}`);
                        this.handleMenuToggle();
                      }
                      }>{skill.title}</a>
                    </p>
                  })
                }
                {/* <p className="before-none">*/}
                {/*<a onClick={() => {*/}
                {/*history.push(`/search/nannies`);*/}
                {/*this.handleMenuToggle();*/}
                {/*}} className="btn btn-outline-primary btn-block px-3">*/}
                {/*BROWSE OUR NANNIES*/}
                {/*</a>*/}
                {/*</p> */}

                <span className={'d-flex justify-content-between '}>
                  {topNanniesFlag &&<a onClick={() => {
                    history.push('/search/nannies');
                    this.handleMenuToggle();
                  }} className="btn btn-outline-primary mt-2 btn-block text-uppercase ml-2">Browse Nannies</a>}

                 {sitter&& <a onClick={() => {
                    history.push('/search/sitters');
                    this.handleMenuToggle();
                  }} className="btn btn-outline-primary mt-2 ml-4 btn-block text-uppercase mr-2">Browse Sitters</a>}

                </span>
              </div>
            </Accordion.Content>
            <Accordion.Title active={this.state.activeIndex === 1} index={1} onClick={this.handleAccordianClick}>
              For Families
            </Accordion.Title>
            <Accordion.Content active={this.state.activeIndex === 1}>
              <div>
                <p>
                  <a onClick={() => {
                    history.push('/family/nanny-pricing');
                    this.handleMenuToggle();
                  }}>
                    Nanny Placement Package Fee
                  </a>
                </p>
               {sitter&& <p>
                  <a onClick={() => {
                    history.push('/family/sitter-pricing');
                    this.handleMenuToggle();
                  }}>
                    Sitter Pricing Packages
                </a>
                </p>}
                <p>
                  <a onClick={() => {
                    history.push('/family/screening-process');
                    this.handleMenuToggle();
                  }}>
                    The Screening Process
                  </a>
                </p>
                <p>
                  <a href="https://blog.elitenannies.com/category/family-blog/">
                    Family Blog
                  </a>
                </p>
                <span className={'d-flex justify-content-between'}>
                  <a onClick={() => {
                    history.push(!isLoggedIn ? '/family/registration' : '/family/preferences?utmSrc=hdr');
                    this.handleMenuToggle();
                  }} className="btn btn-outline-primary mt-2 btn-block ml-2">START YOUR SEARCH</a>
                  {
                    !isLoggedIn && (
                      <a onClick={() => {
                        history.push('/family/login');
                        this.handleMenuToggle();
                      }} className="btn btn-dark mt-2 ml-4 text-white btn-block mr-2">SIGN IN</a>
                    )
                  }
                </span>
              </div>
            </Accordion.Content>
            <Accordion.Title active={this.state.activeIndex === 2} index={2} onClick={this.handleAccordianClick}>
              For Nannies
            </Accordion.Title>
            <Accordion.Content active={this.state.activeIndex === 2}>
              <div>
                <p>
                  <a onClick={() => {
                    history.push('/nanny/why-choose');
                    this.handleMenuToggle();
                  }}>
                    Why Choose Elite Nannies
                  </a>
                </p>
                <p>
                  <a onClick={() => {
                    history.push('/nanny/how-it-works');
                    this.handleMenuToggle();
                  }}>
                    How It Works
                  </a>
                </p>
                <p>
                  <a onClick={() => {
                    history.push('/nanny/requirements');
                    this.handleMenuToggle();
                  }}>
                    Requirements
                  </a>
                </p>

                <p>
                  <a onClick={() => {
                    history.push('/nanny/jobs');
                    this.handleMenuToggle();
                  }}>Jobs
                  </a>
                </p>
                <p className="mb-2">
                  <a href="https://blog.elitenannies.com/category/nanny-blog">
                    Nanny Blog
                  </a>
                </p>
                <span className={'d-flex justify-content-between mb-2'}>
                  <a onClick={() => {
                    history.push('/nanny/registration');
                    this.handleMenuToggle();
                  }} className="btn btn-block btn-outline-primary mt-0 mr-2">APPLY NOW</a>
                  <a onClick={() => {
                    history.push('/nanny/login');
                    this.handleMenuToggle();
                  }} className="btn btn-block btn-dark text-white mt-0 ml-2"> SIGN IN</a>
                </span>
              </div>
            </Accordion.Content>
          </Accordion>
          <p>
            <a onClick={() => {
              history.push('/about-us');
              this.handleMenuToggle();
            }}>About Us</a>
          </p>
          <p>
            <a onClick={() => {
              history.push('/nanny/jobs');
              this.handleMenuToggle();
            }}>Jobs</a>
          </p>
          <p>
            <a href="https://blog.elitenannies.com">Blog</a>
          </p>
          <p>
            <a onClick={() => {
              history.push('/contact-us');
              this.handleMenuToggle();
            }}>Contact</a>
          </p>
          {!isLoggedIn && (
            <Fragment>
              <p>
                <a onClick={() => {
                  history.push('/sign-in');
                  this.handleMenuToggle();
                }} className="btn btn-outline-primary mt-2">Sign In</a>
                <a onClick={() => {
                  history.push('/get-started');
                  this.handleMenuToggle();
                }} className="btn btn-dark mt-2 ml-4 text-white">Get Started</a>
              </p>
            </Fragment>
          )
          }
          {
            isLoggedIn && type == 'Family' &&
            (
              <Fragment>
                <p>
                  <a onClick={() => {
                    history.push('/family/preferences?utmSrc=hdr');
                    this.handleMenuToggle();
                  }} className="btn btn-outline-primary mt-2 px-3 text-uppercase">Request a Nanny</a>
                {sitter&&  <a onClick={() => {
                    history.push('/family/request-sitter');
                    this.handleMenuToggle();
                  }} className="btn btn-dark mt-2 ml-4 px-3 text-white text-uppercase">Book a Sitter</a>}
                </p>
              </Fragment>
            )
          }
          <p>
          </p>

        </section>
        {
          isLoggedIn && !user.isEmailConfirmed &&
          (<div className="fixed-bottom py-3 bg-dark">
            <div className="container">
              <div className="d-flex justify-content-between align-items-start w-100">
                <p className="text-white mb-0 font-14 mr-3">Please verify your account by email otherwise your account
                will be automatically locked after 15 days from initial sign-up. If you did not receive the
                verification email please check your Spam folder.
                  <a className="text-primary font-16 ml-2" href="javascript:void(0);"
                    onClick={this.resendConfirmationMail}>Resend Verification Email</a>
                </p>
                {/*<button className="btn btn-clear p-0"><img src={close} alt="Close" /></button>*/}
              </div>
            </div>
          </div>)
        }
      </div>
    )
  }
}

const MapStateToProps = (state) => {
  return {
    user: state.user,
    skills: state.skills,
  }
};

const MapDispatchToProps = (dispatch) => {
  return {
    doLogout: () => {
      dispatch({
        type: LOGOUT,
        payload: {
          user: {},
          message: '',
          isLoggedIn: undefined,
          type: undefined
        }
      })
    },
    showLoading: (title) => {
      dispatch({
        type: SHOW_LOADING,
        payload: {
          title,
          isLoading: true
        }
      })
    },
    hideLoading: () => {
      dispatch({
        type: HIDE_LOADING,
      })
    },
  }
};

export default withRouter(connect(MapStateToProps, MapDispatchToProps)(HeaderComponent));
