import React, {Component} from 'react';
import thankYou from "../../assets/images/svg/thankyou.svg";
import history from "../../helpers/history";

class FamilyNannyAppConf extends Component {

  handleSubmit = () => history.push('/family/account');

  render() {
    return(
        <div className="page-content page-auth">
          <div className="container">
            <section className="section bg-auth">
              <div className="row">
                <div className="col-12 col-lg-8 col-xl-6 col-xll-6 mx-auto wow fadeInUp">
                  <div className="text-center">
                    <h2 className="heading">
                      THANK YOU!
                    </h2>
                    <p className="px-md-5">We have received your nanny request and will reach out to you shortly.</p>
                  </div>
                  <div className="form-group">
                    <img className="email-icon img-fluid" src={thankYou} alt="thankYou"/>
                  </div>
                  <div className="form-group text-center my-5">
                    <button className="btn btn-outline-primary mt-3" onClick={this.handleSubmit}>ok</button>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
    )
  }
}

export default FamilyNannyAppConf;
