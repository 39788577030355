import React from 'react';
import {Formik} from "formik";
import {object, string} from 'yup';
import TextInput from "../../components/UI/TextInput";

const ForgotPasswordForm = (props) => (
    <Formik
        initialValues={{email: ''}}
        onSubmit={(values) => {
          props.onSubmit(values);
        }}
        validationSchema={object().shape({
          email: string().email("Enter a valid email").required("Email is required"),
        })}>
      {renderProps => {
        const {values, touched, errors, handleChange, handleBlur, handleSubmit} = renderProps;
        return (
         <form className={'row'} onSubmit={handleSubmit}>
           <TextInput id="email"
                      type="email"
                      size="col-12"
                      label="Email"
                      placeholder="Please enter Email"
                      error={touched.email && errors.email}
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}/>
           <div className="form-group col-12 text-center my-5">
             <button type="submit" className="btn btn-outline-primary">Send Reset Mail</button>
           </div>
         </form>
        )
      }}
    </Formik>
);

export default ForgotPasswordForm;
