import history from '../../helpers/history';
import toast from '../../Toast';
import queryString from 'query-string'
import {isEmpty} from 'lodash';
/* REST */
import {GET, POST} from "../../services/rest.service";
import {setRecord} from "../../services/indexdb.service";
import {APPEND_NANNY_REGISTRATION} from "../nanny-registration/NannyRegistrationReducer";

/* ACTIONS */
export const LOGIN_SUCCESS = 'USERS_LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'USERS_LOGIN_FAILURE';
export const LOGOUT = 'USERS_LOGOUT';
export const USER = 'USER';
export const UPDATE_USER = 'UPDATE_USER';

/* LOGIN */
export const login = (payload) => {
  return dispatch => {
    POST('/auth/user/login', payload)
        .then(({access_token, data}) => {
          if (data.register_last_step > 0) {
            // setting a token for later use when the user is able to login completely
            setRecord('TT', access_token); // TT = Temporary token

            // Setting this so app would know that user is continuing the registration process which was partially completed
            setRecord('LCAR', true); // LCAR = Login Continuation After Registration

            // saving user data to use later
            setRecord('UD', data); // UD = User Data

            const step = data.register_last_step;
            toast.success('Pick up where you left of to complete your profile');
            localStorage.setItem('reg_auth', JSON.stringify({email: data.email, _id: data._id}));
            dispatch({
              type: APPEND_NANNY_REGISTRATION,
              payload: {
                first_name: data.first_name,
                last_name: data.last_name,
                email: data.email,
                location: data.location,
                mobile_number: data.mobile_number,
                _id: data._id
              }
            });
            switch (step) {
              case 1:
                history.push('/nanny/profile');
                break;
              case 2:
                history.push('/nanny/skills');
                break;
              case 3:
                history.push('/nanny/references');
                break;
              case 4:
                history.push('/nanny/authorization');
                break;
              default:
                history.push('/nanny/login');
                break;
            }
          } else {
            // writing down the token for auth communications
            localStorage.setItem('token', access_token);
            dispatch({
              type: LOGIN_SUCCESS,
              payload: {
                isLoggedIn: true,
                user: data,
                type: data.type,
                message: 'logged in successfully'
              }
            });


            const parsed = queryString.parse(window.location.search);

            if(!isEmpty(parsed) && parsed.redirectUrl) {
              history.push(parsed.redirectUrl);
            }

            if(isEmpty(parsed)) {
              history.push(`/`);
              // history.push(`/${data.type == 'Family' ? 'family' : 'nanny'}/account`);
            }
          }
        })
        .catch(err => {
          dispatch({
            type: LOGIN_FAILURE,
            payload: {
              isLoggedIn: false,
              user: {},
              type: undefined,
              message: err.message
            }
          });
          toast.error(err.message);
        });
  };
};

export const getLoggedInUser = () => {
  return dispatch => {
    const url = `/users/me`;
    GET(url)
        .then(res => {
          dispatch({
            type: USER,
            payload: {
              user: res.data,
              type: res.data.type,
            }
          });
        })
        .catch(err => {
          dispatch({
            type: LOGIN_FAILURE,
            payload: {
              user: {},
              type: undefined,
              isLoggedIn: false,
              message: err.message
            }
          });
        })
  }
};

export const getMe = (res) => {
  return {
    type: USER,
    payload: {
      user: res.data,
      type: res.data.type,
    }
  }
};
