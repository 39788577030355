import {ERROR, GET_ALL, RESET} from './SkillsActions';

const initialState = [];

const SkillsReducer = (state = initialState, {type, payload}) => {
  switch (type) {
    case GET_ALL:
      return [...payload];

    case RESET:
      return [];

    case ERROR:
      return initialState;

    default:
      return state;
  }
};

export default SkillsReducer;