import React, {Component, Fragment} from 'react';
import M from "materialize-css";
import {Helmet} from "react-helmet";
import ImgChildsSafety from '../../../src/assets/images/safesitter.jpg';
import Image1 from "../../assets/images/thoroughbackgroundcheck.jpg";

class SafeSitterProcessComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    M.Parallax.init(this.Parallax1);
  }

  componentWillUnmount() {
    document.title = "Elite Nannies";
  }

  render() {
    return (
      <Fragment>
        <Helmet>
          <title>Elite Nanny Screening Process</title>
          <meta name="title" content="Elite Nanny Screening Process"/>
          <meta name="description"
                content="We ensure no stone is left unturned when we recruit our nannies. You can be assured that they are honest, reliable and utterly conscious of your child’s safety."/>
          <meta property="og:type" content="website"/>
          <meta property="og:url" content="https://www.elitenannies.com/family/screening-process"/>
          <meta property="og:title" content="Elite Nanny Screening Process"/>
          <meta property="og:description"
                content="We ensure no stone is left unturned when we recruit our nannies. You can be assured that they are honest, reliable and utterly conscious of your child’s safety."/>
          <meta property="og:image" content="https://www.elitenannies.com/static/media/safesitter.4fdbbe4b.jpg"/>
          <meta property="twitter:card" content="summary_large_image"/>
          <meta property="twitter:url" content="https://www.elitenannies.com/family/screening-process"/>
          <meta property="twitter:title" content="Elite Nanny Screening Process"/>
          <meta property="twitter:description"
                content="We ensure no stone is left unturned when we recruit our nannies. You can be assured that they are honest, reliable and utterly conscious of your child’s safety."/>
          <meta property="twitter:image" content="https://www.elitenannies.com/static/media/safesitter.4fdbbe4b.jpg"/>
        </Helmet>
        <div className="page-content">
          <SectionBanner/>
          <SectionChildsSafety/>
          <SectionThoroughBackground para1={Parallax => {
            this.Parallax1 = Parallax;
          }}/>
          <SectionSafeSittersProcess/>
        </div>
      </Fragment>
    )
  }
}

const SectionBanner = () => (
  <section className="page-banner section bg-lightdark">
    <div className="container">
      <div className="banner-caption">
        <div className="row wow fadeInUp">
          <div className="col-12">
            <div className="banner-text">
              <span>ELITE NANNY</span>
              <h2 className="heading page-heading">Screening Process</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

const SectionChildsSafety = () => (
  <section className="section py-lg-0">
    <div className="container-fluid">
      <div className="box">
        <div className="row align-items-center">
          <div className="col-12 col-md-12 col-lg-6 wow fadeIn">
            <div className="row">
              <div className="col-12 col-md-9 mx-md-auto col-xl-8 offset-xl-2 col-xll-6 offset-xll-3">
                <div className="mb-4 pb-4 pb-md-4 mb-lg-0 pl-xl-2 pl-xll-4">
                  <h3 className="sub-heading mb-4">There’s nothing more important than your child’s safety.</h3>
                  <p>We ensure no stone is left unturned when we recruit our nannies. You can be assured that they are
                    honest, reliable, trustworthy and utterly conscious of your child’s safety.</p>
                  <p>We conduct face-to-face interviews, thoroughly reference check candidates and conduct extensive background checks.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-12 px-md-0 col-lg-6 wow fadeInRight">
            <img src={ImgChildsSafety} className="img-fluid float-right" alt=""/>
          </div>
        </div>
      </div>
    </div>
  </section>
);

const SectionThoroughBackground = ({para1}) => (
  <section className="parallax-container section">
    <div ref={para1} className="parallax">
      <img src={Image1}/>
    </div>
    <div className="container">
      <div className="box">
        <div className="row align-items-center wow fadeInUp">
          <div className="col-12 col-lg-10 col-xl-7 col-xll-7 mx-auto">
            <div className="banner-text text-center px-xll-4">
              <h2 className="heading heading-36 text-white">Thorough Background Checks</h2>
              <p className="text-white">We run a complete background check with SSN trace, addresses, national criminal, & 7-year county criminal search, along with a motor vehicle report (if applicable and requested).</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

const SectionSafeSittersProcess = () => (
  <section className="section bg-light-5 mb-60">
    <div className="container px-xll-0">
      <div className="row wow fadeInUp">
        <div className="col-12 col-lg-7 col-xl-6 col-xll-5 mx-auto wow fadeInUp mb-5">
          <div className="banner-text text-center">
            <h2 className="heading heading-36">Our Screening Steps</h2>
            <p>We use the following process to ensure we are matching families with reliable, honest and nurturing
              caregivers. </p>
          </div>
        </div>
        <div className="w-100"></div>
        <div className="col-12 col-xl-8 col-xll-7 mx-auto wow fadeInUp">
          <ul className="list-group list-icon safesitter-step mt-4">
            <li className="list-group-item">
              <div>
                <h5>AN APPLICATION</h5>
                <p>Nannies {/* and Sitters*/} apply to Elite Nannies via our official, confidential sign up process, or
                  alternatively they can submit a formal application.</p>
              </div>
            </li>
            <li className="list-group-item">
              <div>
                <h5>PHONE INTERVIEW</h5>
                <p>Suitable candidates undergo a phone interview to discuss past nanny experience and additional
                  credentials.</p>
              </div>
            </li>
            <li className="list-group-item">
              <div>
                <h5>FACE-TO-FACE INTERVIEW</h5>
                <p>Here we really try and get to know each candidate. Whilst understanding that both may vary depending
                  on the situation, thorough character and personality evaluation is used to assess how candidates would
                  handle an array of situations.
                  Here we also ensure complete proficiency in English (reading, writing and speaking).</p>
              </div>
            </li>
            <li className="list-group-item">
              <div>
                <h5>REFERENCES VERIFICATION</h5>
                <p>References are thoroughly reviewed to evaluate candidate’s reliability and punctuality.</p>
              </div>
            </li>
            <li className="list-group-item">
              <div>
                <h5>SOCIAL MEDIA REVIEW</h5>
                <p>We review candidates’ social presence and past performance to make an educated future decision.</p>
              </div>
            </li>
            <li className="list-group-item">
              <div>
                <h5>BACKGROUND CHECK</h5>
                <p>All of our successful candidates are thoroughly background checked. (Available investigations include
                  - Criminal Background Investigation, DMV Driving Record, Social Security Number Verification, Sex
                  Offender Registry Check, Civil Restraining Orders Check, National Wants and Warrants).</p>
              </div>
            </li>
            <li className="list-group-item">
              <div>
                <h5>TRUSTLINE REGISTERED</h5>
                <p>Each candidate applying to work as a nanny in California is registered and fingerprinted with TrustLine upon hire - California’s screening program of in-home caregivers.</p>
              </div>
            </li>
            <li className="list-group-item">
              <div>
                <h5>CPR & FIRST AID</h5>
                <p>All candidates are CPR and First Aid certified upon hire.</p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
);

export default SafeSitterProcessComponent;
