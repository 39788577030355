import React, {Component, Fragment} from 'react';
import history from "../../helpers/history";
import {connect} from "react-redux";
import {Helmet} from "react-helmet";

class PlacementFeesComponent extends Component {

  handleClick = (e, titleProps) => {
    const {index} = titleProps;
    const {activeIndex} = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({activeIndex: newIndex})
  };

  handleGetStarted = () => {
    const {isLoggedIn} = this.props.user;
    if (isLoggedIn) {
      history.push('/family/preferences');
    } else {
      history.push('/family/registration');
    }
  };

  constructor(props) {
    super(props);
    this.state = {
      activeIndex: 0,
    };
  }

  componentWillUnmount() {
    document.title = "Elite Nannies";
  }

  render() {
    const {activeIndex} = this.state;

    return (
      <Fragment>
        <Helmet>
          <title>Elite Nanny Placement Package Fee</title>
          <meta name="title" content="Elite Nanny Placement Package Fee"/>
          <meta name="description"
                content="Our competitive and fair pricing packages ensures we can offer you the most exceptional elite nannies, cultivating respectful, happy and determined children."/>
          <meta property="og:type" content="website"/>
          <meta property="og:url" content="https://www.elitenannies.com/family/nanny-pricing"/>
          <meta property="og:title" content="Elite Nanny Placement Package Fee"/>
          <meta property="og:description"
                content="Our competitive and fair pricing packages ensures we can offer you the most exceptional elite nannies, cultivating respectful, happy and determined children."/>
          <meta property="og:image"
                content="https://www.elitenannies.com/static/media/hindilanguageteacher.4d78607d.jpg"/>
          <meta property="twitter:card" content="summary_large_image"/>
          <meta property="twitter:url" content="https://www.elitenannies.com/family/nanny-pricing"/>
          <meta property="twitter:title" content="Elite Nanny Placement Package Fee"/>
          <meta property="twitter:description"
                content="Our competitive and fair pricing packages ensures we can offer you the most exceptional elite nannies, cultivating respectful, happy and determined children."/>
          <meta property="twitter:image"
                content="https://www.elitenannies.com/static/media/hindilanguageteacher.4d78607d.jpg"/>
        </Helmet>
        <div className="page-content">
          <section className="page-banner section bg-lightdark mb-5">
            <div className="container">
              <div className="banner-caption">
                <div className="row">
                  <div className="col-12 col-lg-10 col-xl-9 mx-auto wow fadeInUp">
                    <div className="banner-text">
                      <h2 className="heading page-heading">Nanny Placement Package Fee</h2>
                    </div>
                    <div className="banner-text text-center px-xl-5 px-0 mt-4">
                      <p className="sub-heading font-normal px-0 px-xl-5">We endeavor to support you in cultivating
                        respectful, happy and determined children, which is why at Elite Nannies you will be offered
                        only the most exceptional candidates.
                      </p>
                      <p>We are placing for long-term (minimally one year commitment) and full-time (minimally 40 hours per week) nanny placements. Given the quality of candidates we refer to families, nannies are typically requesting $35-$40+ per hour, in addition to minimally two weeks of paid vacation time, paid holidays off, paid sick time, and a healthcare stipend.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container px-xll-0">
              <div className="row row-15 membership-card select-membership pricingpackages justify-content-center">
                {/* <div className="col-12 col-md-6 col-lg-4 col-xll-4 mb-4 wow fadeInUp">
                  <div className="select-membership-item">
                    <div className="membership-item">
                      <h4 className="membership-heading">Classic</h4>
                      <h5 className="membership-price">
                        15%
                        <span>Of Candidate’s Gross Annual Compensation</span>
                      </h5>
                      <div className="membership-divider"></div>
                      <p className="membership-content text-left">
                        We match you with an Elite Nanny at 15% of the Candidate’s Total Gross Annual Compensation.
                      </p>
                      <div className="text-left">
                        <h6>Your investment includes:</h6>
                        <ul className="list-investment">
                          <li>Comprehensive Criminal Background Investigation</li>
                          <li>Social Security Number Verification</li>
                          <li>Sex Offender Registry Check</li>
                          <li>Professional Employment Agreement</li>
                          <li>DMV Driving Record</li>
                          <li>Candidate replacement (one only), if needed, within 90 days from first day of employment.</li>
                        </ul>
                      </div>
                      <div className="packages-footer">
                        <button className="btn btn-outline-primary text-uppercase btn-lg btn-block"
                                onClick={this.handleGetStarted}>Get Started
                        </button>
                      </div>
                    </div>
                  </div>
                </div> */}
                <div className="col-12 col-md-6 col-lg-4 col-xll-4 mb-4 wow fadeInUp">
                  <div className="select-membership-item">
                    <div className="membership-item">
                      <h4 className="membership-heading">Elite</h4>
                      <h5 className="membership-price">
                        18%
                        <span>Of Candidate’s Gross Annual Compensation</span>
                      </h5>
                      <div className="membership-divider"></div>
                      <p className="membership-content text-left">
                        We match you with an Elite Nanny at 18% of the Candidate’s Total Gross Annual Compensation.
                      </p>
                      <div className="text-left">
                        <h6>Your investment includes:</h6>
                        <ul className="list-investment">
                          <li>Confidential Video or Phone Consultation</li>
                          <li>Customizable Job Description/Posting</li>
                          <li>Detailed Support Throughout Placement Process</li>
                          <li>Coordinated Family/Nanny Interviews</li>
                          <li>Extensive Reference Checking</li>
                          <li>Comprehensive Background Check (National & 7 Year County)</li>
                          <li>Motor Vehicle Report (if applicable and requested)</li>
                          <li>Professional Employment Agreement Template</li>
                          <li>Copy of CPR/First Aid Certification</li>
                          <li>Candidate Registration in TrustLine (for CA placements only)</li>
                          <li>Candidate Replacement (one only), if needed, within 180 days from Nanny’s First Day of Employment in Family’s Home</li>
                        </ul>
                      </div>
                      <div className="packages-footer">
                        <button className="btn btn-outline-primary text-uppercase btn-lg btn-block"
                                onClick={this.handleGetStarted}>Get Started
                        </button>
                        <p>
                          <small>Specialty searches may incur an additional fee.</small>  
                        </p>
                      </div>
                    </div>                   
                  </div>
                </div>
                {/* <div className="col-12 col-md-6 col-lg-4 col-xll-4 mb-4 wow fadeInUp">
                  <div className="select-membership-item">
                    <div className="membership-item">
                      <h4 className="membership-heading">Temp</h4>
                      <h5 className="membership-price">
                        $3,000
                        <span>One-Time Placement Fee</span>
                      </h5>
                      <div className="membership-divider"></div>
                      <p className="membership-content text-left">
                        Going on vacation? Looking for a short term engagement? We offer a temporary nanny placement
                        service. These placements usually occur between 1-4 months.
                      </p>
                      <div className="text-left">
                        <h6>Your investment includes:</h6>
                        <ul className="list-investment">
                          <li>Criminal Background Investigation</li>
                          <li>DMV Driving Record Check</li>
                          <li>Social Security Number Verification</li>
                          <li>Sex Offender Registry Check</li>
                        </ul>
                      </div>
                      <div className="packages-footer">
                        <button className="btn btn-outline-primary text-uppercase btn-lg btn-block"
                                onClick={this.handleGetStarted}>Get Started
                        </button>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
              {/*<div className="row mt-5">*/}
              {/*<div className="col-12 col-lg-10 col-xl-8 col-xll-6 mx-auto wow fadeInUp text-center">*/}
              {/*<p className="font-14 px-xl-4 px-0">In order to find you the perfect fit, we require a $500*/}
              {/*registration fee, and upon hire, we apply that registration fee to the referral fee. If you have any*/}
              {/*questions on pricing, do not hesitate to <a className="text-primary"*/}
              {/*href="tel:+1 424-343-3783"> call.</a></p>*/}
              {/*</div>*/}
              {/*</div>*/}
            </div>
          </section>
        </div>
      </Fragment>
    )
  }
}

const MapStateToProps = (state) => {
  return {
    user: state.user
  }
};

export default connect(MapStateToProps, null)(PlacementFeesComponent);
