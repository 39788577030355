import React, {Component} from 'react';
import classnames from 'classnames';
import config from '../config';

// Avatar Image
import avatar from '../assets/images/svg/avatar.svg';

class Thumbnail extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      thumb: undefined,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.file) {
      return;
    }

    this.setState({loading: true}, () => {
      let reader = new FileReader();

      reader.onloadend = () => {
        this.setState({loading: false, thumb: reader.result});
      };

      reader.readAsDataURL(nextProps.file);
    });
  }

  render() {
    const {file, source, tooltip} = this.props; // for external file
    const {loading, thumb} = this.state; // created thumb of the selected file

    let imageHolder = null;
    const InputClasses = classnames(
        'img-fluid',
        this.props.className,
        {
          'file-selected': !!thumb || !!source
        }
    );
    if (loading) {
      return <p>loading...</p>;
    }

    if(source) {
      imageHolder = <img src={`${config.api.URL}${source}`} style={{height:this.props.height, width: this.props.width}} className={InputClasses} alt="avatar" title={tooltip} />;
    }

    if (!file && !source) {
      imageHolder = <img src={avatar} style={{height:this.props.height,width: this.props.width}} className={InputClasses} alt="avatar" title="Please select a profile picture" />;
    }

    if(thumb) {
      imageHolder = <img src={thumb} style={{height:this.props.height, width: this.props.width}}  className={InputClasses} alt="avatar" title="Click to change" />
    }

    return imageHolder;
  }
}

export default Thumbnail;