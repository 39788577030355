import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import LocationGray from "../../assets/images/svg/location-gray.svg";
import { Helmet } from "react-helmet";
import { Select } from "semantic-ui-react";
import { JOB_LOCATION_CITIES } from "../../helpers/constants";
import { GET } from "../../services/rest.service";
import moment from "moment";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import placeholder from "../../assets/images/search-placeholder.svg";
import attachment from "../../assets/images/svg/attachment.svg";
import TextInput from "../../components/UI/TextInput";
import ReactTelInput from "react-telephone-input";
import flag from "../../assets/images/flags.png";
import { POST } from "../../services/rest.service";
import { toast } from "react-toastify";
import { convertJsonToFormData } from "../../helpers/helper";
import { Formik } from "formik";
import { object, string } from "yup";
import history from "../../helpers/history";
import PageLoader from "../../components/PageLoader";
class JobListingsComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      location: "All",
      jobs: null,
      applyModal: false,
      modalData: {},
      name: "",
      email: "",
      phone: "",
      resume: {},
      cover: {},
      htmlData: "",
      showindetails: false,
      id: ""
    };
  }
  toggleApplyModal = x => {
    const { user } = this.props;
    this.setState(prevState => ({
      applyModal: !prevState.applyModal,
      modalData: prevState.applyModal ? {} : x,
      name: prevState.applyModal
        ? ""
        : user.isLoggedIn
        ? user.user.first_name + " " + user.user.last_name
        : "",
      email: prevState.applyModal ? "" : user.isLoggedIn ? user.user.email : "",
      phone: prevState.applyModal
        ? ""
        : user.isLoggedIn
        ? user.user.mobile_number
        : "",
      resume: {},
      cover: {}
    }));
  };
  componentWillUnmount() {
    document.title = "Elite Nannies";
  }
  componentDidMount() {
    this.getDataByState();
  }

  getDataByState = () => {
    let stateName = this.props.match.params.type;
    if (stateName) {
      this.setState(
        {
          location: stateName
            .replace(/-/g, " ")
            .split(" ")
            .map(a => a.charAt(0).toUpperCase() + a.slice(1))
            .join(" ")
        },
        () => {
          this.getPage(1);
        }
      );
    } else {
      this.getPage(1);
    }
  };
  componentDidUpdate(prevProps) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.getDataByState();
    }
  }

  getPage = page => {
    GET(`/public/job-posting/get/${page}?city=${this.state.location}`).then(
      res => {
        this.setState({
          jobs: res.data.Jobs
        });
      }
    );
  };

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };
  handleChangePhoneNumber = e => {
    this.setState({
      phone: e
    });
  };

  handleResumeChange = e => {
    const document = e.target.files[0];
    const payload = convertJsonToFormData({ skill_document: document });
    POST("/auth/user/nannyFileUpload", payload)
      .then(res => {
        this.setState({
          resume: {
            mimeType: res.data.document.mimeType,
            name: res.data.document.name,
            filename: document.name
          }
        });
      })
      .catch(err => {
        toast.error(err.message);
      });
  };

  handleCoverChange = e => {
    const document = e.target.files[0];
    const payload = convertJsonToFormData({ skill_document: document });
    POST("/auth/user/nannyFileUpload", payload)
      .then(res => {
        this.setState({
          cover: {
            mimeType: res.data.document.mimeType,
            name: res.data.document.name,
            filename: document.name
          }
        });
      })
      .catch(err => {
        toast.error(err.message);
      });
  };

  handleForm = values => {
    if (
      this.state.resume.name === undefined &&
      this.state.resume.mimeType === undefined
    ) {
      return toast.error("Please Provide Resume");
    }
    // if (
    //   this.state.cover.name === undefined &&
    //   this.state.cover.mimeType === undefined
    // ) {
    //   return toast.error("Please Provide Cover Letter");
    // } 
    else {
      const payload = {
        jobPosting: this.state.modalData && this.state.modalData._id,
        name: values.name,
        email: values.email,
        phone: values.phone,
        resume: {
          mimeType: this.state.resume.mimeType,
          name: this.state.resume.name
        },
        coverLetter: {
          mimeType: this.state.cover.mimeType || '',
          name: this.state.cover.name|| ''
        }
      };

      POST("/job-application/create", payload)
        .then(res => {
          // toast.success("Your Application is Successfully Submitted!")
          this.toggleApplyModal();
          history.push("/nanny/jobs-thankyou");
        })
        .catch(err => {
          toast.error(err);
        });
    }
  };

  showinDetails = (data, x) => {
    this.setState({
      id: x._id,
      showindetails: true
    });
  };

  render() {
    const { jobs } = this.state;
    if (!jobs) {
      return <PageLoader />;
    }
    const title =
      this.state.location === "All"
        ? "New York & Los Angeles"
        : this.state.location;

    return (
      <Fragment>
        <div className="page-content">
          <Helmet>
            <title>{`Nanny Jobs ${title}`}</title>
            <meta name="title" content={`Nanny Jobs ${title}`} />
            <meta
              name="description"
              content={`High paid nanny jobs in ${title} with high profile and loving families. Find part-time or full-time child care jobs. Elite Nannies provide Elite Jobs with Elite Families - apply for free today! `}
            />
            <meta property="og:type" content="website" />
            <meta property="og:url" content={window.location.href} />
            <meta property="og:title" content={`Nanny Jobs ${title}`} />
            <meta
              property="og:description"
              content={`High paid nanny jobs in ${title} with high profile and loving families. Find part-time or full-time child care jobs. Elite Nannies provide Elite Jobs with Elite Families - apply for free today! `}
            />
            <meta
              property="og:image"
              content="https://www.elitenannies.com/static/media/roxanne-dominic.8f38b02b.jpg"
            />
            <meta property="twitter:card" content="summary_large_image" />
            <meta property="twitter:url" content={window.location.href} />
            <meta property="twitter:title" content={`Nanny Jobs ${title}`} />
            <meta
              property="twitter:description"
              content={`High paid nanny jobs in ${title} with high profile and loving families. Find part-time or full-time child care jobs. Elite Nannies provide Elite Jobs with Elite Families - apply for free today! `}
            />
            <meta
              property="twitter:image"
              content="https://www.elitenannies.com/static/media/roxanne-dominic.8f38b02b.jpg"
            />
          </Helmet>
          <section className="page-banner section bg-lightdark">
            <div className="container">
              <div className="banner-caption">
                <div className="row">
                  <div className="col-12 col-xl-8 col-xll-7 mx-auto">
                    <div className="banner-text">
                      <h2 className="heading page-heading">Nanny Jobs</h2>
                      <h3 className="sub-heading my-2">
                        Do you have what it takes to be an Elite Nanny?
                      </h3>
                      <p className="mb-0 px-xll-2">
                        If you are an experienced nanny, qualified in a specialty
                        field, and have a love for children, <b><Link to={'/nanny/registration'}>apply now</Link></b> to join
                        the Elite!
                      </p>
                    </div>
                  </div>
                  <div className="w-100"></div>
                  <div className="col-12 col-md-7 col-xl-5 col-xll-3 mx-auto">
                    <div className="banner-text mt-5">
                      <div className="form-group">
                        {/* <label className="form-label font-weight-bold">Job Location</label> */}
                        <Select
                          value={this.state.location}
                          selection
                          options={JOB_LOCATION_CITIES}
                          onChange={(e, { value }) => {
                            this.setState(
                              {
                                location: value
                              },
                              () => {
                                // this.getPage(1);
                                if (this.state.location !== "All") {
                                  history.push(
                                    `/nanny/jobs/${this.state.location
                                      .replace(/ /g, "-")
                                      .toLowerCase()}`
                                  );
                                } else {
                                  history.push(`/nanny/jobs`);
                                }
                              }
                            );
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="section pt-lg-0">
            <div className="container">
              <div className="row wow fadeInUp">
                <div className="col-12 col-lg-10 col-xl-8 col-xll-7 mx-auto">
                  <div className="joblist">
                    {jobs.length > 0 ? (
                      jobs.map((x, index) => (
                        <div className="joblist-item" key={index}>
                          <div className="d-flex justify-content-between">
                            {/*  <p className="post-date">
                              Posted:{" "}
                              <strong>
                                {moment(x.createdAt).format("MMMM Do YYYY")}
                              </strong>
                            </p>  */}
                            <p className="post-date">
                              Status:{" "}
                              <strong
                                className={
                                  x.status === "Open"
                                    ? "text-status-success"
                                    : "text-status-filled"
                                }
                              >
                                {x.status}
                              </strong>
                            </p>
                          </div>
                          <h2
                            className="post-title c-pointer text-hover-primary"
                            onClick={() => {
                              history.push(`/nanny/job-posting/${x.slug}`);
                            }}
                          >
                            {x.title}
                          </h2>
                          <ul className="post-list list-inline">
                            <li className="list-inline-item">
                              <span>
                                <img
                                  src={LocationGray}
                                  width="18"
                                  height="18"
                                  alt="location img"
                                  className="mr-2"
                                />
                              </span>
                              {x.location && x.location.address
                                ? x.location.address.split(",")[0] +
                                  ", " +
                                  x.location.address.split(",")[1]
                                : ""}
                            </li>
                            <li className="list-inline-item"> {x.type}</li>
                            {x.status ==="Open" && moment().isSameOrBefore(moment(x.approxStartDate)) &&   <li className="list-inline-item"> Start Date:  <strong>
                                {moment(x.approxStartDate).format("MMMM Do YYYY")}
                              </strong></li>}
                          </ul>
                          {this.state.showindetails &&
                          this.state.id === x._id ? (
                            <Fragment>
                              <div
                                className="post-text"
                                dangerouslySetInnerHTML={{
                                  __html: x.jobDescription
                                }}
                              />
                              <span
                                className="d-block c-pointer text-primary font-bold"
                                onClick={() => {
                                  this.setState({
                                    showindetails: false,
                                    id: ""
                                  });
                                }}
                              >
                                Read less
                              </span>
                            </Fragment>
                          ) : (
                            <Fragment>
                              <div
                                className="post-text"
                                dangerouslySetInnerHTML={{
                                  __html:
                                    x.jobDescription.substring(0, 450) +
                                    (x.jobDescription.length > 450 ? "..." : "")
                                }}
                              />
                              {x.jobDescription.length > 450 && (
                                // this.showinDetails(x.jobDescription,x)  onClick={() => { this.showinDetails(x.jobDescription,x) }}

                                <span
                                  className="d-block c-pointer text-primary font-bold"
                                  onClick={() => {
                                    history.push(
                                      `/nanny/job-posting/${x.slug}`
                                    );
                                  }}
                                >
                                  Read more
                                </span>
                              )}
                            </Fragment>
                          )}

                          <div className="post-action">
                            <p className="post-rate">
                              Rate: <strong>{x.rate}</strong>
                            </p>

                            {x.status !== "Filled" && (
                              <button
                                className="btn btn-primary text-white text-uppercase btn-lg btn-250"
                                onClick={() => {
                                  this.toggleApplyModal(x);
                                }}
                              >
                                Apply Now
                              </button>
                            )}
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="row">
                        <div className="col-12 col-lg-9 col-xl-8 mx-auto">
                          <div className="my-5 text-center">
                            <img
                              className="img-fluid img-middle"
                              src={placeholder}
                              alt="no-results-found"
                            />
                            <h4 className="sub-heading text-uppercase mt-4">
                              WE CURRENTLY HAVE NO JOB POSTING IN THE{" "}
                              <span className="text-primary">
                                {this.state.location}
                              </span>{" "}
                              REGION.
                            </h4>
                            <p>
                              However, please check back at a later time or{" "}
                              <br /> <Link to={"/contact-us"}>contact us</Link>{" "}
                              to discuss options.
                            </p>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

        <Modal isOpen={this.state.applyModal} centered={true}>
          <ModalHeader
            className="modal-title font-18 py-3"
            toggle={this.toggleApplyModal}
          >
            {this.state.modalData && this.state.modalData.type} Nanny
            <small className="d-block">
              {this.state.modalData &&
              this.state.modalData.location &&
              this.state.modalData.location.address
                ? this.state.modalData.location.address.split(",")[0] +
                  ", " +
                  this.state.modalData.location.address.split(",")[1]
                : ""}
            </small>
          </ModalHeader>
          <ModalBody className="modal-body">
            <Formik
              initialValues={{
                name: this.state.name,
                email: this.state.email,
                phone: this.state.phone
              }}
              onSubmit={values => {
                this.handleForm(values);
              }}
              validationSchema={object().shape({
                email: string()
                  .email("Please enter your valid email")
                  .required("Please enter your email"),
                name: string().required("Please enter your name"),
                phone: string().required("Please enter your phone number")
              })}
            >
              {renderProps => {
                const {
                  values,
                  touched,
                  errors,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                  setFieldTouched,
                  setFieldError
                } = renderProps;

                return (
                  <form onSubmit={handleSubmit}>
                    <div className="form-group">
                      <label className="form-label">Name</label>
                      <TextInput
                        id="name"
                        className="form-control"
                        type="text"
                        placeholder="Please enter name"
                        error={touched.name && errors.name}
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                    <div className="form-group">
                      <label className="form-label">Email</label>
                      <TextInput
                        id="email"
                        type="email"
                        className="form-control"
                        placeholder="Please enter email"
                        error={touched.email && errors.email}
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>

                    <div className="form-group">
                      <label className="form-label">Phone Number</label>
                      <ReactTelInput
                        defaultCountry="us"
                        value={values.phone}
                        flagsImagePath={flag}
                        onChange={e => setFieldValue("phone", e)}
                        onBlur={value => {
                          setFieldTouched("phone", true);
                          setFieldError("phone", value.error);
                        }}
                      />
                      {errors.phone && touched.phone && (
                        <small className="text-danger">{errors.phone}</small>
                      )}
                    </div>

                    <div className="form-group form-group-btn">
                      <label className="form-label">Resume</label>
                      <div className="input-group append-left">
                        <div className="input-group-append">
                          <button
                            className="btn btn-clear btn-lg text-primary px-4"
                            type="button"
                          >
                            <img
                              width="15"
                              height="19"
                              src={attachment}
                              alt="attachment"
                            />
                          </button>
                        </div>
                        <input
                          type="file"
                          className="form-control d-none"
                          name="other"
                          id="Resume"
                          onChange={this.handleResumeChange}
                        />

                        <label htmlFor="Resume" className="form-control">
                          {" "}
                          <span className="d-inline-block pt-1">
                            {this.state.resume && this.state.resume.filename
                              ? this.state.resume.filename
                              : "Upload your Resume"}
                          </span>
                        </label>
                      </div>
                    </div>

                    <div className="form-group form-group-btn">
                      <label className="form-label">Cover Letter (Optional)</label>
                      <div className="input-group append-left">
                        <div className="input-group-append">
                          <button
                            className="btn btn-clear btn-lg text-primary px-4"
                            type="button"
                          >
                            <img
                              width="15"
                              height="19"
                              src={attachment}
                              alt="attachment"
                            />
                          </button>
                        </div>
                        <input
                          type="file"
                          className="form-control d-none"
                          name="other"
                          id="CoverLetter"
                          onChange={this.handleCoverChange}
                        />
                        <label htmlFor="CoverLetter" className="form-control">
                          {" "}
                          <span className="d-inline-block pt-1">
                            {this.state.cover && this.state.cover.filename
                              ? this.state.cover.filename
                              : "Upload your Cover Letter"}
                          </span>
                        </label>
                      </div>
                    </div>
                    <div className="mt-2 text-right">
                      <button
                        type="button"
                        data-dismiss="modal"
                        className="btn btn-default d-none d-lg-inline-block mb-2 text-uppercase"
                        onClick={this.toggleApplyModal}
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="btn btn-primary text-white ml-lg-3 mb-2 text-uppercase"
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                );
              }}
            </Formik>
          </ModalBody>
        </Modal>
      </Fragment>
    );
  }
}

const MapStateToProps = state => {
  return {
    user: state.user
  };
};

export default connect(
  MapStateToProps,
  null
)(JobListingsComponent);
